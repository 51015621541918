import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import DropdownWidget3 from '../dropdown-widgets/dropdown-widget-3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faGripVertical,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import UserImg from '../user/UserImg';
import * as Icon from 'react-feather';
import actions from '../../services/redux/actions';
import { useRouter } from 'next/router';
import { CircularBadge } from '../badges';
import useLocalStorage from '../../hooks/useLocalStorage';
import FluentIcon from '../fluenticons/FluentIcon';
import {
  fiDashboard,
  fiExplore,
  fiHome,
  fiLogout,
  fiProfile,
} from '../fluenticons';
import Button from '../action/Button';
import VerifiedBadge from '../badges/VerifiedBadge';

const Navbar = ({ title, mod }) => {
  const router = useRouter();
  const { logo } = useSelector(
    (state) => ({
      logo: state.ui.logo,
    }),
    shallowEqual
  );

  const { direction } = useSelector(
    (state) => ({
      direction: state.ui.direction,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [admin_access, setAdminAccess] = useLocalStorage('admin_access');
  const [admin_refresh, setAdminRefresh] = useLocalStorage('admin_refresh');

  const logout = actions.user.logout
    ? async () => {
        setAdminAccess(null);
        setAdminRefresh(null);
        await dispatch(actions.user.logout());
        window.location.href = '/';
        // router.push('/login');
      }
    : () => {};

  const { user, ui } = useSelector(
    (state) => ({
      user: state.user,
      ui: state.ui,
    }),
    shallowEqual
  );

  const [hidden, setHidden] = useState(true);
  const buttonRef = React.createRef();
  const dropdownRef = React.createRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        (buttonRef.current && buttonRef.current.contains(event.target)) ||
        (dropdownRef.current && dropdownRef.current.contains(event.target))
      ) {
        return false;
      }
      setHidden(!hidden);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef, buttonRef]);
  const [tabs, setTabs] =
    mod === 'Explore'
      ? useState('explore')
      : mod === 'None'
      ? useState('none')
      : useState('dashboard');

  const items = [
    {
      name: 'Profile',
      url: '/profile',
      badge: false,
      icon: <FluentIcon icon={fiProfile} />,
    },
    // {
    //   url: '/help',
    //   icon: <Icon.HelpCircle size={16} />,
    //   name: 'Help',
    //   badge: false,
    // },
    {
      url: '/logout',
      icon: <FluentIcon icon={fiLogout} />,
      name: 'Logout',
      badge: false,
      onClick: logout,
    },
  ];

  const login = () => {
    router.push('/login');
  };

  return (
    <div
      className={`w-full border-b-1 border-gray-300 bg-white flex fixed z-40 top-0`}
    >
      <div className="logoa sm:w-20 lg:w-80 pl-2 md:pl-6">
        <Link href={user && user.id ? '/dashboard' : '/'}>
          <a
            href={user && user.id ? '/dashboard' : '/'}
            className="flex flex-row items-center children-x-1"
          >
            <img
              src={logo || '/64x64.png'}
              alt="image"
              className="h-16 rounded"
            />
            {/*<span className="hidden sm:block font-bold text-lg">{ui.name}</span>*/}
          </a>
        </Link>
      </div>
      <div className="flex-1 w-full sm:flex h-full md:px-6">
        <div className="hidden md:flex pt-5">
          {user && user.id ? (
            <div
              className="sm:mr-5 lg:mr-12 h-full"
              onClick={() => setTabs('dashboard')}
            >
              <Link href="/dashboard">
                <a
                  className={`relative pb-4 font-bold text-lg ${
                    tabs === 'dashboard'
                      ? 'border-brand-base text-brand-base border-b-4'
                      : ''
                  }`}
                >
                  {/*<FontAwesomeIcon*/}
                  {/*  className={`${*/}
                  {/*    tabs === 'dashboard'*/}
                  {/*      ? 'border-brand-base text-brand-base'*/}
                  {/*      : 'text-gray-500'*/}
                  {/*  }`}*/}
                  {/*  icon={faGripVertical}*/}
                  {/*/>*/}
                  <FluentIcon icon={fiDashboard} />
                  <span className="ml-2">Dashboard</span>
                </a>
              </Link>
            </div>
          ) : (
            <div
              className="sm:mr-5 lg:mr-12 h-full"
              onClick={() => setTabs('dashboard')}
            >
              <Link href="/">
                <a
                  href="/"
                  className={`relative pb-4 font-bold text-lg ${
                    tabs === 'dashboard'
                      ? 'border-brand-base text-brand-base border-b-4'
                      : ''
                  }`}
                >
                  {/*<FontAwesomeIcon*/}
                  {/*  className={`${*/}
                  {/*    tabs === 'dashboard'*/}
                  {/*      ? 'border-brand-base text-brand-base'*/}
                  {/*      : 'text-gray-500'*/}
                  {/*  }`}*/}
                  {/*  icon={faGripVertical}*/}
                  {/*/>*/}
                  <FluentIcon icon={fiHome} />
                  <span className="ml-2">Home</span>
                </a>
              </Link>
            </div>
          )}
          <div onClick={() => setTabs('explore')}>
            <Link href="/explore">
              <a
                className={`relative pb-4 font-bold text-lg ${
                  tabs === 'explore'
                    ? 'border-brand-base text-brand-base border-b-4'
                    : ''
                }`}
              >
                {/*<FontAwesomeIcon*/}
                {/*  className={`${*/}
                {/*    tabs === 'explore' ? ' text-brand-base' : 'text-gray-500'*/}
                {/*  }`}*/}
                {/*  icon={faSearch}*/}
                {/*/>*/}
                <FluentIcon icon={fiExplore} />
                <span className="ml-2">Explore</span>
              </a>
            </Link>
          </div>
        </div>
        <div className="flex flex-1 pr-3 md:pr-0">
          <div className="items-center flex flex-1">
            <div className="flex-1 invisible bg-gray-200 flex items-center py-2 pl-2 rounded-md w-full">
              <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
              <input
                type="text"
                className="hidden bg-transparent outline-none pl-2"
                placeholder="Search"
              />
            </div>
            <div className="flex gap-2 hidden">
              <div className="hidden sm:inline-block">sm</div>
              <div className="hidden md:inline-block">md</div>
              <div className="hidden lg:inline-block">lg</div>
              <div className="hidden xl:inline-block">xl</div>
            </div>
            <div className="mr-4">
              {user && user.first_name && <DropdownWidget3 />}
            </div>
            {!user ||
              (!user.id && (
                <div>
                  <Button onClick={login} className="mt-3">
                    Login
                  </Button>
                </div>
              ))}
          </div>
          <div
            className={`${
              user && user.name ? '' : 'hidden'
            } items-center flex rounded-25 px-2 md:px-4 my-2 cursor-pointer customShadow`}
            onClick={() => setHidden(!hidden)}
          >
            <div className="flex flex-row gap-3 items-center ">
              <div className="flex-initial">
                <div className="h-8 w-8 rounded-full overflow-hidden">
                  <UserImg
                    image={user.image}
                    email={user.email}
                    src={user.image}
                    name={user.name}
                    gender={user.gender}
                    alt={
                      user.user
                        ? user.user.id
                        : user.user_id
                        ? user.user_id
                        : user.name
                    }
                    isBrand={user.type === 'brand'}
                    isAgency={user.type === 'agency'}
                    className={`h-8 w-8 rounded-full`}
                  />
                </div>
              </div>
              <div className="hidden md:block flex-initial">
                <p className="font-bold text-sm whitespace-no-wrap flex flex-row items-center">
                  {user.name}
                  <VerifiedBadge verified={user.verified} />
                </p>
                {/*<p className="text-xs text-gray-500">{user.type_display}</p>*/}
              </div>
            </div>
            <div className="cursor-pointer relative">
              <button
                ref={buttonRef}
                className="hidden flex relative border-brand-base hover:border-white md:border-gray-100 md:hover:border-brand-base "
              >
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className="text-gray-500 text-lg ml-2"
                />
              </button>
              <div
                ref={dropdownRef}
                style={{ right: '-8px', top: '30px', boxShadow: 'none' }}
                className={`navbar-dropdowns dropdown backdrop-blur bg-whitex ${
                  direction === 'ltr' ? 'dropdown-right' : 'dropdown-left'
                } w-48 ${hidden ? '' : 'open'}`}
              >
                <div className="w-48 dropdown-contents">
                  <div className="flex flex-col w-full">
                    {items.map((item, i) => {
                      const lprops = { key: i };
                      if (item.onClick) {
                        // lprops.onClick = item.onClick;
                        lprops.href = item.url || '/';
                      } else if (item.url) {
                        lprops.href = item.url || '/';
                      }

                      const handleClick = (item, e) => {
                        if (item.onClick) {
                          e.preventDefault();
                          item.onClick();
                        }
                      };

                      return (
                        <Link key={i} {...lprops}>
                          <a
                            className="w-full flex items-center justify-start px-4 py-2 font-bold bg-white m-2 customShadow text-sm children-x-2 rounded-10px "
                            onClick={handleClick.bind(this, item)}
                          >
                            {item.icon}
                            <span className="text-center flex-1">
                              {item.name}
                            </span>
                            {item.badge && (
                              <span className="ltr:ml-auto rtl:mr-auto">
                                <CircularBadge
                                  size="sm"
                                  color={item.badgeColor}
                                >
                                  {item.badgeNumber}
                                </CircularBadge>
                              </span>
                            )}
                          </a>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
