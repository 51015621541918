import { useRouter } from 'next/router';
import Button from '../action/Button';
import React from 'react';

export default function NoCampaigns({ url, text }) {
  const router = useRouter();
  const explore = () => {
    router.push(url);
  };

  return (
    <div className="w-full">
      <div className="text-center">
        {/*<div className="mt-5 mb-5">No Campaigns Found</div>*/}
        <img
          src="/images/illustration/no_influencers_brand.png"
          width="50%"
          className="m-auto"
          style={{ maxWidth: '300px' }}
        />
      </div>
      {text && (
        <div className="text-center mt-4">
          <Button onClick={explore}>{text}</Button>
        </div>
      )}
    </div>
  );
}
