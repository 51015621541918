import { create } from 'zustand';
import { client } from '../api';
import authMiddleware from '../redux/middleware/authMiddleware';
import moment from 'moment';
import { save } from '../redux/modules/user';
import { toast } from 'react-toastify';

export const useChat = create((set, get) => ({
  count: 0,
  chats: [],
  chatDetails: [],
  chatUsers: [],
  search: "",
  selectedChat: null,
  // markChatRead: async (id) => {
    // const chats = await clientLoader(set, '/inflosocampaigns/chat/read/' + id, {method: "PUT"});
    // const chats = [{influencer_id: 1}, {influencer_id: 2}, {influencer_id:3}]
  // },
  fetchChatCount: async (brand_id) => {
    const chats = await clientLoader(set, '/inflosocampaigns/chat/count/' + brand_id);
    // const chats = [{influencer_id: 1}, {influencer_id: 2}, {influencer_id:3}]
    if(chats && chats.length > 0){
      set({count:chats[0].unread});
    }else{
      set({count:0});
    }
  },
  fetchInfluencerChatCount: async (influencer_id) => {
    const chats = await clientLoader(set, '/inflosocampaigns/chat/influencer/count/' + influencer_id);
    // const chats = [{influencer_id: 1}, {influencer_id: 2}, {influencer_id:3}]
    if(chats && chats.length > 0){
      set({count:chats[0].unread});
    }else{
      set({count:0});
    }

  },
  fetchChatList: async (brand_id) => {
    const chats = await clientLoader(set, '/inflosocampaigns/chat/list/' + brand_id);
    // const chats = [{influencer_id: 1}, {influencer_id: 2}, {influencer_id:3}]
    set({
      chats,
      selectedChat: chats && chats.length > 0 ? chats[0] : null,
    });
  },
  fetchInfluencerChatList: async (influencer_id) => {
    const chats = await clientLoader(set, '/inflosocampaigns/chat/influencer/list/' + influencer_id);
    // const chats = [{influencer_id: 1}, {influencer_id: 2}, {influencer_id:3}]
    set({
      chats,
      selectedChat: chats && chats.length > 0 ? chats[0] : null,
    });
  },
  fetchChat: async (brand_id, influencer_id, campaign_id, data) => {
    const chatDetails = await clientLoader(set, '/inflosocampaigns/chat/list/' + brand_id + '/' + influencer_id + '/' + campaign_id);
    set({chatDetails})
    return chatDetails;
  },
  fetchInfluencerChat: async (brand_id, influencer_id, campaign_id, data) => {
    const chatDetails = await clientLoader(set, '/inflosocampaigns/chat/influencer/list/' + brand_id + '/' + influencer_id + '/' + campaign_id);
    set({chatDetails})
    return chatDetails;
  },
  searchChatUser: async (brand_id, type, q) => {
    set({search:q});
    const chatUsers = await clientLoader(set, '/inflosocampaigns/chat/' + (type === 'influencer' ? 'influencer/' : '' ) + 'search/' + brand_id + '?q=' + q);
    set({chatUsers});
    return chatUsers;
  },
  // searchInfluencerChatUser: async (influencer_id, q) => {
  //   set({search:q});
  //   const chatUsers = await clientLoader(set, '/inflosocampaigns/chat/influencer/search/' + influencer_id + '?q=' + q);
  //   set({chatUsers});
  //   return chatUsers;
  // },
  selectChat: (coupon) => {
    const data = get();
    if( data.selectedChat !== coupon){
      set({ selectedChat: coupon, chatDetails:[] });
    }
  },
  clearSelection: () => {
    set({ selectedChat: null, chatDetails: [] });
  },
  sendChat: async (application_id, sender, message) => {

    const data = {
      "application_id":application_id,
      "sender":sender,
      "message":message
    };
    const coupons = await clientLoader(
      set,
      `/inflosocampaigns/campaign-application-chat/${data.application_id}`,
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    );
    return coupons;
  }
}));

const clientLoader = async (set, url, params = {}, authenticated = true) => {
  return new Promise(async (resolve, reject) => {
    set({ loading: true });
    try {
      const data = await client(url, params, authenticated);
      set({ loading: false });
      resolve(data);
    } catch (e) {
      set({ loading: false });
      resolve({ error: e.message });
    }
  });
};
