import {
  BARTER_PRICING,
  calcBudgetPerInfluencer,
  diffArray,
  markFailure,
  markLoading,
  markSuccess,
  VISITS_PRICING,
} from '../../util';
import {
  parseCampaignApplicationData,
  parseCampaignApplicationDataES,
  parseCampaignCriteria,
  parseCampaignData,
  parseEachCampaignApplicationData,
  parseEachCampaignData,
  reverseCampaignCriteria,
} from '../../mapping';
import moment from 'moment';
import { LOGOUT_SUCCESS } from './user';
import { search } from '../../es';
import { getPlatform, getSocialContents } from '../../../platforms';
import { DEFAULT_PRICING } from '../../util';
import {
  calcCampaignBudgetBreakdown as utilCalcCampaignBudgetBreakdown,
  calcCampaignBudget as utilCalcCampaignBudget,
} from '../../util';
import { InfluencersHired } from '../../../pages/dashboard/influencer';

export const LOAD_CAMPAIGN_REQUEST = 'campaign/LOAD_CAMPAIGN_REQUEST';
export const LOAD_CAMPAIGN_SUCCESS = 'campaign/LOAD_CAMPAIGN_SUCCESS';
export const LOAD_CAMPAIGN_FAILURE = 'campaign/LOAD_CAMPAIGN_FAILURE';

export const LOAD_MY_CAMPAIGN_REQUEST = 'campaign/LOAD_MY_CAMPAIGN_REQUEST';
export const LOAD_MY_CAMPAIGN_SUCCESS = 'campaign/LOAD_MY_CAMPAIGN_SUCCESS';
export const LOAD_MY_CAMPAIGN_FAILURE = 'campaign/LOAD_MY_CAMPAIGN_FAILURE';

export const LOAD_CAMPAIGN_MODES_REQUEST =
  'campaign/LOAD_CAMPAIGN_MODES_REQUEST';
export const LOAD_CAMPAIGN_MODES_SUCCESS =
  'campaign/LOAD_CAMPAIGN_MODES_SUCCESS';
export const LOAD_CAMPAIGN_MODES_FAILURE =
  'campaign/LOAD_CAMPAIGN_MODES_FAILURE';

export const CAMPAIGN_DATA_REQUEST = 'campaign/CAMPAIGN_DATA_REQUEST';
export const CAMPAIGN_DATA_SUCCESS = 'campaign/CAMPAIGN_DATA_SUCCESS';
export const CAMPAIGN_DATA_FAILURE = 'campaign/CAMPAIGN_DATA_FAILURE';

export const CAMPAIGN_SAVE_REQUEST = 'campaign/CAMPAIGN_SAVE_REQUEST';
export const CAMPAIGN_SAVE_SUCCESS = 'campaign/CAMPAIGN_SAVE_SUCCESS';
export const CAMPAIGN_SAVE_FAILURE = 'campaign/CAMPAIGN_SAVE_FAILURE';

export const CAMPAIGN_ADD_MONEY_REQUEST = 'campaign/CAMPAIGN_ADD_MONEY_REQUEST';
export const CAMPAIGN_ADD_MONEY_SUCCESS = 'campaign/CAMPAIGN_ADD_MONEY_SUCCESS';
export const CAMPAIGN_ADD_MONEY_FAILURE = 'campaign/CAMPAIGN_ADD_MONEY_FAILURE';

export const CAMPAIGN_COST_PER_INFLUENCER_REQUEST =
  'campaign/CAMPAIGN_COST_PER_INFLUENCER_REQUEST';
export const CAMPAIGN_COST_PER_INFLUENCER_SUCCESS =
  'campaign/CAMPAIGN_COST_PER_INFLUENCER_SUCCESS';
export const CAMPAIGN_COST_PER_INFLUENCER_FAILURE =
  'campaign/CAMPAIGN_COST_PER_INFLUENCER_FAILURE';

export const CAMPAIGN_DELETE_REQUEST = 'campaign/CAMPAIGN_DELETE_REQUEST';
export const CAMPAIGN_DELETE_SUCCESS = 'campaign/CAMPAIGN_DELETE_SUCCESS';
export const CAMPAIGN_DELETE_FAILURE = 'campaign/CAMPAIGN_DELETE_FAILURE';

export const CAMPAIGN_CRITERIA_SAVE_REQUEST =
  'campaign/CAMPAIGN_CRITERIA_SAVE_REQUEST';
export const CAMPAIGN_CRITERIA_SAVE_SUCCESS =
  'campaign/CAMPAIGN_CRITERIA_SAVE_SUCCESS';
export const CAMPAIGN_CRITERIA_SAVE_FAILURE =
  'campaign/CAMPAIGN_CRITERIA_SAVE_FAILURE';

export const CAMPAIGN_MODE_SAVE_REQUEST = 'campaign/CAMPAIGN_MODE_SAVE_REQUEST';
export const CAMPAIGN_MODE_SAVE_SUCCESS = 'campaign/CAMPAIGN_MODE_SAVE_SUCCESS';
export const CAMPAIGN_MODE_SAVE_FAILURE = 'campaign/CAMPAIGN_MODE_SAVE_FAILURE';

export const CAMPAIGN_MODE_ADD_REQUEST = 'campaign/CAMPAIGN_MODE_ADD_REQUEST';
export const CAMPAIGN_MODE_ADD_SUCCESS = 'campaign/CAMPAIGN_MODE_ADD_SUCCESS';
export const CAMPAIGN_MODE_ADD_FAILURE = 'campaign/CAMPAIGN_MODE_ADD_FAILURE';

export const CAMPAIGN_MODE_DELETE_REQUEST =
  'campaign/CAMPAIGN_MODE_DELETE_REQUEST';
export const CAMPAIGN_MODE_DELETE_SUCCESS =
  'campaign/CAMPAIGN_MODE_DELETE_SUCCESS';
export const CAMPAIGN_MODE_DELETE_FAILURE =
  'campaign/CAMPAIGN_MODE_DELETE_FAILURE';

export const APPLY_CAMPAIGN_REQUEST = 'campaign/APPLY_CAMPAIGN_REQUEST';
export const APPLY_CAMPAIGN_SUCCESS = 'campaign/APPLY_CAMPAIGN_SUCCESS';
export const APPLY_CAMPAIGN_FAILURE = 'campaign/APPLY_CAMPAIGN_FAILURE';

export const LOAD_CAMPAIGN_APPLICATION_REQUEST =
  'campaign/LOAD_CAMPAIGN_APPLICATION_REQUEST';
export const LOAD_CAMPAIGN_APPLICATION_SUCCESS =
  'campaign/LOAD_CAMPAIGN_APPLICATION_SUCCESS';
export const LOAD_CAMPAIGN_APPLICATION_FAILURE =
  'campaign/LOAD_CAMPAIGN_APPLICATION_FAILURE';

export const COUNT_CAMPAIGN_APPLICATION_REQUEST =
  'campaign/COUNT_CAMPAIGN_APPLICATION_REQUEST';
export const COUNT_CAMPAIGN_APPLICATION_SUCCESS =
  'campaign/COUNT_CAMPAIGN_APPLICATION_SUCCESS';
export const COUNT_CAMPAIGN_APPLICATION_FAILURE =
  'campaign/COUNT_CAMPAIGN_APPLICATION_FAILURE';

export const LOAD_CAMPAIGN_APPLICATION_IDS_REQUEST =
  'campaign/LOAD_CAMPAIGN_APPLICATION_IDS_REQUEST';
export const LOAD_CAMPAIGN_APPLICATION_IDS_SUCCESS =
  'campaign/LOAD_CAMPAIGN_APPLICATION_IDS_SUCCESS';
export const LOAD_CAMPAIGN_APPLICATION_IDS_FAILURE =
  'campaign/LOAD_CAMPAIGN_APPLICATION_IDS_FAILURE';

export const LOAD_CAMPAIGN_APPLICATION_ES_REQUEST =
  'campaign/LOAD_CAMPAIGN_APPLICATION_ES_REQUEST';
export const LOAD_CAMPAIGN_APPLICATION_ES_SUCCESS =
  'campaign/LOAD_CAMPAIGN_APPLICATION_ES_SUCCESS';
export const LOAD_CAMPAIGN_APPLICATION_ES_FAILURE =
  'campaign/LOAD_CAMPAIGN_APPLICATION_ES_FAILURE';

export const UPDATE_CAMPAIGN_INSIGHT_STATUS_REQUEST =
  'campaign/UPDATE_CAMPAIGN_INSIGHT_STATUS_REQUEST';
export const UPDATE_CAMPAIGN_INSIGHT_STATUS_SUCCESS =
  'campaign/UPDATE_CAMPAIGN_INSIGHT_STATUS_SUCCESS';
export const UPDATE_CAMPAIGN_INSIGHT_STATUS_FAILURE =
  'campaign/UPDATE_CAMPAIGN_INSIGHT_STATUS_FAILURE';

export const GET_CAMPAIGN_INFO_REQUEST = 'campaign/GET_CAMPAIGN_INFO_REQUEST';
export const GET_CAMPAIGN_INFO_SUCCESS = 'campaign/GET_CAMPAIGN_INFO_SUCCESS';
export const GET_CAMPAIGN_INFO_FAILURE = 'campaign/GET_CAMPAIGN_INFO_FAILURE';

export const GET_INFLUENCER_INFO_REQUEST =
  'campaign/GET_INFLUENCER_INFO_REQUEST';
export const GET_INFLUENCER_INFO_SUCCESS =
  'campaign/GET_INFLUENCER_INFO_SUCCESS';
export const GET_INFLUENCER_INFO_FAILURE =
  'campaign/GET_INFLUENCER_INFO_FAILURE';

export const GET_TRANSACTION_INFO_REQUEST =
  'campaign/GET_TRANSACTION_INFO_REQUEST';
export const GET_TRANSACTION_INFO_SUCCESS =
  'campaign/GET_TRANSACTION_INFO_SUCCESS';
export const GET_TRANSACTION_INFO_FAILURE =
  'campaign/GET_TRANSACTION_INFO_FAILURE';

export const GET_REACH_BY_PLATFORM_REQUEST =
  'campaign/GET_REACH_BY_PLATFORM_REQUEST';
export const GET_REACH_BY_PLATFORM_SUCCESS =
  'campaign/GET_REACH_BY_PLATFORM_SUCCESS';
export const GET_REACH_BY_PLATFORM_FAILURE =
  'campaign/GET_REACH_BY_PLATFORM_FAILURE';

export const GET_REACH_BY_GENDER_REQUEST =
  'campaign/GET_REACH_BY_GENDER_REQUEST';
export const GET_REACH_BY_GENDER_SUCCESS =
  'campaign/GET_REACH_BY_GENDER_SUCCESS';
export const GET_REACH_BY_GENDER_FAILURE =
  'campaign/GET_REACH_BY_GENDER_FAILURE';

export const GET_TOP_HASHTAGS_REQUEST = 'campaign/GET_TOP_HASHTAGS_REQUEST';
export const GET_TOP_HASHTAGS_SUCCESS = 'campaign/GET_TOP_HASHTAGS_SUCCESS';
export const GET_TOP_HASHTAGS_FAILURE = 'campaign/GET_TOP_HASHTAGS_FAILURE';

export const GET_TOP_AUDIENCE_REQUEST = 'campaign/GET_TOP_AUDIENCE_REQUEST';
export const GET_TOP_AUDIENCE_SUCCESS = 'campaign/GET_TOP_AUDIENCE_SUCCESS';
export const GET_TOP_AUDIENCE_FAILURE = 'campaign/GET_TOP_AUDIENCE_FAILURE';

export const GET_TOP_INFLUENCER_REQUEST = 'campaign/GET_TOP_INFLUENCER_REQUEST';
export const GET_TOP_INFLUENCER_SUCCESS = 'campaign/GET_TOP_INFLUENCER_SUCCESS';
export const GET_TOP_INFLUENCER_FAILURE = 'campaign/GET_TOP_INFLUENCER_FAILURE';

export const GET_TOP_INFLUENCER_REACH_REQUEST =
  'campaign/GET_TOP_INFLUENCER_REACH_REQUEST';
export const GET_TOP_INFLUENCER_REACH_SUCCESS =
  'campaign/GET_TOP_INFLUENCER_REACH_SUCCESS';
export const GET_TOP_INFLUENCER_REACH_FAILURE =
  'campaign/GET_TOP_INFLUENCER_REACH_FAILURE';

export const GET_TRANSACTION_BY_PLATFORM_REQUEST =
  'campaign/GET_TRANSACTION_BY_PLATFORM_REQUEST';
export const GET_TRANSACTION_BY_PLATFORM_SUCCESS =
  'campaign/GET_TRANSACTION_BY_PLATFORM_SUCCESS';
export const GET_TRANSACTION_BY_PLATFORM_FAILURE =
  'campaign/GET_TRANSACTION_BY_PLATFORM_FAILURE';

export const GET_TRANSACTION_BY_CAMPAIGN_REQUEST =
  'campaign/GET_TRANSACTION_BY_CAMPAIGN_REQUEST';
export const GET_TRANSACTION_BY_CAMPAIGN_SUCCESS =
  'campaign/GET_TRANSACTION_BY_CAMPAIGN_SUCCESS';
export const GET_TRANSACTION_BY_CAMPAIGN_FAILURE =
  'campaign/GET_TRANSACTION_BY_CAMPAIGN_FAILURE';

export const GET_TRANSACTION_BY_GENDER_REQUEST =
  'campaign/GET_TRANSACTION_BY_GENDER_REQUEST';
export const GET_TRANSACTION_BY_GENDER_SUCCESS =
  'campaign/GET_TRANSACTION_BY_GENDER_SUCCESS';
export const GET_TRANSACTION_BY_GENDER_FAILURE =
  'campaign/GET_TRANSACTION_BY_GENDER_FAILURE';

export const GET_INFLUENCERS_HIRED_REQUEST =
  'campaign/GET_INFLUENCERS_HIRED_REQUEST';
export const GET_INFLUENCERS_HIRED_SUCCESS =
  'campaign/GET_INFLUENCERS_HIRED_SUCCESS';
export const GET_INFLUENCERS_HIRED_FAILURE =
  'campaign/GET_INFLUENCERS_HIRED_FAILURE';

export const GET_INFLUENCERS_GENDER_REQUEST =
  'campaign/GET_INFLUENCERS_GENDER_REQUEST';
export const GET_INFLUENCERS_GENDER_SUCCESS =
  'campaign/GET_INFLUENCERS_GENDER_SUCCESS';
export const GET_INFLUENCERS_GENDER_FAILURE =
  'campaign/GET_INFLUENCERS_GENDER_FAILURE';

export const GET_CAMPAIGN_BY_COLLABORATION_REQUEST =
  'campaign/GET_CAMPAIGN_BY_COLLABORATION_REQUEST';
export const GET_CAMPAIGN_BY_COLLABORATION_SUCCESS =
  'campaign/GET_CAMPAIGN_BY_COLLABORATION_SUCCESS';
export const GET_CAMPAIGN_BY_COLLABORATION_FAILURE =
  'campaign/GET_CAMPAIGN_BY_COLLABORATION_FAILURE';

export const GET_MEDIA_BY_TYPE_REQUEST = 'campaign/GET_MEDIA_BY_TYPE_REQUEST';
export const GET_MEDIA_BY_TYPE_SUCCESS = 'campaign/GET_MEDIA_BY_TYPE_SUCCESS';
export const GET_MEDIA_BY_TYPE_FAILURE = 'campaign/GET_MEDIA_BY_TYPE_FAILURE';

export const UPDATE_CAMPAIGN_MEDIA_STATUS_REQUEST =
  'campaign/UPDATE_CAMPAIGN_MEDIA_STATUS_REQUEST';
export const UPDATE_CAMPAIGN_MEDIA_STATUS_SUCCESS =
  'campaign/UPDATE_CAMPAIGN_MEDIA_STATUS_SUCCESS';
export const UPDATE_CAMPAIGN_MEDIA_STATUS_FAILURE =
  'campaign/UPDATE_CAMPAIGN_MEDIA_STATUS_FAILURE';

export const GET_CAMPAIGN_APPLICATION_REQUEST =
  'campaign/GET_CAMPAIGN_APPLICATION_REQUEST';
export const GET_CAMPAIGN_APPLICATION_SUCCESS =
  'campaign/GET_CAMPAIGN_APPLICATION_SUCCESS';
export const GET_CAMPAIGN_APPLICATION_FAILURE =
  'campaign/GET_CAMPAIGN_APPLICATION_FAILURE';

export const UPDATE_APPLICATION_REQUEST = 'campaign/UPDATE_APPLICATION_REQUEST';
export const UPDATE_APPLICATION_SUCCESS = 'campaign/UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FAILURE = 'campaign/UPDATE_APPLICATION_FAILURE';

export const TOP_LOCATIONS_REQUEST = 'campaign/TOP_LOCATIONS_REQUEST';
export const TOP_LOCATIONS_SUCCESS = 'campaign/TOP_LOCATIONS_SUCCESS';
export const TOP_LOCATIONS_FAILURE = 'campaign/TOP_LOCATIONS_FAILURE';

export const TOP_CATEGORIES_REQUEST = 'campaign/TOP_CATEGORIES_REQUEST';
export const TOP_CATEGORIES_SUCCESS = 'campaign/TOP_CATEGORIES_SUCCESS';
export const TOP_CATEGORIES_FAILURE = 'campaign/TOP_CATEGORIES_FAILURE';

export const BRANDS_WORKED_WITH_REQUEST = 'campaign/BRANDS_WORKED_WITH_REQUEST';
export const BRANDS_WORKED_WITH_SUCCESS = 'campaign/BRANDS_WORKED_WITH_SUCCESS';
export const BRANDS_WORKED_WITH_FAILURE = 'campaign/BRANDS_WORKED_WITH_FAILURE';

export const GET_ALL_CAMPAIGN_MEDIA_REQUEST =
  'campaign/GET_ALL_CAMPAIGN_MEDIA_REQUEST';
export const GET_ALL_CAMPAIGN_MEDIA_SUCCESS =
  'campaign/GET_ALL_CAMPAIGN_MEDIA_SUCCESS';
export const GET_ALL_CAMPAIGN_MEDIA_FAILURE =
  'campaign/GET_ALL_CAMPAIGN_MEDIA_FAILURE';

export const GET_ALL_CAMPAIGN_COMMENT_MEDIA_REQUEST =
  'campaign/GET_ALL_CAMPAIGN_COMMENT_MEDIA_REQUEST';
export const GET_ALL_CAMPAIGN_COMMENT_MEDIA_SUCCESS =
  'campaign/GET_ALL_CAMPAIGN_COMMENT_MEDIA_SUCCESS';
export const GET_ALL_CAMPAIGN_COMMENT_MEDIA_FAILURE =
  'campaign/GET_ALL_CAMPAIGN_COMMENT_MEDIA_FAILURE';

export const GET_ALL_CAMPAIGN_EXTRA_MEDIA_REQUEST =
  'campaign/GET_ALL_CAMPAIGN_EXTRA_MEDIA_REQUEST';
export const GET_ALL_CAMPAIGN_EXTRA_MEDIA_SUCCESS =
  'campaign/GET_ALL_CAMPAIGN_EXTRA_MEDIA_SUCCESS';
export const GET_ALL_CAMPAIGN_EXTRA_MEDIA_FAILURE =
  'campaign/GET_ALL_CAMPAIGN_EXTRA_MEDIA_FAILURE';

export const GET_BRAND_MEDIA_COUNT_REQUEST =
  'campaign/GET_BRAND_MEDIA_COUNT_REQUEST';
export const GET_BRAND_MEDIA_COUNT_SUCCESS =
  'campaign/GET_BRAND_MEDIA_COUNT_SUCCESS';
export const GET_BRAND_MEDIA_COUNT_FAILURE =
  'campaign/GET_BRAND_MEDIA_COUNT_FAILURE';

export const CHECK_SEND_PRODUCT_CODE_REQUEST =
  'campaign/CHECK_SEND_PRODUCT_CODE_REQUEST';
export const CHECK_SEND_PRODUCT_CODE_SUCCESS =
  'campaign/CHECK_SEND_PRODUCT_CODE_SUCCESS';
export const CHECK_SEND_PRODUCT_CODE_FAILURE =
  'campaign/CHECK_SEND_PRODUCT_CODE_FAILURE';

export const CAMPAIGN_MEDIA_DATA_REQUEST =
  'campaign/CAMPAIGN_MEDIA_DATA_REQUEST';
export const CAMPAIGN_MEDIA_DATA_SUCCESS =
  'campaign/CAMPAIGN_MEDIA_DATA_SUCCESS';
export const CAMPAIGN_MEDIA_DATA_FAILURE =
  'campaign/CAMPAIGN_MEDIA_DATA_FAILURE';

export const CAMPAIGN_MEDIA_SELECT_REQUEST =
  'campaign/CAMPAIGN_MEDIA_SELECT_REQUEST';
export const CAMPAIGN_MEDIA_SELECT_SUCCESS =
  'campaign/CAMPAIGN_MEDIA_SELECT_SUCCESS';
export const CAMPAIGN_MEDIA_SELECT_FAILURE =
  'campaign/CAMPAIGN_MEDIA_SELECT_FAILURE';

export const INFLUENCERS_FOR_BRAND_REQUEST =
  'campaign/INFLUENCERS_FOR_BRAND_REQUEST';
export const INFLUENCERS_FOR_BRAND_SUCCESS =
  'campaign/INFLUENCERS_FOR_BRAND_SUCCESS';
export const INFLUENCERS_FOR_BRAND_FAILURE =
  'campaign/INFLUENCERS_FOR_BRAND_FAILURE';

export const APPLICATION_MEDIA_UPLOAD_REQUEST =
  'campaign/APPLICATION_MEDIA_UPLOAD_REQUEST';
export const APPLICATION_MEDIA_UPLOAD_SUCCESS =
  'campaign/APPLICATION_MEDIA_UPLOAD_SUCCESS';
export const APPLICATION_MEDIA_UPLOAD_FAILURE =
  'campaign/APPLICATION_MEDIA_UPLOAD_FAILURE';

export const CAMPAIGN_MEDIA_UPLOAD_REQUEST =
  'campaign/CAMPAIGN_MEDIA_UPLOAD_REQUEST';
export const CAMPAIGN_MEDIA_UPLOAD_SUCCESS =
  'campaign/CAMPAIGN_MEDIA_UPLOAD_SUCCESS';
export const CAMPAIGN_MEDIA_UPLOAD_FAILURE =
  'campaign/CAMPAIGN_MEDIA_UPLOAD_FAILURE';

export const CAMPAIGN_COMMENTS_UPLOAD_REQUEST =
  'campaign/CAMPAIGN_COMMENTS_UPLOAD_REQUEST';
export const CAMPAIGN_COMMENTS_UPLOAD_SUCCESS =
  'campaign/CAMPAIGN_COMMENTS_UPLOAD_SUCCESS';
export const CAMPAIGN_COMMENTS_UPLOAD_FAILURE =
  'campaign/CAMPAIGN_COMMENTS_UPLOAD_FAILURE';

export const CAMPAIGN_EXTRA_MEDIA_UPLOAD_REQUEST =
  'campaign/CAMPAIGN_EXTRA_MEDIA_UPLOAD_REQUEST';
export const CAMPAIGN_EXTRA_MEDIA_UPLOAD_SUCCESS =
  'campaign/CAMPAIGN_EXTRA_MEDIA_UPLOAD_SUCCESS';
export const CAMPAIGN_EXTRA_MEDIA_UPLOAD_FAILURE =
  'campaign/CAMPAIGN_EXTRA_MEDIA_UPLOAD_FAILURE';

export const CAMPAIGN_IMAGE_UPLOAD_REQUEST =
  'campaign/CAMPAIGN_IMAGE_UPLOAD_REQUEST';
export const CAMPAIGN_IMAGE_UPLOAD_SUCCESS =
  'campaign/CAMPAIGN_IMAGE_UPLOAD_SUCCESS';
export const CAMPAIGN_IMAGE_UPLOAD_FAILURE =
  'campaign/CAMPAIGN_IMAGE_UPLOAD_FAILURE';

export const CAMPAIGN_COVER_UPLOAD_REQUEST =
  'campaign/CAMPAIGN_COVER_UPLOAD_REQUEST';
export const CAMPAIGN_COVER_UPLOAD_SUCCESS =
  'campaign/CAMPAIGN_COVER_UPLOAD_SUCCESS';
export const CAMPAIGN_COVER_UPLOAD_FAILURE =
  'campaign/CAMPAIGN_COVER_UPLOAD_FAILURE';

export const REFERENCE_FILE_UPLOAD_REQUEST =
  'campaign/REFERENCE_FILE_UPLOAD_REQUEST';
export const REFERENCE_FILE_UPLOAD_SUCCESS =
  'campaign/REFERENCE_FILE_UPLOAD_SUCCESS';
export const REFERENCE_FILE_UPLOAD_FAILURE =
  'campaign/REFERENCE_FILE_UPLOAD_FAILURE';

export const APPLICATION_INSIGHTS_REQUEST =
  'campaign/APPLICATION_INSIGHTS_REQUEST';
export const APPLICATION_INSIGHTS_SUCCESS =
  'campaign/APPLICATION_INSIGHTS_SUCCESS';
export const APPLICATION_INSIGHTS_FAILURE =
  'campaign/APPLICATION_INSIGHTS_FAILURE';

export const CAMPAIGN_ANALYTICS_REQUEST = 'campaign/CAMPAIGN_ANALYTICS_REQUEST';
export const CAMPAIGN_ANALYTICS_SUCCESS = 'campaign/CAMPAIGN_ANALYTICS_SUCCESS';
export const CAMPAIGN_ANALYTICS_FAILURE = 'campaign/CAMPAIGN_ANALYTICS_FAILURE';

export const APPLICATION_MEDIAS_REQUEST = 'campaign/APPLICATION_MEDIAS_REQUEST';
export const APPLICATION_MEDIAS_SUCCESS = 'campaign/APPLICATION_MEDIAS_SUCCESS';
export const APPLICATION_MEDIAS_FAILURE = 'campaign/APPLICATION_MEDIAS_FAILURE';

export const COUPON_ALL_REQUEST = 'campaign/COUPON_ALL_REQUEST';
export const COUPON_ALL_SUCCESS = 'campaign/COUPON_ALL_SUCCESS';
export const COUPON_ALL_FAILURE = 'campaign/COUPON_ALL_FAILURE';

export const CONFIRM_PRODUCT_CODE_REQUEST =
  'campaign/CONFIRM_PRODUCT_CODE_REQUEST';
export const CONFIRM_PRODUCT_CODE_SUCCESS =
  'campaign/CONFIRM_PRODUCT_CODE_SUCCESS';
export const CONFIRM_PRODUCT_CODE_FAILURE =
  'campaign/CONFIRM_PRODUCT_CODE_FAILURE';

export const SEND_CHAT_REQUEST = 'campaign/SEND_CHAT_REQUEST';
export const SEND_CHAT_SUCCESS = 'campaign/SEND_CHAT_SUCCESS';
export const SEND_CHAT_FAILURE = 'campaign/SEND_CHAT_FAILURE';

export const GET_CHAT_REQUEST = 'campaign/GET_CHAT_REQUEST';
export const GET_CHAT_SUCCESS = 'campaign/GET_CHAT_SUCCESS';
export const GET_CHAT_FAILURE = 'campaign/GET_CHAT_FAILURE';

export const NEW_CAMPAIGN = 'campaign/NEW_CAMPAIGN';
export const SELECT_APPLICATION = 'campaign/SELECT_APPLICATION';
export const UNSELECT_APPLICATION = 'campaign/UNSELECT_APPLICATION';

export const CODE_APPROVED = 'campaign/CODE_APPROVED';
export const SHOW_ERROR = 'campaign/SHOW_ERROR';
export const CLEAR_ERROR = 'campaign/CLEAR_ERROR';
export const MARK_LOADING = 'campaign/MARK_LOADING';
export const CLEAR_SUCCESS = 'campaign/CLEAR_SUCCESS';
export const SET_IMAGE_OF_PRODUCT = 'campaign/SET_IMAGE_OF_PRODUCT';

export const UPDATE_WALLET_BALANCE = 'campaign/UPDATE_WALLET_BALANCE';
export const SET_CREATE_CAMPAIGN_STEP = 'campaign/SET_CREATE_CAMPAIGN_STEP';
export const SET_FILTER_STRING = 'campaign/SET_FILTER_STRING';

const initialState = {
  // ...users[1],
  // id:8,
  // campaign_name: "Draft",
  status: 'pending',
  loading: [],
  errors: {},
  success: {},
  insights: {},
  media: {},
  campaigns_by_status: {},
  my_campaigns: [],
  wallet_balance: 0,
  create_campaign_step: -1,
  codeApproved: false,
  filterString: null
};

export const STATE = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS: {
      return { ...initialState };
    }

    // case MARK_LOADING: {
    //   const mark = markLoading(action.mark, state);
    //   return {...state, ...mark /*loading: Object.keys(action.payload)*/};
    // }
    case CODE_APPROVED: {
      return { ...state, loading: [], codeApproved: action.payload };
    }
    case SHOW_ERROR: {
      return { ...state, loading: [], error: action.payload, errors: null };
    }
    case CLEAR_ERROR: {
      return { ...state, loading: [], error: null, errors: null };
    }
    case CLEAR_SUCCESS: {
      const success = { ...state.success };
      if (Array.isArray(action.payload)) {
        action.payload.map((p) => delete success[p]);
      }

      return { ...state, success };
    }
    case SET_IMAGE_OF_PRODUCT: {
      const progressv2 = calcStepProgressV2({ ...state }, state.wallet_balance);
      const totalProgressv2 = calcTotalProgress(progressv2);

      return {
        ...state,
        [action.payload.key]: action.payload.location,
        progressv2,
        totalProgressv2,
      };
    }
    case UPDATE_WALLET_BALANCE: {
      return { ...state, wallet_balance: action.payload };
    }
    case SET_CREATE_CAMPAIGN_STEP: {
      return { ...state, create_campaign_step: action.payload };
    }
    case SET_FILTER_STRING: {
      return { ...state, filterString: action.payload };
    }
    case CAMPAIGN_SAVE_REQUEST: {
      const payload = { ...action.payload };

      delete payload['user'];

      const mark = markLoading(action, state);
      return { ...state, ...mark /*loading: Object.keys(action.payload)*/ };
    }
    // case CAMPAIGN_DELETE_SUCCESS: {
    //   return { ...initialState };
    // }
    // case SAVE_USER_SUCCESS:
    //   const smark = markSuccess(action, state);
    //
    //   let user = {};
    //   if(action.update === 'user') {
    //     const mUser = action.result;
    //     user = loginUser(mUser);
    //   }else if(action.update === 'profile'){
    //     const mUser = action.result;
    //     user = profileUser(mUser);
    //   }
    //
    //   return {...state, ...smark, ...user          /*loading: Object.keys(action.payload)*/};
    case CAMPAIGN_SAVE_SUCCESS: {
      const emark = markFailure(action, state);

      if (action.result.error_code === '400') {
        return { ...state, ...emark, status: STATE.SUCCESS };
      }

      const smark = markSuccess(action, state);

      const c = parseEachCampaignData(action.result);
      const progress = calcStepProgress(c, state.wallet_balance);
      const totalProgress = calcTotalProgress(progress);
      const progressv2 = calcStepProgressV2(c, state.wallet_balance);
      const totalProgressv2 = calcTotalProgress(progressv2);

      return {
        ...state,
        ...c,
        ...smark,
        progress,
        totalProgress,
        progressv2,
        totalProgressv2,
        status: STATE.SUCCESS,
      };
    }
    case CAMPAIGN_SAVE_FAILURE:
      const fmark = markFailure(action, state);

      return { ...state, ...fmark /*loading: Object.keys(action.payload)*/ };

    case LOAD_CAMPAIGN_SUCCESS: {
      const smark = markSuccess(action, state);
      const c = parseCampaignData(action.result.results);
      const status = action.payload.status;
      const campaigns_by_status = { ...state.campaigns_by_status };
      campaigns_by_status[status] = c;

      return { ...state, campaigns_by_status, ...smark, status: STATE.SUCCESS };
    }

    case LOAD_MY_CAMPAIGN_SUCCESS: {
      const smark = markSuccess(action, state);

      const my_campaigns = action.result
        ? parseCampaignData(action.result)
        : [];

      return { ...state, my_campaigns, ...smark, status: STATE.SUCCESS };
    }

    case LOAD_MY_CAMPAIGN_REQUEST:
    case CAMPAIGN_CRITERIA_SAVE_REQUEST:
    case CAMPAIGN_MODE_ADD_REQUEST:
    case REFERENCE_FILE_UPLOAD_REQUEST:
    case APPLICATION_INSIGHTS_REQUEST:
    case APPLICATION_MEDIAS_REQUEST:
    case CAMPAIGN_MODE_DELETE_REQUEST: {
      const mark = markLoading(action, state);
      return { ...state, ...mark /*loading: Object.keys(action.payload)*/ };
    }
    case CAMPAIGN_CRITERIA_SAVE_SUCCESS: {
      const smark = markSuccess(action, state);
      const criteria = parseCampaignCriteria(action.result);
      const progress = calcStepProgress({ ...state, ...criteria });
      const totalProgress = calcTotalProgress(progress);
      const progressv2 = calcStepProgressV2(
        { ...state, ...criteria },
        state.wallet_balance
      );
      const totalProgressv2 = calcTotalProgress(progressv2);

      return {
        ...state,
        ...smark,
        progress,
        totalProgress,
        progressv2,
        totalProgressv2,
        ...criteria,
        status: STATE.SUCCESS,
      };
    }
    case LOAD_MY_CAMPAIGN_FAILURE:
    case CAMPAIGN_CRITERIA_SAVE_FAILURE:
    case CAMPAIGN_MODE_ADD_FAILURE:
    case REFERENCE_FILE_UPLOAD_FAILURE:
    case APPLICATION_INSIGHTS_FAILURE:
    case APPLICATION_MEDIAS_FAILURE:
    case CAMPAIGN_MODE_DELETE_FAILURE: {
      const fmark = markFailure(action, state);
      return { ...state, ...fmark /*loading: Object.keys(action.payload)*/ };
    }

    case CAMPAIGN_MODE_ADD_SUCCESS: {
      if (action.result.error_code === '400') {
        const fmark = markFailure(action, state);
        return { ...state, ...fmark, status: STATE.SUCCESS };
      } else {
        const smark = markSuccess(action, state);
        const modes = [...state.campaignmodes, action.result];
        const progress = calcStepProgress({ ...state, campaignmodes: modes });
        const totalProgress = calcTotalProgress(progress);
        const progressv2 = calcStepProgressV2(
          { ...state, campaignmodes: modes },
          state.wallet_balance
        );
        const totalProgressv2 = calcTotalProgress(progressv2);

        return {
          ...state,
          ...smark,
          progress,
          totalProgress,
          progressv2,
          totalProgressv2,
          campaignmodes: modes,
          status: STATE.SUCCESS,
        };
      }
    }

    case APPLICATION_INSIGHTS_SUCCESS: {
      const smark = markSuccess(action, state);
      const insight =
        action.result && action.result.error_code === '400'
          ? []
          : action.result;
      const ins = { ...state.insights };
      const i = insight && insight.length > 0 ? insight[0] : null;

      if (i !== null) {
        if (!ins[i.campaignapplication]) {
          ins[i.campaignapplication] = [];
        }
        ins[i.campaignapplication] = insight;
      }
      return { ...state, ...smark, insights: ins, status: STATE.SUCCESS };
    }

    case APPLICATION_MEDIAS_SUCCESS: {
      const smark = markSuccess(action, state);
      const media =
        action.result && action.result.error_code === '400'
          ? []
          : action.result;
      const med = { ...state.media };
      const i = media && media.length > 0 ? media[0] : null;

      if (i !== null) {
        if (!med[i.campaignapplication]) {
          med[i.campaignapplication] = [];
        }
        med[i.campaignapplication] = media;
      }
      return { ...state, ...smark, media: med, status: STATE.SUCCESS };
    }

    case CAMPAIGN_MODE_DELETE_SUCCESS: {
      const smark = markSuccess(action, state);
      const id = action.payload.id;
      let modes = [...state.campaignmodes];
      const deletedMode = modes.filter((m) => m.id === id)[0];
      const index = modes.indexOf(deletedMode);
      modes.splice(index, 1);

      const progress = calcStepProgress({ ...state, campaignmodes: modes });
      const totalProgress = calcTotalProgress(progress);
      const progressv2 = calcStepProgressV2(
        { ...state, campaignmodes: modes },
        state.wallet_balance
      );
      const totalProgressv2 = calcTotalProgress(progressv2);

      return {
        ...state,
        ...smark,
        progress,
        totalProgress,
        progressv2,
        totalProgressv2,
        campaignmodes: modes,
        status: STATE.SUCCESS,
      };
    }

    case CAMPAIGN_MODE_SAVE_REQUEST: {
      const mark = markLoading(action, state);
      return { ...state, ...mark /*loading: Object.keys(action.payload)*/ };
    }
    case CAMPAIGN_MODE_SAVE_SUCCESS: {
      if (action.result.error_code === '400') {
        const fmark = markFailure(action, state);
        return { ...state, ...fmark, status: STATE.SUCCESS };
      } else {
        const updatedMode = action.result;
        const id = updatedMode.id;
        const modes = [...state.campaignmodes];
        const foundMode = modes.filter((m) => m.id == id)[0];
        const index = modes.indexOf(foundMode);
        modes[index] = updatedMode;
        const smark = markSuccess(action, state);

        const progress = calcStepProgress({ ...state, campaignmodes: modes });
        const totalProgress = calcTotalProgress(progress);
        const progressv2 = calcStepProgressV2(
          { ...state, campaignmodes: modes },
          state.wallet_balance
        );
        const totalProgressv2 = calcTotalProgress(progressv2);

        return {
          ...state,
          ...smark,
          progress,
          totalProgress,
          progressv2,
          totalProgressv2,
          campaignmodes: modes,
          status: STATE.SUCCESS,
        };
      }
    }
    case CAMPAIGN_MODE_SAVE_FAILURE: {
      const fmark = markFailure(action, state);
      return { ...state, ...fmark /*loading: Object.keys(action.payload)*/ };
    }

    case CAMPAIGN_DATA_REQUEST:
      return {
        ...initialState,
        selectedApplication: state.selectedApplication,
      };
    case CAMPAIGN_DATA_SUCCESS: {
      const c = parseEachCampaignData(action.result);
      const progress = calcStepProgress(c, state.wallet_balance);
      const totalProgress = calcTotalProgress(progress);
      const progressv2 = calcStepProgressV2(c, state.wallet_balance);
      const totalProgressv2 = calcTotalProgress(progressv2);
      return {
        ...state,
        ...c,
        progress,
        totalProgress,
        progressv2,
        totalProgressv2,
      };
    }
    case REFERENCE_FILE_UPLOAD_SUCCESS: {
      const newMedia = action.result;
      const media = [...state.media, newMedia];
      const smark = markSuccess(action, state);

      return { ...state, ...smark, media: media };
    }

    case LOAD_CAMPAIGN_APPLICATION_IDS_REQUEST: {
      const rmark = markLoading(action, state);
      return { ...state, ...rmark };
    }

    case LOAD_CAMPAIGN_APPLICATION_IDS_SUCCESS: {
      const smark = markSuccess(action, state);
      return { ...state };
    }

    case LOAD_CAMPAIGN_APPLICATION_REQUEST: {
      const rmark = markLoading(action, state);
      return { ...state, ...rmark };
    }
    // case SEND_CHAT_SUCCESS: {
    //   // console.log(action, state);
    //   // debugger;
    //   const a = state.applications[0];
    //   a.chats = [...a.chats, action.result];
    //   console.log(state);
    //   return { ...state };
    // }
    // case GET_CHAT_SUCCESS: {
    //   const a = state.applications[0];
    //   console.log('res', action);
    //   a.chats = [...action.result.results];
    //   console.log(state);
    //   return { ...state };
    // }
    case LOAD_CAMPAIGN_APPLICATION_SUCCESS: {
      const smark = markSuccess(action, state);
      const next = action.result.next;
      let ca = parseCampaignApplicationData(action.result.results);
      if (action.payload.campaign_id) {
        ca = ca.filter((c) => c.campaign.id === action.payload.campaign_id);
      }

      return { ...state, ...smark, applications: ca };
    }
    case LOAD_CAMPAIGN_APPLICATION_ES_SUCCESS: {
      const smark = markSuccess(action, state);

      let ca = parseCampaignApplicationDataES(
        action.result.hits && action.result.hits.hits
          ? action.result.hits.hits.map((s) => ({ id: s._id, ...s._source }))
          : []
      );

      const applicationStatus = getApplicationStatusFromFilter(
        action.payload.appliedFilters
      );
      if (applicationStatus && applicationStatus.length > 0) {
        ca = ca.filter(
          (c) => applicationStatus.indexOf(c.application_status) > -1
        );
      }
      const { applications } = state;
      const {
        payload: { page },
      } = action;
      return {
        ...state,
        ...smark,
        total: action.result.hits ? action.result.hits.total.value : 0,
        applications: page == 1 ? [...ca] : [...applications, ...ca],
      };
    }
    case GET_CAMPAIGN_APPLICATION_REQUEST: {
      const ca = parseEachCampaignApplicationData(action.result);

      return { ...state, application: ca };
    }
    case NEW_CAMPAIGN:
      return { ...initialState };
    case SELECT_APPLICATION:
      return { ...state, selectedApplication: action.payload };
    case UNSELECT_APPLICATION:
      return { ...state, selectedApplication: null };
    default: {
      return state;
    }
  }
}

function getApplicationStatusFromFilter(filter = '') {
  const r = new RegExp('application_status\\:"([\\w\\s]+)"', 'g');
  const matches = (filter || '').match(r);
  return (matches || []).map((m) =>
    m.replace('application_status:"', '').replace('"', '')
  );
}
/*
{
    "id": 8,
    "ownerType": "Brand",
    "brand": {
        "id": 4,
        "alias": "",
        "tagLine": "supaco",
        "bio": "something about me",
        "location": "Delhi",
        "mobile": "9876543210",
        "brandlogo": "",
        "status": "('New', 'New')",
        "user": {
            "id": 27,
            "username": "brand@infloso.com",
            "email": "brand@infloso.com",
            "first_name": "Super Brand",
            "last_name": ""
        },
        "role": 2
    },
    "campaign_name": "First Campaign",
    "alias": "",
    "campaign_goal": "",
    "campaign_desc": "",
    "location": "",
    "timeline": "",
    "start_date": null,
    "end_date": null,
    "collaboration_type": "",
    "campaign_modes": "",
    "content_guidelines": "",
    "campaign_budget": null,
    "no_of_influencers": 5,
    "is_active": false,
    "campaign_state": [
        "Draft",
        "Draft"
    ],
    "owner": {
        "id": 27,
        "username": "brand@infloso.com",
        "email": "brand@infloso.com",
        "first_name": "Super Brand",
        "last_name": "",
        "usertype": "Brand",
        "brand": {
            "id": 4,
            "alias": "",
            "tagLine": "supaco",
            "bio": "something about me",
            "location": "Delhi",
            "mobile": "9876543210",
            "brandlogo": "",
            "status": "('New', 'New')",
            "user": {
                "id": 27,
                "username": "brand@infloso.com",
                "email": "brand@infloso.com",
                "first_name": "Super Brand",
                "last_name": ""
            },
            "role": 2
        }
    },
    "keywords": [],
    "categories": [],
    "media": []
}
 */
export function createNew(campaign) {
  if (campaign.id) {
    //Update campaign
    return saveCampaign(campaign);
  }

  const payloadCampaign = { ...campaign };
  if (!payloadCampaign.campaign_name) {
    payloadCampaign.campaign_name = 'Campaign ' + new Date().getTime();
    payloadCampaign.campaign_state = 'Draft';
  }

  return {
    payload: campaign,
    mark: campaign,
    types: [
      CAMPAIGN_SAVE_REQUEST,
      CAMPAIGN_SAVE_SUCCESS,
      CAMPAIGN_SAVE_FAILURE,
    ],
    // promise: (client) => client('/api/modules/create-campaign', {method: 'POST', body: JSON.stringify(campaign)}, true)
    promise: (client) =>
      client(
        '/inflosocampaigns/campaign',
        { method: 'POST', body: JSON.stringify(payloadCampaign) },
        true
      ),
  };
}

export function deleteCampaign(campaign) {
  return {
    payload: campaign,
    mark: campaign,
    types: [
      CAMPAIGN_DELETE_REQUEST,
      CAMPAIGN_DELETE_SUCCESS,
      CAMPAIGN_DELETE_FAILURE,
    ],
    // promise: (client) => client('/api/modules/create-campaign', {method: 'POST', body: JSON.stringify(campaign)}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-delete/${campaign.id}`,
        { method: 'PUT' },
        true
      ),
  };
}

export function addCampaignCoowner(campaign, coowner) {
  const payloadCampaign = {
    campaign_name: campaign.campaign_name,
    brand: campaign.brand.id,
    coowner: coowner,
  };

  return {
    payload: campaign,
    mark: '', //criteria || mode || campaign,
    types: [
      CAMPAIGN_SAVE_REQUEST,
      CAMPAIGN_SAVE_SUCCESS,
      CAMPAIGN_SAVE_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/create-campaign/${savedCampaign.id}`, {method:'PUT', body:JSON.stringify(campaign)}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign/${campaign.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(payloadCampaign),
        },
        true
      ),
  };
}

export function saveCampaign(campaign, mark) {
  return async (dispatch, getState) => {
    const savedCampaign = getState().campaign;
    const user = getState().user;
    // debugger;
    if (!savedCampaign.id) {
      //Campaign not there, creating new
      return dispatch(createNew(campaign));
    }

    const payloadCampaign = { ...campaign };
    if (!payloadCampaign.campaign_name) {
      payloadCampaign.campaign_name = savedCampaign.campaign_name;
    }
    if (!payloadCampaign.brand && savedCampaign.owner.brand) {
      payloadCampaign.brand = savedCampaign.owner.brand.id;
    }

    if (!payloadCampaign.brand && savedCampaign.brand) {
      payloadCampaign.brand = savedCampaign.brand.id;
    }

    if (!payloadCampaign.agency && savedCampaign.owner.agency) {
      payloadCampaign.agency = savedCampaign.owner.agency.id;
    }

    // if (user.coupon) {
    //   payloadCampaign.brand_coupon = user.coupon;
    // }

    if (payloadCampaign.coowner) {
      const coowner = payloadCampaign.coowner
        .split('|')
        .map((c) => parseInt(c, 10));
      payloadCampaign.coowner = [...coowner];
    }

    if (
      payloadCampaign.timeline === 'Fixed Timeline' &&
      !payloadCampaign.start_date
    ) {
      payloadCampaign.start_date = moment().add('days', 1).format('yyyy-MM-DD');
    }

    if (
      payloadCampaign.timeline === 'Fixed Timeline' &&
      !payloadCampaign.end_date
    ) {
      payloadCampaign.end_date = moment().add('days', 7).format('yyyy-MM-DD');
    }

    const campaignCriteriaKeys = [
      'influencer_gender',
      'age_group',
      'influencer_category',
    ];
    const campaignModesKeys = [
      'mode_name',
      'no_of_influencers',
      'no_of_friends_min',
      'no_of_friends_max',
      'budget_per_influencer',
      'static_post',
      'video_post',
      'story_post',
      'igtv',
      'insta_live',
      'reels',
      'swipe_up',
      'youtube_live',
      'reference_links',
      'highlights',
      'campaignmodes',
      'written_content',
      'written_hashtag',
      'written_accounts_tag',
      'written_title',
      'oral_content',
      'oral_suggested_content',
      'visual_content',
    ];
    const isCampaignCriteria =
      Object.keys(campaign).filter((k) => campaignCriteriaKeys.indexOf(k) > -1)
        .length > 0;
    const isCampaignModes =
      Object.keys(campaign).filter((k) => campaignModesKeys.indexOf(k) > -1)
        .length > 0;

    let criteria = null,
      mode = null;
    if (isCampaignCriteria) {
      //update
      if (
        !savedCampaign.campaigncriteria ||
        !savedCampaign.campaigncriteria.id
      ) {
        //Campaign criteria not there, creating new
        const resp = await newCampaignCriteria(
          campaign,
          savedCampaign,
          dispatch
        );
        criteria = {
          id: savedCampaign.id,
          campaign_name: savedCampaign.campaign_name,
          campaigncriteria: resp.id,
          brand: savedCampaign.owner
            ? savedCampaign.owner.brand
              ? savedCampaign.owner.brand.id
              : savedCampaign.owner.agency
              ? savedCampaign.owner.agency.id
              : savedCampaign.brand.id
            : savedCampaign.brand.id,
        };
      } else {
        return saveCampaignCriteria(campaign, savedCampaign, dispatch);
      }
    } else if (isCampaignModes) {
      if (campaign.campaignmodes || campaign.campaignmodes === '') {
        return saveCampaignMode(
          campaign,
          mark,
          savedCampaign,
          dispatch,
          calcBudgetPerInfluencer(savedCampaign, campaign, user),
          user
        );
      } else if (!campaign.id) {
        const resp = newCampaignMode(
          campaign,
          mark,
          savedCampaign,
          dispatch,
          calcBudgetPerInfluencer(savedCampaign, campaign, user),
          user
        );
        mode = {
          id: savedCampaign.id,
          campaignmodes: [resp.id],
        };
        // debugger;
        return mode;
      } else {
        return saveCampaignMode(
          campaign,
          mark,
          savedCampaign,
          dispatch,
          calcBudgetPerInfluencer(savedCampaign, campaign, user),
          user
        );
      }
    }

    if (
      payloadCampaign.cno_of_influencers ||
      payloadCampaign.cno_of_influencers === 0
    ) {
      payloadCampaign.no_of_influencers = payloadCampaign.cno_of_influencers;
      delete payloadCampaign.cno_of_influencers;
    }

    return dispatch({
      payload: criteria || mode || campaign,
      mark: mark, //criteria || mode || campaign,
      types: [
        CAMPAIGN_SAVE_REQUEST,
        CAMPAIGN_SAVE_SUCCESS,
        CAMPAIGN_SAVE_FAILURE,
      ],
      // promise: (client) => client(`/api/modules/create-campaign/${savedCampaign.id}`, {method:'PUT', body:JSON.stringify(campaign)}, true)
      promise: (client) =>
        client(
          `/inflosocampaigns/campaign/${savedCampaign.id}`,
          {
            method: 'PUT',
            body: JSON.stringify(criteria || mode || payloadCampaign),
          },
          true
        ),
    });
  };
}

export function saveCampaignAdmin(campaign) {
  return {
    payload: 'campaign_admin',
    mark: 'campaign_admin',
    types: [
      CAMPAIGN_SAVE_REQUEST,
      CAMPAIGN_SAVE_SUCCESS,
      CAMPAIGN_SAVE_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/create-campaign/${savedCampaign.id}`, {method:'PUT', body:JSON.stringify(campaign)}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign/${campaign.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(campaign),
        },
        true
      ),
  };
}

export function addMoneyToCampaignWallet(campaign, amount, influencerCount) {
  const payload = { amount, influencer_count: influencerCount };
  return {
    payload: payload,
    mark: campaign,
    types: [
      CAMPAIGN_ADD_MONEY_REQUEST,
      CAMPAIGN_ADD_MONEY_SUCCESS,
      CAMPAIGN_ADD_MONEY_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/create-campaign/${savedCampaign.id}`, {method:'PUT', body:JSON.stringify(campaign)}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign/add_money/${campaign.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
  // }
}

export function costPerInfluencer(campaign) {
  return {
    payload: {},
    mark: campaign,
    types: [
      CAMPAIGN_COST_PER_INFLUENCER_REQUEST,
      CAMPAIGN_COST_PER_INFLUENCER_SUCCESS,
      CAMPAIGN_COST_PER_INFLUENCER_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/create-campaign/${savedCampaign.id}`, {method:'PUT', body:JSON.stringify(campaign)}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign/cost-per-influencer/${campaign.id}`,
        {},
        true
      ),
  };
  // }
}

function newCampaignCriteria(campaign, savedCampaign, dispatch) {
  // return (dispatch, getState) => {
  //   const savedCampaign = getState().campaign;

  if (savedCampaign.campaigncriteria && savedCampaign.campaigncriteria.id) {
    //Campaign there, saving
    return dispatch(saveCampaignCriteria(campaign, savedCampaign, dispatch));
  }

  const newPayload = reverseCampaignCriteria(
    campaign,
    savedCampaign.campaigncriteria,
    savedCampaign
  );

  return dispatch({
    payload: campaign,
    mark: campaign,
    types: [
      CAMPAIGN_CRITERIA_SAVE_REQUEST,
      CAMPAIGN_CRITERIA_SAVE_SUCCESS,
      CAMPAIGN_CRITERIA_SAVE_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/create-campaign/${savedCampaign.id}`, {method:'PUT', body:JSON.stringify(campaign)}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/campaigncriteria`,
        {
          method: 'POST',
          body: JSON.stringify(newPayload),
        },
        true
      ),
  });
  // }
}

export function saveCampaignCriteria(campaign, savedCampaign, dispatch) {
  // return (dispatch, getState) => {
  //   const savedCampaign = getState().campaign;

  if (!savedCampaign.campaigncriteria || !savedCampaign.campaigncriteria.id) {
    //Campaign not there, creating new
    //throw new Error('not creating');
    return dispatch(newCampaignCriteria(campaign, savedCampaign, dispatch));
  }

  const newPayload = reverseCampaignCriteria(
    campaign,
    savedCampaign.campaigncriteria,
    savedCampaign
  );

  //const payloadCampaign = {...savedCampaign.campaigncriteria, campaign};

  return dispatch({
    payload: campaign,
    mark: campaign,
    types: [
      CAMPAIGN_CRITERIA_SAVE_REQUEST,
      CAMPAIGN_CRITERIA_SAVE_SUCCESS,
      CAMPAIGN_CRITERIA_SAVE_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/create-campaign/${savedCampaign.id}`, {method:'PUT', body:JSON.stringify(campaign)}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/campaigncriteria/${savedCampaign.campaigncriteria.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(newPayload),
        },
        true
      ),
  });
  // }
}

function newCampaignMode(
  campaign,
  mark,
  savedCampaign,
  dispatch,
  budget_per_influencer = DEFAULT_PRICING,
  user
) {
  // return (dispatch, getState) => {
  //   const savedCampaign = getState().campaign;
  if (campaign.id) {
    //Campaign there, saving
    return dispatch(
      saveCampaignMode(
        campaign,
        mark,
        savedCampaign,
        dispatch,
        budget_per_influencer,
        user
      )
    );
  }
  // debugger;
  const newPayload = {
    mode_name: '',
    no_of_influencers: 0,
    no_of_followers: 0,
    no_of_friends: 0,
    budget_per_influencer: budget_per_influencer,
    campaign: [savedCampaign.id],
    ...campaign,
  }; //reverseCampaignCriteria(campaign, savedCampaign.campaigncriteria, savedCampaign);

  return dispatch({
    payload: campaign,
    mark: mark,
    types: [
      CAMPAIGN_MODE_ADD_REQUEST,
      CAMPAIGN_MODE_ADD_SUCCESS,
      CAMPAIGN_MODE_ADD_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/create-campaign/${savedCampaign.id}`, {method:'PUT', body:JSON.stringify(campaign)}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/campaignmode`,
        {
          method: 'POST',
          body: JSON.stringify(newPayload),
        },
        true
      ),
  });
  // }
}

function deleteCampaignMode(campaign, mark, savedCampaign, dispatch) {
  // return (dispatch, getState) => {
  //   const savedCampaign = getState().campaign;

  // if (campaign.id) { //Campaign there, saving
  //   return dispatch(saveCampaignMode(campaign, mark, savedCampaign, dispatch));
  // }

  // const newPayload = {
  //   mode_name : "",
  //   no_of_influencers : 0,
  //   no_of_followers : 0,
  //   no_of_friends : 0,
  //   budget_per_influencer : 0,
  //   "campaign" : [savedCampaign.id],
  //   ...campaign};//reverseCampaignCriteria(campaign, savedCampaign.campaigncriteria, savedCampaign);

  // debugger;
  return dispatch({
    payload: campaign,
    mark: mark,
    types: [
      CAMPAIGN_MODE_DELETE_REQUEST,
      CAMPAIGN_MODE_DELETE_SUCCESS,
      CAMPAIGN_MODE_DELETE_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/create-campaign/${savedCampaign.id}`, {method:'PUT', body:JSON.stringify(campaign)}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/campaignmode/${campaign.id}`,
        {
          method: 'DELETE',
        },
        true
      ),
  });
  // }
}

export function saveCampaignMode(
  campaign,
  mark,
  savedCampaign,
  dispatch,
  budget_per_influencer,
  user
) {
  // return (dispatch, getState) => {
  //   const savedCampaign = getState().campaign;
  // debugger;
  if (campaign.campaignmodes || campaign.campaignmodes === '') {
    //todo check if deleting or adding
    const origModes = savedCampaign.campaignmodes.map((c) => c.mode_name);
    const newModes = campaign.campaignmodes.split('|');

    if (origModes.length > newModes.length) {
      //Deleting
      const diff = diffArray(newModes, origModes);
      const id = savedCampaign.campaignmodes.filter(
        (c) => c.mode_name === diff[0]
      )[0].id;
      return deleteCampaignMode({ id }, mark, savedCampaign, dispatch);
    } else if (origModes.length < newModes.length) {
      //Adding
      const diff = diffArray(origModes, newModes);
      return newCampaignMode(
        { mode_name: diff[0] || campaign.mode_name },
        mark,
        savedCampaign,
        dispatch,
        calcBudgetPerInfluencer(savedCampaign, campaign, user),
        user
      );
    } else {
      const id = savedCampaign.campaignmodes[0].id;
      return deleteCampaignMode({ id }, mark, savedCampaign, dispatch);
    }
  }

  if (!campaign.id) {
    //Campaign there, saving
    // throw new Error('not creating');
    return dispatch(
      newCampaignMode(
        campaign,
        mark,
        savedCampaign,
        dispatch,
        calcBudgetPerInfluencer(savedCampaign, campaign, user),
        user
      )
    );
  }

  const newPayload = campaign; //reverseCampaignCriteria(campaign, savedCampaign.campaigncriteria, savedCampaign);

  //    TODO check if this is required
  //delete newPayload.id;

  //const payloadCampaign = {...savedCampaign.campaigncriteria, campaign};

  return dispatch({
    payload: {
      ...campaign,
      budget_per_influencer: calcBudgetPerInfluencer(
        savedCampaign,
        campaign,
        user
      ),
    },
    mark: mark,
    types: [
      CAMPAIGN_MODE_SAVE_REQUEST,
      CAMPAIGN_MODE_SAVE_SUCCESS,
      CAMPAIGN_MODE_SAVE_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/create-campaign/${savedCampaign.id}`, {method:'PUT', body:JSON.stringify(campaign)}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/campaignmode/${campaign.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(newPayload),
        },
        true
      ),
  });
  // }
}

export function load(id, key) {
  return {
    payload: { id },
    mark: { campaign: id, [key]: 1 },
    types: [
      CAMPAIGN_DATA_REQUEST,
      CAMPAIGN_DATA_SUCCESS,
      CAMPAIGN_DATA_FAILURE,
    ],
    promise: (client) => client('/inflosocampaigns/campaigns/' + id, {}, true),
  };
}

export function loadMyCampaigns() {
  return {
    payload: {},
    mark: { my_campaign: 1 },
    types: [
      LOAD_MY_CAMPAIGN_REQUEST,
      LOAD_MY_CAMPAIGN_SUCCESS,
      LOAD_MY_CAMPAIGN_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/campaigns?limit=20`, {}, true),
  };
}

export function loadMyCampaignsAgency(filter) {
  const query = filter;
  const index = 'campaigns';
  return {
    payload: index,
    types: [
      LOAD_MY_CAMPAIGN_REQUEST,
      LOAD_MY_CAMPAIGN_SUCCESS,
      LOAD_MY_CAMPAIGN_FAILURE,
    ],
    promise: search(index, query),
  };

  // return {
  //   payload: {},
  //   mark: { my_campaign: 1 },
  //   types: [
  //     LOAD_MY_CAMPAIGN_REQUEST,
  //     LOAD_MY_CAMPAIGN_SUCCESS,
  //     LOAD_MY_CAMPAIGN_FAILURE,
  //   ],
  //   promise: (client) =>
  //     client(`/inflosocampaigns/campaigns?limit=20`, {}, true),
  // };
}

export function loadCampaignByState(status) {
  return {
    payload: { status: status },
    mark: { campaign: status },
    types: [
      LOAD_CAMPAIGN_REQUEST,
      LOAD_CAMPAIGN_SUCCESS,
      LOAD_CAMPAIGN_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/campaigns-by-status?status=${status}&limit=20`,
        {},
        true
      ),
  };
}

export function loadCampaignModeByCampaignId(campaign_id) {
  return {
    payload: { campaign_mode: campaign_id },
    mark: { campaign_mode: campaign_id },
    types: [
      LOAD_CAMPAIGN_MODES_REQUEST,
      LOAD_CAMPAIGN_MODES_SUCCESS,
      LOAD_CAMPAIGN_MODES_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/campaignmodes/${campaign_id}`, {}, true),
  };
}

export function saveForLater(campaign) {
  return newCampaignApplication(
    campaign,
    { platforms: ['Facebook', 'YouTube', 'Instagram'] },
    'Saved'
  );
}

export function newCampaignApplication(
  campaign,
  application,
  status = 'Applied',
  media
) {
  return (dispatch, getState) => {
    const user = getState().user;

    if (!user.social_profiles || user.social_profiles.length === 0) {
      return new Promise((resolve, reject) => {
        reject('No social profile connected.');
      });
    }

    const payloadCampaign = {
      influencer: user.type_id,
      profile: user.profile_id,
      about_influencer: application.about || 'about_influencer',
      social_media:
        media ||
        user.social_profiles
          .filter(
            (s) =>
              (application.platforms || []).indexOf(s.social_media_platform) >
              -1
          )
          .map((s) => s.id),
      media_name: 'media_name{{$timestamp}}',
      why_me: application.why_me || 'why_me',
      campaign: campaign.id,
      application_status: status,
      quote_amount: application.quote_amount,
      deliverables: application.deliverables,
      selectedProducts: application.selectedProducts,
    };

    return dispatch({
      payload: campaign,
      mark: { 'new-campaign-application': 1 },
      types: [
        APPLY_CAMPAIGN_REQUEST,
        APPLY_CAMPAIGN_SUCCESS,
        APPLY_CAMPAIGN_FAILURE,
      ],
      promise: (client) =>
        client(
          `/inflosocampaigns/campaign-application${
            application.id ? '/' + application.id : ''
          }`,
          {
            method: application.id ? 'PUT' : 'POST',
            body: JSON.stringify(payloadCampaign),
          },
          true
        ),
    });
  };
}

export function loadCampaignApplication(status) {
  return {
    payload: status,
    mark: { ['campaign-application-' + status]: 1 },
    types: [
      LOAD_CAMPAIGN_APPLICATION_REQUEST,
      LOAD_CAMPAIGN_APPLICATION_SUCCESS,
      LOAD_CAMPAIGN_APPLICATION_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/campaign-applications`, {}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-application-by-status?status=${status}&limit=20`,
        {},
        true
      ),
  };
}

export function getMyCampaignApplicationsByStatus(status, key) {
  // return getAllCampaignApplicationsByCampaignId(campaign);

  return {
    payload: {},
    mark: { ['my-campaign-application' + (key ? '-' + key : '')]: 1 },
    types: [
      LOAD_CAMPAIGN_APPLICATION_REQUEST,
      LOAD_CAMPAIGN_APPLICATION_SUCCESS,
      LOAD_CAMPAIGN_APPLICATION_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/campaign-applications`, {}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/my-campaign-applications?${
          status ? 'status=' + status + '&' : ''
        }limit=50`,
        {},
        true
      ),
  };
}

export function countCampaignApplications(user_id, campaign_id, key) {
  let url = user_id
    ? `/inflosocampaigns/campaigns-application/count?user_id=${user_id}`
    : `/inflosocampaigns/campaigns-application/count`;

  if (campaign_id) {
    url = `/inflosocampaigns/campaigns-application/count?campaign_id=${campaign_id}`;
  }
  return {
    payload: {},
    mark: { ['count-campaign-application' + (key ? '-' + key : '')]: 1 },
    types: [
      COUNT_CAMPAIGN_APPLICATION_REQUEST,
      COUNT_CAMPAIGN_APPLICATION_SUCCESS,
      COUNT_CAMPAIGN_APPLICATION_FAILURE,
    ],
    promise: (client) => client(url, {}, true),
  };
}

export function getMyCampaignApplications(campaign, key) {
  // return getAllCampaignApplicationsByCampaignId(campaign);

  return {
    payload: { campaign_id: campaign.id },
    mark: { ['my-campaign-application' + (key ? '-' + key : '')]: 1 },
    types: [
      LOAD_CAMPAIGN_APPLICATION_REQUEST,
      LOAD_CAMPAIGN_APPLICATION_SUCCESS,
      LOAD_CAMPAIGN_APPLICATION_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/campaign-applications`, {}, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/my-campaign-applications/${campaign.id}?limit=5`,
        {},
        true
      ),
  };
}

// export function loadCampaignApplicationFilter(status, brand_id, campaign_id) {
//   return {
//     payload: status,
//     mark: status,
//     types: [
//       LOAD_CAMPAIGN_APPLICATION_REQUEST,
//       LOAD_CAMPAIGN_APPLICATION_SUCCESS,
//       LOAD_CAMPAIGN_APPLICATION_FAILURE,
//     ],
//     // promise: (client) => client(`/api/modules/campaign-applications`, {}, true)
//
//     promise: (client) =>
//       client(
//         `/inflosocampaigns/campaign-application-filter/?application_status=${status}&campaign_brand=${brand_id}&campaign_id=${campaign_id}`,
//         {},
//         true
//       ),
//   };
// }

export function getCampaignInfo() {
  return {
    payload: 'get_campaign_info',
    mark: { get_campaign_info: 1 },
    types: [
      GET_CAMPAIGN_INFO_REQUEST,
      GET_CAMPAIGN_INFO_SUCCESS,
      GET_CAMPAIGN_INFO_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/get-campaign-info`, {}, true),
  };
}

export function getInfluencerInfo() {
  return {
    payload: 'get_influencer_info',
    mark: { get_influencer_info: 1 },
    types: [
      GET_INFLUENCER_INFO_REQUEST,
      GET_INFLUENCER_INFO_SUCCESS,
      GET_INFLUENCER_INFO_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/get-influencer-info`, {}, true),
  };
}

export function getTransactionInfo() {
  return {
    payload: 'get_transaction_info',
    mark: { get_transaction_info: 1 },
    types: [
      GET_TRANSACTION_INFO_REQUEST,
      GET_TRANSACTION_INFO_SUCCESS,
      GET_TRANSACTION_INFO_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/get-transaction-info`, {}, true),
  };
}

export function getReachByPlatform() {
  return {
    payload: 'get_reach_platform',
    mark: { get_reach_platform: 1 },
    types: [
      GET_REACH_BY_PLATFORM_REQUEST,
      GET_REACH_BY_PLATFORM_SUCCESS,
      GET_REACH_BY_PLATFORM_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/reach-by-platform`, {}, true),
  };
}

export function getReachByGender() {
  return {
    payload: 'get_reach_gender',
    mark: { get_reach_gender: 1 },
    types: [
      GET_REACH_BY_GENDER_REQUEST,
      GET_REACH_BY_GENDER_SUCCESS,
      GET_REACH_BY_GENDER_FAILURE,
    ],
    promise: (client) => client(`/inflosocampaigns/reach-by-gender`, {}, true),
  };
}

export function getTopHashtags() {
  return {
    payload: 'get_top_hashtags',
    mark: { get_top_hashtags: 1 },
    types: [
      GET_TOP_HASHTAGS_REQUEST,
      GET_TOP_HASHTAGS_SUCCESS,
      GET_TOP_HASHTAGS_FAILURE,
    ],
    promise: (client) => client(`/inflosocampaigns/top-hashtags`, {}, true),
  };
}

export function getTopAudience() {
  return {
    payload: 'get_top_audience',
    mark: { get_top_audience: 1 },
    types: [
      GET_TOP_AUDIENCE_REQUEST,
      GET_TOP_AUDIENCE_SUCCESS,
      GET_TOP_AUDIENCE_FAILURE,
    ],
    promise: (client) => client(`/inflosocampaigns/top-audience`, {}, true),
  };
}

export function getTopAudienceReach() {
  return {
    payload: 'get_top_audience',
    mark: { get_top_audience: 1 },
    types: [
      GET_TOP_AUDIENCE_REQUEST,
      GET_TOP_AUDIENCE_SUCCESS,
      GET_TOP_AUDIENCE_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/top-audience-reach`, {}, true),
  };
}

export function getExpenditureByLocation() {
  return {
    payload: 'get_top_audience',
    mark: { get_top_audience: 1 },
    types: [
      GET_TOP_AUDIENCE_REQUEST,
      GET_TOP_AUDIENCE_SUCCESS,
      GET_TOP_AUDIENCE_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/transaction-by-location`, {}, true),
  };
}

export function getTopInfluencer() {
  return {
    payload: 'get_top_influencer',
    mark: { get_top_influencer: 1 },
    types: [
      GET_TOP_INFLUENCER_REQUEST,
      GET_TOP_INFLUENCER_SUCCESS,
      GET_TOP_INFLUENCER_FAILURE,
    ],
    promise: (client) => client(`/inflosocampaigns/top-influencer`, {}, true),
  };
}

export function getTopInfluencerReach() {
  return {
    payload: 'get_top_influencer_reach',
    mark: { get_top_influencer_reach: 1 },
    types: [
      GET_TOP_INFLUENCER_REACH_REQUEST,
      GET_TOP_INFLUENCER_REACH_SUCCESS,
      GET_TOP_INFLUENCER_REACH_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/top-influencer-reach`, {}, true),
  };
}

export function getTransactionByPlatform() {
  return {
    payload: 'get_txn_platform',
    mark: { get_txn_platform: 1 },
    types: [
      GET_TRANSACTION_BY_PLATFORM_REQUEST,
      GET_TRANSACTION_BY_PLATFORM_SUCCESS,
      GET_TRANSACTION_BY_PLATFORM_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/transaction-by-platform`, {}, true),
  };
}

export function getTransactionByCampaign() {
  return {
    payload: 'get_txn_campaign',
    mark: { get_txn_campaign: 1 },
    types: [
      GET_TRANSACTION_BY_CAMPAIGN_REQUEST,
      GET_TRANSACTION_BY_CAMPAIGN_SUCCESS,
      GET_TRANSACTION_BY_CAMPAIGN_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/transaction-by-abc`, {}, true),
  };
}

export function getTransactionByGender() {
  return {
    payload: 'get_txn_campaign',
    mark: { get_txn_campaign: 1 },
    types: [
      GET_TRANSACTION_BY_GENDER_REQUEST,
      GET_TRANSACTION_BY_GENDER_SUCCESS,
      GET_TRANSACTION_BY_GENDER_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/transaction-by-gender`, {}, true),
  };
}

export function getInfluencersHired() {
  return {
    payload: 'get_influencers_hired',
    mark: { get_influencers_hired: 1 },
    types: [
      GET_INFLUENCERS_HIRED_REQUEST,
      GET_INFLUENCERS_HIRED_SUCCESS,
      GET_INFLUENCERS_HIRED_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/influencers-hired`, {}, true),
  };
}

export function getInfluencersGender() {
  return {
    payload: 'get_influencers_gender',
    mark: { get_influencers_gender: 1 },
    types: [
      GET_INFLUENCERS_GENDER_REQUEST,
      GET_INFLUENCERS_GENDER_SUCCESS,
      GET_INFLUENCERS_GENDER_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/influencers-gender`, {}, true),
  };
}

export function getCampaignByCollaboration() {
  return {
    payload: 'get_campaign_by_collaboration',
    mark: { get_campaign_by_collaboration: 1 },
    types: [
      GET_CAMPAIGN_BY_COLLABORATION_REQUEST,
      GET_CAMPAIGN_BY_COLLABORATION_SUCCESS,
      GET_CAMPAIGN_BY_COLLABORATION_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/campaign-by-collaboration`, {}, true),
  };
}

export function getMediaByType() {
  return {
    payload: 'get_media_by_type',
    mark: { get_media_by_type: 1 },
    types: [
      GET_MEDIA_BY_TYPE_REQUEST,
      GET_MEDIA_BY_TYPE_SUCCESS,
      GET_MEDIA_BY_TYPE_FAILURE,
    ],
    promise: (client) => client(`/inflosocampaigns/media-by-type`, {}, true),
  };
}

export function updateCampaignInsightsStatus(insights, status, key) {
  const payload = {
    campaign_insights_state: status, //"Approved"
  };
  return {
    payload: payload,
    mark: { [key]: 1 },
    types: [
      UPDATE_CAMPAIGN_INSIGHT_STATUS_REQUEST,
      UPDATE_CAMPAIGN_INSIGHT_STATUS_SUCCESS,
      UPDATE_CAMPAIGN_INSIGHT_STATUS_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/update-campaign-insights-status/${insights.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function updateCampaignMediaStatus(
  media,
  status,
  key = 'campaign_insights_state'
) {
  const payload = {
    [key]: status, //"Approved"
  };

  return {
    payload: payload,
    mark: { [key]: 1 },
    types: [
      UPDATE_CAMPAIGN_MEDIA_STATUS_REQUEST,
      UPDATE_CAMPAIGN_MEDIA_STATUS_SUCCESS,
      UPDATE_CAMPAIGN_MEDIA_STATUS_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-application-media-status/${media.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function campaignApplicationMediaDelete(media, key = 'delete-media') {
  const payload = {
    media_status: 'Inactive',
  };

  return {
    payload: payload,
    mark: { [key]: 1 },
    types: [
      UPDATE_CAMPAIGN_MEDIA_STATUS_REQUEST,
      UPDATE_CAMPAIGN_MEDIA_STATUS_SUCCESS,
      UPDATE_CAMPAIGN_MEDIA_STATUS_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-application-media-status/${media.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function campaignInsightMediaDelete(media, key = 'delete-media') {
  return updateCampaignInsightsStatus(media, 'Rejected', key);
}

export function campaignApplicationLinkDelete(link, key = 'delete-link') {
  const payload = {
    ...link,
    link_status: 'Inactive',
  };

  return {
    payload: payload,
    mark: { [key]: 1 },
    types: [
      UPDATE_CAMPAIGN_MEDIA_STATUS_REQUEST,
      UPDATE_CAMPAIGN_MEDIA_STATUS_SUCCESS,
      UPDATE_CAMPAIGN_MEDIA_STATUS_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-application-links/${link.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function getAllCampaignMediaByCampaignId(campaignId) {
  return {
    mark: { get_all_media: 1 },
    types: [
      GET_ALL_CAMPAIGN_MEDIA_REQUEST,
      GET_ALL_CAMPAIGN_MEDIA_SUCCESS,
      GET_ALL_CAMPAIGN_MEDIA_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/campaign/media/${campaignId}`, {}, true),
  };
}

export function getAllCampaignCommentMediaByCampaignId(campaignId) {
  return {
    mark: { get_all_media: 1 },
    types: [
      GET_ALL_CAMPAIGN_COMMENT_MEDIA_REQUEST,
      GET_ALL_CAMPAIGN_COMMENT_MEDIA_SUCCESS,
      GET_ALL_CAMPAIGN_COMMENT_MEDIA_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign/comment-media/${campaignId}`,
        {},
        true
      ),
  };
}

export function getAllCampaignExtraMediaByCampaignId(campaignId) {
  return {
    mark: { get_all_media: 1 },
    types: [
      GET_ALL_CAMPAIGN_EXTRA_MEDIA_REQUEST,
      GET_ALL_CAMPAIGN_EXTRA_MEDIA_SUCCESS,
      GET_ALL_CAMPAIGN_EXTRA_MEDIA_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/campaign/extra-media/${campaignId}`, {}, true),
  };
}

export function getBrandMediaCountByBrandId(brandId) {
  return {
    mark: { get_brand_media: 1 },
    types: [
      GET_BRAND_MEDIA_COUNT_REQUEST,
      GET_BRAND_MEDIA_COUNT_SUCCESS,
      GET_BRAND_MEDIA_COUNT_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/brand/media/count/${brandId}`, {}, true),
  };
}

export function getAllCampaignApplicationsByCampaignIdSelf(
  campaign_id,
  key = 'campaign-application'
) {
  return (dispatch, getState) => {
    const campaign = getState().campaign;
    return dispatch(getAllCampaignApplicationsByCampaignId(campaign, key));
  };
}

export function getAllCampaignApplicationsByCampaignId(
  campaign,
  key = 'campaign-application',
  size = 4000,
  page = 1,
  appliedFilters
) {
  const filter = {
    application_status: '',
    campaign__brand:
      campaign &&
      campaign.owner &&
      campaign.owner.brand &&
      campaign.owner.brand.id
        ? campaign.owner.brand.id
        : '',
    campaign__id: campaign ? campaign.id : null,
    campaign__owner:
      campaign && campaign.owner && campaign.owner.id ? campaign.owner.id : '',
    limit: 200,
  };
  // if (
  //   typeof document !== 'undefined' &&
  //   (document.domain === 'uat.infloso.in' ||
  //     document.domain === 'pre.infloso.com')
  // ) {
  return filterCampaignApplicationsES(filter, key, size, page, appliedFilters);
  // } else {
  //   return filterCampaignApplications(filter, key);
  // }
}

/*
 {
  application_status:'Approved',
  campaign__brand: 4,
  campaign__id: 5,
  campaign__owner:4,
  limit:10
 }
 */
export function filterCampaignApplications(
  filter,
  key = 'campaign-application-filter'
) {
  const filterStr = Object.keys(filter)
    .map((f) => f + '=' + filter[f])
    .join('&');

  // return (dispatch, getState) => {
  const next = 1; //getState().campaign.application_next;

  if (next) {
    return {
      payload: { next },
      mark: { [key]: 1 },
      types: [
        LOAD_CAMPAIGN_APPLICATION_REQUEST,
        LOAD_CAMPAIGN_APPLICATION_SUCCESS,
        LOAD_CAMPAIGN_APPLICATION_FAILURE,
      ],
      promise: (client) =>
        client(
          `/inflosocampaigns/campaign-application-filter?${filterStr}`,
          {},
          true
        ),
    };
  }
}

export function filterCampaignApplicationsES(
  filter,
  key = 'campaign-application-filter',
  size,
  page,
  appliedFilters
) {
  const filterStr = Object.keys(filter)
    .map((f) => f + '=' + filter[f])
    .join('&');

  const esFilter = [];

  if (filter.application_status) {
    esFilter.push(
      '(NOT(application_status:Rejected) AND NOT(application_status:Saved))'
    );
  }
  if (filter.campaign__brand) {
  }
  if (filter.campaign__id) {
    esFilter.push(`(campaign.id:${filter.campaign__id})`);
  }
  if (filter.campaign__owner) {
    esFilter.push(`(campaign.owner.id:${filter.campaign__owner})`);
  }
  const applyFilter = appliedFilters ? ` AND ${appliedFilters}` : '';
  return {
    payload: { filter, page, appliedFilters },
    mark: { [key]: 1 },
    types: [
      LOAD_CAMPAIGN_APPLICATION_ES_REQUEST,
      LOAD_CAMPAIGN_APPLICATION_ES_SUCCESS,
      LOAD_CAMPAIGN_APPLICATION_ES_FAILURE,
    ],
    promise: (client) =>
      client(
        `/es/dev_campaign_applications/_search?q=(${esFilter.join(
          ' AND '
        )})${applyFilter}&sort=modified_date:desc&size=${size}&from=${
          size * (page - 1)
        }`,
        {},
        true
      ),
  };
}

export function getAllCampaignApplications() {
  return {
    payload: {},
    mark: { 'campaign-application': 1 },
    types: [
      LOAD_CAMPAIGN_APPLICATION_REQUEST,
      LOAD_CAMPAIGN_APPLICATION_SUCCESS,
      LOAD_CAMPAIGN_APPLICATION_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosocampaigns/campaigns-application?limit=50`, {}, true),
  };
}

export function getAllCampaignApplicationsIds(
  filter,
  size = 1000,
  key = 'campaign-application-id',
  page = 1
) {
  const esFilter = [];
  if (filter.campaign__id) {
    esFilter.push(`(campaign.id:${filter.campaign__id})`);
  }
  if (filter.campaign__owner) {
    esFilter.push(`(campaign.owner.id:${filter.campaign__owner})`);
  }
  esFilter.push(
    `((application_status:Applied) OR (application_status:Submit Content) OR (application_status:Submit Link) OR (application_status:Submit Insight))`
  );
  return {
    payload: {},
    mark: { key: 1 },
    types: [
      LOAD_CAMPAIGN_APPLICATION_IDS_REQUEST,
      LOAD_CAMPAIGN_APPLICATION_IDS_SUCCESS,
      LOAD_CAMPAIGN_APPLICATION_IDS_FAILURE,
    ],
    promise: (client) =>
      client(
        `/es/dev_campaign_applications/_search?q=(${esFilter.join(
          ' AND '
        )})&sort=modified_date:desc&size=${size}&from=${
          size * (page - 1)
        }&_source=id&_source=application_status`,
        {},
        true
      ),
  };
}

export function walletBalance(wallet_balance) {
  return {
    payload: wallet_balance,
    type: UPDATE_WALLET_BALANCE,
  };
}

export function setCreateCampaignStep(step) {
  return {
    payload: step,
    type: SET_CREATE_CAMPAIGN_STEP,
  };
}

export function setFilterString(filter) {
  return {
    payload: filter,
    type: SET_FILTER_STRING,
  };
}

setFilterString
export function approve(application, isBarter) {
  return campaignApplicationStatus(
    application,
    'Approved',
    'application-status-approved',
    null,
    isBarter
  );
}

export function rescheduleApplication(application, isBarter) {
  return campaignApplicationStatus(
    application,
    'Reschedule',
    'application-status-approved',
    null,
    isBarter
  );
}

export function submitInsights(application) {
  return campaignApplicationStatus(
    application,
    'Submit Insight',
    'my-campaign-application-submit-insights'
  );
}

export function archive(application) {
  return campaignApplicationStatus(
    application,
    'Archive',
    'my-campaign-application-submit-insights'
  );
}

export function markCompleted(application) {
  return campaignApplicationStatus(
    application,
    'Completed',
    'my-campaign-application-submit-insights'
  );
}

export function submitLink(application) {
  return campaignApplicationStatus(
    application,
    'Submit Link',
    'my-campaign-application-submit-link'
  );
}

export function submitContent(application) {
  return campaignApplicationStatus(
    application,
    'Submit Content',
    'my-campaign-application-submit-content'
  );
}

export function inreview(application) {
  return campaignApplicationStatus(
    application,
    'Application In Review',
    'application-status-inreview'
  );
}

export function reject(application) {
  return campaignApplicationStatus(
    application,
    'Rejected',
    'application-status-rejected'
  );
}

export function pending(application) {
  return campaignApplicationStatus(
    application,
    'Applied',
    'application-status-applied'
  );
}

export function approveContent(application) {
  return campaignApplicationStatus(
    application,
    'Approve Content',
    'application-status-approve-content'
  );
}

export function rejectContent(application, reason) {
  return campaignApplicationStatus(
    application,
    'Reject Content',
    'application-status-reject-content',
    reason
  );
}

export function approveLink(application) {
  return campaignApplicationStatus(
    application,
    'Approve Link',
    'application-status-approve-link'
  );
}

export function rejectLink(application, reason) {
  return campaignApplicationStatus(
    application,
    'Reject Link',
    'application-status-reject-link',
    reason
  );
}

export function approveInsight(application) {
  return campaignApplicationStatus(
    application,
    'Insight Approved',
    'application-status-approve-insight'
  );
}

export function rejectInsight(application, reason) {
  return campaignApplicationStatus(
    application,
    'Insight Rejected',
    'application-status-reject-insight',
    reason
  );
}

export function sendForReview(campaign) {
  return updateCampaignStatus(campaign, 'In Review');
}

export function approveCampaign(campaign) {
  return updateCampaignStatus(campaign, 'Approved');
}

export function campaignSaveDraft(campaign) {
  return updateCampaignStatus(campaign, 'Draft');
}

export function campaignPause(campaign) {
  return updateCampaignStatus(campaign, 'Paused');
}

export function campaignActive(campaign) {
  return updateCampaignStatus(campaign, 'Active');
}

export function campaignComplete(campaign) {
  return updateCampaignStatus(campaign, 'Completed');
}

export function updateCampaignStatus(campaign, status, param = {}) {
  const payload = { campaign_state: status, ...param };
  return {
    payload: {},
    mark: { ['campaign-' + status.replaceAll(' ', '-').toLowerCase()]: 1 },
    types: [
      CAMPAIGN_SAVE_REQUEST,
      CAMPAIGN_SAVE_SUCCESS,
      CAMPAIGN_SAVE_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/update-campaign-status/${campaign.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function campaignApplicationStatus(
  application,
  status,
  mark,
  why_me,
  isBarter
) {
  const payload = {
    campaign_application_state: status,
  };
  if (isBarter !== null && isBarter !== undefined) {
    payload.is_barter_campaign = isBarter;
  }
  if (why_me) {
    payload.reject_content_reason = why_me;
    payload.reject_link_reason = why_me;
    payload.reject_insight_reason = why_me;
  }

  return campaignApplicationStatusPayload(application, status, mark, payload);
}

export function campaignApplicationStatusPayload(
  application,
  status,
  mark,
  payload
) {
  return {
    payload: payload,
    mark: { [mark]: 1 },
    types: [
      UPDATE_APPLICATION_REQUEST,
      UPDATE_APPLICATION_SUCCESS,
      UPDATE_APPLICATION_FAILURE,
    ],
    // promise: (client) => client(`/api/modules/campaign-application/${application.id}`, {
    //   method:'PUT',
    //   body: JSON.stringify(payload)
    // }, true)
    promise: (client) =>
      client(
        `/inflosocampaigns/update-campaign-application-status/${application.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function checkSendProductCode(influencer_id, campaign_id, mark) {
  return {
    payload: {},
    mark: { [mark]: 1 },
    types: [
      CHECK_SEND_PRODUCT_CODE_REQUEST,
      CHECK_SEND_PRODUCT_CODE_SUCCESS,
      CHECK_SEND_PRODUCT_CODE_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/check-product-code?influencer=${influencer_id}&campaign=${campaign_id}`,
        {},
        true
      ),
  };
}

export function verifySendProductCode(influencer_id, campaign_id, mark) {
  return {
    payload: {},
    mark: { [mark]: 1 },
    types: [
      CHECK_SEND_PRODUCT_CODE_REQUEST,
      CHECK_SEND_PRODUCT_CODE_SUCCESS,
      CHECK_SEND_PRODUCT_CODE_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/check-verify-product-code?influencer=${influencer_id}&campaign=${campaign_id}`,
        {},
        true
      ),
  };
}

export function topLocations(campaign_id, platform, mark) {
  return {
    payload: {},
    mark: { [mark]: 1 },
    types: [
      TOP_LOCATIONS_REQUEST,
      TOP_LOCATIONS_SUCCESS,
      TOP_LOCATIONS_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosousers/influencers/top-locations/${campaign_id}`,
        {},
        true
      ),
  };
}

export function topCategories(campaign_id, platform, mark) {
  return {
    payload: {},
    mark: { [mark]: 1 },
    types: [
      TOP_CATEGORIES_REQUEST,
      TOP_CATEGORIES_SUCCESS,
      TOP_CATEGORIES_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosousers/influencers/top-categories/${campaign_id}`,
        {},
        true
      ),
  };
}

export function brandsWorkedWith(user_id) {
  const url = user_id
    ? `/inflosocampaigns/brands-worked-with?user_id=${user_id || ''}`
    : `/inflosocampaigns/brands-worked-with`;
  return {
    payload: {},
    mark: { 'brands-worked-with': 1 },
    types: [
      BRANDS_WORKED_WITH_REQUEST,
      BRANDS_WORKED_WITH_SUCCESS,
      BRANDS_WORKED_WITH_FAILURE,
    ],
    promise: (client) => client(url, {}, true),
  };
}

export function updateCampaignApplication(application, mark) {
  return {
    payload: application,
    mark: { [mark]: 1 },
    types: [
      UPDATE_APPLICATION_REQUEST,
      UPDATE_APPLICATION_SUCCESS,
      UPDATE_APPLICATION_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-application/${application.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(application),
        },
        true
      ),
  };
}

export function updateCampaignApplicationTracking(application, mark) {
  return {
    payload: application,
    mark: { [mark]: 1 },
    types: [
      UPDATE_APPLICATION_REQUEST,
      UPDATE_APPLICATION_SUCCESS,
      UPDATE_APPLICATION_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-application-tracking/${application.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(application),
        },
        true
      ),
  };
}

export function campaignApplicationInsights(application, mark) {
  return {
    payload: {},
    mark: { [mark]: 1 },
    types: [
      APPLICATION_INSIGHTS_REQUEST,
      APPLICATION_INSIGHTS_SUCCESS,
      APPLICATION_INSIGHTS_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-application-insights/${application.id}`,
        {},
        true
      ),
  };
}

export function campaignAnalytics(campaign) {
  return {
    payload: {},
    mark: { 'campaign-analytics-request': 1 },
    types: [
      CAMPAIGN_ANALYTICS_REQUEST,
      CAMPAIGN_ANALYTICS_SUCCESS,
      CAMPAIGN_ANALYTICS_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-insights-analytics/${campaign.id}`,
        {},
        true
      ),
  };
}

export function campaignApplicationMedias(application, mark) {
  return {
    payload: {},
    mark: { [mark]: 1 },
    types: [
      APPLICATION_MEDIAS_REQUEST,
      APPLICATION_MEDIAS_SUCCESS,
      APPLICATION_MEDIAS_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-application-medias/${application.id}`,
        {},
        true
      ),
  };
}

export function confirmCode(application, code, key) {
  const payload = {
    code,
  };

  return {
    payload: payload,
    mark: { [key]: 1 },
    types: [
      CONFIRM_PRODUCT_CODE_REQUEST,
      CONFIRM_PRODUCT_CODE_SUCCESS,
      CONFIRM_PRODUCT_CODE_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/verify-product-code/${application.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function selectApplication(id) {
  return {
    payload: id,
    type: SELECT_APPLICATION,
  };
}

export function unSelectApplication() {
  return {
    payload: null,
    type: UNSELECT_APPLICATION,
  };
}

export function newCampaign(id) {
  return {
    type: NEW_CAMPAIGN,
  };
}

export function showError(message) {
  return {
    payload: message,
    type: SHOW_ERROR,
  };
}

export function setCodeApproved(message) {
  return {
    payload: message,
    type: CODE_APPROVED,
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

// export function marksLoading(key) {
//   return {
//     type: MARK_LOADING,
//     payload: key
//   };
// }
//
// export function marksSuccess(key) {
//   return {
//     type: MARK_LOADING,
//     payload: key
//   };
// }

export function clearSuccess(key) {
  return {
    type: CLEAR_SUCCESS,
    payload: key,
  };
}

export function imageLocation(loc, key) {
  return {
    type: SET_IMAGE_OF_PRODUCT,
    payload: { location: loc, key: key },
  };
}

/*
LOGO = "Logo"
    COVER = "Cover Image"
    ICON = "Icon"
    SAMPLE = "Sample"
    GUIDELINES = "Guidelines"
    DOCS = "Associated Docs"
    VIDEO = "Video"
    POST = "Post"
    Audio = "Audio"
    Image = "Image"
 */
export function referenceFileUpload(file, data) {
  return {
    types: [
      REFERENCE_FILE_UPLOAD_REQUEST,
      REFERENCE_FILE_UPLOAD_SUCCESS,
      REFERENCE_FILE_UPLOAD_FAILURE,
    ],
    mark: { reference_file: 1 },
    promise: (client, fileUpload) =>
      fileUpload(file, '/inflosocampaigns/campaign-media-upload', data),
  };
}

/*
file
campaign
media_type
platform
 */
export function campaignMediaUpload(file, data) {
  return {
    types: [
      CAMPAIGN_MEDIA_UPLOAD_REQUEST,
      CAMPAIGN_MEDIA_UPLOAD_SUCCESS,
      CAMPAIGN_MEDIA_UPLOAD_FAILURE,
    ],
    mark: { reference_file: 1 },
    promise: (client, fileUpload) =>
      fileUpload(file, '/inflosocampaigns/campaign-media-upload', data),
  };
}

export function campaignCommentsUpload(file, data) {
  return {
    types: [
      CAMPAIGN_COMMENTS_UPLOAD_REQUEST,
      CAMPAIGN_COMMENTS_UPLOAD_SUCCESS,
      CAMPAIGN_COMMENTS_UPLOAD_FAILURE,
    ],
    mark: { reference_file: 1 },
    promise: (client, fileUpload) =>
      fileUpload(file, '/inflosocampaigns/campaign-comment-upload', data),
  };
}

export function campaignExtraMediaUpload(file, data) {
  return {
    types: [
      CAMPAIGN_EXTRA_MEDIA_UPLOAD_REQUEST,
      CAMPAIGN_EXTRA_MEDIA_UPLOAD_SUCCESS,
      CAMPAIGN_EXTRA_MEDIA_UPLOAD_FAILURE,
    ],
    mark: { reference_file: 1 },
    promise: (client, fileUpload) =>
      fileUpload(file, '/inflosocampaigns/campaign-extra-media-upload', data),
  };
}

export function campaignImageUpload(file, data) {
  return {
    types: [
      CAMPAIGN_IMAGE_UPLOAD_REQUEST,
      CAMPAIGN_IMAGE_UPLOAD_SUCCESS,
      CAMPAIGN_IMAGE_UPLOAD_FAILURE,
    ],
    mark: { reference_file: 1 },
    promise: (client, fileUpload) =>
      fileUpload(file, '/inflosocampaigns/campaign-image-upload', data),
  };
}

export function campaignCoverUpload(file, data) {
  return {
    types: [
      CAMPAIGN_COVER_UPLOAD_REQUEST,
      CAMPAIGN_COVER_UPLOAD_SUCCESS,
      CAMPAIGN_COVER_UPLOAD_FAILURE,
    ],
    mark: { reference_file: 1 },
    promise: (client, fileUpload) =>
      fileUpload(file, '/inflosocampaigns/campaign-cover-upload', data),
  };
}

export function campaignApplicationMediaUpload(file, data, key, cb) {
  return {
    types: [
      APPLICATION_MEDIA_UPLOAD_REQUEST,
      APPLICATION_MEDIA_UPLOAD_SUCCESS,
      APPLICATION_MEDIA_UPLOAD_FAILURE,
    ],
    mark: { [key]: 1 },
    promise: (client, fileUpload, fileUploadProgress) =>
      fileUploadProgress(
        file,
        '/inflosocampaigns/campaign-application-media-upload',
        data,
        cb
      ),
  };
}

export function campaignInsightsUpload(file, data, key, cb) {
  return {
    types: [
      APPLICATION_MEDIA_UPLOAD_REQUEST,
      APPLICATION_MEDIA_UPLOAD_SUCCESS,
      APPLICATION_MEDIA_UPLOAD_FAILURE,
    ],
    mark: { [key]: 1 },
    promise: (client, fileUpload, fileUploadProgress) =>
      fileUploadProgress(
        file,
        '/inflosocampaigns/campaign-insights-upload',
        data,
        cb
      ),
  };
}

export function campaignApplicationUrlUpload(data, application_id, key) {
  // const payload = {
  //   [data.platform.toLowerCase()]:data.url
  // }
  return {
    types: [
      APPLICATION_MEDIA_UPLOAD_REQUEST,
      APPLICATION_MEDIA_UPLOAD_SUCCESS,
      APPLICATION_MEDIA_UPLOAD_FAILURE,
    ],
    mark: { [key]: 1 },
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-application-links/${application_id}`,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
        true
      ),
  };
}

export function campaignMediaData(data) {
  return {
    types: [
      CAMPAIGN_MEDIA_DATA_REQUEST,
      CAMPAIGN_MEDIA_DATA_SUCCESS,
      CAMPAIGN_MEDIA_DATA_FAILURE,
    ],
    mark: { 'campaign-media-data': 1 },
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-media-data/${data.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
        true
      ),
  };
}

export function sendChat(data) {
  return {
    types: [SEND_CHAT_REQUEST, SEND_CHAT_SUCCESS, SEND_CHAT_FAILURE],
    mark: { 'campaign-media-data': 1 },
    payload: data,
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-application-chat/${data.application_id}`,
        {
          method: 'POST',
          body: JSON.stringify(data),
        },
        true
      ),
  };
}

export function getChat(data) {
  return {
    types: [GET_CHAT_REQUEST, GET_CHAT_SUCCESS, GET_CHAT_FAILURE],
    mark: { 'campaign-media-data': 1 },
    payload: data,
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-application-chat/${data.application_id}/list?limit=1000`,
        {
          method: 'GET',
        },
        true
      ),
  };
}

export function selectDeselectMedia(data, is_selected) {
  return {
    types: [
      CAMPAIGN_MEDIA_SELECT_REQUEST,
      CAMPAIGN_MEDIA_SELECT_SUCCESS,
      CAMPAIGN_MEDIA_SELECT_FAILURE,
    ],
    mark: { 'campaign-media-selected': 1 },
    promise: (client) =>
      client(
        `/inflosocampaigns/campaign-media-select/${data.id}`,
        {
          method: 'PUT',
          body: JSON.stringify({ is_selected }),
        },
        true
      ),
  };
}

export function getInfluencersForBrand(brand_id) {
  return {
    types: [
      INFLUENCERS_FOR_BRAND_REQUEST,
      INFLUENCERS_FOR_BRAND_SUCCESS,
      INFLUENCERS_FOR_BRAND_FAILURE,
    ],
    mark: { 'influencers-for-brand': 1 },
    promise: (client) =>
      client(`/inflosocampaigns/influencers-for-brand/${brand_id}`, {}, true),
  };
}

export function allCouponList(campaign) {
  return {
    payload: {},
    mark: { 'coupon-all': 1 },
    types: [COUPON_ALL_REQUEST, COUPON_ALL_SUCCESS, COUPON_ALL_FAILURE],
    promise: (client) => client(`/inflosocampaigns/coupon/list`, {}, true),
  };
}

const CAMPAIGN_STEPS = {
  1: [
    'campaign_display_name',
    'campaign_goal',
    'campaign_desc',
    'uploaded_image',
  ],
  2: [
    'timeline',
    'start_date',
    'end_date',
    'campaign_type',
    'collaboration_type',
    'campaign_collaboration_type',
    'location',
  ],
  3: [],
  4: ['influencer_gender', 'age_group', 'influencer_category'],
  5: [],
  6: ['balance'],
};

const CAMPAIGN_STEPS_V2 = {
  1: [
    'campaign_display_name',
    'campaign_desc',
    'campaign_goal',
    'uploaded_image',
  ],
  2: ['reach', 'campaign_budget'],
  3: [
    'promote_product',
    'collaboration_type',
    // 'count_of_product',
    // 'value_of_product',
    // 'image_of_product',
  ],
  4: [
    'campaignmodes',
    'campaignmodes.written_title',
    'campaignmodes.written_hashtag',
    'campaignmodes.written_accounts_tag',
    'campaignmodes.written_content',
    'campaignmodes.oral_suggested_content',
    'campaignmodes.oral_content',
    'campaignmodes.visual_content',
    'campaignmodes.no_of_influencers',
    'campaignmodes.no_of_friends_min',
    'campaignmodes.no_of_friends_max',
    'campaignmodes.budget_per_influencer',
  ],
  5: ['influencer_gender', 'location', 'age_group', 'influencer_category'],
};

function calcStepProgressV2(campaign, wallet_balance) {
  const progress = {
    1: calcStepv2(campaign, 1, wallet_balance),
    2: calcStepv2(campaign, 2, wallet_balance),
    3: calcStepv2(campaign, 3, wallet_balance),
    4: calcStepv2(campaign, 4, wallet_balance),
    5: calcStepv2(campaign, 5, wallet_balance),
  };
  // console.log(progress);
  return progress;
}

function calcStepProgress(campaign, wallet_balance) {
  const progress = {
    1: calcStep(campaign, 1, wallet_balance),
    2: calcStep(campaign, 2, wallet_balance),
    3: calcStep(campaign, 3, wallet_balance),
    4: calcStep(campaign, 4, wallet_balance),
    5: calcStep(campaign, 5, wallet_balance),
    6: calcStep(campaign, 5, wallet_balance),
  };
  //Step 1
  // console.log('v1', progress);
  return progress;
}

function calcTotalProgress(step_progress) {
  return Object.keys(step_progress)
    .map((step) => step_progress[step])
    .reduce((prev, currentStep) => {
      return prev + currentStep.percentage;
    }, 0);
}

function checkCampaignMode(campaign, v) {
  const vv = v.split('.')[1];
  const val =
    campaign.campaignmodes && campaign.campaignmodes.length > 0
      ? campaign.campaignmodes[0][vv]
      : '';

  return (
    (typeof val === 'number' && val > 0) ||
    (typeof val === 'string' && val.length > 0)
  );
}

function calcStepv2(campaign, step, wallet_balance) {
  const required = [...CAMPAIGN_STEPS_V2[step]];
  const done = required.filter(
    (v) =>
      (campaign[v] != null &&
        ((typeof campaign[v] === 'string' &&
          campaign[v].length > 0 &&
          campaign[v] !== '0.00') ||
          (Array.isArray(campaign[v]) && campaign[v].length > 0) ||
          (typeof campaign[v] === 'number' && campaign[v] > 0) ||
          typeof campaign[v] === 'boolean')) ||
      (v.indexOf('campaignmodes.') === 0 && checkCampaignMode(campaign, v))
  );

  if (step === 2) {
    // if (campaign.recurring_frequency) {
    //   done.push('recurring_frequency');
    //   required.push('recurring_frequency');
    // }

    if (campaign.timeline) {
      done.push('timeline');
      required.push('timeline');
    }
    // if (campaign.frequency) {
    //   done.push('frequency');
    //   required.push('frequency');
    // }
  }

  if (step === 3) {
    if (campaign.campaign_collaboration_type === 'Paid') {
      CAMPAIGN_STEPS_V2[3].map((s) => {
        if (done.indexOf(s) === -1) {
          done.push(s);
        }
      });
    }

    // if (campaign.digital_product === true) {
    //   CAMPAIGN_STEPS_V2[3].map((s) => {
    //     if (done.indexOf(s) === -1) {
    //       done.push(s);
    //     }
    //   });
    // }
  }

  const pending = diffArray(done, required);
  const count = done.length;
  const total = required.length;

  const progress = {
    count,
    percentage: Math.ceil((count / total) * 100),
    total,
    done,
    pending,
  };
  return progress;
}

function calcStep(campaign, step, wallet_balance) {
  // const progress = {};
  //Step 1
  const required = [...CAMPAIGN_STEPS[step]];
  const done = required.filter((v) => campaign[v] && campaign[v].length > 0);

  //Special handling
  if (step === 2) {
    // if(campaign.name && campaign.name.startsWith('Draft ')){
    //   done.splice(done.indexOf('name'),1);
    // }

    if (campaign.timeline === 'Open Timeline') {
      if (done.indexOf('start_date') === -1) {
        done.push('start_date');
      }

      if (done.indexOf('end_date') === -1) {
        done.push('end_date');
      }
    }

    // if (campaign.campaign_collaboration_type === 'Barter') {
    //   required.push('value_of_product');
    //   if (campaign['value_of_product']) {
    //     done.push('value_of_product');
    //   }
    // }
  }

  if (step === 3) {
    if (campaign.campaignmodes && campaign.campaignmodes.length > 0) {
      campaign.campaignmodes.map((m) => {
        required.push(m.mode_name + '_campaignmodes');
        done.push(m.mode_name + '_campaignmodes');

        let social = [];
        let count = [];
        let countVal = 0;
        if (campaign.campaign_collaboration_type === 'Barter') {
          social = [
            'no_of_influencers',
            'no_of_friends_min',
            'no_of_friends_max',
          ];
        } else if (campaign.campaign_collaboration_type === 'Paid') {
          social = [
            'no_of_influencers',
            'no_of_friends_min',
            'no_of_friends_max',
            'budget_per_influencer',
          ];
        } else {
          social = [
            'no_of_influencers',
            'no_of_friends_min',
            'no_of_friends_max',
          ];
        }

        // if (m.mode_name === 'Facebook') {
        //   count = ['static_post', 'video_post', 'story_post'];
        // } else if (m.mode_name === 'Instagram') {
        //   // social = ['no_of_influencers','no_of_friends_min','no_of_friends_max','budget_per_influencer'];
        //   count = [
        //     'static_post',
        //     'video_post',
        //     'story_post',
        //     'insta_live',
        //     'highlights',
        //     'swipe_up',
        //     'igtv',
        //     'reels',
        //   ];
        // } else if (m.mode_name === 'YouTube') {
        //   // social = ['no_of_influencers','no_of_friends_min','no_of_friends_max','budget_per_influencer'];
        //   count = ['video_post', 'youtube_live'];
        // }

        count = getSocialContents(m.mode_name);

        social.map((s) => {
          required.push(m.mode_name + '_' + s);
          if (m[s] > 0 && m[s] !== '0.00') {
            done.push(m.mode_name + '_' + s);
          }
        });

        required.push(m.mode_name + '_' + 'count');
        count.map((s) => {
          if (m[s] > 0) {
            countVal++;
          }
        });

        if (countVal > 0) {
          done.push(m.mode_name + '_' + 'count');
        }
      });
    }
  }

  if (step === 4) {
    if (
      campaign.influencer_category &&
      campaign.influencer_category.length === 0
    ) {
      // done.splice(done.indexOf('influencer_category'), 1);
    }
  }

  if (step === 5) {
    const req = [
      'written_title',
      'written_hashtag',
      'written_accounts_tag',
      'written_content',
      'oral_content',
      'oral_suggested_content',
      'visual_content',
    ];
    if (campaign.campaignmodes && campaign.campaignmodes.length > 0) {
      campaign.campaignmodes.map((m) => {
        // required.push(m.mode_name + '_campaignmodes');
        // done.push(m.mode_name + '_campaignmodes');
        const filteredReq = req.filter((r) => m[r]);
        if (filteredReq.length === 0) {
          req.map((r) => {
            required.push(m.mode_name + '_' + r);
            if (m[r]) {
              done.push(m.mode_name + '_' + r);
            }
          });
        } else {
          filteredReq.map((r) => {
            required.push(m.mode_name + '_' + r);
            if (m[r]) {
              done.push(m.mode_name + '_' + r);
            }
          });
        }
      });
    }
  }

  if (step === 6) {
    if (wallet_balance >= campaign.campaign_budget) {
      done.push('balance');
    }
  }

  const pending = diffArray(done, required);
  const count = done.length;
  const total = required.length;

  const progress = {
    count,
    percentage: Math.ceil((count / total) * 100),
    total,
    done,
    pending,
  };
  return progress;
}

export function getCampaignStates() {
  return [
    'Saved',
    'Draft',
    'In Review',
    'Approved',
    'Active',
    'Completed',
    'Archived',
    'Deleted',
    'Paused',
  ];
}

export function getCampaignApplicationStates() {
  return [
    'Approved', //0
    'Submit Content', //1
    'Reject Content', //2
    'Approve Content', //3
    'Active', //4
    'Submit Link', //5
    'Reject Link', //6
    'Approve Link', //7
    'Completed', //8
    'Submit Insight', //9
    'Insight Rejected', //10
    'Insight Approved', //11
    'Archived', //12
  ];
}

export function getApplicationUpdateState(application) {
  const UPDATE_STATES = getCampaignApplicationStates();
  if (application.application_status === 'Reschedule') {
    return 0;
  }

  return UPDATE_STATES.indexOf(application.application_status);
}

export function calcNoOfInfluencers(campaign) {
  return campaign.campaignmodes
    ? campaign.campaignmodes.reduce((t, c) => t + c.no_of_influencers, 0)
    : 0;
}

export function calcEachNoOfInfluencers(campaign, num) {
  return campaign.campaignmodes
    ? campaign.campaignmodes[num].no_of_influencers
    : 0;
}

export const calcCampaignBudget = utilCalcCampaignBudget;

export const calcCampaignBudgetBreakdown = utilCalcCampaignBudgetBreakdown;

export function calcMinBudget(campaign) {
  const GST = 18;
  let totalBudget = 0;
  let totalBudgetWithGST = 0;

  let budget_per_influencer = /*
    campaign.brand && campaign.brand.barter_cost
      ? campaign.brand.barter_cost
      : */ BARTER_PRICING;

  if (campaign.type === 'Visits') {
    budget_per_influencer = VISITS_PRICING;
  }

  // if (campaign.discount) {
  //   budget_per_influencer = (campaign.discount / 100) * budget_per_influencer;
  // }

  totalBudget = campaign.campaignmodes
    ? campaign.campaignmodes.reduce(
        (t, c) => t + c.no_of_influencers * budget_per_influencer,
        0
      )
    : 0;

  totalBudgetWithGST = totalBudget + (totalBudget * GST) / 100;
  return [totalBudget, GST, totalBudgetWithGST];
}
