import * as loading from './modules/loading';
import * as toast from './modules/toast';
// import * as dialog from "./modules/dialog";
import * as user from './modules/user';
import * as ui from './modules/ui';
import * as navigation from './modules/navigation';
import * as page from './modules/page';
import * as campaign from './modules/campaign';
import * as modal from './modules/modal';
import * as common from './modules/common';
import * as wallet from './modules/wallet';

const isClient = typeof window !== 'undefined';

const actions = isClient
  ? {
      user: { ...user },
      loading: { ...loading },
      // toast: {...toast},
      // dialog: {...dialog},
      ui: { ...ui },
      navigation: { ...navigation },
      page: { ...page },
      campaign: { ...campaign },
      modal: { ...modal },
      common: { ...common },
      wallet: { ...wallet },
      toast: { ...toast },
    }
  : {
      user: {},
      loading: {},
      // toast: {},
      // dialog: {},
      ui: {},
      navigation: {},
      page: {},
      campaign: {},
      modal: {},
      common: {},
      wallet: {},
      toast: {},
    };

export default actions;
