import Alert from '../alerts/Alert';
import React from 'react';

export default function AppDownload() {
  return (
    <div className="my-5">
      <Alert title="Download the app now">
        <div className="flex flex-rows">
          <a
            href="https://apps.apple.com/in/app/infloso/id1659660559"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/badge/appstore_badge_en.svg"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '10px',
              }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.infloso"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/badge/googleplay_badge_en.svg"
              style={{ height: '60px', margin: 'auto' }}
            />
          </a>
        </div>
      </Alert>
    </div>
  );
}
