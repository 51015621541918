import { useState } from 'react';

const ls =
  typeof localStorage !== 'undefined'
    ? localStorage
    : {
        getItem: () => {},
        setItem: () => {},
        removeItem: () => {},
      };

export default function useLocalStorage(key, initialValue, json = false) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = ls.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? (json ? JSON.parse(item) : item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);

      if (valueToStore === null) {
        // Remove from localstorage if null is set in value
        ls.removeItem(key);
      } else {
        // Save to local storage
        ls.setItem(key, json ? JSON.stringify(valueToStore) : valueToStore);
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  };

  return [storedValue, setValue];
}
