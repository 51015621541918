import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import ColorIcon from '../icon/ColorIcon';

export default function HashTag(props) {
  const {
    cicon,
    name,
    children,
    selected,
    onClick,
    bg = '#0074D9',
    text = 'white',
    type = 'normal',
    size = 1,
  } = props;

  const style = selected
    ? { backgroundColor: onClick ? '#0074D9' : '', color: 'white' }
    : {
        // 'backgroundColor':'#f7f7ff'
        // 'backgroundColor':'#edf2f7'
        backgroundColor: onClick ? '' : type === 'light' ? 'hsl(0,0%,90%)' : bg,
        color: onClick ? '' : type === 'light' ? 'hsl(0,0%,20%)' : text,
      };

  const font = size === 1 ? 'text-xxs mr-2' : 'text-xs';

  return (
    <span
      className={`${font} select-none tracking-wider display-inline font-medium rounded-lg p-1 px-2 text-brand-dark cursor-default ${
        cicon && 'flex flex-row'
      } ${onClick ? 'border-2 border-brand-light cursor-pointer' : ''}`}
      onClick={onClick}
      style={style}
    >
      {cicon && (
        <span className={`flex-initial mr-1 ${cicon ? '' : 'hidden'}`}>
          {cicon && <ColorIcon icon={cicon} />}
          {/*<FontAwesomeIcon icon={props.icon || faHashtag} size="sm" className="mr-1" />*/}
        </span>
      )}
      <span className={`flex-initial whitespace-no-wrap ${cicon && ''}`}>
        {name || children} {onClick && selected ? 'X' : ''}
      </span>
    </span>
  );
}
