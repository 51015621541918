import { cdnDomain, isServer } from '../../util';
import { action, actionNext, find, get } from '../../es';
import {
  parseEachCampaignApplicationData,
  parseEachCampaignData,
} from '../../mapping';
import { LOGOUT_SUCCESS } from './user';

export const PAGE_DATA_REQUEST = 'page/PAGE_DATA_REQUEST';
export const PAGE_DATA_SUCCESS = 'page/PAGE_DATA_SUCCESS';
export const PAGE_DATA_FAILURE = 'page/PAGE_DATA_FAILURE';

export const NEXT_DATA_REQUEST = 'page/NEXT_DATA_REQUEST';
export const NEXT_DATA_SUCCESS = 'page/NEXT_DATA_SUCCESS';
export const NEXT_DATA_FAILURE = 'page/NEXT_DATA_FAILURE';

export const PAGE_DATA_ES_REQUEST = 'page/PAGE_DATA_ES_REQUEST';
export const PAGE_DATA_ES_SUCCESS = 'page/PAGE_DATA_ES_SUCCESS';
export const PAGE_DATA_ES_FAILURE = 'page/PAGE_DATA_ES_FAILURE';

export const DOC_DATA_ES_REQUEST = 'page/DOC_DATA_ES_REQUEST';
export const DOC_DATA_ES_SUCCESS = 'page/DOC_DATA_ES_SUCCESS';
export const DOC_DATA_ES_FAILURE = 'page/DOC_DATA_ES_FAILURE';

export const PAGE_COUNT_ES_REQUEST = 'page/PAGE_COUNT_ES_REQUEST';
export const PAGE_COUNT_ES_SUCCESS = 'page/PAGE_COUNT_ES_SUCCESS';
export const PAGE_COUNT_ES_FAILURE = 'page/PAGE_COUNT_ES_FAILURE';

export const NEXT_DATA_ES_REQUEST = 'page/NEXT_DATA_ES_REQUEST';
export const NEXT_DATA_ES_SUCCESS = 'page/NEXT_DATA_ES_SUCCESS';
export const NEXT_DATA_ES_FAILURE = 'page/NEXT_DATA_ES_FAILURE';

export const CREATE_ROLES_REQUEST = 'page/CREATE_ROLES_REQUEST,';
export const CREATE_ROLES_SUCCESS = 'page/CREATE_ROLES_SUCCESS,';
export const CREATE_ROLES_FAILURE = 'page/CREATE_ROLES_FAILURE,';

export const CREATE_BRAND_MANAGERS_REQUEST =
  'page/CREATE_BRAND_MANAGERS_REQUEST,';
export const CREATE_BRAND_MANAGERS_SUCCESS =
  'page/CREATE_BRAND_MANAGERS_SUCCESS,';
export const CREATE_BRAND_MANAGERS_FAILURE =
  'page/CREATE_BRAND_MANAGERS_FAILURE,';

export const CREATE_PLANS_REQUEST = 'page/CREATE_PLANS_REQUEST,';
export const CREATE_PLANS_SUCCESS = 'page/CREATE_PLANS_SUCCESS,';
export const CREATE_PLANS_FAILURE = 'page/CREATE_PLANS_FAILURE,';

export const SEND_SMS_REQUEST = 'page/SEND_SMS_REQUEST';
export const SEND_SMS_SUCCESS = 'page/SEND_SMS_SUCCESS';
export const SEND_SMS_FAILURE = 'page/SEND_SMS_FAILURE';

export const SEND_EMAIL_REQUEST = 'page/SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'page/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'page/SEND_EMAIL_FAILURE';

export const SEND_PUSH_REQUEST = 'page/SEND_PUSH_REQUEST';
export const SEND_PUSH_SUCCESS = 'page/SEND_PUSH_SUCCESS';
export const SEND_PUSH_FAILURE = 'page/SEND_PUSH_FAILURE';

export const SEND_BROADCAST_REQUEST = 'page/SEND_BROADCAST_REQUEST';
export const SEND_BROADCAST_SUCCESS = 'page/SEND_BROADCAST_SUCCESS';
export const SEND_BROADCAST_FAILURE = 'page/SEND_BROADCAST_FAILURE';

export const CAMPAIGN_MODE_EACH_SAVE_REQUEST =
  'campaign/CAMPAIGN_MODE_EACH_SAVE_REQUEST';
export const CAMPAIGN_MODE_EACH_SAVE_SUCCESS =
  'campaign/CAMPAIGN_MODE_EACH_SAVE_SUCCESS';
export const CAMPAIGN_MODE_EACH_SAVE_FAILURE =
  'campaign/CAMPAIGN_MODE_EACH_SAVE_FAILURE';

export const GET_NOTIFICATIONS_REQUEST = 'page/GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'page/GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'page/GET_NOTIFICATIONS_FAILURE';

export const SET_FILTER = 'page/SET_FILTER';

const initialState = {
  // ...users[1],
  status: 'pending',
  filter: {},
};

export const STATE = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    case SET_FILTER: {
      const payload = action.payload;
      const filter = { ...state.filter, [payload.type]: payload.filter };
      return {
        ...state,
        filter,
        [payload.type]: null,
        [action.payload + '_next']: null,
      };
    }
    case PAGE_DATA_SUCCESS: {
      const data = isServer()
        ? parsePageData(
            action.payload,
            action.result && action.result.results
              ? action.result.results
              : action.result,
            action.data
          )
        : action.result;
      return {
        ...state,
        [action.payload]: data,
        [action.payload + '_next']: action.result.next,
        status: STATE.SUCCESS,
      };
    }
    case NEXT_DATA_SUCCESS: {
      const data = isServer()
        ? parsePageData(
            action.payload,
            action.result && action.result.results
              ? action.result.results
              : action.result
          )
        : action.result;

      const alldata = [...state[action.payload], ...data];
      return {
        ...state,
        [action.payload]: alldata,
        [action.payload + '_next']: action.result.next,
        status: STATE.SUCCESS,
      };
    }
    case PAGE_DATA_ES_SUCCESS: {
      const { index, query, fields, sort, from, size } = action.payload;
      const idata =
        action.result && action.result.results
          ? action.result.results
          : action.result;
      const data = isServer()
        ? parsePageData(index, idata, action.data)
        : action.result;

      return {
        ...state,
        [index]: data,
        [index + '_next']: action.result.next,
        [index + '_last_payload']:
          idata && idata.length >= size ? action.payload : null,
        status: STATE.SUCCESS,
      };
    }
    case DOC_DATA_ES_SUCCESS: {
      const { index, id, type } = action.payload;
      const data = parsePageData(
        type,
        action.result && action.result.results
          ? action.result.results
          : action.result
      );

      const influencers = state[index] || [];
      const newData = [...influencers];
      const oldEle = newData.filter((d) => d.id === id)[0];
      const eleIndex = newData.indexOf(oldEle);
      newData[eleIndex] = data;
      return {
        ...state,
        [index]: newData,
      };
    }
    case NEXT_DATA_ES_SUCCESS: {
      const { index, query, fields, sort, from, size } = action.payload;

      const data = isServer()
        ? parsePageData(
            index,
            action.result && action.result.results
              ? action.result.results
              : action.result
          )
        : action.result;

      const alldata = [...state[index], ...data];
      return {
        ...state,
        [index]: alldata,
        [index + '_next']: action.result.next,
        [index + '_last_payload']:
          data && data.length >= size ? action.payload : null,
        status: STATE.SUCCESS,
      };
    }
    case CAMPAIGN_MODE_EACH_SAVE_SUCCESS: {
      const { campaign } = action.payload;
      const updatedMode = action.result;
      const id = updatedMode.id;
      const scampaign = state.campaigns.filter((c) => c.id === campaign.id)[0];
      const campaignIndex = state.campaigns.indexOf(scampaign);
      const modes = [...scampaign.campaignmodes];
      const foundMode = modes.filter((m) => m.id == id)[0];
      const index = modes.indexOf(foundMode);
      modes[index] = updatedMode;
      state.campaigns[campaignIndex].campaignmodes[index] = updatedMode;

      return { ...state, campaign: state.campaigns, status: STATE.SUCCESS };
    }

    default: {
      return state;
    }
  }
}

export function approvePlatform(campaign, campaignmode) {
  const payload = {
    mode_name: campaignmode.mode_name,
    budget_per_influencer: campaignmode.budget_per_influencer,
    is_active: true,
  };

  return {
    payload: { campaign },
    mark: { 'approve-platform': 1 },
    types: [
      CAMPAIGN_MODE_EACH_SAVE_REQUEST,
      CAMPAIGN_MODE_EACH_SAVE_SUCCESS,
      CAMPAIGN_MODE_EACH_SAVE_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocampaigns/campaignmode/${campaignmode.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function load(url, params, auth, key, data) {
  return {
    payload: key,
    data: data,
    types: [PAGE_DATA_REQUEST, PAGE_DATA_SUCCESS, PAGE_DATA_FAILURE],
    promise: (client) => client(url, params, auth),
  };
}

export function loadNext(type) {
  return (dispatch, getState) => {
    const next = { ...getState().page[type + '_last_payload'] } || {};

    if (next && next.size) {
      next.from = next.from + next.size;

      // return dispatch({
      //   payload: next,
      //   types: [NEXT_DATA_REQUEST, NEXT_DATA_SUCCESS, NEXT_DATA_FAILURE],
      //   promise: (client) => client(next, {}, true),
      // });

      return dispatch(
        actionNext(type, next.query, null, next.sort, next.from, next.size)
      );
    } else {
      return dispatch({
        types: ['', '', ''],
        promise: new Promise((resolve) => resolve()),
      });
    }
  };
}

export function createRole(role) {
  return {
    payload: role,
    mark: { 'create-role': 1 },
    types: [CREATE_ROLES_REQUEST, CREATE_ROLES_SUCCESS, CREATE_ROLES_FAILURE],
    promise: (client) =>
      client(
        '/inflosocommons/role',
        { method: 'POST', body: JSON.stringify(role) },
        true
      ),
  };
}

export function createBrandManager(brandManager) {
  return {
    payload: brandManager,
    mark: { 'create-brandManager': 1 },
    types: [
      CREATE_BRAND_MANAGERS_REQUEST,
      CREATE_BRAND_MANAGERS_SUCCESS,
      CREATE_BRAND_MANAGERS_FAILURE,
    ],
    promise: (client) =>
      client(
        '/inflosousers/brand-manager',
        { method: 'POST', body: JSON.stringify(brandManager) },
        true
      ),
  };
}

export function createPlan(plans) {
  return {
    payload: plans,
    mark: { 'create-plan': 1 },
    types: [CREATE_PLANS_REQUEST, CREATE_PLANS_SUCCESS, CREATE_PLANS_FAILURE],
    promise: (client) =>
      client(
        '/inflososubs/agencyplan',
        { method: 'POST', body: JSON.stringify(plans) },
        true
      ),
  };
}

export function updateRole(role, id) {
  return {
    payload: role,
    mark: { 'update-role': 1 },
    types: [CREATE_ROLES_REQUEST, CREATE_ROLES_SUCCESS, CREATE_ROLES_FAILURE],
    promise: (client) =>
      client(
        `/inflosocommons/role/${id}`,
        { method: 'PUT', body: JSON.stringify(role) },
        true
      ),
  };
}

export function updatePlan(plans, id) {
  return {
    payload: plans,
    mark: { 'update-plan': 1 },
    types: [CREATE_PLANS_REQUEST, CREATE_PLANS_SUCCESS, CREATE_PLANS_FAILURE],
    promise: (client) =>
      client(
        `/inflososubs/agencyplan/${id}`,
        { method: 'PUT', body: JSON.stringify(plans) },
        true
      ),
  };
}

export function updateBrandManager(brandManager, id) {
  return {
    payload: brandManager,
    mark: { 'update-brandManager': 1 },
    types: [
      CREATE_BRAND_MANAGERS_REQUEST,
      CREATE_BRAND_MANAGERS_SUCCESS,
      CREATE_BRAND_MANAGERS_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosousers/brand-manager/${id}`,
        { method: 'PUT', body: JSON.stringify(brandManager) },
        true
      ),
  };
}

export function sendSms(data) {
  return {
    payload: data,
    mark: { 'send-sms': 1 },
    types: [SEND_SMS_REQUEST, SEND_SMS_SUCCESS, SEND_SMS_FAILURE],
    promise: (client) =>
      client(
        '/inflosocoreutils/send_sms',
        { method: 'POST', body: JSON.stringify(data) },
        true
      ),
  };
}

export function sendBroadcast(data) {
  return {
    payload: data,
    mark: { 'send-sms': 1 },
    types: [
      SEND_BROADCAST_REQUEST,
      SEND_BROADCAST_SUCCESS,
      SEND_BROADCAST_FAILURE,
    ],
    promise: (client) =>
      client(
        '/inflosocoreutils/send_broadcast',
        { method: 'POST', body: JSON.stringify(data) },
        true
      ),
  };
}

export function sendEmail(data) {
  return {
    payload: data,
    mark: { 'send-email': 1 },
    types: [SEND_EMAIL_REQUEST, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILURE],
    promise: (client) =>
      client(
        '/inflosocoreutils/send_email',
        { method: 'POST', body: JSON.stringify(data) },
        true
      ),
  };
}

export function sendPush(data) {
  return {
    payload: data,
    mark: { 'send-email': 1 },
    types: [SEND_PUSH_REQUEST, SEND_PUSH_SUCCESS, SEND_PUSH_FAILURE],
    promise: (client) =>
      client(
        '/inflosocoreutils/send_push',
        { method: 'POST', body: JSON.stringify(data) },
        true
      ),
  };
}

export function loadList(type, id, filter, sort, limit = 39, page = 0) {
  switch (type) {
    case 'influencers':
      return loadInfluencers(filter, sort, limit, page);
    case 'agency':
      return loadAgency(filter, limit, page);
    case 'brands':
      return loadBrands(filter, sort, limit, page);
    case 'notifications':
      return loadNotifications();
    case 'passbooks':
      return loadPassbook();
    case 'wallets':
      return loadWallets();
    case 'wallet':
      return loadAllWallets();
    case 'campaigns':
      return loadCampaigns(filter, sort, limit);
    case 'campaign_applications':
      return loadCampaignApplications(filter, limit);
    case 'minfluencer':
      return loadMInfluencer(id);
    case 'influencer':
      return loadInfluencer(id);
    case 'brand':
      return loadBrand(id);
    case 'campaign':
      return loadCampaign(id);
    case 'roles':
      return loadRoles();
    case 'role':
      return loadRole(id);
    case 'plans':
      return loadPlans();
    case 'plan':
      return loadPlan(id);
    case 'permissions':
      return loadPermissions();
    case 'permission':
      return loadPermission(id);
    case 'brandManagers':
      return loadbrandManagers(limit, id);
    case 'brandManager':
      return loadbrandManager(id);
    case 'agency-brands':
      return loadAgencyBrands();
    case 'agency-brand':
      return loadAgencyBrand(id);
    default:
      return {};
  }
}

export function loadAllWallets(limit = 50) {
  if (isServer()) {
    return load(
      '/inflosowallet/get-all-wallet?limit=' + limit,
      {},
      true,
      'wallet'
    );
  } else {
    //{{baseurl}}/inflosousers/influencers
    return load('/api/modules/wallet', {}, false, 'wallet');
  }
}

export function loadPlans() {
  if (isServer()) {
    return load('/inflososubs/agencyplans', {}, true, 'plans');
  } else {
    return load('/inflososubs/agencyplans', {}, false, 'plans');
  }
}

export function loadbrandManagers(limit = 50, id) {
  if (isServer()) {
    return load(
      `/inflosousers/brand-managers?limit=${limit}`,
      {},
      true,
      'brandManagers',
      { agency_id: id }
    );
  } else {
    return load(
      `/inflosousers/brand-managers?limit=${limit}`,
      {},
      true,
      'brandManagers',
      { agency_id: id }
    );
  }
}

export function loadbrandManager(id) {
  if (isServer()) {
    return load(`/inflosousers/brand-managers/${id}`, {}, true, 'brandManager');
  } else {
    return load(`/inflosousers/brand-managers/${id}`, {}, true, 'brandManager');
  }
}

export function loadPlan(id) {
  if (isServer()) {
    return load(`/inflososubs/agencyplans/${id}`, {}, true, 'plan');
  } else {
    return load(`/inflososubs/agencyplans/${id}`, {}, true, 'plan');
  }
}

export function getInfluencerQueryFromFilter(filter) {
  return Object.keys(filter).length > 0 ? Object.keys(filter)
    .map((k) => {
      switch (k) {
        case 'is_active':
          return 'user.is_active:true';
        case 'q':
          return filter[k];
        case 'text':
          if (filter[k]) {
            return (
              '(user.first_name:' +
              filter[k] +
              '* OR user.last_name:' +
              filter[k] +
              '* OR user.username.keyword:' +
              filter[k] +
              '* OR influencer_profile.social_profiles.profile_name.keyword:' +
              filter[k] +
              '* OR user.email.keyword:' +
              filter[k] +
              '* OR mobile:' +
              filter[k] +
              '* OR id:' +
              filter[k] +
              ')'
            );
          } else {
            return null;
          }
        case 'social_profiles__social_media_platform':
          if (filter[k] && filter[k].length > 0) {
            const key =
              'influencer_profile.social_profiles.social_media_platform:';
            return '(' + filter[k].map((v) => key + v).join(' OR ') + ')';
          } else {
            return null;
          }
        case 'influencergender':
          if (filter[k] && filter[k].length > 0) {
            return (
              '(' + filter[k].map((v) => 'gender' + ':' + v).join(' OR ') + ')'
            );
          } else {
            return null;
          }
        case 'influencer__location':
          if (filter[k] && filter[k].length > 0) {
            return (
              '(' +
              filter[k]
                .split('|')
                .map((v) => 'location' + ':' + v)
                .join(' OR ') +
              ')'
            );
          } else {
            return null;
          }
        case 'category__category_name':
          return (
            '(' +
            filter[k]
              .map((v) => {
                return (
                  'influencer_profile.category' +
                  ':"' +
                  encodeURIComponent(v) +
                  '"'
                );
              })
              .join(' OR ') +
            ')'
          );
        // case 'platforms':
        //   return (
        //     '(' +
        //     filter[k]
        //       .map((v) => {
        //         return 'campaign_mode.mode_name' + ':"' + v + '"';
        //       })
        //       .join(' OR ') +
        //     ')'
        //   );
        // default:
        //   return k + ':' + filter[k];
      }
    })
    .filter((a) => a)
    .join(' AND ') : 'influencer_profile.followers_count:[100000 TO 500000000]';
}

export function loadInfluencers(
  filter,
  sort,
  limit = 20,
  page = 0,
  raw = false
) {
  if (isServer()) {
    return (dispatch, getState) => {
      const categories = getState().common.categories_ids;
      try {
        const query = filter ? getInfluencerQueryFromFilter({ ...filter }) : '';
        return dispatch(
          action('influencers', query, null, sort, page * limit, limit, raw)
        );
      } catch (e) {
        console.log(e);
      }
    };

    // return load(
    //   `/inflosousers/influencer-filter?limit=20&${pfilter}`,
    //   {},
    //   true,
    //   'influencers'
    // );
    // return load('/inflosousers/influencers?limit=20', {}, true, 'influencers');
  } else {
    //{{baseurl}}/inflosousers/influencers
    return load('/api/modules/influencers', {}, false, 'influencers');
  }
}

export function loadMInfluencer(id) {
  return find('influencers', id, false, 'influencer');
}

export function loadInfluencer(id) {
  if (isServer()) {
    return load('/inflosousers/influencers/' + id, {}, true, 'influencer');
  } else {
    //{{baseurl}}/inflosousers/influencers
    return load('/api/modules/influencers', {}, false, 'influencer');
  }
}

export function loadPermission(id) {
  if (isServer()) {
    return load('/inflosocommons/permissions/' + id, {}, true, 'permission');
  } else {
    //{{baseurl}}/inflosousers/influencers
    return load('/inflosocommons/permissions/' + id, {}, true, 'permission');
  }
}

export function count(type, query, fields) {
  // return (dispatch, getState)=> {
  //   return dispatch(
  return action(type, query, fields, null, 0, 0, true, true);
  // );
  // }
}

export function loadRoles() {
  if (isServer()) {
    return load('/inflosocommons/roles/', {}, true, 'roles');
  } else {
    //{{baseurl}}/inflosousers/influencers
    return load('/inflosocommons/roles/', {}, false, 'roles');
  }
}

export function loadRole(id) {
  if (isServer()) {
    return load(`/inflosocommons/roles/${id}`, {}, true, 'role');
  } else {
    //{{baseurl}}/inflosousers/influencers
    return load(`/inflosocommons/roles/${id}`, {}, false, 'role');
  }
}

export function loadNotifications(limit = 50) {
  if (isServer()) {
    return load(
      `/inflosocommons/get-notifications/?limit=${limit}`,
      {},
      true,
      'notifications'
    );
  } else {
    return load(
      `/inflosocommons/get-notifications/?limit=${limit}`,
      {},
      true,
      'notifications'
    );
  }
}

export function loadWallets(limit = 50) {
  if (isServer()) {
    return load(
      `/inflosowallet/get-all-wallet?limit=${limit}`,
      {},
      true,
      'wallets'
    );
  } else {
    return load(
      `/inflosowallet/get-all-wallet?limit=${limit}`,
      {},
      true,
      'wallets'
    );
  }
}

export function loadPassbook(limit = 50) {
  if (isServer()) {
    return load(
      `/inflosowallet/get-wallets-transactions/?limit=${limit}`,
      {},
      true,
      'passbooks'
    );
  } else {
    return load(
      `/inflosowallet/get-wallets-transactions/?limit=${limit}`,
      {},
      true,
      'passbooks'
    );
  }
}

export function loadAgencyBrands(limit = 50, id) {
  return load(
    id
      ? `/inflosousers/agency-brands/list/${id}?limit=${limit}`
      : `/inflosousers/agency-brands?limit=${limit}`,
    {},
    true,
    'agency-brands'
  );
}

export function loadAgencyBrand(id) {
  return load(`/inflosousers/agency-brands/${id}`, {}, true, 'agency-brand');
}

export function loadBrands(filter, sort) {
  if (isServer()) {
    return (dispatch, getState) => {
      const industries = getState().common.industries_ids;
      let query = null;
      if (typeof filter === 'string') {
        query = filter;
      } else {
        query = Object.keys(filter || {})
          .map((k) => {
            switch (k) {
              case 'text':
                if (filter[k]) {
                  return (
                    '(' +
                    'user.first_name:' +
                    filter[k] +
                    '* OR user.username.keyword:' +
                    filter[k] +
                    '* OR user.email.keyword:' +
                    filter[k] +
                    '* OR mobile:' +
                    filter[k] +
                    '* OR id:' +
                    filter[k] +
                    ')'
                  );
                } else {
                  return null;
                }
              case 'brand__location':
                if (filter[k] && filter[k].length > 0) {
                  return (
                    '(' +
                    filter[k]
                      .split('|')
                      .map((v) => 'location' + ':' + v)
                      .join(' OR ') +
                    ')'
                  );
                } else {
                  return null;
                }
              case 'industry__industry_name':
                return (
                  '(' +
                  filter[k]
                    .map((v) => {
                      return (
                        'brand_profile.industries' +
                        ':"' +
                        encodeURIComponent(v) +
                        '"'
                      );
                    })
                    .join(' OR ') +
                  ')'
                );
              // default:
              //   return k + ':' + filter[k];
            }
          })
          .filter((a) => a)
          .join(' AND ');
      }
      return dispatch(action('brands', query, null, sort));
    };

    // return load(
    //   `/inflosousers/brand-filter?limit=20&${pfilter}`,
    //   {},
    //   true,
    //   'brands'
    // );
    // return load('/inflosousers/brands?limit=20', {}, true, 'brands');
  } else {
    return load('/api/modules/brands', {}, false, 'brands');
  }
}

export function loadAgency(filter, limit = 10, page = 0) {
  if (isServer()) {
    // const pfilter = processFilter(
    //   filter && filter.agency ? filter.agencys : null
    // );

    const query = Object.keys(filter || {})
      .map((k) => {
        switch (k) {
          case 'text':
            if (filter[k]) {
              return (
                '(' +
                'user.first_name:' +
                filter[k] +
                '* OR user.username.keyword:' +
                filter[k] +
                '* OR user.email.keyword:' +
                filter[k] +
                '* OR mobile:' +
                filter[k] +
                '* OR id:' +
                filter[k] +
                ')'
              );
            } else {
              return null;
            }
          case 'brand__location':
            if (filter[k] && filter[k].length > 0) {
              return (
                '(' +
                filter[k]
                  .split('|')
                  .map((v) => 'location' + ':' + v)
                  .join(' OR ') +
                ')'
              );
            } else {
              return null;
            }
          case 'industry__industry_name':
            return (
              '(' +
              filter[k]
                .map((v) => {
                  return (
                    'brand_profile.industries' +
                    ':"' +
                    encodeURIComponent(v) +
                    '"'
                  );
                })
                .join(' OR ') +
              ')'
            );
          // default:
          //   return k + ':' + filter[k];
        }
      })
      .filter((a) => a)
      .join(' AND ');

    return action('agency', query, null, null, page * limit, limit);

    // return load(
    //   `/inflosousers/agency-filter?limit=20&${pfilter}`,
    //   {},
    //   true,
    //   'agencys'
    // );
  } else {
    return load('/api/modules/agencys', {}, false, 'agencys');
  }
}

export function loadBrand(id) {
  if (isServer()) {
    return load('/inflosousers/brands/' + id, {}, true, 'brand');
  } else {
    return load('/api/modules/brands', {}, false, 'brand');
  }
}

export function loadCampaigns(filter, sort, limit = 10, page = 0) {
  if (isServer()) {
    // const pfilter = processFilter(
    //   filter && filter.campaigns ? filter.campaigns : null
    // );

    let query = null;
    if (typeof filter === 'string') {
      query = filter;
    } else {
      query = Object.keys(filter || {})
        .map((k) => {
          switch (k) {
            case 'active':
              // console.log('filterk', k, filter[k]);
              if (filter[k] === undefined || filter[k] === false) {
                return 'campaign_state:Active';
              } else {
                return null;
              }
            case 'text':
              if (filter[k]) {
                return (
                  '(campaign_display_name:*' +
                  filter[k] +
                  '* OR id:' +
                  filter[k] +
                  ')'
                );
              } else {
                return null;
              }
            case 'brand__location':
              if (filter[k] && filter[k].length > 0) {
                return (
                  '(' +
                  filter[k]
                    .split('|')
                    .map((v) => 'location' + ':' + v)
                    .join(' OR ') +
                  ')'
                );
              } else {
                return null;
              }
            case 'collaboration_type':
              if (filter[k] && filter[k].length > 0) {
                return (
                  '(' +
                  filter[k]
                    .map((v) => {
                      return 'collaboration_type' + ':"' + v + '"';
                    })
                    .join(' OR ') +
                  ')'
                );
              } else {
                return null;
              }
            case 'platforms':
              if (filter[k] && filter[k].length > 0) {
                return (
                  '(' +
                  filter[k]
                    .map((v) => {
                      return 'campaign_mode.mode_name' + ':"' + v + '"';
                    })
                    .join(' OR ') +
                  ' AND campaign_mode.is_active:true)'
                );
              } else {
                return null;
              }
            case 'query':
              return filter[k] ? '(' + filter[k] + ')' : null;
            // default:
            //   return k + ':' + filter[k];
          }
        })
        .filter((a) => a)
        .join(' AND ');
    }
    //done collaboration_type,
    //owner__username&brand&campaign_name=&alias&location&start_date&end_date&&campaign_collaboration_type&keywords__keyword_name&categories__category_name&industries__industry_name
    return action('campaigns', query, null, sort, page * limit, limit);

    // return load(
    //   `/inflosocampaigns/campaign-filter?limit=${limit}&${pfilter}`,
    //   {},
    //   true,
    //   'campaigns'
    // );
    // return load('/inflosocampaigns/active-campaigns?limit=20', {}, true, 'campaigns');
  } else {
    return load('/api/modules/campaigns', {}, false, 'campaigns');
  }
}

export function loadCampaignApplications(filter, limit = 10, page = 0) {
  if (isServer()) {
    // const pfilter = processFilter(
    //   filter && filter.campaigns ? filter.campaigns : null
    // );

    let query = null;
    if (typeof filter === 'string') {
      query = filter;
    }
    //else {
    //   query = Object.keys(filter || {})
    //     .map((k) => {
    //       switch (k) {
    //         case 'text':
    //           if (filter[k]) {
    //             return (
    //               '(campaign_display_name:*' +
    //               filter[k] +
    //               '* OR id:' +
    //               filter[k] +
    //               ')'
    //             );
    //           } else {
    //             return null;
    //           }
    //         case 'brand__location':
    //           if (filter[k] && filter[k].length > 0) {
    //             return (
    //               '(' +
    //               filter[k]
    //                 .split('|')
    //                 .map((v) => 'location' + ':' + v)
    //                 .join(' OR ') +
    //               ')'
    //             );
    //           } else {
    //             return null;
    //           }
    //         case 'collaboration_type':
    //           return (
    //             '(' +
    //             filter[k]
    //               .map((v) => {
    //                 return 'collaboration_type' + ':"' + v + '"';
    //               })
    //               .join(' OR ') +
    //             ')'
    //           );
    //         case 'platforms':
    //           return (
    //             '(' +
    //             filter[k]
    //               .map((v) => {
    //                 return 'campaign_mode.mode_name' + ':"' + v + '"';
    //               })
    //               .join(' OR ') +
    //             ')'
    //           );
    //         // default:
    //         //   return k + ':' + filter[k];
    //       }
    //     })
    //     .filter((a) => a)
    //     .join(' AND ');
    // }
    //done collaboration_type,
    //owner__username&brand&campaign_name=&alias&location&start_date&end_date&&campaign_collaboration_type&keywords__keyword_name&categories__category_name&industries__industry_name
    return action(
      'campaign_applications',
      query,
      null,
      null,
      page * limit,
      limit
    );

    // return load(
    //   `/inflosocampaigns/campaign-filter?limit=${limit}&${pfilter}`,
    //   {},
    //   true,
    //   'campaigns'
    // );
    // return load('/inflosocampaigns/active-campaigns?limit=20', {}, true, 'campaigns');
  } else {
    return load('/api/modules/campaigns', {}, false, 'campaigns');
  }
}

export function loadPermissions() {
  if (isServer()) {
    return load('/inflosocommons/permissions', {}, true, 'permissions');
  } else {
    //{{baseurl}}/inflosousers/influencers
    return load('/inflosocommons/permissions', {}, false, 'permissions');
  }
}

export function loadPayments(limit = 50) {
  if (isServer()) {
    return load(
      `/inflosowallet/get-wallets-transactions/?limit=${limit}`,
      {},
      true,
      'payments'
    );
  } else {
    //{{baseurl}}/inflosousers/influencers
    return load(
      `/inflosowallet/get-wallets-transactions/?limit=${limit}`,
      {},
      false,
      'payments'
    );
  }
}

export function setFilter(type, filter) {
  return {
    payload: { type, filter },
    type: SET_FILTER,
  };
}

export function createAgency(agencyDetails) {
  if (isServer()) {
    return load(
      '/inflosousers/agency-brand',
      { method: 'POST', body: JSON.stringify(agencyDetails) },
      true,
      'agency'
    );
  } else {
    return load(
      '/inflosousers/agency-brand',
      { method: 'POST', body: JSON.stringify(agencyDetails) },
      false,
      'agency'
    );
  }
}

function parsePageData(type, data, payloadData) {
  switch (type) {
    case 'agency-brands':
      return parseAgencyBrandData(data);
    case 'agency-brand':
      return parseEachAgencyBrandData(data);
    case 'influencers':
      return parseInfluencerData(data);
    case 'agencys':
      return parseAgencysData(data);
    case 'agency':
      return parseAgencysData([data]);
    case 'brands':
      return parseBrandData(data);
    case 'notifications':
      return parseNotificationsData(data);
    case 'passbooks':
      return parsePassbookData(data);
    case 'campaigns':
      return parseCampaignData(data);
    case 'campaign_applications':
      return parseCampaignApplicationsData(data);
    case 'payments':
      return parsePaymentsData(data);
    case 'payment':
      return parsePaymentData(data);
    case 'wallets':
      return parseWalletsData(data);
    case 'wallet':
      return parseWalletData(data);
    case 'influencer':
      return parseEachInfluencerData(data);
    case 'brand':
      return parseEachBrandData(data);
    case 'campaign':
      return parseEachCampaignData(data);
    case 'roles':
      return parseRolesData(data);
    case 'role':
      return parseRoleData(data);
    case 'plans':
      return parsePlansData(data);
    case 'plan':
      return parsePlanData(data);
    case 'permissions':
      return parsePermissionsData(data);
    case 'permission':
      return parsePermissionData(data);
    case 'brandManagers':
      return parseBrandManagersData(data, payloadData);
    case 'brandManager':
      return parseEachBrandManagerData(data);
    default:
      return {};
  }
}

function parseBrandManagersData(data, payloadData) {
  return data && !data.error_code
    ? data
        .filter((d) => d.agency === payloadData.agency_id)
        .map(parseEachBrandManagerData)
        .sort((a, b) => b.id - a.id)
    : [];
}

function parseEachBrandManagerData(data) {
  const brand_profile = data.brand_profile || {};
  return {
    username: data.user.username,
    user_id: data.user.id,
    id: data.id,
    type_id: data.id,
    agency: data.agency,
    name:
      data.user.first_name +
      (data.user.last_name ? ' ' + data.user.last_name : ''),
    email: data.user.email,
    email_verified: data.email_verified,
    location: data.location,
    mobile: data.mobile,
    mobile_verified: data.mobile_verified,
    verified: data.verified,
    account_status: data.account_status,
    company: 'None',
    description: '', //data.tagLine,
    bio: data.bio,
    img: data.brandlogo,
    image: data.brandlogo,
    cover: data.coverImage,
    color: 'red',
    country: 'India',
    type: 'brand-manager',
    typeStr: 'Brand Manager',
    type_display: 'Brand Manager',
    brand_profile: data.brand_profile,
    user: data.user,
    business_name: brand_profile.business_name,
    legal_name: brand_profile.legal_name,
    gst_number: brand_profile.gst_number,
    pan_or_tan: brand_profile.pan_or_tan,
    tax_certificate: brand_profile.tax_certificate,
    pan_or_tan_image: brand_profile.pan_or_tan_image,
    completed_campaigns: data.completed_campaigns,
    active_campaigns: data.active_campaigns,
    influencers_count: data.influencers_count,
    active_promotions: data.active_promotions,
    successful_promotions: data.successful_promotions,
    industries: data['brand_profile'] ? data['brand_profile'].industries : [],
    address: {},
  };
}

function parseAgencysData(data) {
  if (data && data.length > 0 && Array.isArray(data[0])) {
    data = data[0];
  }
  return data ? data.map(parseEachAgencyData).sort((a, b) => b.id - a.id) : [];
}

function parsePassbookData(data) {
  return data ? data : [];
}

function parseAgencyBrandData(data) {
  return data && data.length > 0 ? data.map(parseEachAgencyBrandData) : [];
}

function parseEachAgencyBrandData(data) {
  const brand_profile = data.brand_profile || {};
  return {
    user_id: data.user.id,
    username: data.user.username,
    agency: data.agency,
    id: data.id,
    type_id: data.id,
    name: data.user.first_name, // + " " + data.user.last_name,
    first_name: data.user.first_name, // + " " + data.user.last_name,
    last_name: data.user.last_name, // + " " + data.user.last_name,
    email: data.user.email,
    email_verified: data.email_verified,
    location: data.location,
    mobile: data.mobile,
    mobile_verified: data.mobile_verified,
    verified: data.verified,
    account_status: data.account_status,
    company: 'None',
    description: '', //data.tagLine,
    bio: data.bio,
    img: data.brandlogo,
    image: data.brandlogo,
    cover: data.coverImage,
    color: 'red',
    country: 'India',
    type: 'agency-brand',
    typeStr: 'Agency Brand',
    type_display: 'Agency Brand',
    brand_profile: data.brand_profile,
    user: data.user,
    business_name: brand_profile.business_name,
    legal_name: brand_profile.legal_name,
    gst_number: brand_profile.gst_number,
    pan_or_tan: brand_profile.pan_or_tan,
    tax_certificate: brand_profile.tax_certificate,
    pan_or_tan_image: brand_profile.pan_or_tan_image,
    completed_campaigns: data.completed_campaigns,
    active_campaigns: data.active_campaigns,
    influencers_count: data.influencers_count,
    active_promotions: data.active_promotions,
    successful_promotions: data.successful_promotions,
    brand_managers: data.brand_managers,
    industries: data['brand_profile'] ? data['brand_profile'].industries : [],
  };
}

function parseRolesData(data) {
  return data ? data : [];
}

function parseRoleData(data) {
  return data ? data : [];
}

function parsePlansData(data) {
  return data ? data : [];
}

function parsePlanData(data) {
  return data ? data : [];
}

function parsePermissionsData(data) {
  return data ? data : [];
}

function parsePermissionData(data) {
  return data ? data : [];
}

export function parseInfluencerData(data) {
  return data
    ? data.map(parseEachInfluencerData) /*.sort((a, b) => b.id - a.id)*/
    : [];
}

function parsePaymentsData(data) {
  return data ? data : [];
}

function parsePaymentData(data) {
  return data ? data : {};
}

function parseWalletsData(data) {
  return data ? data : [];
}

function parseWalletData(data) {
  return data ? data : {};
}

function parseEachInfluencerData(data) {
  const profileImage =
    data.profileImage && data.profileImage.indexOf('http') === 0
      ? data.profileImage
      : data.profileImage
      ? cdnDomain(data.profileImage)
      : '/icons/color/user.svg';
  const profile = {
    ...data.influencer_profile,
  };

  data.user = data.user || {};

  return {
    username: data.user.username,
    created_date: data.created_date,
    modified_date: data.modified_date,
    id: data.id,
    name:
      data.user.first_name +
      ' ' +
      (data.user.first_name
        .toLowerCase()
        .indexOf(' ' + data.user.last_name.toLowerCase()) === -1
        ? data.user.last_name
        : ''),
    email: data.user.email,
    location: data.location,
    address: [
      profile.registered_address,
      (profile.city || '') + '-' + (profile.zip || ''),
      profile.state,
    ].join(', '),
    company: 'None',
    description: '', //data.tagLine,
    bio: data.bio,
    img: profileImage,
    image: profileImage,
    cover: data.coverImage,
    color: 'green',
    country: data.location,
    gender: data.gender,
    birth_date: data.birth_date,
    mobile: data.mobile,
    email_verified: data.email_verified,
    mobile_verified: data.mobile_verified,
    verified: data.verified,
    feedback: data.feedback,
    type: 'influencer',
    typeStr: 'Influencer',
    influencer_profile: data.influencer_profile,
    media: data.media,
    user: data.user,
    type_id: data.influencer_profile ? data.influencer_profile.id : '',
    keywords: data['influencer_profile']
      ? data['influencer_profile'].keywords
      : [],
    categories: data['influencer_profile']
      ? data['influencer_profile'].categories
      : [],
    category: data['influencer_profile']
      ? data['influencer_profile'].category
      : [],
    brand_category: data['influencer_profile']
      ? data['influencer_profile'].brand_category
      : [],
    social_profiles: data['influencer_profile']
      ? data['influencer_profile'].social_profiles
      : [],
  };
}

export function parseBrandData(data) {
  return data
    ? data.map(parseEachBrandData) /*.sort((a, b) => b.id - a.id)*/
    : [];
}

function parseNotificationsData(data) {
  return data ? data : [];
}

/*
{
        "id": 1,
        "alias": "alias",
        "tagLine": "tagLine",
        "bio": "bio",
        "location": "location",
        "mobile": "",
        "brandlogo": "brandlogo",
        "status": "('New', 'New')",
        "user": {
            "id": 12,
            "username": "username1623252163",
            "email": "email1623252163@gmail.com",
            "first_name": "Inflosouser",
            "last_name": "1623252163"
        },
        "role": 2,
        "brand_profile": {
            "id": 1,
            "registered_address": "registered_address1623252193",
            "city": "city1623252193",
            "zip": "1623252193",
            "brand": 1,
            "keywords": [
                {
                    "id": 1,
                    "keyword_name": "keyword_name_002",
                    "keyword_desc": "keyword_name_002_desc",
                    "is_active": true
                }
            ],
            "social_profiles": [
                {
                    "id": 1,
                    "social_media_platform": "Facebook",
                    "followers": 100,
                    "following": 99,
                    "brand_name": "brand_name1623252189",
                    "brand_url": "brand_url1623252189"
                }
            ],
            "category": [
                {
                    "id": 1,
                    "category_name": "category_name_001",
                    "category_desc": "category_name_001_desc_updated",
                    "is_active": true
                }
            ]
        }
    },
 */
export function parseEachBrandData(data) {
  const brand_profile = data.brand_profile || {};
  const brandlogo =
    data.brandlogo && data.brandlogo.indexOf('http') === 0
      ? data.brandlogo
      : data.brandlogo
      ? cdnDomain(data.brandlogo)
      : '/icons/color/user.svg';

  const profile = {
    ...data.brand_profile,
  };

  return {
    username: data.user.username,
    id: data.id,
    name: data.user.first_name, // + " " + data.user.last_name,
    email: data.user.email,
    email_verified: data.email_verified,
    location: data.location,
    address: [
      profile.registered_address,
      (profile.city || '') + '-' + (profile.zip || ''),
      profile.state,
    ].join(', '),
    mobile: data.mobile,
    mobile_verified: data.mobile_verified,
    verified: data.verified,
    account_status: data.account_status,
    created_date: data.created_date,
    company: 'None',
    description: '', //data.tagLine,
    bio: data.bio,
    img: brandlogo,
    image: brandlogo,
    cover: data.coverImage,
    color: 'red',
    country: 'India',
    type: 'brand',
    typeStr: 'Brand',
    brand_profile: data.brand_profile,
    user: data.user,
    business_name: brand_profile.business_name,
    legal_name: brand_profile.legal_name,
    gst_number: brand_profile.gst_number,
    pan_or_tan: brand_profile.pan_or_tan,
    tax_certificate: brand_profile.tax_certificate,
    pan_or_tan_image: brand_profile.pan_or_tan_image,
    completed_campaigns: data.completed_campaigns,
    active_campaigns: data.active_campaigns,
    influencers_count: data.influencers_count,
    paid_support_percentage: data.paid_support_percentage,
    premium_on_actions: data.premium_on_actions,
    premium_on_buy_product: data.premium_on_buy_product,
    premium_on_digital_product: data.premium_on_digital_product,
    premium_on_send_product: data.premium_on_send_product,
    premium_on_support: data.premium_on_support,
    premium_on_visits: data.premium_on_visits,
    active_promotions: data.active_promotions,
    successful_promotions: data.successful_promotions,
    barter_cost: data.barter_cost || 100,
    coupon: data.coupon,
    industries: data['brand_profile'] ? data['brand_profile'].industries : [],
  };
}

function parseEachAgencyData(data) {
  const agency_profile = data.agency_profile || {};

  const profile = {
    ...data.agency_profile,
  };

  return {
    username: data.user.username,
    created_date: data.created_date,
    modified_date: data.modified_date,
    id: data.id,
    address: [
      profile.registered_address,
      (profile.city || '') + '-' + (profile.zip || ''),
      profile.state,
    ].join(', '),
    name: data.user.first_name, // + " " + data.user.last_name,
    email: data.user.email,
    email_verified: data.email_verified,
    location: data.location,
    mobile: data.mobile,
    mobile_verified: data.mobile_verified,
    verified: data.verified,
    account_status: data.account_status,
    company: 'None',
    description: '', //data.tagLine,
    bio: data.bio,
    img: data.brandlogo,
    image: data.brandlogo,
    cover: data.coverImage,
    color: 'red',
    country: 'India',
    type: 'agency',
    typeStr: 'Agency',
    brand_profile: data.brand_profile,
    agency_profile: data.agency_profile,
    user: data.user,
    business_name: agency_profile.business_name,
    legal_name: agency_profile.legal_name,
    gst_number: agency_profile.gst_number,
    pan_or_tan: agency_profile.pan_or_tan,
    tax_certificate: agency_profile.tax_certificate,
    pan_or_tan_image: agency_profile.pan_or_tan_image,
    completed_campaigns: data.completed_campaigns,
    active_campaigns: data.active_campaigns,
    influencers_count: data.influencers_count,
    paid_support_percentage: data.paid_support_percentage,
    premium_on_actions: data.premium_on_actions,
    premium_on_buy_product: data.premium_on_buy_product,
    premium_on_digital_product: data.premium_on_digital_product,
    premium_on_send_product: data.premium_on_send_product,
    premium_on_support: data.premium_on_support,
    premium_on_visits: data.premium_on_visits,
    active_promotions: data.active_promotions,
    successful_promotions: data.successful_promotions,
    coupon: data.coupon,
    industries: data['brand_profile'] ? data['brand_profile'].industries : [],
  };
}

function parseCampaignData(data) {
  return data
    ? data.map(parseEachCampaignData) /*.sort((a, b) => b.id - a.id)*/
    : [];
}

function parseCampaignApplicationsData(data) {
  return data
    ? data.map(parseEachCampaignApplicationData).sort((a, b) => b.id - a.id)
    : [];
}

function processFilter(filter) {
  if (filter) {
    return Object.keys(filter)
      .map((f) => {
        if (Array.isArray(filter[f])) {
          return filter[f].map((fv) => f + '=' + fv).join('&');
        } else if (filter[f].indexOf('|') > -1) {
          return filter[f]
            .split('|')
            .map((fv) => f + '=' + fv)
            .join('&');
        } else {
          return f + '=' + filter[f];
        }
      })
      .join('&');
  } else {
    return '';
  }
}

/*
{
        "id": 1,
        "ownerType": "Brand",
        "brand": {
            "id": 2,
            "alias": "alias",
            "tagLine": "tagLine",
            "bio": "bio",
            "location": "location",
            "mobile": "",
            "brandlogo": "brandlogo",
            "status": "('New', 'New')",
            "user": {
                "id": 22,
                "username": "username1623429104",
                "email": "email1623429104@gmail.com",
                "first_name": "Inflosouser",
                "last_name": "1623429104"
            },
            "role": 2,
            "brand_profile": {
                "id": 2,
                "registered_address": "registered_address1623429221",
                "city": "city1623429221",
                "zip": "1623429221",
                "brand": 2,
                "keywords": [
                    {
                        "id": 1,
                        "keyword_name": "keyword_name_002",
                        "keyword_desc": "keyword_name_002_desc",
                        "is_active": true
                    }
                ],
                "social_profiles": [
                    {
                        "id": 2,
                        "social_media_platform": "Facebook",
                        "followers": 100,
                        "following": 99,
                        "brand_name": "brand_name1623429263",
                        "brand_url": "brand_url1623429263"
                    }
                ],
                "category": [
                    {
                        "id": 1,
                        "category_name": "category_name_001",
                        "category_desc": "category_name_001_desc_updated",
                        "is_active": true
                    }
                ]
            }
        },
        "campaign_name": "campaign_name1623429310",
        "alias": "alias1623429310",
        "campaign_goal": "campaign_goal1623429310",
        "campaign_desc": "campaign_desc1623429310",
        "location": "location1623429310",
        "timeline": "Fixed Timeline",
        "start_date": "2021-06-09",
        "end_date": "2021-06-19",
        "collaboration_type": "Buy Products",
        "campaign_modes": "Facebook",
        "content_guidelines": "content_guidelines1623429310",
        "campaign_budget": "100.00",
        "no_of_influencers": 5,
        "is_active": false,
        "campaign_state": "In Review",
        "owner": {
            "id": 22,
            "username": "username1623429104",
            "email": "email1623429104@gmail.com",
            "first_name": "Inflosouser",
            "last_name": "1623429104",
            "usertype": "Brand",
            "brand": {
                "id": 2,
                "alias": "alias",
                "tagLine": "tagLine",
                "bio": "bio",
                "location": "location",
                "mobile": "",
                "brandlogo": "brandlogo",
                "status": "('New', 'New')",
                "user": {
                    "id": 22,
                    "username": "username1623429104",
                    "email": "email1623429104@gmail.com",
                    "first_name": "Inflosouser",
                    "last_name": "1623429104"
                },
                "role": 2,
                "brand_profile": {
                    "id": 2,
                    "registered_address": "registered_address1623429221",
                    "city": "city1623429221",
                    "zip": "1623429221",
                    "brand": 2,
                    "keywords": [
                        {
                            "id": 1,
                            "keyword_name": "keyword_name_002",
                            "keyword_desc": "keyword_name_002_desc",
                            "is_active": true
                        }
                    ],
                    "social_profiles": [
                        {
                            "id": 2,
                            "social_media_platform": "Facebook",
                            "followers": 100,
                            "following": 99,
                            "brand_name": "brand_name1623429263",
                            "brand_url": "brand_url1623429263"
                        }
                    ],
                    "category": [
                        {
                            "id": 1,
                            "category_name": "category_name_001",
                            "category_desc": "category_name_001_desc_updated",
                            "is_active": true
                        }
                    ]
                }
            }
        },
        "keywords": [
            {
                "id": 1,
                "keyword_name": "keyword_name_002",
                "keyword_desc": "keyword_name_002_desc",
                "is_active": true
            }
        ],
        "categories": [
            {
                "id": 1,
                "category_name": "category_name_001",
                "category_desc": "category_name_001_desc_updated",
                "is_active": true
            }
        ]
    }
 */
