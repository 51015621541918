import React from 'react';
import Head from 'next/head';
import { shallowEqual, useSelector } from 'react-redux';
// import Layout1 from './layout-1';
// import Layout2 from './layout-2';
import Layout3 from './layout-3';
// import Layout4 from './layout-4';
// import {NotificationContainer} from 'react-notifications'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/display/Loader';

const Wrapper = ({
  name,
  description,
  layout,
  direction,
  collapsed,
  background,
  navbar,
  logo,
  leftSidebar,
  rightSidebar,
  topNavigation,
  children,
}) => (
  <>
    <Head>
      <title>{name}</title>
      <meta name="description" content={description} />
      <link rel="icon" href={logo} />
    </Head>
    <div
      data-layout={layout}
      data-direction={direction}
      data-collapsed={collapsed}
      data-background={background}
      data-navbar={navbar}
      data-logo={logo}
      data-left-sidebar={leftSidebar}
      data-right-sidebar={rightSidebar}
      data-top-navigation={topNavigation}
    >
      <div className="text-black">
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ color: '#000' }}
        />
      </div>
      {children}
    </div>
  </>
);

const Layout = ({ children, title, mod, bg, download }) => {
  const { ui, layout, direction, collapsed, name, palettes, logo } =
    useSelector(
      (state) => ({
        ui: state.ui,
        layout: state.ui.layout,
        direction: state.ui.direction,
        collapsed: state.ui.collapsed,
        name: state.ui.name,
        palettes: state.ui.palettes,
        logo: state.ui.logo,
      }),
      shallowEqual
    );
  if (layout === 'layout-1') {
    return (
      <Loader />
      // <Wrapper
      //   name={name}
      //   direction={direction}
      //   layout={layout}
      //   collapsed={collapsed}
      //   {...palettes}
      // >
      //   <Layout1 title={title} mod={mod}>
      //     {children}
      //   </Layout1>
      // </Wrapper>
    );
  }
  if (layout === 'layout-2') {
    return (
      <Loader />

      // <Wrapper
      //     name={name}
      //     direction={direction}
      //     layout={layout}
      //     collapsed={collapsed}
      //     {...palettes}
      //   >
      //     <Layout2 title={title} mod={mod}>
      //       {children}
      //     </Layout2>
      //   </Wrapper>
    );
  }
  if (layout === 'layout-3') {
    return (
      <Wrapper
        name={name}
        direction={direction}
        layout={layout}
        collapsed={collapsed}
        {...palettes}
        logo={logo}
      >
        <Layout3 title={title} mod={mod} bg={bg} download={download}>
          {children}
        </Layout3>
      </Wrapper>
    );
  }
  if (layout === 'layout-4') {
    return (
      <Loader />

      // <Wrapper
      //   name={name}
      //   direction={direction}
      //   layout={layout}
      //   collapsed={collapsed}
      //   {...palettes}
      // >
      //   <Layout4 title={title} mod={mod}>
      //     {children}
      //   </Layout4>
      // </Wrapper>
    );
  }

  return (
    <Loader />

    // <Wrapper
    //   name={name}
    //   direction={direction}
    //   layout={layout}
    //   collapsed={collapsed}
    //   {...palettes}
    // >
    //   <Layout1>{children}</Layout1>
    // </Wrapper>
  );
};

export default Layout;
