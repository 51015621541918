import Image from '../display/Image';
import React from 'react';

export default function VerifiedBadge({ verified, size = 6 }) {
  if (verified) {
    return (
      <Image
        src="/icons/color/verified.svg"
        cdn={false}
        className={`w-${size}`}
        allowExpand={false}
      />
    );
  } else {
    return <></>;
  }
}
