import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FluentIcon from '../fluenticons/FluentIcon';

export default function FlatButton(props) {
  const {
    icon,
    label,
    onClick,
    className,
    style,
    type,
    disabled,
    children,
    size = 2,
  } = props;

  // const TYPES = {
  //   primary:{
  //     bgColor: disabled ? 'bg-gray-200' : 'bg-brand-light',
  //     color: disabled ? 'text-gray-500' : 'text-gray-100'
  //   },
  //   normal: {
  //     bgColor: 'bg-gray-300',
  //     color: 'text-brand-dark'
  //   }
  // }
  //
  // const {bgColor, color} = TYPES[type || 'primary'];

  return (
    <button
      className={`btn btn-defasult btn-flat  text-center transition duration-500 rounded-25 p-${size} px-${
        size * 2
      } opacity-100 ${
        disabled
          ? 'text-gray-400 cursor-not-allowed'
          : 'text-brand-light hover:opacity-75 border-gray-100'
      } ${className || ''}`}
      style={style}
      onClick={disabled ? () => {} : onClick}
    >
      {icon && (
        <>
          {icon.prefix ? (
            <FontAwesomeIcon icon={icon} />
          ) : (
            <FluentIcon icon={icon} size={20} />
          )}
        </>
      )}
      {label && <span className="ml-1">{label}</span>}
      {children && <span className="ml-1">{children}</span>}
    </button>
  );
}
