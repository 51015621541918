import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import LeftSideBarv2 from '../../components/left-sidebar-v2';
import Navbar1 from '../../components/navbar-v2';
import BottomBar from '../../components/bottom-bar';
import GlobalModal from '../../components/modals/GlobalModal';
import actions from '../../services/redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AppDownload from '../../components/banner/AppDownload';


const Layout3 = ({ title, mod, children, bg = 'bg-white', download=true }) => {
  const { categories, industries, user } = useSelector(
    (state) => ({
      categories: state.common.categories,
      industries: state.common.industries,
      user: state.user,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {

    if (!categories || categories.length === 0) {
      dispatch(actions.common.loadCategories());
    }
    if (!industries || industries.length === 0) {
      dispatch(actions.common.loadIndustries());
    }
  }, []);

  const closePayment = () => {
    if (window.closePayment) {
      window.closePayment();
    } else {
      const paymentWrapper = document.getElementById('payment-form-wrapper');
      const paymentWrapper2 = document.getElementById('payment-form-wrapper2');

      paymentWrapper.style.display = 'none';
      paymentWrapper2.style.display = 'none';
    }
  };

  return (
    <>
      <div>

        <IFrame />
        <div
          id="payment-form-wrapper"
          style={{
            display: 'none',
            position: 'fixed',
            backgroundColor: 'rgb(0,0,0,0.5)',
            width: '100%',
            height: '100vh',
            zIndex: 100,
            top: 0,
          }}
        >
          &nbsp;
        </div>{' '}
        <div
          className="flex justify-center items-center w-full h-screen fixed top-0"
          id="payment-form-wrapper2"
          style={{ zIndex: 101, display: 'none' }}
        >
          <div
            className="absolute md:text-white text-2xl cursor-pointer"
            style={{ right: '20px', top: '20px' }}
            onClick={closePayment}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <div
            id="payment-form"
            className="w-full h-full md:h-auto md:w-auto mx-auto"
            style={{ minWidth: '450px' }}
          ></div>
        </div>
      </div>
      <Navbar1 title={title} mod={mod} />
      <div
        className="flex w-full mt-16"
        style={{ minHeight: 'calc(100vh - 64px' }}
      >
        {mod === 'Explore' || mod === 'None' ? null : (
          <div className="fixed h-full z-30 hidden sm:block md:w-20 lg:w-80">
            <LeftSideBarv2 mod={mod} />
            {/*<RightSidebar1 />*/}
          </div>
        )}

        <div
          className={`w-full max-w-screen-xld overflosw-y-scroll ${
            mod === 'Explore' || mod === 'None'
              ? ''
              : 'md:ml-20 lg:ml-80 px-3 md:px-6'
          }`}
        >
          {user.type === 'influencer' && download ? (
            <div
              className={`${mod === 'Explore' || mod === 'None' ? 'mx-5' : ''}`}
            >
              <AppDownload />
            </div>
          ) : (
            <></>
          )}
          <div
            className={` ${bg} `}
            // style={{ height: 'calc(100vh - 64px)' }}
          >
            <div className="pt-5 md:pt-6 pb-20 md:pb-0">{children}</div>
            <div className="text-center text-gray-100">
              <span>
                {process.env.VERSION}-{process.env.BRANCH}
              </span>
            </div>
          </div>

          {user && user.id && <BottomBar mod={mod} />}
          <GlobalModal />
        </div>
      </div>
    </>
  );
};

// import React, { useState } from 'react'

export function IFrame() {
  if (typeof window === 'undefined') {
    return <></>;
  } else {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<iframe name="download_content" style="display:none" src="about:blank" />',
        }}
      />
    );
  }
}

Layout3.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  mod: PropTypes.string,
};

export default Layout3;
