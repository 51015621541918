import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { cdnDomain } from '../../services/util';

export default function Image({
  src,
  className,
  defaultImg,
  defaultContent,
  allowExpand = true,
  cdn = false,
}) {
  const [img, setImg] = useState(src);
  const [expanded, setExpanded] = useState(false);
  const isprod =
    ['www.infloso.in', 'www.infloso.com', 'pre.infloso.com'].indexOf(
      location.hostname
    ) > -1;

  useEffect(() => {
    setImg(src);
  }, [src]);

  const onError = () => {
    if (defaultContent) {
      setImg('CONTENT');
    } else {
      setImg(defaultImg || '/icons/color/user.svg');
    }
  };

  if (expanded) {
    const expandedStyle = {
      position: 'fixed',
      width: '100%',
      top: 0,
      zIndex: '100',
      height: '100vh',
      left: 0,
      cursor: 'default',
    };

    return (
      <div
        className={` bg-white flex flex-row items-center cursor-pointer`}
        style={expandedStyle}
        onClick={() => {
          setExpanded(false);
        }}
      >
        <img
          className={'m-auto max-h-screen'}
          src={cdn ? cdnDomain(img) : img}
          onError={onError}
        />
        {expanded && (
          <div
            className="absolute top-0 right-0 m-5 cursor-pointer"
            onMouseDown={() => {
              setExpanded(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </div>
        )}
      </div>
    );
  }

  return !img || img === 'CONTENT' ? (
    defaultContent
  ) : (
    <img
      className={className}
      src={cdn ? cdnDomain(img) : img}
      onError={onError}
      onClick={() => {
        if (allowExpand) {
          setExpanded(true);
        }
      }}
    />
  );
}
