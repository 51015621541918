import React, { useEffect } from 'react';
import Link from 'next/link';
import { fiIcons } from '../../services/util';
import FluentIcon from '../fluenticons/FluentIcon';
import { useChat } from '../../services/zustand/chat';
import { shallowEqual, useSelector } from 'react-redux';

const ICONS = fiIcons();

const A = ({ href, children }) => {
  return <a href={href}>{children}</a>;
};

const NavItems = ({ navs, tabs, mod, hash, selectFirst = false }) => {
  const { wallet, user } = useSelector(
    (state) => ({
      wallet: state.wallet,
      user: state.user,
    }),
    shallowEqual
  );

  const {count, fetchChatCount, fetchInfluencerChatCount} = useChat();

  useEffect(()=>{
    if(user.brand_id){
      fetchChatCount(user.brand_id);
    }
  },[user.brand_id]);

  useEffect(()=>{
    if(user.influencer_id){
      fetchInfluencerChatCount(user.influencer_id);
    }
  },[user.influencer_id]);

  return (
    <div>
      {(navs || []).map((nav, i) => {
        const LinkEle = nav.url && nav.url[0] === '#' ? A : Link;
        const active =
          nav.title === mod ||
          nav.url === hash ||
          ((!hash || hash.length === 0) && selectFirst && i === 0);

        return (
          <div key={i} onClick={nav.onClick} className=" mt-6 cursor-pointer">
            <LinkEle href={nav.url}>
              <div
                className={`flex items-center justify-start lg:pl-6
                 ${nav.hideOnDesktop ? 'hidden' : ''}`}
              >
                <div
                  className={` ${
                    active
                      ? `border-brand-base text-brand-base ${nav.className}`
                      : `text-gray-500 ${nav.className}`
                  }`}
                >
                  {ICONS[nav.icon] && <FluentIcon icon={ICONS[nav.icon]} />}
                </div>
                <div className="flex flex-row items-center flex-1">
                  <p
                    className={`ml-3 flex-1 text-lg hidden font-bold lg:block ${
                      active ? ' text-brand-base' : ''
                    }`}
                  >
                    {nav.title}
                  </p>
                  {nav.title === "Chat" && count > 0 && <div className="text-xs rounded-25 bg-green-600 text-white px-2 py-1">
                      {count} new
                  </div>}
                </div>
              </div>
            </LinkEle>
          </div>
        );
      })}
    </div>
  );
};

export default NavItems;
