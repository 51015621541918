import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { shallowEqual, useSelector } from 'react-redux';
import FeatherIcon from '../icon/feather-icon';
import { OldPopover } from '../popovers';
import { fiIcons } from '../../services/util';
import FluentIcon from '../fluenticons/FluentIcon';

const ICONS = fiIcons();

export default function BottomBar({ mod }) {
  const { navigation } = useSelector(
    (state) => ({
      navigation: state.navigation,
    }),
    shallowEqual
  );

  const { items } = navigation[0];

  return (
    <div className="w-full md:invisible text-white">
      <section
        id="bottom-navigation"
        className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow"
      >
        <div id="tabs" className="flex justify-between">
          {items.map((item, i) => (
            <Item key={i} {...item} mod={mod} />
          ))}
        </div>
      </section>
    </div>
  );
}

function Item(props) {
  const router = useRouter();
  const active = props.title === props.mod; //router.pathname.split('/')[1] === (props.url.split('/')[1]) ;//(router.pathname === props.url)

  if (props.isMobile === false) {
    return null;
  }

  // const Icon = props.icon;
  // const style = active ? {"filter":"grayscale('90%')"} : {};

  if (props.popover) {
    return (
      <OldPopover placement="top" title={props.popover.title} content={'ok'}>
        <a
          className={`w-full flex justify-center inline-block text-center pb-3 ${
            active ? 'border-t-4 pt-2' : 'text-gray-300 pt-3'
          }`}
          style={{ color: active ? '#0074D9' : 'gray' }}
        >
          {/*<FeatherIcon icon={props.icon} className="justify-center flex-1 m-2"/>*/}
          <FluentIcon icon={ICONS[props.icon]} />
          {/*<ColorIcon icon={props.icon} grayscale={!active} size={6}/>*/}
          <span className="tab tab-account block text-xs">{props.title}</span>
        </a>
      </OldPopover>
    );
  }

  return (
    <Link href={props.url}>
      <a
        className={`w-full flex flex-col justify-center inline-block text-center pb-1 border-brand-light ${
          active ? 'border-t-4 pt-1' : 'pt-2'
        }`}
        style={{ color: active ? '#0074D9' : 'gray' }}
      >
        {/*<FeatherIcon icon={props.icon} className="justify-center flex-1 m-2"/>*/}
        <div className="text-center">
          <FluentIcon icon={ICONS[props.icon]} />
        </div>
        {/*<ColorIcon icon={props.icon} grayscale={!active} size={6}/>*/}
        <span
          className={`tab tab-account block text-xxs ${
            active ? 'text-brand-light' : 'text-gray-700'
          } font-medium`}
        >
          {props.title}
        </span>
      </a>
    </Link>
  );
}

function ItemLink(props) {
  const router = useRouter();
  const active = router.pathname === props.url;

  return (
    <a
      className={`w-full ${
        active ? 'bg-brand-dark' : ''
      } flex justify-center inline-block text-center pt-2 pb-1`}
    >
      <FeatherIcon icon={props.icon} className="justify-center flex-1 m-2" />
      {/*<span className="tab tab-account block text-xs">{props.title}</span>*/}
    </a>
  );
}
