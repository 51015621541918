export const SHOW_MODAL = 'modal/SHOW_MODAL';
export const HIDE_MODAL = 'modal/HIDE_MODAL';

const initialState = {
  open: false,
  title: '',
  content: '',
  width: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL: {
      const modal = action.payload;

      return {
        ...initialState,
        ...modal,
        open: true,
      };
    }
    case HIDE_MODAL: {
      return { ...state, open: false };
    }
    default: {
      return { ...state };
    }
  }
}

export function show(modal) {
  return {
    type: SHOW_MODAL,
    payload: modal,
  };
}

export function hide() {
  return {
    type: HIDE_MODAL,
  };
}

export function close() {
  return hide();
}
