import { LOGOUT_SUCCESS } from './user';

const SHOW_TOAST = 'SHOW_TOAST';
const HIDE_TOAST = 'HIDE_TOAST';

const initialState = {
  open: false,
  message: '',
  content: '',
  severity: 'success',
  duration: 3000,
};

export default function reducer(state = initialState, action) {
  // return (dispatch) => {
  //If action has toast, toast is to be shown

  switch (action.type) {
    case LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    case SHOW_TOAST: {
      const { severity, message, content, duration } = action.payload;

      return {
        ...state,
        ...initialState,
        severity,
        message,
        content,
        open: true,
      };
    }
    case HIDE_TOAST: {
      return { ...state, open: false };
    }
    default: {
      return { ...initialState };
    }
  }
  // }
}

export function show(message, content = '', severity = 'success') {
  return {
    type: 'SHOW_TOAST',
    payload: {
      message,
      content,
      severity,
    },
  };
}

export function showFailure(message, content) {
  return show('failure', message, content);
}

export function showError(message, content) {
  return showFailure(message, content);
}

export function showSuccess(message, content) {
  return show('success', message, content);
}

export function hide() {
  return {
    type: 'HIDE_TOAST',
  };
}

export function close() {
  return hide();
}
