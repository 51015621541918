// import users from "../../../json/users.json";
import {
  isServer,
  ls,
  markFailure,
  markLoading,
  markSuccess,
} from '../../util';
import saveUserMapping, {
  decodeUser,
  loadTokens,
  loadUser,
  loginUser,
} from '../../mapping';
import moment from 'moment';
import { search } from '../../es';

export const GET_USER_REQUEST = 'user/GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'user/GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'user/GET_USER_FAILURE';

export const GET_USER_ADMIN_REQUEST = 'user/GET_USER_ADMIN_REQUEST';
export const GET_USER_ADMIN_SUCCESS = 'user/GET_USER_ADMIN_SUCCESS';
export const GET_USER_ADMIN_FAILURE = 'user/GET_USER_ADMIN_FAILURE';

export const SAVE_USER_REQUEST = 'user/SAVE_USER_REQUEST';
export const SAVE_USER_SUCCESS = 'user/SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'user/SAVE_USER_FAILURE';

export const SAVE_SOCIAL_PROFILE_REQUEST = 'user/SAVE_SOCIAL_PROFILE_REQUEST';
export const SAVE_SOCIAL_PROFILE_SUCCESS = 'user/SAVE_SOCIAL_PROFILE_SUCCESS';
export const SAVE_SOCIAL_PROFILE_FAILURE = 'user/SAVE_SOCIAL_PROFILE_FAILURE';

export const REMOVE_SOCIAL_PROFILE_REQUEST =
  'user/REMOVE_SOCIAL_PROFILE_REQUEST';
export const REMOVE_SOCIAL_PROFILE_SUCCESS =
  'user/REMOVE_SOCIAL_PROFILE_SUCCESS';
export const REMOVE_SOCIAL_PROFILE_FAILURE =
  'user/REMOVE_SOCIAL_PROFILE_FAILURE';

export const REGISTER_REQUEST = 'user/REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'user/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'user/REGISTER_FAILURE';

export const LOGIN_REQUEST = 'user/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'user/LOGIN_FAILURE';

export const SEND_OTP_REQUEST = 'user/SEND_OTP_REQUEST';
export const SEND_OTP_SUCCESS = 'user/SEND_OTP_SUCCESS';
export const SEND_OTP_FAILURE = 'user/SEND_OTP_FAILURE';

export const RESET_PASSWORD_REQUEST = 'user/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'user/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'user/RESET_PASSWORD_FAILURE';

export const LOGOUT_REQUEST = 'user/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'user/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'user/LOGOUT_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'user/CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'user/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'user/CHANGE_PASSWORD_FAILURE';

export const LOAD_BRAND_REQUEST = 'user/LOAD_BRAND_REQUEST';
export const LOAD_BRAND_SUCCESS = 'user/LOAD_BRAND_SUCCESS';
export const LOAD_BRAND_FAILURE = 'user/LOAD_BRAND_FAILURE';

export const REFRESH_TOKEN_REQUEST = 'user/REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'user/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'user/REFRESH_TOKEN_FAILURE';

export const CHECK_TOKEN_REQUEST = 'user/CHECK_TOKEN_REQUEST';
export const CHECK_TOKEN_SUCCESS = 'user/CHECK_TOKEN_SUCCESS';
export const CHECK_TOKEN_FAILURE = 'user/CHECK_TOKEN_FAILURE';

export const DISABLE_ACCOUNT_REQUEST = 'user/DISABLE_ACCOUNT_REQUEST';
export const DISABLE_ACCOUNT_SUCCESS = 'user/DISABLE_ACCOUNT_SUCCESS';
export const DISABLE_ACCOUNT_FAILURE = 'user/DISABLE_ACCOUNT_FAILURE';

export const GET_SOCIAL_DETAILS_REQUEST = 'user/GET_SOCIAL_DETAILS_REQUEST';
export const GET_SOCIAL_DETAILS_SUCCESS = 'user/GET_SOCIAL_DETAILS_SUCCESS';
export const GET_SOCIAL_DETAILS_FAILURE = 'user/GET_SOCIAL_DETAILS_FAILURE';

export const TAX_MEDIA_UPLOAD_REQUEST = 'user/TAX_MEDIA_UPLOAD_REQUEST';
export const TAX_MEDIA_UPLOAD_SUCCESS = 'user/TAX_MEDIA_UPLOAD_SUCCESS';
export const TAX_MEDIA_UPLOAD_FAILURE = 'user/TAX_MEDIA_UPLOAD_FAILURE';

export const PAN_MEDIA_UPLOAD_REQUEST = 'user/PAN_MEDIA_UPLOAD_REQUEST';
export const PAN_MEDIA_UPLOAD_SUCCESS = 'user/PAN_MEDIA_UPLOAD_SUCCESS';
export const PAN_MEDIA_UPLOAD_FAILURE = 'user/PAN_MEDIA_UPLOAD_FAILURE';

export const LOAD_INFLUENCER_LIST_REQUEST = 'user/LOAD_INFLUENCER_LIST_REQUEST';
export const LOAD_INFLUENCER_LIST_SUCCESS = 'user/LOAD_INFLUENCER_LIST_SUCCESS';
export const LOAD_INFLUENCER_LIST_FAILURE = 'user/LOAD_INFLUENCER_LIST_FAILURE';

export const SAVE_INFLUENCER_LIST_REQUEST = 'user/SAVE_INFLUENCER_LIST_REQUEST';
export const SAVE_INFLUENCER_LIST_SUCCESS = 'user/SAVE_INFLUENCER_LIST_SUCCESS';
export const SAVE_INFLUENCER_LIST_FAILURE = 'user/SAVE_INFLUENCER_LIST_FAILURE';

export const SEND_EMAIL_CODE_REQUEST = 'user/SEND_EMAIL_CODE_REQUEST';
export const SEND_EMAIL_CODE_SUCCESS = 'user/SEND_EMAIL_CODE_SUCCESS';
export const SEND_EMAIL_CODE_FAILURE = 'user/SEND_EMAIL_CODE_FAILURE';

export const VERIFY_EMAIL_CODE_REQUEST = 'user/VERIFY_EMAIL_CODE_REQUEST';
export const VERIFY_EMAIL_CODE_SUCCESS = 'user/VERIFY_EMAIL_CODE_SUCCESS';
export const VERIFY_EMAIL_CODE_FAILURE = 'user/VERIFY_EMAIL_CODE_FAILURE';

export const SEND_MOBILE_CODE_REQUEST = 'user/SEND_MOBILE_CODE_REQUEST';
export const SEND_MOBILE_CODE_SUCCESS = 'user/SEND_MOBILE_CODE_SUCCESS';
export const SEND_MOBILE_CODE_FAILURE = 'user/SEND_MOBILE_CODE_FAILURE';

export const VERIFY_MOBILE_CODE_REQUEST = 'user/VERIFY_MOBILE_CODE_REQUEST';
export const VERIFY_MOBILE_CODE_SUCCESS = 'user/VERIFY_MOBILE_CODE_SUCCESS';
export const VERIFY_MOBILE_CODE_FAILURE = 'user/VERIFY_MOBILE_CODE_FAILURE';

export const FACEBOOK_CONNECT_REQUEST = 'user/FACEBOOK_CONNECT_REQUEST';
export const FACEBOOK_CONNECT_SUCCESS = 'user/FACEBOOK_CONNECT_SUCCESS';
export const FACEBOOK_CONNECT_FAILURE = 'user/FACEBOOK_CONNECT_FAILURE';

export const INSTAGRAM_CONNECT_REQUEST = 'user/INSTAGRAM_CONNECT_REQUEST';
export const INSTAGRAM_CONNECT_SUCCESS = 'user/INSTAGRAM_CONNECT_SUCCESS';
export const INSTAGRAM_CONNECT_FAILURE = 'user/INSTAGRAM_CONNECT_FAILURE';

export const YOUTUBE_CONNECT_REQUEST = 'user/YOUTUBE_CONNECT_REQUEST';
export const YOUTUBE_CONNECT_SUCCESS = 'user/YOUTUBE_CONNECT_SUCCESS';
export const YOUTUBE_CONNECT_FAILURE = 'user/YOUTUBE_CONNECT_FAILURE';

export const SUBSCRIBE_PLAN_REQUEST = 'user/SUBSCRIBE_PLAN_REQUEST';
export const SUBSCRIBE_PLAN_SUCCESS = 'user/SUBSCRIBE_PLAN_SUCCESS';
export const SUBSCRIBE_PLAN_FAILURE = 'user/SUBSCRIBE_PLAN_FAILURE';

export const MY_PLAN_REQUEST = 'user/MY_PLAN_REQUEST';
export const MY_PLAN_SUCCESS = 'user/MY_PLAN_SUCCESS';
export const MY_PLAN_FAILURE = 'user/MY_PLAN_FAILURE';

export const SAVE_AGENCY_BRAND_REQUEST = 'user/SAVE_AGENCY_BRAND_REQUEST';
export const SAVE_AGENCY_BRAND_SUCCESS = 'user/SAVE_AGENCY_BRAND_SUCCESS';
export const SAVE_AGENCY_BRAND_FAILURE = 'user/SAVE_AGENCY_BRAND_FAILURE';

export const SAVE_BRAND_MANAGER_REQUEST = 'user/SAVE_BRAND_MANAGER_REQUEST';
export const SAVE_BRAND_MANAGER_SUCCESS = 'user/SAVE_BRAND_MANAGER_SUCCESS';
export const SAVE_BRAND_MANAGER_FAILURE = 'user/SAVE_BRAND_MANAGER_FAILURE';

export const CALC_DATA_REQUEST = 'user/CALC_DATA_REQUEST';
export const CALC_DATA_SUCCESS = 'user/CALC_DATA_SUCCESS';
export const CALC_DATA_FAILURE = 'user/CALC_DATA_FAILURE';

export const INSIGHTS_REQUEST = 'user/INSIGHTS_REQUEST';
export const INSIGHTS_SUCCESS = 'user/INSIGHTS_SUCCESS';
export const INSIGHTS_FAILURE = 'user/INSIGHTS_FAILURE';

export const DAILY_INSIGHTS_REQUEST = 'user/DAILY_INSIGHTS_REQUEST';
export const DAILY_INSIGHTS_SUCCESS = 'user/DAILY_INSIGHTS_SUCCESS';
export const DAILY_INSIGHTS_FAILURE = 'user/DAILY_INSIGHTS_FAILURE';

export const REACH_REQUEST = 'user/REACH_REQUEST';
export const REACH_SUCCESS = 'user/REACH_SUCCESS';
export const REACH_FAILURE = 'user/REACH_FAILURE';

export const FEED_REQUEST = 'user/FEED_REQUEST';
export const FEED_SUCCESS = 'user/FEED_SUCCESS';
export const FEED_FAILURE = 'user/FEED_FAILURE';

export const FEED_COUNT_REQUEST = 'user/FEED_COUNT_REQUEST';
export const FEED_COUNT_SUCCESS = 'user/FEED_COUNT_SUCCESS';
export const FEED_COUNT_FAILURE = 'user/FEED_COUNT_FAILURE';

export const SIMILAR_INFLUENCERS_REQUEST = 'user/SIMILAR_INFLUENCERS_REQUEST';
export const SIMILAR_INFLUENCERS_SUCCESS = 'user/SIMILAR_INFLUENCERS_SUCCESS';
export const SIMILAR_INFLUENCERS_FAILURE = 'user/SIMILAR_INFLUENCERS_FAILURE';

export const SIMILAR_BRAND_REQUEST = 'user/SIMILAR_BRAND_REQUEST';
export const SIMILAR_BRAND_SUCCESS = 'user/SIMILAR_BRAND_SUCCESS';
export const SIMILAR_BRAND_FAILURE = 'user/SIMILAR_BRAND_FAILURE';

export const BRAND_PRODUCT_IMAGE_UPLOAD_REQUEST =
  'user/BRAND_PRODUCT_IMAGE_UPLOAD_REQUEST';
export const BRAND_PRODUCT_IMAGE_UPLOAD_SUCCESS =
  'user/BRAND_PRODUCT_IMAGE_UPLOAD_SUCCESS';
export const BRAND_PRODUCT_IMAGE_UPLOAD_FAILURE =
  'user/BRAND_PRODUCT_IMAGE_UPLOAD_FAILURE';

export const AGENCY_BRAND_PRODUCT_IMAGE_UPLOAD_REQUEST =
  'user/AGENCY_BRAND_PRODUCT_IMAGE_UPLOAD_REQUEST';
export const AGENCY_BRAND_PRODUCT_IMAGE_UPLOAD_SUCCESS =
  'user/AGENCY_BRAND_PRODUCT_IMAGE_UPLOAD_SUCCESS';
export const AGENCY_BRAND_PRODUCT_IMAGE_UPLOAD_FAILURE =
  'user/AGENCY_BRAND_PRODUCT_IMAGE_UPLOAD_FAILURE';

export const CREATE_BRAND_PRODUCT_REQUEST = 'user/CREATE_BRAND_PRODUCT_REQUEST';
export const CREATE_BRAND_PRODUCT_SUCCESS = 'user/CREATE_BRAND_PRODUCT_SUCCESS';
export const CREATE_BRAND_PRODUCT_FAILURE = 'user/CREATE_BRAND_PRODUCT_FAILURE';

export const UPDATE_BRAND_PRODUCT_REQUEST = 'user/UPDATE_BRAND_PRODUCT_REQUEST';
export const UPDATE_BRAND_PRODUCT_SUCCESS = 'user/UPDATE_BRAND_PRODUCT_SUCCESS';
export const UPDATE_BRAND_PRODUCT_FAILURE = 'user/UPDATE_BRAND_PRODUCT_FAILURE';

export const REMOVE_BRAND_PRODUCT_REQUEST = 'user/REMOVE_BRAND_PRODUCT_REQUEST';
export const REMOVE_BRAND_PRODUCT_SUCCESS = 'user/REMOVE_BRAND_PRODUCT_SUCCESS';
export const REMOVE_BRAND_PRODUCT_FAILURE = 'user/REMOVE_BRAND_PRODUCT_FAILURE';

export const GET_BRAND_PRODUCTS_REQUEST = 'user/GET_BRAND_PRODUCTS_REQUEST';
export const GET_BRAND_PRODUCTS_SUCCESS = 'user/GET_BRAND_PRODUCTS_SUCCESS';
export const GET_BRAND_PRODUCTS_FAILURE = 'user/GET_BRAND_PRODUCTS_FAILURE';

export const GET_AGENCY_BRAND_PRODUCTS_REQUEST =
  'user/GET_AGENCY_BRAND_PRODUCTS_REQUEST';
export const GET_AGENCY_BRAND_PRODUCTS_SUCCESS =
  'user/GET_AGENCY_BRAND_PRODUCTS_SUCCESS';
export const GET_AGENCY_BRAND_PRODUCTS_FAILURE =
  'user/GET_AGENCY_BRAND_PRODUCTS_FAILURE';

export const CREATE_AGENCY_BRAND_PRODUCT_REQUEST =
  'user/CREATE_AGENCY_BRAND_PRODUCT_REQUEST';
export const CREATE_AGENCY_BRAND_PRODUCT_SUCCESS =
  'user/CREATE_AGENCY_BRAND_PRODUCT_SUCCESS';
export const CREATE_AGENCY_BRAND_PRODUCT_FAILURE =
  'user/CREATE_AGENCY_BRAND_PRODUCT_FAILURE';

export const UPDATE_AGENCY_BRAND_PRODUCT_REQUEST =
  'user/UPDATE_AGENCY_BRAND_PRODUCT_REQUEST';
export const UPDATE_AGENCY_BRAND_PRODUCT_SUCCESS =
  'user/UPDATE_AGENCY_BRAND_PRODUCT_SUCCESS';
export const UPDATE_AGENCY_BRAND_PRODUCT_FAILURE =
  'user/UPDATE_AGENCY_BRAND_PRODUCT_FAILURE';

export const REMOVE_AGENCY_BRAND_PRODUCT_REQUEST =
  'user/REMOVE_AGENCY_BRAND_PRODUCT_REQUEST';
export const REMOVE_AGENCY_BRAND_PRODUCT_SUCCESS =
  'user/REMOVE_AGENCY_BRAND_PRODUCT_SUCCESS';
export const REMOVE_AGENCY_BRAND_PRODUCT_FAILURE =
  'user/REMOVE_AGENCY_BRAND_PRODUCT_FAILURE';

export const GET_INFLUENCER_TOKENS_REQUEST =
  'user/GET_INFLUENCER_TOKENS_REQUEST';
export const GET_INFLUENCER_TOKENS_SUCCESS =
  'user/GET_INFLUENCER_TOKENS_SUCCESS';
export const GET_INFLUENCER_TOKENS_FAILURE =
  'user/GET_INFLUENCER_TOKENS_FAILURE';

export const SESSION_EXPIRED = 'user/SESSION_EXPIRED';

export const SHOW_ERROR = 'user/SHOW_ERROR';
export const CLEAR_ERROR = 'user/CLEAR_ERROR';
export const CLEAR_SUCCESS = 'user/CLEAR_SUCCESS';
export const ASK_PASSWORD_DIALOG = 'user/ASK_PASSWORD_DIALOG';

const initialState = {
  // ...users[1],
  status: 'pending',
  ...loadUser(),
  loading: [],
  errors: {},
  success: {},
  // refresh:ls.getItem('access'),
  // access:ls.getItem('refresh')
};

export const STATE = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_ERROR: {
      return { ...state, loading: [], error: action.payload, errors: null };
    }
    case CLEAR_ERROR: {
      return { ...state, loading: [], error: null, errors: null };
    }
    case CLEAR_SUCCESS: {
      const success = { ...state.success };
      if (Array.isArray(action.payload)) {
        action.payload.map((p) => delete success[p]);
      }

      return { ...state, success };
    }
    case TAX_MEDIA_UPLOAD_REQUEST: {
      // const payload = {...action.payload};
      const mark = markLoading(action, state);
      return { ...state, ...mark /*loading: Object.keys(action.payload)*/ };
    }
    case GET_USER_REQUEST:
    case GET_SOCIAL_DETAILS_REQUEST:
    case SAVE_SOCIAL_PROFILE_REQUEST:
    case TAX_MEDIA_UPLOAD_SUCCESS:
    case PAN_MEDIA_UPLOAD_REQUEST: {
      const mark = markLoading(action, state);
      return { ...state, ...mark /*loading: Object.keys(action.payload)*/ };
    }
    case SAVE_SOCIAL_PROFILE_SUCCESS:
    case GET_SOCIAL_DETAILS_SUCCESS:
    case PAN_MEDIA_UPLOAD_SUCCESS: {
      const tmark = markSuccess(action, state);
      return { ...state, ...tmark };
    }

    case CHANGE_PASSWORD_REQUEST: {
      const mark = markLoading(action, state);
      return { ...state, ...mark };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      const smark = markSuccess(action, state);
      return { ...state, ...smark };
    }
    case SAVE_SOCIAL_PROFILE_FAILURE:
    case GET_SOCIAL_DETAILS_FAILURE:
    case CHANGE_PASSWORD_FAILURE: {
      const fmark = markFailure(action, state);

      return { ...state, ...fmark, error: action.error };
    }
    case SAVE_USER_REQUEST: {
      const payload = { ...action.payload };

      delete payload['user'];

      const mark = markLoading(action, state);
      return { ...state, ...mark /*loading: Object.keys(action.payload)*/ };
    }
    case SAVE_USER_SUCCESS:
      if (action.statusCode == '400' || !action.result || !action.result.id) {
        const fmark = markFailure(action, state);

        return { ...state, ...fmark, status: STATE.SUCCESS, loading: [] };
      } else {
        const smark = markSuccess(action, state);

        let user = {};
        // if(action.update === 'user') {
        const mUser = action.result;
        if (mUser.user && !mUser.user.usertype) {
          mUser.user.usertype = state.usertype;
        }
        user = loginUser(mUser);
        if (action.update === 'profile') {
          delete user.id; //Doing this to prevent user.id from getting changed in case of profile edit
        }
        // }else if(action.update === 'profile'){
        //   const mUser = action.result;
        //   user = parseProfile(mUser, state.type);
        // }
        return {
          ...state,
          ...smark,
          ...user /*loading: Object.keys(action.payload)*/,
        };
      }
    case SAVE_USER_FAILURE: {
      const fmark = markFailure(action, state);

      return { ...state, ...fmark /*loading: Object.keys(action.payload)*/ };
    }
    case MY_PLAN_SUCCESS: {
      const smark = markSuccess(action, state);
      const subscription = action.result;
      return { ...state, ...smark, subscription };
    }
    case SAVE_AGENCY_BRAND_REQUEST:
    case SAVE_BRAND_MANAGER_REQUEST: {
      const mark = markLoading(action, state);
      return { ...state, ...mark /*loading: Object.keys(action.payload)*/ };
    }
    case SAVE_AGENCY_BRAND_SUCCESS:
    case SAVE_BRAND_MANAGER_SUCCESS: {
      if (action.statusCode == '400' || !action.result || !action.result.id) {
        const fmark = markFailure(action, state);

        return { ...state, ...fmark, status: STATE.SUCCESS, loading: [] };
      } else {
        const smark = markSuccess(action, state);

        let user = {};
        // if(action.update === 'user') {
        const mUser = action.result;
        if (mUser.user && !mUser.user.usertype) {
          mUser.user.usertype = state.usertype;
        }
        user = loginUser(mUser);
        // }else if(action.update === 'profile'){
        //   const mUser = action.result;
        //   user = parseProfile(mUser, state.type);
        // }
        return {
          ...state,
          ...smark,
          // ...user /*loading: Object.keys(action.payload)*/,
        };
      }
    }
    case SAVE_AGENCY_BRAND_FAILURE:
    case SAVE_BRAND_MANAGER_FAILURE: {
      const fmark = markFailure(action, state);

      return { ...state, ...fmark /*loading: Object.keys(action.payload)*/ };
    }
    case GET_USER_SUCCESS: {
      const userType = action.payload.type;
      const smark = markSuccess(action, state);
      const mUser = action.result;
      const accessToken = loadTokens().access;
      const tokenUser = accessToken ? decodeUser(accessToken, true) : {};

      const user = loginUser(
        mUser,
        tokenUser.usertype || userType,
        tokenUser.admin
      );

      if (user) {
        return {
          ...state,
          ...user,
          ...smark,
          status: STATE.SUCCESS,
          loading: [],
        };
      } else {
        return { ...state, ...smark, status: STATE.SUCCESS, loading: [] };
      }
    }
    case GET_USER_FAILURE: {
      const fmark = markFailure(action, state);
      return { ...state, ...fmark, status: STATE.FAILURE, loading: [] };
    }
    case LOGIN_REQUEST: {
      return { ...state, loading: [action.service], error: null };
    }
    case LOGIN_SUCCESS: {
      const { platform, social_access_token } = action.payload
        ? action.payload
        : {};

      const access = action.result.access || action.result.access_token;
      const refresh = action.result.refresh || action.result.refresh_token;
      const userToken = decodeUser(access);
      const admin = userToken && userToken.usertype === 'Admin';

      if (access && refresh) {
        const res = { access, refresh };
        // const tokenUser = access ? decodeUser(access,true) : {};

        let user = action.result['user-profile']
          ? loginUser(action.result['user-profile'], userToken.usertype, admin)
          : decodeUser(access, null, admin);

        // console.log('user', user);

        // if(action.result.user){
        //   user = {...action.result.user, ...user};
        // }

        //save token in localstorage
        ls.setItem('access', res.access);
        ls.setItem('refresh', res.refresh);

        return {
          ...state,
          ...res,
          ...user,
          status: STATE.SUCCESS,
          platform,
          social_access_token,
        };
      } else {
        let message = 'Could not login';
        if (
          action.result.password &&
          action.result.password[0] === 'This field may not be blank.'
        ) {
          message = 'Password is required';
        }

        if (
          action.result.username &&
          action.result.username[0] === 'This field may not be blank.'
        ) {
          message = 'Email, Phone or Username is required';
        }

        return { ...state, error: message, status: STATE.FAILURE, loading: [] };
      }
    }
    case LOGIN_FAILURE: {
      let message = 'Invalid email or password';
      if (action.service === 'facebook') {
        message = 'Could not login to Facebook';
      } else if (action.service === 'google') {
        // action.
        message = 'Could not login to Google';
        if (
          action.error.non_field_errors[0] ===
          'User is already registered with this e-mail address.'
        ) {
          message = 'User is already registered with this e-mail address';
        }
      }

      return { ...state, error: message, status: STATE.FAILURE, loading: [] };
    }
    case SEND_OTP_SUCCESS:
    case SEND_OTP_FAILURE: {
      if (action?.result?.error_code === '400') {
        let message = 'Email or Phone is required';
        if (action?.result?.message === 'User not found') {
          message = 'Invalid Email or Phone';
        }

        return { ...state, error: message, status: STATE.FAILURE, loading: [] };
      } else {
        return { ...state };
      }
    }
    case REGISTER_REQUEST: {
      return { ...state, loading: ['register'], error: null };
    }
    case REGISTER_SUCCESS: {
      let error = null;
      if (
        action &&
        action.result &&
        action.result.message &&
        action.result.message.email &&
        action.result.message.email.length > 0 &&
        action.result.message.email[0] === 'This field must be unique.'
      ) {
        error = 'User already exists';
      } else if (
        action.result &&
        action.result.message ===
          'Influencer is already registered with this e-mail address.'
      ) {
        error = 'User already exists';
      } else if (action.result && action.result.message) {
        error = action.result.message;
      }

      console.log(error);

      return { ...state, loading: [], error: error };
    }
    case REGISTER_FAILURE: {
      let message = 'Something went wrong. Please contact support on team@infloso.com.';

      if (
        action.error.message &&
        action.error.message ===
          'Influencer is already registered with this e-mail address.'
      ) {
        message = 'User already exists';
      } else if (
        action.error.message &&
        action.error.message ===
          'User is already registered with this e-mail address.'
      ) {
        message = 'User already exists';
      } else if (action.error.message && action.error.message) {
        message = action.error.message;
      }

      return { ...state, error: message, status: STATE.FAILURE, loading: [] };
    }
    case REFRESH_TOKEN_SUCCESS: {
      return { ...state, ...action.payload, status: STATE.SUCCESS };
    }
    case LOGOUT_SUCCESS: {
      const logoutState = {
        admin: null,
        usertype: null,
        user_id: null,
        username: null,
        exp: null,
        type: null,
        access: null,
        refresh: null,
      };
      ls.removeItem('access');
      ls.removeItem('refresh');
      ls.removeItem('admin_access');
      ls.removeItem('admin_refresh');

      return { ...initialState, ...logoutState, status: STATE.SUCCESS };
    }
    default: {
      return { ...state };
    }
  }
}

function processMark(action, state) {
  if (action.type.indexOf('_REQUEST') > -1) {
    return markLoading(action, state);
  } else if (action.type.indexOf('_SUCCESS') > -1) {
    return markSuccess(action, state);
  } else if (action.type.indexOf('_FAILURE') > -1) {
    return markFailure(action, state);
  }
  return {};
}

// export function loginAs(user_type, user_id, type_id, username, email) {
//   const data = {
//     user_type,
//     user_id,
//     type_id,
//     username,
//     email,
//   };
//
//   return {
//     types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
//     mark: 'delegate-login',
//     service: 'delegate-login',
//     promise: (client) =>
//       client('/inflosousers/login', {
//         method: 'POST',
//         body: JSON.stringify(data),
//       }),
//   };
// }

export function login(service, params) {
  const data = {
    username: params.isMobile
      ? 'mobile:' + (params.username || params.email)
      : params.username || params.email, //.split("@")[0],
    password: params.isOTP ? 'otp:' + params.password : params.password,
  };

  if (params.impersonation_email) {
    data.impersonation_email = params.impersonation_email;
  }

  if (isServer()) {
    return {
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
      mark: service + '-login',
      service: service + '-login',
      promise: (client) =>
        client('/inflosousers/login', {
          method: 'POST',
          body: JSON.stringify(data),
        }),
    };
  }

  return {
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    mark: service + '-login',
    service: service + '-login',
    promise: (client) =>
      client('/api/auth/login', {
        method: 'POST',
        body: JSON.stringify({ service, params }),
      }),
  };
}

export function resendOTP(emailOrPhone) {}

export function sendOTP(emailOrPhone) {
  let data = {};
  if (emailOrPhone.indexOf('@') > 0) {
    //is email
    data = {
      email_id: emailOrPhone,
    };
  } else if (emailOrPhone.length > 9) {
    //is phone
    data = {
      mobile_no: emailOrPhone,
    };
  }

  return {
    types: [SEND_OTP_REQUEST, SEND_OTP_SUCCESS, SEND_OTP_FAILURE],
    mark: 'otp-login',
    service: 'otp-login',
    promise: (client) =>
      client('/inflosousers/login/otp', {
        method: 'PUT',
        body: JSON.stringify(data),
      }),
  };
}

export function youtubeLogin(access_token) {
  /**
   email: "delsanic@gmail.com"
   first_name: "Del"
   id: "3147430372154022"
   last_name: "Sanic"
   name: "Del Sanic"
   picture:{
             data:{
               height: 50
               is_silhouette: false
               url: "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=3147430372154022&height=50&width=50&ext=1625251027&hash=AeR9q92DfOSytwNtV1Q"
               width: 50
             }
           }
   */

  // const service = 'facebook';
  // const params = {
  //   accessToken: response.accessToken
  // };

  //

  return {
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    mark: 'youtube' + '-login',
    service: 'youtube' + '-login',
    promise: (client) =>
      client('/inflosousers/sociallogin/youtube', {
        method: 'POST',
        body: JSON.stringify({ access_token }),
      }),
  };
}

export function resetPassword(email) {
  const payload = {
    email,
  };

  return {
    payload: payload,
    types: [
      RESET_PASSWORD_REQUEST,
      RESET_PASSWORD_SUCCESS,
      RESET_PASSWORD_FAILURE,
    ],
    mark: { 'reset-confirm-password': 1 },
    promise: (client) =>
      client('/inflosousers/reset-password/forgot-password', {
        method: 'POST',
        body: JSON.stringify(payload),
      }),
  };
}

export function resetConfirmPassword(token, email, password) {
  const payload = {
    token,
    email,
    password,
  };

  return {
    payload: payload,
    types: [
      RESET_PASSWORD_REQUEST,
      RESET_PASSWORD_SUCCESS,
      RESET_PASSWORD_FAILURE,
    ],
    mark: { 'reset-password': 1 },
    promise: (client) =>
      client('/inflosousers/reset-password/confirm/', {
        method: 'POST',
        body: JSON.stringify(payload),
      }),
  };
}

export function facebookLogin(access_token) {
  /**
   email: "delsanic@gmail.com"
   first_name: "Del"
   id: "3147430372154022"
   last_name: "Sanic"
   name: "Del Sanic"
   picture:{
             data:{
               height: 50
               is_silhouette: false
               url: "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=3147430372154022&height=50&width=50&ext=1625251027&hash=AeR9q92DfOSytwNtV1Q"
               width: 50
             }
           }
   */

  // const service = 'facebook';
  // const params = {
  //   accessToken: response.accessToken
  // };

  //

  return {
    payload: { platform: 'facebook', social_access_token: access_token },
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    mark: 'facebook' + '-login',
    service: 'facebook' + '-login',
    promise: (client) =>
      client('/inflosousers/sociallogin/facebook', {
        credentials : 'omit',
        method: 'POST',
        body: JSON.stringify({ access_token }),
      }),
  };
}

export function googleLogin(access_token) {
  /**
   response:{
       profileObj:{
         email: "delsanic@gmail.com"
         familyName: "sanic"
         givenName: "del"
         googleId: "114012659342847965475"
         imageUrl: "https://lh3.googleusercontent.com/a-/AOh14GgXlnvcszXUhyrdcns8DUQEtaOd45a2cQPYXCa9=s96-c"
         name: "del sanic"
       }
     }
   */

  return {
    payload: { platform: 'google', social_access_token: access_token },
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    mark: 'google' + '-login',
    service: 'google' + '-login',
    promise: (client) =>
      client('/inflosousers/sociallogin/google', {
        method: 'POST',
        body: JSON.stringify({ access_token }),
      }),
  };
}

export function socialLogin(access_token, platform) {
  /**
   response:{
       profileObj:{
         email: "delsanic@gmail.com"
         familyName: "sanic"
         givenName: "del"
         googleId: "114012659342847965475"
         imageUrl: "https://lh3.googleusercontent.com/a-/AOh14GgXlnvcszXUhyrdcns8DUQEtaOd45a2cQPYXCa9=s96-c"
         name: "del sanic"
       }
     }
   */

  return {
    payload: { platform: platform, social_access_token: access_token },
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
    mark: 'google' + '-login',
    service: 'google' + '-login',
    promise: (client) =>
      client(`/inflosousers/sociallogin/${platform}`, {
        method: 'POST',
        body: JSON.stringify({ access_token }),
      }),
  };
}

export function register(params) {
  const data = {
    user: {
      email: params.email,
      username: params.username || 'user' + new Date().getTime(),
      first_name: params.first_name || params.name,
      last_name: params.last_name || '', // NA
      password: params.password,
      password2: params.password, //NA
    },
    // "alias": "alias",
    // "tagLine": "tagLine",
    // "bio": "bio",
    // "location": "location",
    birth_date: '1970-01-01',
    // "profileImage": "profileImage",
    // "gender": "Male"
  };

  return {
    types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE],
    mark: 'register',
    // promise: (client) => client('/api/auth/login', {method:'POST', body:JSON.stringify( {service, params})})
    // promise: (client) => client(`/inflosousers/${params.type}`, {method:'POST', body:JSON.stringify(data)})
    promise: (client) =>
      client(`/inflosousers/${params.type}`, {
        method: 'POST',
        body: JSON.stringify(data),
      }),
  };
}

export function deactivate(user, data) {
  return save({ is_active: false, ...data }, user);
}
export function deactivateAgencyBrand(user, data) {
  return saveAgencyBrand({ is_active: false, ...data }, user);
}
export function deactivateBrandManager(user, data) {
  return saveBrandManager({ is_active: false, ...data }, user);
}

export function getSocialDetails(v, key = 'get-social-details') {
  return {
    types: [
      GET_SOCIAL_DETAILS_REQUEST,
      GET_SOCIAL_DETAILS_SUCCESS,
      GET_SOCIAL_DETAILS_FAILURE,
    ],
    mark: { [key]: 1 },
    promise: (client) =>
      client(
        `/inflososocial/platform/connect?url=${v.url}&platform=${v.platform}&username=${v.username}`,
        {},
        true
      ),
  };
}

export function logout(service, options) {
  return {
    types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
    mark: 'logout',
    promise: (client) => client('/api/auth/logout'),
  };
}

export function changePassword(old_pass, new_pass, user_email) {
  const payload = {
    old_password: old_pass,
    new_password: new_pass,
  };

  if (user_email) {
    payload.user_email = user_email;
  }

  return {
    types: [
      CHANGE_PASSWORD_REQUEST,
      CHANGE_PASSWORD_SUCCESS,
      CHANGE_PASSWORD_FAILURE,
    ],
    mark: { 'change-password': 1 },
    promise: (client) =>
      client(
        '/inflosousers/change-password',
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function disable(user_id) {
  const payload = {
    user_id,
  };

  return {
    types: [
      DISABLE_ACCOUNT_REQUEST,
      DISABLE_ACCOUNT_SUCCESS,
      DISABLE_ACCOUNT_FAILURE,
    ],
    mark: { 'change-password': 1 },
    promise: (client) =>
      client(
        '/inflosousers/disable',
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function refresh() {
  return ({ dispatch, getState }) => {
    const user = getState().user;
    const token = user.refresh;

    return {
      types: [
        REFRESH_TOKEN_REQUEST,
        REFRESH_TOKEN_SUCCESS,
        REFRESH_TOKEN_FAILURE,
      ],
      mark: 'refresh',
      promise: (client) =>
        client('/inflosousers/login/refresh', {
          method: 'POST',
          body: JSON.stringify({ refresh: token }),
        }),
    };
  };
}

export function checkUsername(username) {
  return {
    types: [CHECK_TOKEN_REQUEST, CHECK_TOKEN_SUCCESS, CHECK_TOKEN_FAILURE],
    mark: 'refresh',
    promise: (client) =>
      client(`/inflososocial/discovery/check/?username=${username}`, {}),
  };
}

export function loadBrand(filter) {
  const query = filter;
  const index = 'brands';
  return {
    payload: index,
    types: [LOAD_BRAND_REQUEST, LOAD_BRAND_SUCCESS, LOAD_BRAND_FAILURE],
    promise: search(index, query),
  };
}

export function getUser(type = 'all', key = 'get_user', _user) {
  if (isServer()) {
    return (dispatch, getState) => {
      const user = _user || getState().user;
      return dispatch({
        types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE],
        mark: { [key]: 1 },
        payload: { type },
        promise: (client) => {
          // console.log('luse', user);
          if (user && (user.id || user.user_id)) {
            const URLS = {
              influencer: '/inflosousers/influencers',
              brand: '/inflosousers/brands',
              agency: '/inflosousers/agencies',
              agencybrand: '/inflosousers/agency-brand',
              brandmanager: '/inflosousers/brand-manager',
              all: '/inflosousers/userprofile',
            };

            if (type && type !== 'all' && user.type_id) {
              return client(`${URLS[type]}/${user.type_id}`, {}, true).catch(
                (e) => console.log
              );
            } else {
              return client(
                `${URLS['all']}/${user.id || user.user_id}`,
                {},
                true
              ).catch((e) => console.log);
            }
          } else {
            return new Promise((resolve, reject) => {
              resolve({ error_: 'No Access token' });
            });
          }
        },
      });
    };
  }
  return {
    types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE],
    mark: { [key]: 1 },
    promise: (client) => client('/api/auth/user'),
  };
}

export function getUserAdmin(type = 'all', user) {
  return {
    types: [
      GET_USER_ADMIN_REQUEST,
      GET_USER_ADMIN_SUCCESS,
      GET_USER_ADMIN_FAILURE,
    ],
    mark: { ['get_user']: 1 },
    payload: { type },
    promise: (client) => {
      // console.log('lluusseerr', user);
      if (user && user.user_id) {
        const URLS = {
          influencer: '/inflosousers/influencers',
          brand: '/inflosousers/brands',
          agency: '/inflosousers/agencies',
          agencybrand: '/inflosousers/agency-brand',
          brandmanager: '/inflosousers/brand-manager',
          all: '/inflosousers/userprofile',
        };

        if (type && type !== 'all' && user.type_id) {
          return client(`${URLS[type]}/${user.type_id}`, {}, true).catch(
            (e) => console.log
          );
        } else {
          return client(`${URLS['all']}/${user.user_id}`, {}, true).catch(
            (e) => console.log
          );
        }
      } else {
        return new Promise((resolve, reject) => {
          resolve({ error_: 'No Access token' });
        });
      }
    },
  };
}

export function saveSocialProfile(profile, key = 'save-social-profile') {
  return async (dispatch, getState) => {
    const user = getState().user;

    const serverProfile = {
      social_media_platform: profile.platform || profile.social_media_platform,
      followers: profile.followers || 0,
      following: profile.following || 0,
    };

    const type = profile.type || user.type;
    if (type === 'influencer') {
      serverProfile.profile_name = profile.name || profile.profile_name;
      serverProfile.profile_url = profile.url || profile.profile_url;
    } else if (type === 'brand') {
      serverProfile.brand_name = profile.name || profile.profile_name;
      serverProfile.brand_url = profile.url || profile.profile_url;
    }

    let url = `/inflosousers/${type}-sp`;
    let method = 'POST';

    if (profile.profile_id || user.profile_id) {
      serverProfile[type + '_profile'] = profile.profile_id || user.profile_id;
    } else {
      const newProfile = await dispatch(
        save({ social_profile: 1 }, profile || user)
      );
      serverProfile[type + '_profile'] = newProfile.id;
    }

    if (profile.id) {
      url = url + '/' + profile.id;
      method = 'PUT';
    }

    return dispatch({
      types: [
        SAVE_SOCIAL_PROFILE_REQUEST,
        SAVE_SOCIAL_PROFILE_SUCCESS,
        SAVE_SOCIAL_PROFILE_FAILURE,
      ],
      payload: serverProfile,
      update: serverProfile,
      mark: { [profile.platform]: 1, [key]: 1 },
      promise: (client) =>
        client(
          url,
          {
            method: method,
            body: JSON.stringify(serverProfile),
          },
          true
        ),
    });
  };
}

export function removeSocialProfile(sp_id, t, platform, profile_id) {
  return (dispatch, getState) => {
    const type = t || getState().user.type;

    return dispatch({
      types: [
        REMOVE_SOCIAL_PROFILE_REQUEST,
        REMOVE_SOCIAL_PROFILE_SUCCESS,
        REMOVE_SOCIAL_PROFILE_FAILURE,
      ],
      payload: {},
      mark: { ['delete-social-profile-' + platform]: 1 },
      promise: (client) =>
        client(
          `/inflosousers/${type}-sp-unlink/${sp_id}/`,
          {
            method: 'DELETE',
            body: JSON.stringify({ influencer_profile: profile_id }),
          },
          true
        ),
    });
  };
}

export function save(userUpdate, suser) {
  if (isServer()) {
    return (dispatch, getState) => {
      const user = suser || getState().user;
      const { payload, url, update, method } = saveUserMapping(
        userUpdate,
        user
      );

      // userUpdate.user = user.user_id;
      // userUpdate[user.type] = user.type_id;
      //
      // const type_profile = {
      //   registered_address: user.address.registered_address,
      //   city: user.address.city,
      //   zip: user.address.zip,
      //   keywords: (user.keywords||[]).map(k=>k.id),
      //   category: (user.category||[]).map(k=>k.id)
      // }
      //
      // userUpdate = { ...type_profile, ...userUpdate };

      // if(userUpdate.username){
      //   userUpdate.user  = {id:user.user_id, username:userUpdate.username};
      // }

      return dispatch({
        types: [SAVE_USER_REQUEST, SAVE_USER_SUCCESS, SAVE_USER_FAILURE],
        payload: userUpdate,
        update: update,
        mark: userUpdate,
        // promise: (client) => client(`/inflosousers/xxx/${user.id}`, {
        promise: (client) =>
          client(
            url,
            {
              // promise: (client) => client(`/inflosousers/${user.type}/${user.type_id}`, {
              // promise: (client) => client(`/inflosousers/${user.type}-profile/${user.profile_id}`, {
              // promise: (client) => client(`/inflosousers/userprofile/${user.user_id}`, {
              method: method,
              body: JSON.stringify(payload),
            },
            true
          ),
      });
    };
  }

  return {
    types: [SAVE_USER_REQUEST, SAVE_USER_SUCCESS, SAVE_USER_FAILURE],
    payload: userUpdate,
    mark: userUpdate,
    promise: (client) =>
      client('/api/auth/user', {
        method: 'POST',
        body: JSON.stringify(userUpdate),
      }),
  };
}

export function showError(message) {
  return {
    payload: message,
    type: SHOW_ERROR,
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

export function clearSuccess(key) {
  return {
    type: CLEAR_SUCCESS,
    payload: key,
  };
}

export function taxMediaUpload(file) {
  return {
    types: [
      TAX_MEDIA_UPLOAD_REQUEST,
      TAX_MEDIA_UPLOAD_SUCCESS,
      TAX_MEDIA_UPLOAD_FAILURE,
    ],
    mark: { tax_media: 1 },
    promise: (client, fileUpload) =>
      fileUpload(file, '/inflosousers/tax-media-upload'),
  };
}

export function panMediaUpload(file) {
  return {
    types: [
      PAN_MEDIA_UPLOAD_REQUEST,
      PAN_MEDIA_UPLOAD_SUCCESS,
      PAN_MEDIA_UPLOAD_FAILURE,
    ],
    mark: { pan_media: 1 },
    promise: (client, fileUpload) =>
      fileUpload(file, '/inflosousers/tan-media-upload'),
  };
}

export function approveProfile(id) {
  return updateAccountStatus(id, 'Approved');
}

export function rejectProfile(id) {
  return updateAccountStatus(id, 'Rejected');
}

export function updateAccountStatus(id, status) {
  const payload = {
    account_state: status,
  };

  return {
    types: [
      SEND_EMAIL_CODE_REQUEST,
      SEND_EMAIL_CODE_SUCCESS,
      SEND_EMAIL_CODE_FAILURE,
    ],
    mark: { 'send-email-code': 1 },
    promise: (client) =>
      client(
        `/inflosousers/account-status/${id}`,
        {
          method: 'PUT',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function loadInfluencerList() {
  return {
    types: [
      LOAD_INFLUENCER_LIST_REQUEST,
      LOAD_INFLUENCER_LIST_SUCCESS,
      LOAD_INFLUENCER_LIST_FAILURE,
    ],
    mark: { load_influencer_list: 1 },
    promise: (client) => client(`/inflosousers/influencer-lists`, {}, true),
  };
}

export function saveInfluencerList(data) {
  return {
    types: [
      SAVE_INFLUENCER_LIST_REQUEST,
      SAVE_INFLUENCER_LIST_SUCCESS,
      SAVE_INFLUENCER_LIST_FAILURE,
    ],
    mark: { save_influencer_list: 1 },
    promise: (client) =>
      client(
        data.id
          ? `/inflosousers/influencer-list/${data.id}`
          : `/inflosousers/influencer-list/`,
        {
          method: data.id ? 'PUT' : 'POST',
          body: JSON.stringify(data),
        },
        true
      ),
  };
}

export function sendEmailCode() {
  return (dispatch, getState) => {
    const user = getState().user;

    return dispatch({
      types: [
        SEND_EMAIL_CODE_REQUEST,
        SEND_EMAIL_CODE_SUCCESS,
        SEND_EMAIL_CODE_FAILURE,
      ],
      mark: { 'send-email-code': 1 },
      promise: (client) =>
        client(
          `/inflosousers/verify-email/${user.type_id}`,
          {
            method: 'PUT',
            body: '',
          },
          true
        ),
    });
  };
}

export function verifyEmailCode(code) {
  const payload = {
    code,
  };
  return (dispatch, getState) => {
    const user = getState().user;

    return dispatch({
      types: [
        VERIFY_EMAIL_CODE_REQUEST,
        VERIFY_EMAIL_CODE_SUCCESS,
        VERIFY_EMAIL_CODE_FAILURE,
      ],
      mark: { 'verify-email-code': 1 },
      promise: (client) =>
        client(
          `/inflosousers/email-verify/${user.type_id}`,
          {
            method: 'PUT',
            body: JSON.stringify(payload),
          },
          true
        ),
    });
  };
}

export function sendMobileCode() {
  return (dispatch, getState) => {
    const user = getState().user;

    return dispatch({
      types: [
        SEND_MOBILE_CODE_REQUEST,
        SEND_MOBILE_CODE_SUCCESS,
        SEND_MOBILE_CODE_FAILURE,
      ],
      mark: { 'send-mobile-code': 1 },
      promise: (client) =>
        client(
          `/inflosousers/verify-sms/${user.type_id}`,
          {
            method: 'PUT',
            body: '',
          },
          true
        ),
    });
  };
}

export function verifyMobileCode(code) {
  const payload = {
    code,
  };
  return (dispatch, getState) => {
    const user = getState().user;

    return dispatch({
      types: [
        VERIFY_MOBILE_CODE_REQUEST,
        VERIFY_MOBILE_CODE_SUCCESS,
        VERIFY_MOBILE_CODE_FAILURE,
      ],
      mark: { 'verify-mobile-code': 1 },
      promise: (client) =>
        client(
          `/inflosousers/sms-verify/${user.type_id}`,
          {
            method: 'PUT',
            body: JSON.stringify(payload),
          },
          true
        ),
    });
  };
}

export function facebookConnect(access_token, isRefresh) {
  const payload = {
    access_token,
  };

  return {
    types: [
      FACEBOOK_CONNECT_REQUEST,
      FACEBOOK_CONNECT_SUCCESS,
      FACEBOOK_CONNECT_FAILURE,
    ],
    mark: { 'facebook-connect': 1 },
    promise: (client) =>
      client(
        `/inflosousers/social${isRefresh ? 'refresh' : 'link'}/facebook`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function instagramConnect(payload, isRefresh) {
  // const payload = {
  //   accessToken
  // };

  return {
    types: [
      INSTAGRAM_CONNECT_REQUEST,
      INSTAGRAM_CONNECT_SUCCESS,
      INSTAGRAM_CONNECT_FAILURE,
    ],
    mark: { 'instagram-connect': 1 },
    promise: (client) =>
      client(
        `/inflosousers/social${isRefresh ? 'refresh' : 'link'}/instagram`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function youtubeConnect(access_token, isRefresh) {
  const payload = {
    access_token,
  };

  return {
    types: [
      YOUTUBE_CONNECT_REQUEST,
      YOUTUBE_CONNECT_SUCCESS,
      YOUTUBE_CONNECT_FAILURE,
    ],
    mark: { 'youtube-connect': 1 },
    promise: (client) =>
      client(
        `/inflosousers/social${isRefresh ? 'refresh' : 'link'}/youtube`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function addAgencyBrandManager(brandManager, extraData, user) {
  return saveBrandManager({ brand_managers: brandManager, ...extraData }, user);
}

export function saveAgencyBrand(userUpdate, user) {
  return (dispatch, getState) => {
    const suser = user || getState().page['agency-brand'];
    const { payload, url, update, method } = saveUserMapping(userUpdate, suser);

    // userUpdate.user = user.user_id;
    // userUpdate[user.type] = user.type_id;
    //
    // const type_profile = {
    //   registered_address: user.address.registered_address,
    //   city: user.address.city,
    //   zip: user.address.zip,
    //   keywords: (user.keywords||[]).map(k=>k.id),
    //   category: (user.category||[]).map(k=>k.id)
    // }
    //
    // userUpdate = { ...type_profile, ...userUpdate };

    // if(userUpdate.username){
    //   userUpdate.user  = {id:user.user_id, username:userUpdate.username};
    // }

    return dispatch({
      types: [
        SAVE_AGENCY_BRAND_REQUEST,
        SAVE_AGENCY_BRAND_SUCCESS,
        SAVE_AGENCY_BRAND_FAILURE,
      ],
      payload: userUpdate,
      update: update,
      mark: userUpdate,
      // promise: (client) => client(`/inflosousers/xxx/${user.id}`, {
      promise: (client) =>
        client(
          url,
          {
            // promise: (client) => client(`/inflosousers/${user.type}/${user.type_id}`, {
            // promise: (client) => client(`/inflosousers/${user.type}-profile/${user.profile_id}`, {
            // promise: (client) => client(`/inflosousers/userprofile/${user.user_id}`, {
            method: method,
            body: JSON.stringify(payload),
          },
          true
        ),
    });
  };
}

export function saveBrandManager(userUpdate) {
  return (dispatch, getState) => {
    const user = getState().page.brandManager;
    const { payload, url, update, method } = saveUserMapping(userUpdate, user);

    // userUpdate.user = user.user_id;
    // userUpdate[user.type] = user.type_id;
    //
    // const type_profile = {
    //   registered_address: user.address.registered_address,
    //   city: user.address.city,
    //   zip: user.address.zip,
    //   keywords: (user.keywords||[]).map(k=>k.id),
    //   category: (user.category||[]).map(k=>k.id)
    // }
    //
    // userUpdate = { ...type_profile, ...userUpdate };

    // if(userUpdate.username){
    //   userUpdate.user  = {id:user.user_id, username:userUpdate.username};
    // }

    return dispatch({
      types: [
        SAVE_BRAND_MANAGER_REQUEST,
        SAVE_BRAND_MANAGER_SUCCESS,
        SAVE_BRAND_MANAGER_FAILURE,
      ],
      payload: userUpdate,
      update: update,
      mark: userUpdate,
      // promise: (client) => client(`/inflosousers/xxx/${user.id}`, {
      promise: (client) =>
        client(
          url,
          {
            // promise: (client) => client(`/inflosousers/${user.type}/${user.type_id}`, {
            // promise: (client) => client(`/inflosousers/${user.type}-profile/${user.profile_id}`, {
            // promise: (client) => client(`/inflosousers/userprofile/${user.user_id}`, {
            method: method,
            body: JSON.stringify(payload),
          },
          true
        ),
    });
  };
}

//TODO move to client

export function enableWhitelabel() {
  return subscribePlan('1');
}

export function subscribePlan(plan_id, agency_id) {
  const payload = {
    plan: `${plan_id}`,
    agency: `${agency_id}`,
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().add(10, 'year').format('YYYY-MM-DD'),
  };
  return {
    types: [
      SUBSCRIBE_PLAN_REQUEST,
      SUBSCRIBE_PLAN_SUCCESS,
      SUBSCRIBE_PLAN_FAILURE,
    ],
    mark: { subscribe_plan: 1 },
    promise: (client) =>
      client(
        `/inflososubs/subscription`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function getMySubscription() {
  return {
    types: [MY_PLAN_REQUEST, MY_PLAN_SUCCESS, MY_PLAN_FAILURE],
    mark: { my_plan: 1 },
    promise: (client) => client(`/inflososubs/my-subscriptions`, {}, true),
  };
}

export function getFeed(platform, user_id) {
  const url = user_id
    ? `/inflososocial/posts?platform=${platform}&user_id=${user_id || ''}`
    : `/inflososocial/posts?platform=${platform}`;

  return {
    types: [FEED_REQUEST, FEED_SUCCESS, FEED_FAILURE],
    mark: { my_plan: 1 },
    promise: (client) => client(url, {}, true),
  };
}

export function getCalcData(platform, user_id) {
  const url = user_id
    ? `/inflososocial/calc_data?platform=${platform}&user_id=${user_id || ''}`
    : `/inflososocial/calc_data?platform=${platform}`;

  return {
    types: [CALC_DATA_REQUEST, CALC_DATA_SUCCESS, CALC_DATA_FAILURE],
    mark: { insights: 1 },
    promise: (client) => client(url, {}, true),
  };
}

export function getInsights(platform, user_id) {
  const url = user_id
    ? `/inflososocial/insights?platform=${platform}&user_id=${user_id || ''}`
    : `/inflososocial/insights?platform=${platform}`;

  return {
    types: [INSIGHTS_REQUEST, INSIGHTS_SUCCESS, INSIGHTS_FAILURE],
    mark: { insights: 1 },
    promise: (client) => client(url, {}, true),
  };
}

export function getDailyInsights(platform, user_id) {
  const url = user_id
    ? `/inflososocial/daily_insights?platform=${platform}&user_id=${
        user_id || ''
      }`
    : `/inflososocial/daily_insights?platform=${platform}`;

  return {
    types: [
      DAILY_INSIGHTS_REQUEST,
      DAILY_INSIGHTS_SUCCESS,
      DAILY_INSIGHTS_FAILURE,
    ],
    mark: { insights: 1 },
    promise: (client) => client(url, {}, true),
  };
}

export function getReach(platform, user_id) {
  const url = user_id
    ? `/inflososocial/reach?platform=${platform}&user_id=${user_id || ''}`
    : `/inflososocial/reach?platform=${platform}`;

  return {
    types: [REACH_REQUEST, REACH_SUCCESS, REACH_FAILURE],
    mark: { insights: 1 },
    promise: (client) => client(url, {}, true),
  };
}

export function getFeedCount(user_id) {
  const url = user_id
    ? `/inflososocial/count_posts?user_id=${user_id}`
    : `/inflososocial/count_posts`;
  return {
    types: [FEED_COUNT_REQUEST, FEED_COUNT_SUCCESS, FEED_COUNT_FAILURE],
    mark: { my_plan: 1 },
    promise: (client) => client(url, {}, true),
  };
}

export function similarInfluencers(user_id) {
  const url = user_id
    ? `/inflosousers/influencer/similar?user_id=${user_id}`
    : `/inflosousers/influencer/similar`;
  return {
    types: [
      SIMILAR_INFLUENCERS_REQUEST,
      SIMILAR_INFLUENCERS_SUCCESS,
      SIMILAR_INFLUENCERS_FAILURE,
    ],
    mark: { similar_influencers: 1 },
    promise: (client) => client(url, {}, true),
  };
}

export function similarBrands(user_id) {
  const url = user_id
    ? `/inflosousers/brand/similar?user_id=${user_id}`
    : `/inflosousers/brand/similar`;
  return {
    types: [
      SIMILAR_BRAND_REQUEST,
      SIMILAR_BRAND_SUCCESS,
      SIMILAR_BRAND_FAILURE,
    ],
    mark: { similar_brand: 1 },
    promise: (client) => client(url, {}, true),
  };
}

export function agencyBrandProductImageUpload(file, agency_brand_id, data) {
  return {
    types: [
      AGENCY_BRAND_PRODUCT_IMAGE_UPLOAD_REQUEST,
      AGENCY_BRAND_PRODUCT_IMAGE_UPLOAD_SUCCESS,
      AGENCY_BRAND_PRODUCT_IMAGE_UPLOAD_FAILURE,
    ],
    mark: { reference_file: 1 },
    promise: (client, fileUpload) =>
      fileUpload(
        file,
        `/inflosousers/agency-brand-product/${agency_brand_id}/image-upload`,
        data
      ),
  };
}

export function createAgencyBrandProduct(data, agency_brand_id) {
  return {
    types: [
      CREATE_AGENCY_BRAND_PRODUCT_REQUEST,
      CREATE_AGENCY_BRAND_PRODUCT_SUCCESS,
      CREATE_AGENCY_BRAND_PRODUCT_FAILURE,
    ],
    mark: { subscribe_plan: 1 },
    promise: (client) =>
      client(
        `/inflosousers/agency-brand-product/${agency_brand_id}`,
        {
          method: 'POST',
          body: JSON.stringify(data),
        },
        true
      ),
  };
}

export function updateAgencyBrandProduct(data, agency_brand_id) {
  return {
    types: [
      UPDATE_AGENCY_BRAND_PRODUCT_REQUEST,
      UPDATE_AGENCY_BRAND_PRODUCT_SUCCESS,
      UPDATE_AGENCY_BRAND_PRODUCT_FAILURE,
    ],
    mark: { subscribe_plan: 1 },
    promise: (client) =>
      client(
        `/inflosousers/agency-brand-product/${agency_brand_id}/${data.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
        true
      ),
  };
}

export function removeAgencyBrandProduct(data, agency_brand_id) {
  return {
    types: [
      REMOVE_AGENCY_BRAND_PRODUCT_REQUEST,
      REMOVE_AGENCY_BRAND_PRODUCT_SUCCESS,
      REMOVE_AGENCY_BRAND_PRODUCT_FAILURE,
    ],
    mark: { subscribe_plan: 1 },
    promise: (client) =>
      client(
        `/inflosousers/agency-brand-product-delete/${agency_brand_id}/${data.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
        true
      ),
  };
}

export function getAgencyProducts(agency_brand_id) {
  return {
    types: [
      GET_AGENCY_BRAND_PRODUCTS_REQUEST,
      GET_AGENCY_BRAND_PRODUCTS_SUCCESS,
      GET_AGENCY_BRAND_PRODUCTS_FAILURE,
    ],
    mark: { subscribe_plan: 1 },
    promise: (client) =>
      client(
        `/inflosousers/agency-brand-products/${agency_brand_id}`,
        {},
        true
      ),
  };
}

export function brandProductImageUpload(file, data) {
  return {
    types: [
      BRAND_PRODUCT_IMAGE_UPLOAD_REQUEST,
      BRAND_PRODUCT_IMAGE_UPLOAD_SUCCESS,
      BRAND_PRODUCT_IMAGE_UPLOAD_FAILURE,
    ],
    mark: { reference_file: 1 },
    promise: (client, fileUpload) =>
      fileUpload(file, '/inflosousers/brand-product/image-upload', data),
  };
}

export function createBrandProduct(data) {
  return {
    types: [
      CREATE_BRAND_PRODUCT_REQUEST,
      CREATE_BRAND_PRODUCT_SUCCESS,
      CREATE_BRAND_PRODUCT_FAILURE,
    ],
    mark: { subscribe_plan: 1 },
    promise: (client) =>
      client(
        `/inflosousers/brand-product`,
        {
          method: 'POST',
          body: JSON.stringify(data),
        },
        true
      ),
  };
}

export function updateBrandProduct(data) {
  return {
    types: [
      UPDATE_BRAND_PRODUCT_REQUEST,
      UPDATE_BRAND_PRODUCT_SUCCESS,
      UPDATE_BRAND_PRODUCT_FAILURE,
    ],
    mark: { subscribe_plan: 1 },
    promise: (client) =>
      client(
        `/inflosousers/brand-product/${data.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
        true
      ),
  };
}

export function removeBrandProduct(data) {
  return {
    types: [
      REMOVE_BRAND_PRODUCT_REQUEST,
      REMOVE_BRAND_PRODUCT_SUCCESS,
      REMOVE_BRAND_PRODUCT_FAILURE,
    ],
    mark: { subscribe_plan: 1 },
    promise: (client) =>
      client(
        `/inflosousers/brand-product-delete/${data.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(data),
        },
        true
      ),
  };
}

export function getProducts(brand_id) {
  return {
    types: [
      GET_BRAND_PRODUCTS_REQUEST,
      GET_BRAND_PRODUCTS_SUCCESS,
      GET_BRAND_PRODUCTS_FAILURE,
    ],
    mark: { subscribe_plan: 1 },
    promise: (client) =>
      client(`/inflosousers/brand-products/${brand_id}`, {}, true),
  };
}

export function getInfluencerTokens(influencer_id) {
  return {
    types: [
      GET_INFLUENCER_TOKENS_REQUEST,
      GET_INFLUENCER_TOKENS_SUCCESS,
      GET_INFLUENCER_TOKENS_FAILURE,
    ],
    mark: { subscribe_plan: 1 },
    promise: (client) =>
      client(
        `/inflosousers/influencer/${influencer_id}/get-push-token`,
        {},
        true
      ),
  };
}

// function authenticated(action){
//   return (dispatch, getState) =>{
//     dispatch({
//       types: action.types,
//       promise: (client, user) => {
//         if(user && user.user_id) {
//           return action.promise;
//         }else{
//           return new Promise((resolve, reject)=>{reject({result:'No Access token'})})
//         }
//       }
//     });
//   }
// }
