import React from 'react';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import UserImg from '../user/UserImg';

const Notification = ({
  number,
  title,
  subtitle,
  bg,
  color,
  data,
  hide,
  onClick,
  is_read,
  hover = true,
  name,
}) => {
  // const {user, campaign} = useSelector(
  //   state => ({
  //     campaign: state.campaign,
  //     user: state.user
  //   }),
  //   shallowEqual
  // )

  // const router = useRouter();
  // const dispatch = useDispatch();
  // const show= async (data)=>{
  //   if(hide) {
  //     hide(true);
  //   }
  //   if(router.pathname === '/campaign/[id]' && data.campaign_id == router.query.id) {
  //     if(user.usertype === 'Brand') {
  //       await dispatch(actions.campaign.getAllCampaignApplicationsByCampaignId( campaign));
  //     }else if(user.usertype === 'Influencer') {
  //       await dispatch(actions.campaign.getMyCampaignApplications(campaign));
  //     }
  //   }else{
  //     router.push('/campaign/' + data.campaign_id);
  //   }
  //
  //   await dispatch(actions.common.notificationMarkRead(data.id));
  //   await dispatch(actions.common.loadNotifications());
  // }

  const daysOld = moment().diff(moment(data.created_date), 'days');
  const isUnRead = !is_read && daysOld <= 7;

  return (
    <div
      className={`flex flex-col px-2 border-b-1 border-gray-250 cursor-pointer ${
        hover && 'hover:bg-gray-300'
      } ${isUnRead ? 'bg-blue-100' : ''}`}
      onClick={onClick.bind(this, data)}
    >
      <div className="flex flex-row items-center">
        <div className="flex-shrink-0 w-8 mt-2 relative">
          <span
            // style={is_read ? { filter: 'grayscale(100%)' } : {}}
            className={`h-8 w-8 ${bg} ${color} flex items-center justify-center rounded-full overflow-hidden text-lg font-display font-bold`}
          >
            {data.influencer_name || name ? (
              <div className="rounded-25 h-8 w-8 overflow-hidden">
                <UserImg
                  className="rounded-25"
                  image={number}
                  src={number}
                  alt={data.user_id}
                  name={data.influencer_name}
                  gender={data.influencer_gender}
                  isBrand={!!data.brand}
                  isCampaign={!data.influencer_name}
                />
              </div>
            ) : (
              <FontAwesomeIcon icon={faBullhorn} />
            )}
          </span>
        </div>
        <div className="flex-1 ltr:ml-2 rtl:mr-2 py-2">
          <p className="text-sm font-bold">{title}</p>
          <p
            className="text-xs"
            dangerouslySetInnerHTML={{
              __html: subtitle
                .replace('<', '')
                .replace('>', '')
                .replace(data.campaign_name, '' + data.campaign_name + '')
                .replace(
                  data.influencer_name,
                  '<b>' + data.influencer_name + '</b>'
                ),
            }}
          />
          <div className="text-left text-xxs text-gray-700 font-medium my-1">
            {moment(data.created_date).format('D MMM yyyy, HH:mm')}
          </div>
        </div>
        {isUnRead && (
          <div className="flex-initial h-3 w-3 bg-red-500 rounded-full aabsolute"></div>
        )}
      </div>
    </div>
  );
};
//
// const DropdownWidget3 = () => {
//   const {direction, notifications} = useSelector(
//     state => ({
//       direction: state.ui.direction,
//       notifications: state.common.notifications,
//     }),
//     shallowEqual
//   )
//
//
//   const [hidden, setHidden] = useState(true)
//   const buttonRef = React.createRef()
//   const dropdownRef = React.createRef()
//   const dispatch = useDispatch();
//
//   useEffect(() => {
//     // setTimeout(()=>{
//     //     toast(notifications[0].message);
//     //   }, 3000);
//
//     const interval = setInterval(() => {
//       if (document.hasFocus()) {
//         dispatch(actions.common.loadNotifications());
//       } else {
//       }
//     }, 5000);
//
//     if (!notifications || notifications.length === 0) {
//       // dispatch(actions.common.loadNotifications());
//     }
//
//     return () => {
//       clearInterval(interval);
//     }
//   }, [])
//
//   useEffect(() => {
//     const handleClickOutside = event => {
//       if (
//         hidden ||
//         buttonRef.current.contains(event.target) ||
//         dropdownRef.current.contains(event.target)
//       ) {
//         return false
//       }
//       setHidden(!hidden)
//     }
//     document.addEventListener('mousedown', handleClickOutside)
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside)
//     }
//   }, [dropdownRef, buttonRef])
//
//   const unread = (notifications || []).filter(n => !n.is_read).length;
//
//   const markAllRead = async () => {
//     await Promise.all((notifications || []).map(async (n) => {
//       if (!n.is_read) {
//         return await dispatch(actions.common.notificationMarkRead(n.id));
//       }
//     }))
//
//     await dispatch(actions.common.loadNotifications());
//   }
//
//   const btnstyle = isMobile() ? {right: '2px', bottom: '18px'} : {right: '3px', bottom: '31px'};
//
//   return (
//     <div className="lg:flex h-14 w-12">
//       <div className="relative">
//         <button
//           ref={buttonRef}
//           className="flex items-center justify-center rounded-full  my-3 h-10 w-10 hover:bg-brand-bg hover:text-brand-light"
//           onClick={() => setHidden(!hidden)}>
//           {/*<Bell size={18} />*/}
//           <FontAwesomeIcon icon={faBell}/>
//           {(unread > 0) &&
//           <div className="h-5 w-5 rounded-full bg-red-500 animate-ping absolute" style={btnstyle}></div>}
//           {(unread > 0) && <div
//             className="h-5 w-5 rounded-full bg-red-500 border-2 text-xxs border-brand-light md:border-gray-100 absolute text-white"
//             style={btnstyle}>{unread}</div>}
//         </button>
//         <div
//           ref={dropdownRef}
//           className={`navbar-dropdown dropdown ${direction === 'ltr' ? 'dropdown-right' : 'dropdown-left'} w-72 md:w-80 ${hidden ? '' : 'open'}`}>
//           <div className="w-72 md:w-80 dropdown-content">
//             <div className="navbar-dropdown-title dropdown-title">Notifications</div>
//             <div className="text-xs cursor-pointer p-1 flex flex-row">
//               <div className="flex-1 font-medium">Unread: {unread}</div>
//               {unread > 0 &&
//               <div className="text-right hover:text-brand-light font-medium" onClick={markAllRead}>Mark All Read</div>}
//             </div>
//             <div className="overflow-auto" style={{height: '300px'}}>
//               {(notifications || []).map((n, i) => {
//                 return (
//                   <ListWidget1
//                     key={i}
//                     number={<FontAwesomeIcon icon={faBullhorn}/>}
//                     title=""
//                     subtitle={n.message
//                       .replace("<","")
//                       .replace(">","")
//                       .replace(n.campaign_name, '<b>' + n.campaign_name + '</b> on ')}
//                     bg={n.is_read ? "bg-gray-300" : "bg-indigo-500"}
//                     color="text-white"
//                     data={n}
//                   />
//                 )
//               })}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

export default Notification;
