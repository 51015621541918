import Notification from './index';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import actions from '../../services/redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export default function Notifications({ notifications, hide }) {
  const { user, campaign } = useSelector(
    (state) => ({
      campaign: state.campaign,
      user: state.user,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const router = useRouter();

  const onNotiClick = async (data) => {
    if (hide) {
      hide(true);
    }
    if (data.url) {
      if (data.url.indexOf('http') === 0) {
        window.open(data.url);
      } else {
        router.push(data.url);
      }
    } else if (
      router.pathname === '/campaign/[id]' &&
      data.campaign_id == router.query.id
    ) {
      if (user.usertype === 'Brand') {
        await dispatch(
          actions.campaign.getAllCampaignApplicationsByCampaignIdSelf(
            data.campaign_id
          )
        );
      } else if (user.usertype === 'Influencer') {
        await dispatch(
          actions.campaign.getMyCampaignApplications({ id: data.campaign_id })
        );
      }
      router.push('/campaign/' + data.campaign_id + '#show_chat');
    } else {
      router.push('/campaign/' + data.campaign_id);
      setTimeout(() => {
        router.push('/campaign/' + data.campaign_id + '#show_chat');
      }, 1000);
    }
    dispatch(actions.campaign.selectApplication(data.influencer_id));
    if (data.type === 'Chat') {
      //Do not mark unread, will be marked 5 seconds after opening
    } else {
      await dispatch(actions.common.notificationMarkRead(data.id));
      await dispatch(actions.common.loadNotifications());
    }
  };

  useEffect(() => {
    const handleNotificationClick = async (e) => {
      if (e && e.detail) {
        await onNotiClick(e.detail);
      }
    };

    document.addEventListener('notification_click', handleNotificationClick);

    return () => {
      document.removeEventListener(
        'notification_click',
        handleNotificationClick
      );
    };
  }, []);

  return (
    <>
      {(notifications || []).map((n, i) => {
        return (
          <Notification
            key={i}
            number={n.profileImage || n.brandlogo}
            title=""
            subtitle={n.message}
            bg={n.is_read ? 'bg-gray-300' : 'bg-indigo-500'}
            color="text-white"
            data={n}
            is_read={n.is_read}
            onClick={onNotiClick}
            hide={hide}
            name={n.campaign_name}
          />
        );
      })}
      {!notifications ||
        (notifications.length === 0 && (
          <div className="m-auto w-1/2">
            <img src="/images/illustration/no_notification.png" />
          </div>
        ))}
    </>
  );
}
