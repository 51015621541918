import {
  NEXT_DATA_ES_FAILURE,
  NEXT_DATA_ES_REQUEST,
  NEXT_DATA_ES_SUCCESS,
  PAGE_COUNT_ES_FAILURE,
  PAGE_COUNT_ES_REQUEST,
  PAGE_COUNT_ES_SUCCESS,
  PAGE_DATA_ES_FAILURE,
  PAGE_DATA_ES_REQUEST,
  PAGE_DATA_ES_SUCCESS,
  DOC_DATA_ES_FAILURE,
  DOC_DATA_ES_REQUEST,
  DOC_DATA_ES_SUCCESS,
} from './redux/modules/page';

export function find(index, id, raw = false, type) {
  return {
    payload: { index, id, raw, type },
    types: [DOC_DATA_ES_REQUEST, DOC_DATA_ES_SUCCESS, DOC_DATA_ES_FAILURE],
    promise: get(index, id, raw, type),
  };
}

export function action(
  index,
  query,
  fields,
  sort = { created_date: 'desc' },
  from,
  size,
  raw = false,
  count = false
) {
  return {
    payload: { index, query, fields, sort, from, size, raw },
    types: raw
      ? [PAGE_COUNT_ES_REQUEST, PAGE_COUNT_ES_SUCCESS, PAGE_COUNT_ES_FAILURE]
      : [PAGE_DATA_ES_REQUEST, PAGE_DATA_ES_SUCCESS, PAGE_DATA_ES_FAILURE],
    promise: search(index, query, fields, sort, from, size, raw, count),
  };
}

export function actionNext(
  index,
  query,
  fields,
  sort = { created_date: 'desc' },
  from,
  size,
  raw = false
) {
  return {
    payload: { index, query, fields, sort, from, size, raw },
    types: [NEXT_DATA_ES_REQUEST, NEXT_DATA_ES_SUCCESS, NEXT_DATA_ES_FAILURE],
    promise: search(index, query, fields, sort, from, size, raw),
  };
}

export function search(
  index,
  query,
  fields = ['id'],
  sort = { created_date: 'desc' },
  from = 0,
  size = 100,
  raw = false,
  count = false
) {
  let url = `/es/dev_${index}/_search?` + (query ? `q=${query}` : '');

  if (!sort) {
    sort = { created_date: 'desc' };
  }

  url += `&size=${size}`;
  url += `&from=${from}`;
  url += `&sort=${Object.keys(sort)
    .map((k) => k + ':' + sort[k])
    .join(' AND ')}`;

  if (count) {
    url = `/es/dev_${index}/_count?` + (query ? `q=${query}` : '');
  }

  // const params = {
  //   query: query,
  //   from: from,
  //   size: size,
  //   sort: sort,
  //   aggs: {},
  //   // fields: fields,
  //   // _source: false,
  // };

  // const url = `/es/${index}/_search`;
  // const params = {
  //   query: query,
  //   from: from,
  //   size: size,
  //   sort: sort,
  //   aggs: {},
  //   // fields: fields,
  //   // _source: false,
  // };

  return (client) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await client(url, {}, true);
        if (raw) {
          resolve(resp);
        } else {
          resolve(resp.hits.hits.map((s) => ({ id: s._id, ...s._source })));
        }
      } catch (e) {
        reject();
      }
    });
  };
}

export function get(index, id, raw = false, type) {
  let url = `/es/dev_${index}/_doc/${id}`;

  return (client) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await client(url, {}, true);
        if (raw) {
          resolve(resp);
        } else {
          // resp._source.influencer_profile.social_profiles[0].followers = 999;
          resolve(resp._source);
        }
      } catch (e) {
        reject();
      }
    });
  };
}

export function queryFromFilter(filter) {
  // case 'text':
  // case 'social_profiles__social_media_platform':
  // case 'influencergender':
  // case 'influencer__location':
  // case 'category__category_name':
  return {
    query: {
      bool: {
        must: [
          {
            match: {
              application_status: 'Approved',
            },
          },
        ],
        must_not: [],
        should: [],
      },
    },
    from: 0,
    size: 10,
    sort: [],
    aggs: {},
  };
}
