import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
// import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import actions from '../../../services/redux/actions';
import { useRouter } from 'next/router';
import { isMobile } from '../../../services/util';
import moment from 'moment';
import Notifications from '../../notifications/Notifications';
import FluentIcon from '../../fluenticons/FluentIcon';
import { fiBell } from '../../fluenticons';
import useLocalStorage from '../../../hooks/useLocalStorage';

const ListWidget1 = ({ number, title, subtitle, bg, color, data }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const show = async (data) => {
    //toast("Wow so easy!", title);
    await dispatch(actions.common.notificationMarkRead(data.id));
    await dispatch(actions.common.loadNotifications());
    router.push('/campaign/' + data.campaign_id);
  };

  return (
    <div
      className={`flex flex-col px-2 cursor-pointer hover:bg-gray-300`}
      onClick={show.bind(this, data)}
    >
      <div className="flex flex-row">
        <div className="flex-shrink-0 w-8 mt-3">
          <span
            className={`h-8 w-8 ${bg} ${color} flex items-center justify-center rounded-full text-lg font-display font-bold`}
          >
            {number}
          </span>
        </div>
        <div className="ltr:ml-2 rtl:mr-2 py-2">
          <p className="text-sm font-bold">{title}</p>
          <p
            className="text-xs"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </div>
      </div>
      <div className="text-left text-xxs text-gray-700 ml-10 font-medium -mt-1">
        {moment(data.created_date).format('Do MMM yyyy HH:mm')}
      </div>
    </div>
  );
};

const DropdownWidget3 = () => {
  const { direction, notifications } = useSelector(
    (state) => ({
      direction: state.ui.direction,
      notifications: state.common.notifications,
    }),
    shallowEqual
  );

  const [hidden, setHidden] = useState(true);
  const buttonRef = React.createRef();
  const dropdownRef = React.createRef();
  const dispatch = useDispatch();

  useEffect(() => {
    // setTimeout(()=>{
    //     toast(notifications[0].message);
    //   }, 3000);

    const interval = setInterval(() => {
      if (document.hasFocus()) {
        try {
          dispatch(actions.common.loadNotifications());
        } catch (e) {}
      } else {
      }
    }, 5000);

    dispatch(actions.common.loadNotifications());

    // function displayData(d) {
    //   console.log('d', d);
    // }
    //
    // function displayError(d) {
    //   console.log('e', d);
    // }
    //
    // let notif = {};
    // let lastNotifId = '';
    // try {
    //   notif = JSON.stringify(localStorage.getItem('notif'));
    //   if (notif.id) {
    //     lastNotifId = '&lastId=' + notif.id;
    //   }
    // } catch (e) {}
    //
    // const tokens = loadTokens();
    // const source = new EventSource(
    //   `/api/notifications?a=${tokens.access}${lastNotifId}`
    // );
    // source.addEventListener('data', displayData, false);
    //
    // //listen for error
    // source.addEventListener('error', displayError, false);
    //
    // setTimeout(() => {
    //   console.log('closing');
    //   source.close();
    // }, 5000);
    //
    // if (!notifications || notifications.length === 0) {
    //   // dispatch(actions.common.loadNotifications());
    // }

    return () => {
      // source.close();
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        buttonRef.current.contains(event.target) ||
        dropdownRef.current.contains(event.target)
      ) {
        return false;
      }
      setHidden(!hidden);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, buttonRef]);

  const [readTill, setRedTill] = useLocalStorage('notiReadTill');

  const unread =
    notifications &&
    notifications.length > 0 &&
    (!readTill || readTill < notifications[0].id); //(notifications || []).filter((n) => !n.is_read).length > 0;

  const markAllRead = async () => {
    await Promise.all(
      (notifications || []).map(async (n) => {
        if (!n.is_read) {
          return await dispatch(actions.common.notificationMarkRead(n.id));
        }
      })
    );

    try {
      await dispatch(actions.common.loadNotifications());
    } catch (e) {}
  };

  const btnstyle = isMobile()
    ? { right: '13px', bottom: '21px', width: '14px', height: '14px' }
    : { right: '6px', bottom: '33px', width: '14px', height: '14px' };

  return (
    <div className="lg:flex h-14 w-12">
      <div className="relative">
        <button
          ref={buttonRef}
          className="flex items-center justify-center rounded-full  my-3 h-10 w-10 hover:bg-brand-bg hover:text-brand-light"
          onClick={() => {
            if (notifications && notifications.length > 0) {
              setRedTill(notifications[0].id);
            }
            setHidden(!hidden);
          }}
        >
          {/*<Bell size={18} />*/}
          <FluentIcon icon={fiBell} />
          {/*{unread > 0 && (*/}
          {/*  <div*/}
          {/*    className="h-5 w-5 rounded-full bg-red-500 animate-ping absolute"*/}
          {/*    style={btnstyle}*/}
          {/*  ></div>*/}
          {/*)}*/}
          {unread && (
            <div
              className="rounded-full bg-red-500 text-xxs absolute text-white"
              style={btnstyle}
            ></div>
          )}
        </button>
        <div
          ref={dropdownRef}
          className={`navbar-dropdown dropdown ${
            direction === 'ltr' ? 'dropdown-right' : 'dropdown-left'
          } w-72 md:w-80 ${hidden ? '' : 'open'}`}
        >
          <div className="w-72 md:w-80 dropdown-contentx bg-white customShadow">
            <div className="navbar-dropdown-titlez dropdown-titlez text-3xl font-black text-brand-base pl-4 pt-2">
              Notifications
            </div>
            {/*<div className="text-xs cursor-pointer p-1 flex flex-row">*/}
            {/*  <div className="flex-1 font-medium">Unread: {unread}</div>*/}
            {/*  {unread > 0 && (*/}
            {/*    <div*/}
            {/*      className="text-right hover:text-brand-light font-medium"*/}
            {/*      onClick={markAllRead}*/}
            {/*    >*/}
            {/*      Mark All Read*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}
            <div className="overflow-auto" style={{ height: '300px' }}>
              <Notifications notifications={notifications} hide={setHidden} />
              {/*{(notifications || []).map((n, i) => {*/}
              {/*  return (*/}
              {/*    <Notification*/}
              {/*      key={i}*/}
              {/*      number={n.profileImage || n.brandlogo ? <img className="rounded-full" src={n.profileImage||n.brandlogo}/> : <FontAwesomeIcon icon={faBullhorn}/>}*/}
              {/*      title=""*/}
              {/*      subtitle={n.message}*/}
              {/*      bg={n.is_read ? "bg-gray-300" : "bg-indigo-500"}*/}
              {/*      color="text-white"*/}
              {/*      data={n}*/}
              {/*      hide={setHidden}*/}
              {/*    />*/}
              {/*  )*/}
              {/*})}*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropdownWidget3;
