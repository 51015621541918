import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import Avatar from './../Avatar';

// import Avatar from 'boring-avatars';
import { cdnDomain, colors, randomIntFromInterval } from '../../services/util';

export default function UserImg(props) {
  const {
    className,
    defaultImg,
    email,
    image,
    name = 'A',
    alt,
    isBrand,
    isCampaign,
    isAgency,
    gender = 'Other',
  } = props;

  // const {user} = useSelector(
  //   state => ({
  //     user: state.user
  //   }),
  //   shallowEqual
  // )

  // const {} = user;

  const finalImage = getFinalImage(image, defaultImg);

  useEffect(() => {
    const finalImage = getFinalImage(image, defaultImg);
    setImg(finalImage);
  }, [image]);

  const [img, setImg] = useState(finalImage);

  const onError = () => {
    setImg('/icons/color/user.svg');
  };

  const charNum = name.toLowerCase().charCodeAt(0) - 97;
  let gImg = '/images/no_gender.png';
  if (gender === 'Male') {
    gImg = '/images/male.png';
  } else if (gender === 'Female') {
    gImg = '/images/female.png';
  }

  if (finalImage && finalImage[0] === '#') {
    return (
      <div className="w-20 h-20" style={{ backgroundColor: finalImage }}>
        {alt}
      </div>
    );
  } else {
    return (
      <>
        {img === '/icons/color/user.svg' ? (
          isCampaign ? (
            <Avatar
              size="100%"
              name={alt ? (alt.trim ? alt.trim() : alt) + '' : 'Infloso'}
              variant={
                isBrand
                  ? 'bauhaus'
                  : isCampaign
                  ? 'marble'
                  : isAgency
                  ? 'ring'
                  : 'beam'
              }
              square={isCampaign || isBrand}
              colors={['#556270', '#4ECDC4', '#C7F464', '#FF6B6B', '#C44D58']}
              // colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
            />
          ) : isBrand || isAgency ? (
            <div
              className="text-white w-full h-full text-4xl font-bold flex flex-col text-center justify-center"
              style={{
                backgroundColor: colors()[charNum],
              }}
            >
              {name && name.length > 0 ? name[0].toUpperCase() : '-'}
            </div>
          ) : (
            <div
              style={{
                backgroundColor: colors()[charNum],
              }}
            >
              <img src={gImg} />
            </div>
          )
        ) : (
          <LazyLoad once placeholder={<PlaceHolder className={className} />}>
            <img
              src={img}
              data-img={image}
              alt="image"
              onError={onError}
              className={className}
            />
          </LazyLoad>
        )}
      </>
    );
  }
}

export function getFinalImage(image, defaultImg) {
  let finalImage = image;
  const dImg = defaultImg; // ||
  // 'https://infloso.vercel.app/icons/color/user.svg';
  // 'http://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Image.png';
  // 'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png';
  // 'https://ui-avatars.com/api/?name=JohnDoes';


  finalImage =
    (finalImage && finalImage.indexOf && finalImage.indexOf('http') === 0) ||
    finalImage === '/icons/color/user.svg'
      ? finalImage
      : finalImage
      ? cdnDomain(finalImage)
      : '/icons/color/user.svg';

  if (image && image.indexOf && image.indexOf('amazon') > -1) {
    // finalImage = `https://www.gravatar.com/avatar/${md5(email)}?d=${encodeURIComponent(dImg)}`;
    //https://infloso-dev.s3.ap-south-1.amazonaws.com/
    //https://infloso-pre-prod-storage.s3.ap-south-1.amazonaws.com/   -- this is prod
    //infloso-pre-prod-storage.s3.amazonaws.com

    finalImage = finalImage.replace(
      'https://infloso-pre-prod-storage.s3.amazonaws.com/',
      'https://cdnc.infloso.com/'
    );
    finalImage = finalImage.replace(
      'https://infloso-pre-prod-storage.s3.ap-south-1.amazonaws.com/',
      'https://cdnc.infloso.com/'
    );

    finalImage = finalImage.replace(
      'https://infloso-dev.s3.ap-south-1.amazonaws.com/',
      'https://dev-cdn.infloso.in/cdn/'
    );
    finalImage = finalImage.replace(
      'https://infloso-dev.s3.amazonaws.com/',
      'https://dev-cdn.infloso.in/cdn/'
    );

    finalImage = finalImage.split('?')[0];

    // finalImage = "#F1705F";
  } else if (image && image.indexOf && image.indexOf('.') > -1) {
    // finalImage = image;
    // }else if(email && email.indexOf('email1623') === -1){
    //   finalImage = `https://www.gravatar.com/avatar/${md5(email)}?d=${dImg ?  encodeURIComponent(dImg):'404'}`;
    // } else if (!image || image.length === 0) {
    //   finalImage = "#F1705F";
  } else {
    // finalImage = '/icons/color/user.svg';
  }
  return finalImage;
}

function PlaceHolder({ className }) {
  return <img src="/icons/color/user.svg" alt="image" className={className} />;
}
