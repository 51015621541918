import { isMobile, ls } from '../../util';
import { toast } from 'react-toastify';
import Notification from '../../../components/notifications';
import React from 'react';
import { LOGOUT_SUCCESS } from './user';

export const LOAD_CATEGORIES_REQUEST = 'campaign/LOAD_CATEGORIES_REQUEST';
export const LOAD_CATEGORIES_SUCCESS = 'campaign/LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_FAILURE = 'campaign/LOAD_CATEGORIES_FAILURE';

export const LOAD_INDUSTRIES_REQUEST = 'campaign/LOAD_INDUSTRIES_REQUEST';
export const LOAD_INDUSTRIES_SUCCESS = 'campaign/LOAD_INDUSTRIES_SUCCESS';
export const LOAD_INDUSTRIES_FAILURE = 'campaign/LOAD_INDUSTRIES_FAILURE';

export const LOAD_NOTIFICATIONS_REQUEST = 'campaign/LOAD_NOTIFICATIONS_REQUEST';
export const LOAD_NOTIFICATIONS_SUCCESS = 'campaign/LOAD_NOTIFICATIONS_SUCCESS';
export const LOAD_NOTIFICATIONS_FAILURE = 'campaign/LOAD_NOTIFICATIONS_FAILURE';

export const MARK_READ_NOTIFICATION_REQUEST =
  'campaign/MARK_READ_NOTIFICATION_REQUEST';
export const MARK_READ_NOTIFICATION_SUCCESS =
  'campaign/MARK_READ_NOTIFICATION_SUCCESS';
export const MARK_READ_NOTIFICATION_FAILURE =
  'campaign/MARK_READ_NOTIFICATION_FAILURE';

export const MATCH_LOCATION_REQUEST = 'campaign/MATCH_LOCATION_REQUEST';
export const MATCH_LOCATION_SUCCESS = 'campaign/MATCH_LOCATION_SUCCESS';
export const MATCH_LOCATION_FAILURE = 'campaign/MATCH_LOCATION_FAILURE';

export const CREATE_TICKET_REQUEST = 'campaign/CREATE_TICKET_REQUEST';
export const CREATE_TICKET_SUCCESS = 'campaign/CREATE_TICKET_SUCCESS';
export const CREATE_TICKET_FAILURE = 'campaign/CREATE_TICKET_FAILURE';

export const CREATE_TICKET_CONVERSATION_REQUEST =
  'campaign/CREATE_TICKET_CONVERSATION_REQUEST';
export const CREATE_TICKET_CONVERSATION_SUCCESS =
  'campaign/CREATE_TICKET_CONVERSATION_SUCCESS';
export const CREATE_TICKET_CONVERSATION_FAILURE =
  'campaign/CREATE_TICKET_CONVERSATION_FAILURE';

export const LOAD_TICKET_REQUEST = 'campaign/LOAD_TICKET_REQUEST';
export const LOAD_TICKET_SUCCESS = 'campaign/LOAD_TICKET_SUCCESS';
export const LOAD_TICKET_FAILURE = 'campaign/LOAD_TICKET_FAILURE';

export const UPDATES_REQUEST = 'common/UPDATES_REQUEST';
export const UPDATES_SUCCESS = 'common/UPDATES_SUCCESS';
export const UPDATES_FAILURE = 'common/UPDATES_FAILURE';
export const SET_UPDATES = 'common/SET_UPDATES';

export const GET_TICKET_REQUEST = 'campaign/GET_TICKET_REQUEST';
export const GET_TICKET_SUCCESS = 'campaign/GET_TICKET_SUCCESS';
export const GET_TICKET_FAILURE = 'campaign/GET_TICKET_FAILURE';

const initialState = {
  categories: [],
  categories_ids: {},
  categories_names: {},
  industries: [],
  industries_ids: {},
  industries_names: {},
  notifications: [],
  tickets: [],
  selectedTicket: {},
  updates: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    case LOAD_TICKET_SUCCESS: {
      const tickets =
        action.result && action.result.error_code === '404'
          ? []
          : action.result;
      return {
        ...state,
        tickets,
        selectedTicket: tickets && tickets.length > 0 ? tickets[0] : null,
      };
    }
    case UPDATES_SUCCESS: {
      const updates =
        action.result && action.result.error_code === '404'
          ? []
          : action.result;
      return {
        ...state,
        updates,
      };
    }
    case SET_UPDATES: {
      const updates = { ...state.updates };
      updates[action.payload] = 0;
      return {
        ...state,
        updates,
      };
    }

    case GET_TICKET_SUCCESS: {
      const ticket = action.result;
      const tickets =
        state.tickets && state.tickets.length > 0 ? [...state.tickets] : [];

      const foundTicket = tickets.filter((t) => t.id === ticket.id);
      if (foundTicket && foundTicket.length > 0) {
        const index = tickets.indexOf(foundTicket[0]);
        tickets[index] = ticket;
      } else {
        tickets.push(ticket);
      }
      const selectedTicket = ticket;

      return { ...state, tickets, selectedTicket };
    }

    case LOAD_CATEGORIES_SUCCESS: {
      const categories = processCategories(action.result);

      // ls.setItem('categories', JSON.stringify(action.result));

      return { ...state, ...categories };
    }

    case LOAD_INDUSTRIES_SUCCESS: {
      const industries = processIndustries(action.result);

      // ls.setItem('industries', JSON.stringify(action.result));
      return { ...state, ...industries };
    }
    case LOAD_NOTIFICATIONS_SUCCESS: {
      const { user_id } = action.payload;

      let notifications = [];

      if (action.result && action.result.length > 0) {
        notifications = action.result;
      }
      if (
        action.result &&
        action.result.results &&
        action.result.results.length > 0
      ) {
        notifications = action.result.results;
      }

      //Added so that if you logout with one id and then login with another and the notifications
      // dont get mixed up
      notifications = notifications.filter((n) => n.user === user_id);

      const lastNotification =
        notifications && notifications.length > 0 ? notifications[0] : {};

      //Show notif if any new
      if (notifications && notifications.length > 0) {
        let lastSavedNotif = {};
        try {
          lastSavedNotif = JSON.parse(ls.getItem('notif'));
        } catch (e) {}

        if (lastSavedNotif == null || !lastSavedNotif.id) {
          lastSavedNotif = {
            id:
              notifications && notifications.length > 1
                ? notifications[1].id
                : 0,
          };
        }

        const toBeShown = notifications
          .filter((n) => n.id > lastSavedNotif.id)
          .filter((n) => !n.is_read);
        toBeShown.map((n) => {
          if (!isMobile() && n) {
            toast(
              <Notification
                number={n.profileImage || n.brandlogo}
                title=""
                hover={false}
                subtitle={n.message}
                bg={n.is_read ? 'bg-gray-300' : 'bg-indigo-500'}
                color="text-white"
                data={n}
                is_read={true}
                onClick={() => {
                  const event = new CustomEvent('notification_click', {
                    detail: n,
                  });
                  document.dispatchEvent(event);
                }}
              />,
              {
                onClick: () => {
                  const event = new CustomEvent('notification_click', {
                    detail: n,
                  });
                  document.dispatchEvent(event);
                },
              }
            );
          }
        });

        // toast(notifications[0].message, {onClick:()=>{
        //   const event = new CustomEvent("notification_click", {
        //     detail: notifications[0]
        //   });
        //   document.dispatchEvent(event);
        // }});

        ls.setItem('notif', JSON.stringify(lastNotification));
        return { ...state, notifications, lastNotification };
      }
      return { ...state, notifications: [] };
    }
    default: {
      return state;
    }
  }
}

function processCategories(categories) {
  const cat = {
    categories: [],
    categories_ids: {},
    categories_names: {},
    cat_len: categories.length,
  };
  if (categories) {
    categories.map((c) => {
      if (c.is_active) {
        const item = { name: c.category_name, id: c.id };
        cat.categories_ids[c.id] = item;
        cat.categories_names[c.category_name] = item;

        if (c.parent === null) {
          cat.categories.push(item);
        } else {
          const parent = cat.categories_ids[c.parent.id];
          if (parent) {
            if (!parent.children) {
              parent.children = [];
            }
            parent.children.push(item);
          } else {
          }
        }
      }
    });
  }

  cat.categories = cat.categories.sort((first, second) => {
    if (first.name[0] < second.name[0]) {
      return -1;
    }
    if (first.name[0] > second.name[0]) {
      return 1;
    }
    return 0;
  });

  return cat;
}

function processIndustries(industries) {
  const ind = {
    industries: [],
    industries_ids: {},
    industries_names: {},
    industries_len: industries.length,
  };
  if (industries) {
    industries.map((c) => {
      if (c.is_active) {
        const item = { name: c.industry_name, id: c.id };
        ind.industries_ids[c.id] = item;
        ind.industries_names[c.industry_name] = item;

        if (c.parent === null) {
          ind.industries.push(item);
        } else {
          const parent = ind.industries_ids[c.parent.id];
          if (parent) {
            if (!parent.children) {
              parent.children = [];
            }
            parent.children.push(item);
          } else {
          }
        }
      }
    });
  }
  return ind;
}

export function loadCategories() {
  let categories = null;
  // try {
  //   categories = JSON.parse(ls.getItem('categories'));
  // }catch (e){
  //
  // }
  return {
    payload: {},
    mark: {},
    types: [
      LOAD_CATEGORIES_REQUEST,
      LOAD_CATEGORIES_SUCCESS,
      LOAD_CATEGORIES_FAILURE,
    ],
    promise: (client) =>
      categories
        ? new Promise((resolve) => {
            resolve(categories);
          })
        : client('/inflosocommons/categories', {}, true),
  };
}

export function loadIndustries() {
  let industries = null;
  // try {
  //   industries = JSON.parse(ls.getItem('industries'));
  // }catch (e){
  //
  // }

  return {
    payload: {},
    mark: {},
    types: [
      LOAD_INDUSTRIES_REQUEST,
      LOAD_INDUSTRIES_SUCCESS,
      LOAD_INDUSTRIES_FAILURE,
    ],
    promise: (client) =>
      industries
        ? new Promise((resolve) => {
            resolve(industries);
          })
        : client('/inflosocommons/industries', {}, true),
  };
}

export function loadNotifications() {
  // return {type:"ABC"};
  return async (dispatch, getState) => {
    const user = getState().user;
    return dispatch({
      payload: { user_id: user.id },
      mark: {},
      types: [
        LOAD_NOTIFICATIONS_REQUEST,
        LOAD_NOTIFICATIONS_SUCCESS,
        LOAD_NOTIFICATIONS_FAILURE,
      ],
      promise: (client) =>
        client('/inflosocommons/notification?limit=50', {}, true),
    });
  };
}

export function notificationMarkRead(id) {
  const payload = {
    // "id":"{{sms_template_id}}",
    // "name": "Deleting SMS",
    // "text": "Dear Customer {{customer_name}}, Thank you for your purchase.",
    // "params": ["{{customer_name}}"],
    // "status": 1,
    // "is_predefined":true
  };

  return {
    payload: {},
    mark: {},
    types: [
      MARK_READ_NOTIFICATION_REQUEST,
      MARK_READ_NOTIFICATION_SUCCESS,
      MARK_READ_NOTIFICATION_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocommons/update-notification-read/${id}`,
        { method: 'PUT' },
        true
      ),
  };
}

export function locationMatch(influencer_locations, brand_locations) {
  return {
    payload: {},
    mark: {},
    types: [
      MATCH_LOCATION_REQUEST,
      MATCH_LOCATION_SUCCESS,
      MATCH_LOCATION_FAILURE,
    ],
    promise: (client) =>
      client(
        `/api/locationMatch?i=${influencer_locations}&b=${brand_locations}`,
        {},
        true
      ),
  };
}

export function createTicket(
  title,
  summary,
  user,
  campaign_id,
  application_id
) {
  const payload = {
    user: user,
    ticket_info: title,
    ticket_desc: summary,
    campaign: campaign_id,
    campaign_application: application_id,
  };

  return {
    payload: payload,
    mark: {},
    types: [
      CREATE_TICKET_REQUEST,
      CREATE_TICKET_SUCCESS,
      CREATE_TICKET_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocoreutils/cs-ticket`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function createTicketConversation(comment, ticket_id) {
  const payload = {
    ticket: ticket_id,
    message: comment,
  };

  return {
    payload: payload,
    mark: {},
    types: [
      CREATE_TICKET_CONVERSATION_REQUEST,
      CREATE_TICKET_CONVERSATION_SUCCESS,
      CREATE_TICKET_CONVERSATION_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosocoreutils/ticket-conversation`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function loadTickets() {
  const payload = {};

  return {
    payload: payload,
    mark: {},
    types: [LOAD_TICKET_REQUEST, LOAD_TICKET_SUCCESS, LOAD_TICKET_FAILURE],
    promise: (client) => client(`/inflosocoreutils/cs-tickets/`, {}, true),
  };
}

export function loadTicketsForUser(user_id) {
  const payload = {};

  return {
    payload: payload,
    mark: {},
    types: [LOAD_TICKET_REQUEST, LOAD_TICKET_SUCCESS, LOAD_TICKET_FAILURE],
    promise: (client) =>
      client(`/inflosocoreutils/cs-tickets/?user_id=${user_id}`, {}, true),
  };
}

export function getTicket(id) {
  const payload = {};

  return {
    payload: payload,
    mark: {},
    types: [GET_TICKET_REQUEST, GET_TICKET_SUCCESS, GET_TICKET_FAILURE],
    promise: (client) => client(`/inflosocoreutils/cs-tickets/${id}`, {}, true),
  };
}

export function getUpdates(filter) {
  const payload = filter;

  return {
    payload: payload,
    mark: {},
    types: [UPDATES_REQUEST, UPDATES_SUCCESS, UPDATES_FAILURE],
    promise: (client) =>
      client(
        `/inflosocoreutils/updates`,
        {
          method: 'POST',
          body: JSON.stringify(payload),
        },
        true
      ),
  };
}

export function setUpdates(key) {
  return {
    payload: key,
    type: SET_UPDATES,
  };
}
