import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ColorIcon from '../icon/ColorIcon';

const WidgetTitle = ({ icon, cicon, title, description }) => (
  <div className="flex flex-col widget-title w-full">
    <div className="title text-base font-base font-bold font-poppins flex">
      <div className="flex-initial w-6">
        {cicon && <ColorIcon icon={cicon} />}
        {icon ? (
          <FontAwesomeIcon icon={icon} size="sm" className="mr-1 mb-px pb-px" />
        ) : (
          <></>
        )}
      </div>
      <div className="flex-1 mb-3">{title}</div>
    </div>
    <div className="description text-sm">{description}</div>
  </div>
);

export default WidgetTitle;
