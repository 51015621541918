import jwt from 'jsonwebtoken';
import { ls } from './util';

export function decodeJWT(token, checkExpiry) {
  const decodedJWT = jwt.decode(token, { complete: true });
  if (decodedJWT && checkExpiry) {
    if (isExpired(decodedJWT.payload.exp * 1000)) {
      return null;
    } else {
      return decodedJWT;
    }
  }
  return decodedJWT;
}

export function decodeUser(token, checkExpiry) {
  if (token) {
    const authUser = decodeJWT(token, checkExpiry);
    if (authUser && authUser.payload) {
      return {
        admin: authUser.payload.usertype === 'Admin',
        usertype: authUser.payload.usertype,
        // email: authUser.payload.email,
        // id: authUser.payload.user_id,
        user_id: authUser.payload.user_id,
        username: authUser.payload.username,
        exp: authUser.payload.exp * 1000,
        type: authUser.payload.usertype
          ? authUser.payload.usertype.toLowerCase()
          : '',
      };
    }
  }
  return null;
}

export function loginUser(mUser, usertype, admin) {
  // if(mUser && !mUser.usertype){
  //   if(mUser.influencer_profile){
  //     mUser.usertype = 'Influencer';
  //   }else if(mUser.brand_profile){
  //     mUser.usertype = 'Brand';
  //   }
  // }

  if (mUser && mUser.usertype) {
    return socialUser(mUser, usertype, admin);
  } else if (mUser && mUser.user) {
    let madeUser = {};
    if (mUser && !mUser.usertype) {
      if (mUser.influencer_profile || mUser.influencer) {
        madeUser.usertype = 'Influencer';
        // madeUser[madeUser.usertype.toLowerCase()]
      } else if (mUser.brand_profile || mUser.brand) {
        madeUser.usertype = 'Brand';
      } else if (mUser.agency_profile || mUser.agency) {
        madeUser.usertype = 'Agency';
      } else if (mUser.user.usertype) {
        madeUser.usertype = mUser.user.usertype;
      } else {
        const tokens = loadTokens();
        const tokenUser = decodeUser(tokens.access, true);
        madeUser.usertype = tokenUser.usertype;
      }

      if (!madeUser.usertype && usertype) {
        madeUser.usertype = usertype;
      }

      const lower_type = madeUser.usertype.toLowerCase();
      madeUser[lower_type] = mUser;
      madeUser = { ...madeUser, ...mUser.user };
    }
    return loginUser(madeUser, madeUser.usertype, admin);
    // } else if (mUser && mUser.user) {
    //   return emailUser( mUser, admin);
  } else if (mUser && mUser.first_name) {
    mUser.name = mUser.first_name; //+ ' ' + mUser.last_name;
    mUser.usertype = mUser.usertype || usertype;
    mUser.type = (mUser.usertype || '').toLowerCase();
    return mUser;
  }
  if (admin) {
    mUser.admin = admin;
  }
  return mUser;
}

/*
{
    "id": 4,
    "username": "aarthy7",
    "email": "aarthy1012@gmail.com",
    "first_name": "Aarthy",
    "last_name": "Ramachandran"
}

{
    "id": 5,
    "username": "delsanic@gmail.com",
    "email": "delsanic@gmail.com",
    "first_name": "Del Sanic",
    "last_name": "",
    "usertype": "Influencer",
    "influencer": {
        "id": 6,
        "alias": "",
        "tagLine": "",
        "bio": "",
        "location": "",
        "birth_date": "1970-01-01",
        "profileImage": "",
        "mobile": "",
        "gender": "",
        "status": "('New', 'New')",
        "user": {
            "id": 5,
            "username": "delsanic@gmail.com",
            "email": "delsanic@gmail.com",
            "first_name": "Del Sanic",
            "last_name": ""
        },
        "role": 1,
        "influencer_profile": {
            "id": 3,
            "registered_address": "Abc",
            "city": "Delhi",
            "zip": "111111",
            "influencer": 6,
            "keywords": [
                {
                    "id": 1,
                    "keyword_name": "keyword_name_002",
                    "keyword_desc": "keyword_name_002_new_desc",
                    "is_active": true
                }
            ],
            "social_profiles": [
                {
                    "id": 2,
                    "social_media_platform": "Facebook",
                    "followers": 100000,
                    "following": 2,
                    "profile_name": "delsanic",
                    "profile_url": "https://www.facebook.com/delsanic"
                }
            ],
            "category": [
                {
                    "id": 1,
                    "category_name": "category_name_001",
                    "category_desc": "category_name_001_desc_updated",
                    "is_active": true
                }
            ]
        }
    }
}
 */

export function socialUser(mUser, user_type, admin) {
  const usertype = mUser.usertype || user_type;
  const type_lower =
    usertype === 'AgencyBrand' ? 'agency_brand' : usertype.toLowerCase();
  const typeUser = mUser[type_lower];
  let profileUser = {};

  const user = {
    loadTime: new Date().getTime(),
    id: mUser.id,
    usertype: mUser.usertype || user_type,
    admin: admin,
    user_id: mUser.id,
    type: type_lower,
    type_display: usertype,
    name: makeName(mUser.first_name, mUser.last_name),
    first_name: mUser.first_name,
    last_name: mUser.last_name,
    email: mUser.email,
    username: mUser.username,
  };

  let profileU = {};
  let typeU = {};
  let userI = {};

  if (typeUser) {
    typeU = {
      [type_lower + '_id']: typeUser.id,
      type_id: typeUser.id,
      account_status: typeUser.account_status,
      alias: typeUser.alias,
      status: typeUser.status,
      role: typeUser.role,
      tagLine: '', //typeUser.tagLine,
      bio: typeUser.bio,
      mobile: typeUser.mobile,
      location: typeUser.location,
      birth_date: typeUser.birth_date,
      img: typeUser.profileImage || typeUser.brandlogo || typeUser.agencylogo,
      image: typeUser.profileImage || typeUser.brandlogo || typeUser.agencylogo,
      cover: typeUser.coverImage,
      gender: typeUser.gender,
      email_verified: typeUser.email_verified,
      mobile_verified: typeUser.mobile_verified,
      verified: typeUser.verified,
      feedback: typeUser.feedback,
      created_date: typeUser.created_date,

      pan_verified: typeUser.pan_verified,

      completed_campaigns:
        typeUser.completed_campaigns || mUser.completed_campaigns,
      active_campaigns: typeUser.active_campaigns || mUser.active_campaigns,
      influencers_count: typeUser.influencers_count || mUser.influencers_count,
      premium_on_actions:
        typeUser.premium_on_actions || mUser.premium_on_actions,
      premium_on_buy_product:
        typeUser.premium_on_buy_product || mUser.premium_on_buy_product,
      premium_on_digital_product:
        typeUser.premium_on_digital_product || mUser.premium_on_digital_product,
      premium_on_send_product:
        typeUser.premium_on_send_product || mUser.premium_on_send_product,
      premium_on_support:
        typeUser.premium_on_support || mUser.premium_on_support,
      premium_on_visits: typeUser.premium_on_visits || mUser.premium_on_visits,
      paid_support_percentage:
        typeUser.paid_support_percentage || mUser.paid_support_percentage,
      coupon: typeUser.coupon || mUser.coupon,
      active_promotions: typeUser.active_promotions,
      successful_promotions: typeUser.successful_promotions,
    };

    profileUser = mUser[type_lower][type_lower + '_profile'];
  }
  if (mUser.user) {
    userI = {
      name: mUser.user.first_name, // + ' ' + mUser.last_name,
      first_name: mUser.user.first_name,
      last_name: mUser.user.last_name,
      email: mUser.user.email,
      username: mUser.user.username,
    };
  }

  if (mUser.agency) {
    userI.domain = mUser.agency.domain;
    userI.subdomain = mUser.agency.subdomain;
    userI.has_domain = mUser.agency.has_domain;
  }

  if (mUser.brand_manager && mUser.brand_manager.agency) {
    userI.agency = mUser.brand_manager.agency;
    userI.type_id = mUser.brand_manager.id;
  }

  profileU = parseProfile(profileUser, type_lower);

  return { ...user, ...typeU, ...userI, ...profileU };
}

function makeName(fName, lName) {
  let name = fName;

  if (
    fName &&
    fName.length > 0 &&
    lName &&
    lName.length > 0 &&
    fName.toLowerCase().indexOf(lName.toLowerCase()) === -1
  ) {
    name = fName + ' ' + lName;
  }

  return name;
}

export function parseProfile(profileUser, type_lower) {
  let profileU = {};
  if (profileUser) {
    profileU = {
      [type_lower + '_profile_id']: profileUser.id,
      profile_id: profileUser.id,
      followers_count: profileUser.followers_count,
      address: {
        //   registered_address: profileUser.registered_address,
        //   city: profileUser.city,
        //   state: profileUser.state,
        //   zip: profileUser.zip,
      },
      registered_address: profileUser.registered_address,
      city: profileUser.city,
      state: profileUser.state,
      zip: profileUser.zip,
      keywords: profileUser.keywords,
      social_profiles: profileUser.social_profiles,
      category: profileUser.category,
      brand_category: profileUser.brand_category,
    };

    if (type_lower === 'brand') {
      profileU.business_name = profileUser.business_name;
      profileU.gst_number = profileUser.gst_number;
      profileU.legal_name = profileUser.legal_name;
      profileU.pan_or_tan = profileUser.pan_or_tan;
      profileU.pan_or_tan_image = profileUser.pan_or_tan_image;
      profileU.tax_certificate = profileUser.tax_certificate;
      profileU.industries = profileUser.industries;
    }

    if (type_lower === 'agency') {
      profileU.business_name = profileUser.business_name;
      profileU.gst_number = profileUser.gst_number;
      profileU.legal_name = profileUser.legal_name;
      profileU.pan_or_tan = profileUser.pan_or_tan;
      profileU.pan_or_tan_image = profileUser.pan_or_tan_image;
      profileU.tax_certificate = profileUser.tax_certificate;
      profileU.industries = profileUser.industries;
    }
  } else {
    profileU = {
      address: {},
    };
  }

  return profileU;
}

/*
{
  "id": 3,
  "registered_address": "Abcdefghi",
  "city": "Kolkata",
  "zip": "123456",
  "influencer": 6,
  "keywords": [{
    "id": 1,
    "keyword_name": "keyword_name_002",
    "keyword_desc": "keyword_name_002_desc",
    "is_active": true
  }],
  "social_profiles": [{
    "id": 2,
    "social_media_platform": "Facebook",
    "followers": 100000,
    "following": 2,
    "profile_name": "delsanic",
    "profile_url": "https://www.facebook.com/delsanic"
  }],
  "category": [{
    "id": 1,
    "category_name": "category_name_001",
    "category_desc": "category_name_001_desc_updated",
    "is_active": true
  }]
}
 */
// export function profileUser(mUser, type) {
//
//   // const type_lower = mUser.usertype.toLowerCase()
//   // const typeUser =  mUser[type_lower];
//   const profileUser = mUser;
//
//   const user = {
//
//
//     // [type_lower + 'profile_id']: profileUser.id,
//     // 'profile_id': profileUser.id,
//     address: {
//       registered_address: profileUser.registered_address,
//       city: profileUser.city,
//       zip: profileUser.zip,
//     },
//     // registered_address: typeUser.registered_address,
//     // city: typeUser.city,
//     // zip: typeUser.zip,
//     keywords: profileUser.keywords,
//     social_profiles: profileUser.social_profiles,
//     category: profileUser.category
//   }
//
//   //
//   // if(type === 'brand'){
//   //   profileU.business_name=profileUser.business_name;
//   //   profileU.gst_number=profileUser.gst_number;
//   //   profileU.legal_name=profileUser.legal_name;
//   //   profileU.pan_or_tan=profileUser.pan_or_tan;
//   //   profileU.pan_or_tan_image=profileUser.pan_or_tan_image;
//   //   profileU.tax_certificate=profileUser.tax_certificate;
//   //   profileU.industries=profileUser.industries;
//   //
//   //
//   //   profileU.categories = mUser.categories;
//   //   profileU.category = mUser.category;
//   //   profileU.city = mUser.city;
//   //   profileU.gst_number = mUser.gst_number;
//   //   profileU.industries = mUser.;[{id = mUser.;6, industry_name = mUser.;"E-Commerce", industry_desc = mUser.;"E-Commerce", is_active = mUser.;true, parent = mUser.;null},…]
//   //   profileU.keywords = mUser.;[]
//   //   profileU.legal_name = mUser.;"Super Supreme Group"
//   //   profileU.pan_or_tan = mUser.;"PAN_1234"
//   //   profileU.pan_or_tan_image = mUser.;"https://infloso-dev.s3.amazonaws.com/user-profile-media/1624904298-sample_pan.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3WACVDGG5BVJ3S5X%2F20210630%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210630T183725Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=c5a9b01a2ceb7e3bfd0560d27bc1d8466cb69fe02e43303a3721ffd156e30824"
//   //   profileU.pan_or_tan_verified = mUser.;false
//   //   profileU.registered_address = mUser.;"12345"
//   //   profileU.social_profiles = mUser.;[{id = mUser.;3, social_media_platform = mUser.;"Instagram", followers = mUser.;0, following = mUser.;0, brand_name = mUser.;"test",…}]
//   //   profileU.state = mUser.;"Haryana"
//   //   profileU.tax_certificate = mUser.;"https://infloso-dev.s3.amazonaws.com/user-profile-media/1624904281-tax_certificate.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3WACVDGG5BVJ3S5X%2F20210630%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210630T183725Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=2396165865d3b10d3aec8f690d8a48842507f71cd03c57a4fc2048198d1cb947"
//   //   profileU.zip = mUser.;"123456"
//   // }
//   //
//
//   return user;
// }

export function emailUser(authUser) {
  let profileU = {};
  const user = {
    user_id: authUser.user.id,
    name: authUser.user.first_name, // + ' ' + authUser.user.last_name,
    first_name: authUser.user.first_name,
    last_name: authUser.user.last_name,
    email: authUser.user.email,
    username: authUser.user.username,
    created_date: authUser.created_date,
    modified_date: data.modified_date,
    alias: authUser.alias,
    tagLine: '', //authUser.tagLine,
    bio: authUser.bio,
    mobile: authUser.mobile,
    location: authUser.location,
    birth_date: authUser.birth_date,
    img: authUser.profileImage,
    image: authUser.profileImage,
    gender: authUser.gender,
  };

  let profileUser = {};
  if (authUser.influencer_profile) {
    profileUser = authUser.influencer_profile;

    profileU = {
      ['influencer_profile_id']: profileUser.id,
      profile_id: profileUser.id,
      address: {
        registered_address: profileUser.registered_address,
        city: profileUser.city,
        state: profileUser.state,
        zip: profileUser.zip,
      },
      // registered_address: typeUser.registered_address,
      // city: typeUser.city,
      // zip: typeUser.zip,
      keywords: profileUser.keywords,
      social_profiles: profileUser.social_profiles,
      category: profileUser.category,
    };
  }

  return { ...user, ...profileU };
}

export function loadTokens() {
  let access = ls.getItem('access');
  let refresh = ls.getItem('refresh');

  if (isTokenExpired(access)) {
    access = null;
  }

  if (isTokenExpired(refresh)) {
    refresh = null;
  }

  return { access, refresh };
}

export function loadUser() {
  const { access, refresh } = loadTokens();

  if (refresh) {
    const authUser = decodeUser(refresh, true); //get it only if not expired
    if (authUser) {
      const user = {
        access,
        refresh,
        ...authUser,
      };

      if (!isUserExpired(user)) {
        return user;
      } else {
      }
    }
  }

  return { access: null, refresh: refresh };
}

function isTokenExpired(token) {
  const decodedToken = decodeJWT(token);
  if (decodedToken && decodedToken.payload) {
    return isExpired(decodedToken.payload.exp * 1000);
  } else {
    return true;
  }
}

function isUserExpired(user) {
  if (user) {
    return isExpired(user.exp);
  } else {
    return true;
  }
}

function isExpired(exp) {
  if (exp) {
    if (exp < new Date().getTime()) {
      //current time is more than expiry time
      return true;
    } else {
      return false;
    }
  }
  return true;
}

export default function saveUserMapping(userUpdate, user) {
  let authUser = {
    email: user.email,
    first_name: user.first_name,
    id: user.user_id,
    last_name: user.last_name,
    username: user.username,
  };

  const USER_UPDATE = ['first_name', 'last_name', 'username', 'is_active'];

  const PROFILE_UPDATE = [
    'registered_address',
    'state',
    'city',
    'zip',
    'keywords',
    'category',
    'brand_category',
    'industries',
    'business_name',
    'gst_number',
    'legal_name',
    'pan_or_tan',
    'pan_or_tan_image',
    'tax_certificate',
    'social_profile',
  ];

  const userUpdateKeys = Object.keys(userUpdate);

  const isProfileUpdate = PROFILE_UPDATE.map(
    (t) => userUpdateKeys.indexOf(t) > -1
  ).filter((t) => t);

  const isUserUpdate = USER_UPDATE.map(
    (t) => userUpdateKeys.indexOf(t) > -1
  ).filter((t) => t);

  //Send different response when profile is being updated
  if (isProfileUpdate.length > 0) {
    if (user.profile_id) {
      // Profile exists
      userUpdate[user.type] = user.type_id;
      const type_profile = {
        registered_address:
          user.registered_address || user.address.registered_address,
        city: user.city || user.address.city,
        state: user.state || user.address.state,
        zip: user.zip || user.address.zip,
        keywords: (user.keywords || []).map((k) => k.id || k),
        category: (user.category || []).map((k) => k.id || k),
        brand_category: user.brand_category,
      };

      return {
        update: 'profile',
        payload: { ...type_profile, ...userUpdate, user: authUser },
        method: 'PUT',
        url: `/inflosousers/${user.type}-profile/${user.profile_id}`,
      };
    } else {
      //Create New profile
      userUpdate[user.type] = user.type_id;
      const type_profile = {
        registered_address:
          user.registered_address ||
          (user.address ? user.address.registered_address : '-'),
        city: user.city || (user.address ? user.address.city : '-'),
        state: user.state || (user.address ? user.address.state : '-'),
        zip: user.zip || (user.address ? user.address.zip : '-'),
      };

      if (user.keywords) {
        type_profile.keywords = user.keywords.map((k) => k.id);
      }

      if (user.category) {
        type_profile.category = user.category.map((k) => k.id);
      }

      if (user.brand_category) {
        type_profile.brand_category = user.brand_category;
      }

      return {
        update: 'profile',
        payload: { ...type_profile, ...userUpdate, user: authUser },
        method: 'POST',
        url: `/inflosousers/${user.type}-profile`,
      };
    }
  } else if (isUserUpdate) {
    authUser = {
      ...authUser,
      ...{
        email: userUpdate.email,
        first_name: userUpdate.first_name,
        id: userUpdate.user_id,
        last_name: userUpdate.first_name ? '' : userUpdate.last_name,
        username: userUpdate.username,
        is_active:
          userUpdate.is_active !== undefined ? userUpdate.is_active : true,
      },
    };
  }

  if (user.type_id) {
    return {
      update: 'user',
      payload: { ...userUpdate, user: { ...authUser } },
      method: 'PUT',
      url: `/inflosousers/${user.type}/${user.type_id}`,
    };
  } else {
    return {
      update: 'user',
      payload: { ...userUpdate, user: { ...authUser } },
      method: 'POST',
      url: `/inflosousers/${user.type}`,
    };
  }
}

export function parseCampaignApplicationData(data) {
  return data && data.map ? data.map(parseEachCampaignApplicationData) : [];
}

export function parseCampaignApplicationDataES(data) {
  return data && data.map ? data.map(parseEachCampaignApplicationDataES) : [];
}

/*
{
        "id": 2,
        "about_influencer": "i can do this",
        "media_name": "media_name{{$timestamp}}",
        "why_me": "i know how to make video",
        "application_status": "Applied",
        "campaign": {
            "id": 7,
            "ownerType": "Brand",
            "brand": {
                "id": 1,
                "alias": "alias",
                "tagLine": "tagLine",
                "bio": "bio",
                "location": "location",
                "mobile": "",
                "brandlogo": "https://infloso-dev.s3.amazonaws.com/brandlogo?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3WACVDGG5BVJ3S5X%2F20210618%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210618T061700Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8ffb95a1fe87c2a157dbde3f5a1081744d6fcc7a1fb1886657be0aa0fe888bb6",
                "coverImage": "",
                "status": "('New', 'New')",
                "user": {
                    "id": 12,
                    "username": "username1623252163",
                    "email": "email1623252163@gmail.com",
                    "first_name": "Inflosouser",
                    "last_name": "1623252163"
                },
                "role": 2,
                "brand_profile": {
                    "id": 1,
                    "registered_address": "registered_address1623252193",
                    "city": "city1623252193",
                    "zip": "1623252193",
                    "brand": 1,
                    "keywords": [
                        {
                            "id": 1,
                            "keyword_name": "keyword_name_002",
                            "keyword_desc": "keyword_name_002_desc",
                            "is_active": true
                        }
                    ],
                    "social_profiles": [
                        {
                            "id": 1,
                            "social_media_platform": "Facebook",
                            "followers": 100,
                            "following": 99,
                            "brand_name": "brand_name1623252189",
                            "brand_url": "brand_url1623252189"
                        }
                    ],
                    "category": [
                        {
                            "id": 1,
                            "category_name": "Fashion",
                            "category_desc": "Fashion",
                            "is_active": true
                        }
                    ]
                }
            },
            "campaign_name": "campaign_name1623589646",
            "alias": "alias1623589646",
            "campaign_goal": "campaign_goal1623589646",
            "campaign_desc": "campaign_desc1623589646",
            "location": "location1623589646",
            "timeline": "Fixed Timeline",
            "start_date": "2021-06-09",
            "end_date": "2021-06-19",
            "collaboration_type": "Buy Products",
            "campaign_modes": "Facebook",
            "content_guidelines": "content_guidelines1623589646",
            "campaign_budget": "100.00",
            "no_of_influencers": 5,
            "is_active": false,
            "campaign_state": "In Review",
            "owner": {
                "id": 12,
                "username": "username1623252163",
                "email": "email1623252163@gmail.com",
                "first_name": "Inflosouser",
                "last_name": "1623252163",
                "usertype": "Brand",
                "brand": {
                    "id": 1,
                    "alias": "alias",
                    "tagLine": "tagLine",
                    "bio": "bio",
                    "location": "location",
                    "mobile": "",
                    "brandlogo": "https://infloso-dev.s3.amazonaws.com/brandlogo?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3WACVDGG5BVJ3S5X%2F20210618%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210618T061700Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=8ffb95a1fe87c2a157dbde3f5a1081744d6fcc7a1fb1886657be0aa0fe888bb6",
                    "coverImage": "",
                    "status": "('New', 'New')",
                    "user": {
                        "id": 12,
                        "username": "username1623252163",
                        "email": "email1623252163@gmail.com",
                        "first_name": "Inflosouser",
                        "last_name": "1623252163"
                    },
                    "role": 2,
                    "brand_profile": {
                        "id": 1,
                        "registered_address": "registered_address1623252193",
                        "city": "city1623252193",
                        "zip": "1623252193",
                        "brand": 1,
                        "keywords": [
                            {
                                "id": 1,
                                "keyword_name": "keyword_name_002",
                                "keyword_desc": "keyword_name_002_desc",
                                "is_active": true
                            }
                        ],
                        "social_profiles": [
                            {
                                "id": 1,
                                "social_media_platform": "Facebook",
                                "followers": 100,
                                "following": 99,
                                "brand_name": "brand_name1623252189",
                                "brand_url": "brand_url1623252189"
                            }
                        ],
                        "category": [
                            {
                                "id": 1,
                                "category_name": "Fashion",
                                "category_desc": "Fashion",
                                "is_active": true
                            }
                        ]
                    }
                }
            },
            "keywords": [
                {
                    "id": 1,
                    "keyword_name": "keyword_name_002",
                    "keyword_desc": "keyword_name_002_desc",
                    "is_active": true
                }
            ],
            "categories": [
                {
                    "id": 1,
                    "category_name": "Fashion",
                    "category_desc": "Fashion",
                    "is_active": true
                }
            ],
            "media": [
                {
                    "id": 1,
                    "media_name": "1623589667-image-001.jpeg",
                    "media_location": "campaigns-media/1623589667-image-001.jpeg",
                    "media_type": "Image",
                    "media_format": "image/jpeg",
                    "campaignname": "campaign_name1623589646",
                    "is_active": true,
                    "presign_url": "https://infloso-dev.s3.amazonaws.com/campaigns-media/1623589667-image-001.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3WACVDGG5BVJ3S5X%2F20210618%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210618T061700Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=ed77a06ada343591440330262566804df257bfd742eb45fbccbc23e1ae964060"
                }
            ]
        },
        "social_media": [
            {
                "id": 2,
                "social_media_platform": "Facebook",
                "followers": 100000,
                "following": 2,
                "profile_name": "delsanic",
                "profile_url": "https://www.facebook.com/delsanic"
            },

        ],
        "media": []
    }
 */
export function parseEachCampaignApplicationData(data) {
  return {
    id: data.id,
    campaign: parseEachCampaignData(data.campaign),
    modified_date: data.modified_date,
    influencer: loginUser(data.influencer),
    about_influencer: data.about_influencer || 'about_influencer',
    why_me: data.why_me || 'why_me',
    application_status: data.application_status,
    social_media: data.social_media,
    profile: data.profile,
    chats: data.chats,
    media: data.media,
    links: data.links,
    reject_content_reason: data.reject_content_reason,
    reject_insight_reason: data.reject_insight_reason,
    reject_link_reason: data.reject_link_reason,
    deliverables: data.deliverables,
    quote_amount: data.quote_amount,
    brand_counter_offer: data.brand_counter_offer,
    influencer_counter_offer: data.influencer_counter_offer,
    tracking_id: data.tracking_id,
    delivery_provider: data.delivery_provider,
    delivery_provider_name: data.delivery_provider_name,
    tracking_url: data.tracking_url,
    venue: data.venue,
    visit_time: data.visit_time,
    selectedProducts: data.selectedProducts,
    is_barter_campaign: data.is_barter_campaign,
  };
}

export function parseEachCampaignApplicationDataES(data) {
  return {
    id: data.id,
    campaign: parseEachCampaignData(data.campaign),
    modified_date: data.modified_date,
    influencer: loginUser({
      ...data.influencer,
      influencer_profile: data.profile,
    }),
    about_influencer: data.about_influencer || 'about_influencer',
    why_me: data.why_me || 'why_me',
    application_status: data.application_status,
    social_media: data.social_media,
    media: data.media,
    links: data.links,
    reject_content_reason: data.reject_content_reason,
    reject_insight_reason: data.reject_insight_reason,
    reject_link_reason: data.reject_link_reason,
    deliverables: data.deliverables,
    quote_amount: data.quote_amount,
    brand_counter_offer: data.brand_counter_offer,
    influencer_counter_offer: data.influencer_counter_offer,
    tracking_id: data.tracking_id,
    delivery_provider: data.delivery_provider,
    delivery_provider_name: data.delivery_provider_name,
    tracking_url: data.tracking_url,
    venue: data.venue,
    visit_time: data.visit_time,
    selectedProducts: data.selectedProducts,
    is_barter_campaign: data.is_barter_campaign,
  };
}

export function parseCampaignData(data) {
  return data && data.map ? data.map(parseEachCampaignData) : [];
}

export function parseEachCampaignData(data = {}) {
  let campaign = {
    v1: 'v1',
    id: data.id,
    status: 'Open',
    brand: data.brand,
    agency: data.agency,
    is_active: data.is_active,
    created_date: data.created_date,
    modified_date: data.modified_date,
    name: data.campaign_display_name || data.campaign_name,
    campaign_name: data.campaign_name,
    campaign_display_name: data.campaign_display_name,
    owner: data.owner,
    coowner: data.coowner,
    ownerType: data.ownerType,
    campaign_goal: data.campaign_goal,
    campaign_desc: data.campaign_desc,
    campaign_state: data.campaign_state,
    email: 'm@rf.com',
    location: data.location,
    description: data.campaign_desc,
    timeline: data.timeline,
    start_date: data.start_date,
    end_date: data.end_date,
    campaign_type: data.campaign_type,
    collaboration_type: data.collaboration_type,
    campaign_collaboration_type: data.campaign_collaboration_type,
    selectedProducts: data.selectedProducts,
    type: data.collaboration_type,
    campaign_budget: data.campaign_budget,
    no_of_influencers: data.no_of_influencers,
    media: data.media,
    value_of_product: parseInt(data.value_of_product, 10),
    reach: data.reach,
    frequency: data.frequency,
    recurring_frequency: data.recurring_frequency,
    count_of_product: data.count_of_product,
    image_of_product: data.image_of_product,
    uploaded_image: data.image,
    promote_product: data.promote_product,
    version: data.version,
    comments: data.comments || [],
    extra_media: data.extra_media || [],
    report_ready: data.report_ready,
    amount_after_discount: data.amount_after_discount,
    influencer_fee: data.influencer_fee,
    platform_fee: data.platform_fee,
    campaign_budget_user: data.campaign_budget_user,
    support_fee: data.support_fee,
    support_fee_tobe: data.support_fee_tobe,
    action: data.action,
    visit_hours: data.visit_hours,
    discount: data.discount,
    support: data.support,
    coupon: data.coupon,
    brand_coupon: data.brand_coupon,
    rejection_reason: data.rejection_reason,
    campaign_tier: data.campaign_tier,
  };

  if (
    campaign.owner &&
    campaign.owner.brand &&
    campaign.owner.brand.barter_cost
  ) {
    campaign.arter_cost = campaign.owner.brand.barter_cost;
  }
  if (campaign.brand && campaign.brand.barter_cost) {
    campaign.barter_cost = campaign.brand.barter_cost;
  }

  if (data.campaigncriteria) {
    campaign = { ...campaign, ...parseCampaignCriteria(data.campaigncriteria) };
    // campaign.campaigncriteria = data.campaigncriteria;
    // campaign.influencer_category = data.campaigncriteria.influencer_category ? data.campaigncriteria.influencer_category.map(k=>({id:k})):[];
    // campaign.influencer_gender = data.campaigncriteria.influencer_gender ? data.campaigncriteria.influencer_gender.split("|") : [];
    // campaign.age_group = [data.campaigncriteria.age_group_lower_limit , data.campaigncriteria.age_group_upper_limit];
  }

  if (data.campaign_influencer_criteria) {
    campaign = {
      ...campaign,
      ...parseCampaignCriteria(data.campaign_influencer_criteria),
    };
    // campaign.campaigncriteria = data.campaigncriteria;
    // campaign.influencer_category = data.campaigncriteria.influencer_category ? data.campaigncriteria.influencer_category.map(k=>({id:k})):[];
    // campaign.influencer_gender = data.campaigncriteria.influencer_gender ? data.campaigncriteria.influencer_gender.split("|") : [];
    // campaign.age_group = [data.campaigncriteria.age_group_lower_limit , data.campaigncriteria.age_group_upper_limit];
  }

  if (data.campaignmodes) {
    campaign.campaignmodes = data.campaignmodes;
    // campaign.influencer_gender = data.campaigncriteria.influencer_gender;
    // campaign.age_group = data.campaigncriteria.age_group_lower_limit + ' - ' + data.campaigncriteria.age_group_upper_limit;
  } else if (data.campaign_mode) {
    campaign.campaignmodes = data.campaign_mode || [];
  } else {
    campaign.campaignmodes = [];
  }

  if (data.brand) {
    campaign.company = data.brand.user
      ? data.brand.user.first_name || data.brand.user.name
      : data.owner.first_name;
    campaign.img = data.brand.brandlogo;
    campaign.image = data.brand.brandlogo;
    campaign.cover = data.brand.brandcover;
  }

  if (data.agency) {
    campaign.company = data.agency.user.first_name;
    campaign.img = data.agency.agencylogo;
    campaign.image = data.agency.agencylogo;
    campaign.cover = data.agency.coverImage;
  }

  if (data.influencer) {
    campaign.company = data.brand.user.first_name;
    campaign.img = data.brand.brandlogo; //TODO what is this?
    campaign.image = data.brand.brandlogo;
    campaign.cover = data.brand.brandcover;
  }

  return campaign;
}

export function parseCampaignCriteria(campaigncriterialist) {
  // return data && data.map ? data.map(parseEachCampaignCriteria) : [];
  const campaign = {};
  const campaigncriteria = Array.isArray(campaigncriterialist)
    ? campaigncriterialist[0]
    : campaigncriterialist;
  campaign.campaigncriteria = campaigncriteria;
  if (campaigncriteria) {
    campaign.influencer_category = campaigncriteria.influencer_category
      ? campaigncriteria.influencer_category.map((k) => ({ id: k }))
      : [];
    campaign.influencer_gender = campaigncriteria.influencer_gender
      ? campaigncriteria.influencer_gender.split('|')
      : [];
    campaign.age_group = [
      campaigncriteria.age_group_lower_limit,
      campaigncriteria.age_group_upper_limit,
    ];
  }
  return campaign;
}

// export function parseEachCampaignCriteria(criterias){
//
// }

export function reverseCampaignCriteria(criteria, savedCriteria, campaign) {
  let payload = {};

  if (savedCriteria && savedCriteria.id) {
    // payload.id = savedCriteria.id;
    payload.criteria_name = savedCriteria.criteria_name;
  } else {
    payload.criteria_name = 'Saved Criteria ' + new Date().getTime();
    payload.campaign = [campaign.id];
    payload.age_group_lower_limit = 14;
    payload.age_group_upper_limit = 80;
  }

  if (criteria.age_group) {
    payload.age_group_lower_limit = criteria.age_group[0];
    payload.age_group_upper_limit = criteria.age_group[1];
  } else if (criteria.influencer_gender) {
    payload.influencer_gender = criteria.influencer_gender.join('|');
  } else if (criteria.influencer_category) {
    payload.influencer_category = criteria.influencer_category;
  }

  // const id = {};

  return { ...payload };
}

// [{
//   "id": 2,
//   "insight_state": "Draft",
//   "insight_comments": "X",
//   "created_date": "2021-07-05T14:03:05.338819Z",
//   "modified_date": "2021-07-05T14:03:05.338844Z",
//   "influencer": 6,
//   "social_media": 8,
//   "campaignapplication": 10,
//   "media": [{
//     "id": 24,
//     "media_name": "1625493783-sample_png.png",
//     "media_location": "campaigns-application-media/1625493783-sample_png.png",
//     "media_type": "Insight Media",
//     "media_format": "image/png",
//     "campaignname": "Square Burger Promotion",
//     "campaignapplication": "",
//     "mode": "",
//     "platform": "",
//     "is_insight": true,
//     "is_active": true,
//     "created_date": "2021-07-05T14:03:04.929138Z",
//     "modified_date": "2021-07-05T14:03:04.929165Z",
//     "media_status": "Uploaded",
//     "presign_url": "https://infloso-dev.s3.amazonaws.com/campaigns-application-media/1625493783-sample_png.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3WACVDGG5BVJ3S5X%2F20210705%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210705T211558Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=ad944e0f89b4407b962c59e16482507487dbeee9381f5cf58e2db285ff830d2a"
//   }]
// }]
export function parseInsightsData(data) {
  return data && data.map(parseEachInsightsData);
}

export function parseEachInsightsData(data) {
  return data;
}
