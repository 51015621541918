import ColorIcon from '../icon/ColorIcon';
import React from 'react';
import { faMapMarkerAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FluentIcon from '../fluenticons/FluentIcon';
import { fiMap } from '../fluenticons';

export default function Location({
  location,
  loading,
  className,
  icon = true,
  ellipsis = true,
}) {
  const loc =
    location && location.trim() ? location.trim().split('|').join(', ') : null;

  return (
    <div className="inline-block">
      <div className={`flex-initial flex ${className} items-center`}>
        {icon && (
          <span className="flex-initial" style={{ marginTop: '0px' }}>
            {!loading && (
              // <FontAwesomeIcon
              //   icon={faMapMarkerAlt}
              //   className="mr-2"
              //   size="xs"
              // />
              <FluentIcon icon={fiMap} size={15} />
            )}
          </span>
        )}
        <span
          className={`flex-1 leading-4 ${
            ellipsis && 'ellipsis'
          } flex-1 font-medium text-xs`}
        >
          {loading ? (
            <div
              className="bg-gray-300 w-20 rounded-lg"
              style={{ margin: '0px', marginTop: '1px' }}
            >
              &nbsp;
            </div>
          ) : (
            <span className="ml-2">{loc || <span>&nbsp;</span>}</span>
          )}
        </span>
      </div>
    </div>
  );
}
