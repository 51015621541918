import OutlineButton from '../action/OutlineButton';
import {
  faExclamationTriangle,
  faLink,
  faQuestionCircle,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { TextInput } from '../inputs/TextInput';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  getSocialDetails,
  getUser,
  saveSocialProfile,
} from '../../services/redux/modules/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import actions from '../../services/redux/actions';
import Button from '../action/Button';
import { isMobile } from '../../services/util';
import { shortNumber } from '../../functions/numbers';
// import { IPhoneX, Pixel3XL, Chrome } from "react-device-frames";
import {
  fbConnect,
  googleAdditionalScope,
  instagramBasicConnect,
  instagramConnect,
} from '../../lib/social';
import Alert from '../alerts/Alert';
import FlatButton from '../action/FlatButton';
import { Tooltip } from '../popovers';
import IPhoneX from '../frames/IPhoneX';
import useLocalStorage from '../../hooks/useLocalStorage';
import FluentIcon from '../fluenticons/FluentIcon';
import { fiHelp } from '../fluenticons';

export default function SocialBlock({
  connected,
  name,
  url,
  profile_name,
  platform,
  id,
  publicProfile,
  followers,
  following,
  manual_creation,
}) {
  const dispatch = useDispatch();
  const { user, facebook, youtube, google, instagram } = useSelector(
    (state) => ({
      user: state.user,
      facebook: state.ui.social.facebook,
      google: state.ui.social.google,
      youtube: state.ui.social.youtube,
      instagram: state.ui.social.instagram,
    }),
    shallowEqual
  );
  const isAdmin = user.admin;

  const platform_lowercase = platform.toLowerCase();
  const [loading, setLoading] = useState(false);
  const [admin_access, setAdminAccess] = useLocalStorage('admin_access');

  const connectedValInitState = {
    name: name || '',
    platform: platform,
    url: url || '',
    id: id,
  };
  const [connectVal, setConnectVal] = useState(connectedValInitState);

  const change = (val) => {
    setConnectVal(val);
  };

  const onConnect = async (v) => {
    let dtls = {};
    try {
      dtls = await dispatch(getSocialDetails(v, 'connect-' + platform));
      v.followers = dtls.followers;
      v.following = dtls.following;
    } catch (e) {}

    try {
      const key = 'connect-' + platform;
      const resp = await dispatch(saveSocialProfile(v, key));
      const savedUser = await dispatch(getUser(null, key));
    } catch (e) {}
    setConnectVal(connectedValInitState);
  };

  const onDisconnect = async () => {
    try {
      const resp = await dispatch(actions.user.removeSocialProfile(id));
      const savedUser = await dispatch(actions.user.getUser());
    } catch (e) {
      console.error(e);
    }
    // setConnectVal(connectedValInitState);
  };

  const disconnect = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      actions.modal.show({
        // successButtonText:"Delink " + platform,
        // successButtonType:'danger',
        showFooter: false,
        showSuccessButton: false,
        closeButtonText: 'Cancel',
        onSuccess: onDisconnect,
        title: 'Disconnect ' + platform,
        content: <Delink name={name} url={url} platform={platform} id={id} />,
      })
    );
  };

  const update = () => {
    dispatch(
      actions.modal.show({
        successButtonText: 'Update',
        // successButtonType:'danger',
        // showFooter:false,
        onSuccess: onConnect,
        title: 'Update ' + platform,
        content: (
          <UpdateBody name={name} url={url} platform={platform} id={id} />
        ),
      })
    );
  };

  const connectDialog = async (isRefresh, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (admin_access) {
      setTimeout(() => {
        dispatch(
          actions.modal.show({
            title: 'Not allowed',
            content: (
              <div>
                <Alert type="error">
                  Feature not available for Admin, login as user.
                </Alert>
              </div>
            ),
          })
        );
      }, 100);
      return;
    }
    setLoading(true);
    try {
      if (platform.toLowerCase() === 'instagramb') {
        const api_response = await instagramBasicConnect(instagram);
        await dispatch(actions.user.instagramConnect(api_response));
        await dispatch(actions.user.getUser());
      } else if (platform.toLowerCase() === 'instagram') {
        const api_response = await instagramConnect(instagram);
        const access_token =
          api_response &&
          api_response.authResponse &&
          api_response.authResponse.accessToken;
        if (!access_token) {
          dispatch(
            actions.modal.show({
              title: 'Could not connect',
              content: (
                <div>
                  <Alert type="error">Permission not granted.</Alert>
                </div>
              ),
            })
          );
          setLoading(false);
          return;
        }

        const resp = await dispatch(
          actions.user.instagramConnect({ access_token }, isRefresh)
        );
        if (resp && resp.non_field_errors && resp.non_field_errors.length > 0) {
          dispatch(
            actions.modal.show({
              title: 'Could not connect',
              content: (
                <div>
                  <Alert type="error"> {resp.non_field_errors[0]}</Alert>
                </div>
              ),
            })
          );
        } else if (resp && resp.error_code === '400') {
          dispatch(
            actions.modal.show({
              title: 'Could not connect',
              content: (
                <div>
                  <Alert type="error">{resp.message}</Alert>
                </div>
              ),
            })
          );
        } else {
          await dispatch(actions.user.getUser());
        }
      } else if (platform.toLowerCase() === 'facebook') {
        const api_response = await fbConnect(facebook);
        const accessToken =
          api_response &&
          api_response.authResponse &&
          api_response.authResponse.accessToken;
        if (!accessToken) {
          dispatch(
            actions.modal.show({
              title: 'Could not connect',
              content: (
                <div>
                  <Alert type="error">Permission not granted.</Alert>
                </div>
              ),
            })
          );
          setLoading(false);
          return;
        }
        const resp = await dispatch(
          actions.user.facebookConnect(accessToken, isRefresh)
        );
        if (resp && resp.non_field_errors && resp.non_field_errors.length > 0) {
          dispatch(
            actions.modal.show({
              title: 'Could not connect',
              content: (
                <div>
                  <Alert type="error"> {resp.non_field_errors[0]}</Alert>
                </div>
              ),
            })
          );
        } else if (resp && resp.error_code === '400') {
          dispatch(
            actions.modal.show({
              title: 'Could not connect',
              content: (
                <div>
                  <Alert type="error">{resp.message}</Alert>
                </div>
              ),
            })
          );
        } else {
          await dispatch(actions.user.getUser());
        }
      } else if (platform.toLowerCase() === 'youtube') {
        // const api_response = await youtubeConnect(youtube);
        let api_response;
        try {
          api_response = await googleAdditionalScope(youtube);
        } catch (e) {
          dispatch(
            actions.modal.show({
              title: 'Could not connect',
              content: (
                <div>
                  <Alert type="error">{e.details}</Alert>
                </div>
              ),
            })
          );
          setLoading(false);
          return;
        }
        if (!api_response || !api_response.accessToken) {
          dispatch(
            actions.modal.show({
              title: 'Could not connect',
              content: (
                <div>
                  <Alert type="error">Permission not granted.</Alert>
                </div>
              ),
            })
          );
          setLoading(false);
          return;
        }
        const resp = await dispatch(
          actions.user.youtubeConnect(api_response.accessToken, isRefresh)
        );
        if (
          resp &&
          resp.non_field_errors &&
          resp.non_field_errors.length > 0 &&
          resp.non_field_errors[0] === "This Account doesn't have any channel"
        ) {
          dispatch(
            actions.modal.show({
              title: 'Could not connect',
              content: (
                <div>
                  <Alert type="error">
                    {' '}
                    This YouTube account does not have a channel
                  </Alert>
                </div>
              ),
            })
          );
        } else if (resp && resp.error_code === '400') {
          dispatch(
            actions.modal.show({
              title: 'Could not connect',
              content: (
                <div>
                  <Alert type="error">{resp.message}</Alert>
                </div>
              ),
            })
          );
        } else {
          await dispatch(actions.user.getUser());
        }
      } else {
        dispatch(
          actions.modal.show({
            title: 'Connect ' + platform,
            successButtonText: 'Connect',
            // showFooter:false,
            // showSuccessButton: false,
            onSuccess: onConnect,
            content: (
              <ConnectBody name={name} url={url} platform={platform} id={id} />
            ),
          })
        );
      }
    } catch (e) {
      dispatch(
        actions.modal.show({
          title: 'Could not connect',
          content: (
            <div>
              <Alert type="error"> Unknown error</Alert>
            </div>
          ),
        })
      );
    }
    setLoading(false);
  };

  const openSocialMedia = (platform, username, url, e) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(url || PREFIX[platform] + username);
  };

  const onClose = () => {
    setConnectVal(connectedValInitState);
  };

  let username = ''; //url ? url.replace(PREFIX[platform], '') : '';
  if (platform === 'Instagram') {
    username = profile_name;
  }

  if (publicProfile && !connected) {
    return <></>;
  }

  const howTo = (platform) => {
    dispatch(
      actions.modal.show({
        title: 'Connect Instagram',
        content: <HowToDialog platform={platform} />,
        width: 'w-1/2',
        successButtonText: 'Connect',
        onSuccess: connectDialog.bind(this, false),
      })
    );
  };

  if (connected === false && isAdmin) {
    return <></>;
  }

  const bigLogo =
    [
      'facebook',
      'youtube',
      'instagram',
      'linkedin',
      'twitter',
      // 'facebook',
      // 'chingari',
      // 'koo',
      // 'moj',
      // 'sharechat',
      'tiktok',
    ].indexOf(platform_lowercase) > -1;

  return (
    <div
      className={`flex-initial relative rounded-lg p-4 px-8 ${
        connected !== false && !publicProfile
          ? 'customDropShadowNoHover'
          : 'border-1'
      }`}
      style={
        connected !== false
          ? {
              backgroundColor:
                manual_creation && !publicProfile ? '#fefcbf' : 'white',
            }
          : { borderStyle: 'dashed' }
      }
      // onClick={connected !== false && !publicProfile ? update : ()=>{}}
    >
      {manual_creation && !publicProfile && (
        <div
          style={{ right: '24px' }}
          className="absolute top-0 mt-1 mr-2 cursor-pointer text-red-500"
          onClick={connectDialog.bind(this, true)}
        >
          <Tooltip content={`Sync required`} placement="bottom">
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </Tooltip>
        </div>
      )}

      {connected && !publicProfile && (
        <div
          className="absolute right-0 top-0 mt-1 mr-2 cursor-pointer text-blue-500 rounded px-1 border-blue-100 hover:border-blue-500 border-1"
          onClick={connectDialog.bind(this, true)}
        >
          <Tooltip content={`Sync with ${platform}`} placement="bottom">
            <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
          </Tooltip>
        </div>
      )}

      {/*{connected !== false && !publicProfile && !isAdmin && (*/}
      {/*  <div className="absolute right-0 top-0 mt-1 mr-2 cursor-pointer text-gray-500 hover:text-red-500">*/}
      {/*    <FontAwesomeIcon icon={faTimes} onClick={disconnect} />*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className="flex flex-col gap-2" style={{ width: '135px' }}>
        <div className={`flex-initial flex flex-row pb-2 items-center `}>
          <div
            className={`${
              connected && 'cursor-pointer'
            } flex-initial w-full text-${platform_lowercase} rounded-25 block py-3 inline-block`}
            onClick={
              connected === false
                ? () => {}
                : openSocialMedia.bind(this, platform, username, url)
            }
          >
            {bigLogo ? (
              <img
                className="h-5 m-auto"
                src={`/images/social/color/${platform_lowercase}_logo.png`}
                style={{ height: '28px' }}
              />
            ) : (
              // <i
              //   className={`icon-social-${platform_lowercase} text-lg font-bold align-middle `}
              // />
              <img
                className="w-7 m-auto"
                src={`/images/social/color/${platform_lowercase}_icon.png`}
              />
            )}
          </div>
          <div
            className={`flex-1 text-md font-medium pt-2 ${
              connected && 'cursor-pointer'
            }`}
            onClick={
              connected === false
                ? () => {}
                : openSocialMedia.bind(this, platform, username, url)
            }
            title={
              connected === false ? '' : url || PREFIX[platform] + username
            }
          >
            <div
              className={`hidden text-${platform_lowercase} ${
                bigLogo ? 'hidden' : ''
              }`}
            >
              {platform}
            </div>
          </div>
        </div>
        {connected === false && !isAdmin && (
          <div className="flex flex-row gap-1">
            <Button
              loading={loading}
              className={`text-center rounded-25 w-full`}
              onClick={
                platform === 'Instagram'
                  ? howTo.bind(this, platform)
                  : connectDialog.bind(this, false)
              }
            >
              Connect
            </Button>
            {/*{platform === 'Instagram' ? (*/}
            {/*  <>*/}
            {/*    {!isMobile() ? (*/}
            {/*      <Tooltip*/}
            {/*        placement="top"*/}
            {/*        content={*/}
            {/*          <div className="text-center">{`How to connect ${platform}`}</div>*/}
            {/*        }*/}
            {/*      >*/}
            {/*        <FlatButton*/}
            {/*          icon={fiHelp}*/}
            {/*          onClick={howTo.bind(this, platform)}*/}
            {/*        />*/}
            {/*      </Tooltip>*/}
            {/*    ) : (*/}
            {/*      <FlatButton*/}
            {/*        icon={fiHelp}*/}
            {/*        onClick={howTo.bind(this, platform)}*/}
            {/*      />*/}
            {/*    )}*/}
            {/*  </>*/}
            {/*) : (*/}
            {/*  ''*/}
            {/*)}*/}
          </div>
        )}

        {connected !== false && (
          <div className="flex">
            <div className="flex-1 font-poppins">
              <div className="title text-md text-center font-semibold">
                {followers ? shortNumber(followers) : 0}
              </div>
              <p className="text-xxs text-gray-600 text-center font-poppins">
                Followers
              </p>
            </div>
            <div className="flex-1">
              <div className="title text-md text-center font-semibold">
                {following ? shortNumber(following) : 0}
              </div>
              <p className="text-xxs text-gray-600 text-center">Following</p>
            </div>
            {/*<div className="flex-1">*/}
            {/*  <div className="title text-md text-center font-semibold">*/}
            {/*    0*/}
            {/*  </div>*/}
            {/*  <p className="text-xxs text-gray-600 text-center">*/}
            {/*    Comments*/}
            {/*  </p>*/}
            {/*</div>*/}
          </div>
        )}
      </div>
    </div>
  );
}

const PREFIX = {
  Instagram: 'https://www.instagram.com/',
  Facebook: 'https://www.facebook.com/',
  Twitter: 'https://www.twitter.com/',
  YouTube: 'https://www.youtube.com/channel/',
  LinkedIn: 'https://www.linkedin.com/',
};

function ConnectBody({ onChange, onSuccess, id, name, url, platform }) {
  const [val, setVal] = useState({ name, url, id, platform });
  const prefix = PREFIX[platform];

  const onNameChange = (value) => {
    if (onChange) {
      const v = { ...val, name: value };
      setVal(v);
      onChange(v);
    }
  };

  const onUsernameChange = (value) => {
    if (onChange) {
      const v = {
        ...val,
        username: value,
        url: url ? url.replace('XXXXXXXXX', value) : value,
      };
      setVal(v);
      onChange(v);
    }
  };

  const username = ''; //url ? url.replace(PREFIX[platform], '') : '';

  return (
    <div className="w-full md:w-80">
      <TextInput
        label={`${platform} ${platform === 'Website' ? '' : 'Profile '}Name`}
        value={name}
        placeholder={platform === 'Website' ? 'Infloso' : 'Akshay Kumar'}
        onChange={onNameChange}
        className="w-full"
      />
      <TextInput
        label={`${platform} ${platform === 'Website' ? 'URL' : 'Username'}`}
        value={username}
        placeholder={
          platform === 'Website'
            ? 'https://www.infloso.com'
            : 'i.e. akshay.kumar'
        }
        hint={platform === 'Website' ? '' : 'Write your username only.'}
        onChange={onUsernameChange}
      />
      {/*<div className="text-right">*/}
      {/*  <Button onClick={onSuccess}>Connect</Button>*/}
      {/*</div>*/}
    </div>
  );
}

function UpdateBody({ onChange, id, name, url, platform }) {
  const [val, setVal] = useState({ name, url, id, platform });

  const prefix = PREFIX[platform];

  const onNameChange = (value) => {
    if (onChange) {
      const v = { ...val, name: value };
      setVal(v);
      onChange(v);
    }
  };

  const onUsernameChange = (value) => {
    if (onChange) {
      const v = { ...val, url: prefix + value };
      setVal(v);
      onChange(v);
    }
  };

  const username = url ? url.replace(PREFIX[platform], '') : '';

  return (
    <div className="w-full md:w-80">
      <TextInput
        label={`${platform} Profile Name`}
        value={name}
        placeholder="Akshay Kumar"
        onChange={onNameChange}
      />
      <TextInput
        label={`${platform} Username`}
        value={username}
        placeholder="i.e. akshay.kumar"
        hint="Write your username only."
        onChange={onUsernameChange}
      />
    </div>
  );
}

function Delink({ name, url, platform, id, onSuccess }) {
  return (
    <div className="flex flex-col gap-4">
      <div>
        Are you sure you want to disconnect?
        <div>&nbsp;</div>
      </div>
      <div className="text-center">
        <Button type="danger" onClick={onSuccess}>
          Disconnect {platform}
        </Button>
      </div>
    </div>
  );
}

// const fbLogin = (facebook) => {
//   return new Promise((resolve => {
//     FB.init({
//       appId      : facebook.appId,
//       version    : 'v11.0'
//     });
//
//     FB.login(function (response) {
//       if (response.authResponse) {
//         FB.api('/me?fields=id,name,first_name,last_name,picture,email', async function (api_response) {
//           api_response.accessToken = response.authResponse.accessToken;
//           resolve(api_response);
//         });
//       } else {
//         // dispatch(actions.user.showError('Could not complete Login'))
//         // if (props.onFailure) {
//         //   props.onFailure(response);
//         // }
//       }
//     }, {scope: 'email,public_profile'});
//   }))
// }

// const instagramLogin = (instagram) => {
//   return new Promise((resolve => {
//     FB.init({
//       appId      : instagram.appId,
//       version    : 'v11.0'
//     });
//
//     FB.login(function (response) {
//       if (response.authResponse) {
//         FB.api('/me?fields=id,name,first_name,last_name,picture,email', async function (api_response) {
//           api_response.accessToken = response.authResponse.accessToken;
//           resolve(api_response);
//         });
//       } else {
//         // dispatch(actions.user.showError('Could not complete Login'))
//         // if (props.onFailure) {
//         //   props.onFailure(response);
//         // }
//       }
//     }, {scope: instagram.scope});
//   }))
// }

function HowToDialog({ platform }) {
  const style = isMobile() ? {} : { height: '500px' };

  return (
    <div className="overflow-auto" style={style}>
      <div className="mb-20">
        <div className="font-bold my-2">1. Open Instagram App</div>
        <div className="font-bold my-2">2. Go to settings</div>
        <div className="font-bold my-2">
          3. Convert your account to an Instagram professional account.
        </div>
        <div className="mx-16 md:mx-48">
          <IPhoneX
            id={0}
            screenshot="/images/infloso_insta_profile_page.jpeg"
          />
        </div>
        <div className="font-bold my-2">3. Open Edit Profile</div>
        <div className="mx-16 md:mx-48">
          <IPhoneX id={1} screenshot="/images/infloso_insta_settings.jpeg" />
        </div>
        <div className="font-bold my-2">
          4. Add a Facebook Page to your Instagram
        </div>
        <div>
          You need to be the admin of the page to connect. Check your Page`s
          settings and make sure you have the required permission.
        </div>
        <div className="font-bold my-2">
          5. Click on Connect in the Infloso App
        </div>
        <div className="my-2">
          Facebook Connect will open (Instagram requires Facebook connect)
        </div>
        <div className="my-2">
          If connecting for the first time, tap on Continue as `your name`.
        </div>
        <div className="my-2">
          If not connecting for the first time, tap on Edit Settings.
        </div>
        <div className="my-2">5.1 Select your Instagram Account, tap Next</div>
        <div className="my-2">
          5.2 Select your Facebook page which is linked with the Instagram
          Professional Account, tap Next
        </div>
        <div className="my-2">
          5.3 Turn on toggles for all the permissions, tap Done
        </div>
        <div className="font-bold my-4">Instagram should be connected now</div>
      </div>
    </div>
  );
}
