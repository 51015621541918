import cnav from '../../../json/navigation.json';
import inav from '../../../json/navigation-influencer.json';
import bnav from '../../../json/navigation-brand.json';
import mnav from '../../../json/navigation-brandmanager.json';
import abnav from '../../../json/navigation-agencybrand.json';
import anav from '../../../json/navigation-agency.json';
import tnav from '../../../json/navigation-talentmanager.json';
import React from 'react';

export const SET_NAV = 'user/SET_NAV';
export const SET_INFLUENCER_NAV = 'user/SET_INFLUENCER_NAV';
export const SET_BRAND_NAV = 'user/SET_BRAND_NAV';
export const SET_AGENCY_NAV = 'user/SET_AGENCY_NAV';

export default function reducer(state = getNavigationTree(cnav), action) {
  switch (action.type) {
    case SET_NAV: {
      return [...getNavigationTree(action.payload)];
    }
    default: {
      return state;
    }
  }
}

export function set(type) {
  let payload;

  switch (type && type.toLowerCase()) {
    case 'influencer':
      payload = inav;
      break;
    case 'brand':
      payload = bnav;
      break;
    case 'agency':
      payload = anav;
      break;
    case 'agencybrand':
      payload = abnav;
      break;
    case 'brandmanager':
      payload = mnav;
      break;
    case 'talentmanager':
      payload = tnav;
      break;
    default:
      payload = cnav;
      break;
  }

  return {
    type: SET_NAV,
    usertype: type,
    payload: payload,
  };
}

export function influencer() {
  return {
    type: SET_NAV,
    payload: inav,
  };
}

export function brand() {
  return {
    type: SET_NAV,
    payload: bnav,
  };
}

export function agency() {
  return {
    type: SET_NAV,
    payload: anav,
  };
}

function getNavigationTree(cnav) {
  const dnav = cnav
    ? cnav.map((n) => ({
        url: n.link,
        // icons: (function() {
        //   const Ic =Icon[n.icon] || Icon.Book;
        //   return <Ic size={20} />
        // }()),
        icon: n.icon || 'Book',
        title: n.name,
        items: [],
        badge: n.badge,
        isMobile: n.isMobile,
        hideOnDesktop: n.hideOnDesktop,
        popover: n.popover,
      }))
    : [];

  return [
    {
      title: 'Applications',
      items: [...dnav],
    },
  ];
}
