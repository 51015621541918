import React from 'react';
import HashTag from '../display/HashTag';
import { useRouter } from 'next/router';
import UserImg from '../user/UserImg';
import Location from './Location';
import {
  faStar as faStarSolid,
  faStarHalfAlt as faStarHalf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';

export default function StarRating({ rating, color = '#FAC858', size = 'md' }) {
  return (
    <div className="flex flex-row gap-2" style={{ color }}>
      <FontAwesomeIcon icon={faStarSolid} size={size} />
      <FontAwesomeIcon icon={faStarSolid} size={size} />
      <FontAwesomeIcon icon={faStarSolid} size={size} />
      <FontAwesomeIcon icon={faStarHalf} size={size} />
      <FontAwesomeIcon icon={faStar} size={size} />
    </div>
  );
}
