import accounting from 'accounting';
import moment from 'moment';

export function random(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function roundNext10(number) {
  return Math.ceil(number / 10) * 10;
}

export function formatPercent(percent) {
  return `${accounting
    .formatMoney(percent, '', 1, ',', '.')
    .replace(/\.00$/g, '')}%`;
}

export function formatCurrencyUSD(value) {
  return accounting.formatMoney(value, '$', 2, ',', '.').replace(/\.00$/g, '');
}

export function formatCurrency(value) {
  try {
    return 'INR ' + formatNumber(parseFloat(value, 10).toFixed(2));
  } catch (e) {
    return value;
  }
}

export function formatShortCurrency(value) {
  const v = parseInt(value, 10);
  if (v >= 10000000) {
    return 'INR ' + Math.round(v / 10000000) + 'cr';
  } else if (v >= 1000000) {
    return 'INR ' + Math.round(v / 100000) + 'lac';
  } else if (v >= 100000) {
    return 'INR ' + (v / 100000).toFixed(1) + 'lac';
    // }else if(v >= 10000){
    //   return '₹ ' + Math.round(v / 10000) + 'K'
  } else if (v >= 10000) {
    return 'INR ' + Math.round(v / 1000) + 'K';
  } else if (v >= 1000) {
    return 'INR ' + v;
  } else {
    return 'INR ' + parseInt(value, 10);
  }

  try {
    return 'INR ' + parseInt(value, 10).toLocaleString('en-IN');
  } catch (e) {
    return value;
  }
}

export function formatNumber(value) {
  try {
    return accounting.formatMoney(value, '', 2, ',', '.').replace(/\.00$/g, '');
  } catch (e) {
    console.log(e);
    return value;
  }
}

export function formatDate(dtStr, format = 'Do MMM YYYY') {
  return moment(dtStr).format(format);
}

export function shortNumber(value) {
  if (value < 1000) {
    return value;
  }
  if (value < 10000) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  if (value < 1000000) {
    return ((value / 1000).toFixed(2) + 'K').replace('.00', '');
  }

  if (value < 1000000000) {
    return ((value / 1000000).toFixed(2) + 'M').replace('.00', '');
  }
}
