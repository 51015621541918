const initialState = {
  loading: {}, //Store all loading actions here
  count: 0,
};

export default function reducer(state = initialState, action) {
  //If loading state changes
  const isLoading = action.type.endsWith('_REQUEST');
  const SUCCESS_ACTION_TYPE = action.type.replace('_SUCCESS', '_REQUEST');

  switch (isLoading) {
    case true: {
      //Need to increment the counter
      //Check if property exists with name 'action.type' else define
      if (!state.loading[action.type]) {
        state.loading[action.type] = 0;
      }
      state.loading[action.type] = state.loading[action.type] + 1;
      return {
        ...state,
        count: count(state.loading),
        loading: { ...state.loading },
      };
    }
    case false: {
      //Need to decremenet the counter
      if (state.loading[SUCCESS_ACTION_TYPE]) {
        state.loading[SUCCESS_ACTION_TYPE] =
          state.loading[SUCCESS_ACTION_TYPE] - 1;
      }

      //Just to avoid error
      if (!state.loading[SUCCESS_ACTION_TYPE]) {
        state.loading[SUCCESS_ACTION_TYPE] = 0;
      }

      if (state.loading[SUCCESS_ACTION_TYPE] === 0) {
        delete state.loading[SUCCESS_ACTION_TYPE];
      }

      return {
        ...state,
        count: count(state.loading),
        loading: { ...state.loading },
      };
    }
    default:
      return state;
  }
}

function count(loading) {
  let count = 0;

  for (let l in loading) {
    count = count + loading[l];
  }

  return count;
}
