import React from 'react';
import WidgetTitle from '../widget-title';

const Widget = ({
  icon,
  cicon,
  title,
  description,
  right = null,
  children,
  className,
  style,
  bg,
  rounded = true,
  border = false,
}) => {
  if (right) {
    return (
      <div
        className={`w-full p-4 mb-4 widget ${
          rounded ? 'rounded-10px' : ''
        } bg-gray-100 ${border ? 'border-1 border-gray-300' : ''}`}
      >
        <div className="flex flex-row items-center justify-between mb-4">
          {title && (
            <WidgetTitle
              icon={icon}
              cicon={cicon}
              title={title}
              description={description}
            />
          )}
          {right}
        </div>
        {children}
      </div>
    );
  }
  return (
    <div
      className={`flex-1 widget ${rounded ? 'rounded-10px' : ''} ${
        border ? 'border-1 border-gray-300' : ''
      } bg-${bg || 'white'} ${className}`}
      style={{ minWidth: '300px', ...style }}
    >
      {title && (
        <WidgetTitle
          icon={icon}
          cicon={cicon}
          title={title}
          description={description}
        />
      )}
      {children}
    </div>
  );
};

export default Widget;
