import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../layouts';
import actions from '../../services/redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  formatCurrency,
  formatNumber,
  shortNumber,
} from '../../functions/numbers';
import OutlineButton from '../../components/action/OutlineButton';
import { useRouter } from 'next/router';
import { colors, isMobile, stateForCity } from '../../services/util';
import Notifications from '../../components/notifications/Notifications';
import ReactSwipe from 'react-swipe';
import {
  fiArrowCircleLeft,
  fiArrowCircleRight,
  fiCalligraphyPen,
  fiCaretDown,
  fiContactCard,
  fiImage,
  fiMap,
  fiPeople,
  fiPhone,
} from '../../components/fluenticons';
import FluentIcon from '../../components/fluenticons/FluentIcon';
import NoCampaigns from '../../components/campaign/NoCampaigns';
import { parseEachCampaignData } from '../../services/mapping';
import * as echarts from 'echarts/dist/echarts.js';
import ReactECharts from 'echarts-for-react';
import Link from 'next/link';
import Loader from '../../components/display/Loader';
import Button from '../../components/action/Button';
import UserImg from '../../components/user/UserImg';
import IndiaMap from '../../components/charts/IndiaMap';
import moment from 'moment';
import {
  getTransactionByCampaign,
  getTransactionByGender,
} from '../../services/redux/modules/campaign';
import { OverviewFilterView } from './brand-dashboard';
import NoChartData from '../../components/charts/NoChartData';

export default function BrandDashboardCampaigns(props) {
  const { wallet, user, notifications } = useSelector(
    (state) => ({
      wallet: state.wallet,
      user: state.user,
      notifications: state.common.notifications,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.wallet.getMyWallet());
  }, []);

  const router = useRouter();
  const viewAll = () => {
    router.push('/wallet');
  };

  const items = [
    { name: 'Overview', url: '/dashboard' },
    { name: 'Campaign Overview', url: '/dashboard/campaign' },
    { name: 'Influencer Overview', url: '/dashboard/influencer' },
    { name: 'Transaction Overview', url: '/dashboard/transaction' },
  ];
  const [hidden, setHidden] = useState(true);
  const dropdownRef = React.createRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        (dropdownRef.current && dropdownRef.current.contains(event.target))
      ) {
        return false;
      }
      setHidden(!hidden);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <Layout title="Overview" mod="Overview">
      <div className="flex flex-row justify-center flex-wrap w-full">
        <div className="flex flex-col w-full">
          <OverviewFilterView />
          <div className="flex flex-row gap-3">
            <div className="w-2/3">
              <TransactionInfo />
            </div>
            <div className="w-1/3">
              <LatestTransaction />
            </div>
          </div>
          <div className="flex flex-row gap-10 mt-10">
            <div className="w-1/2">
              <ExpenditureByCampaign />
            </div>
            <div className="w-1/2">
              <PlatformWiseExpenditure />
            </div>
          </div>
          <div className="flex flex-row gap-10 mt-10">
            <div className="w-1/2">
              <CostByGender />
            </div>
            <div className="w-1/2">
              <ExpenditureByLocation />
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </Layout>
  );
}

function TransactionInfo() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getTransactionInfo());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading) {
    return (
      <div className="customDropShadowNoHover p-5 h-full text-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="customDropShadowNoHover p-5 h-full flex flex-row gap-10">
      <div>
        <div className="text-lg font-semibold">Lifetime Spend</div>
        <div className="blueBold">{formatNumber(data.lifetime_spend)}</div>
        <div className="text-lg font-semibold">Avg. Cost of Influencer</div>
        <div className="blueBold">
          {formatNumber(data.avg_cost_per_influencer)}
        </div>
        <div className="text-lg font-semibold">Avg. Cost of Campaign</div>
        <div className="blueBold">
          {formatNumber(data.avg_cost_per_campaign)}
        </div>
        <div className="text-lg font-semibold">Avg. Cost of Content</div>
        <div className="blueBold">
          {formatNumber(data.avg_cost_per_content)}
        </div>
      </div>
      <div>
        <div className="text-lg font-semibold">Avg. Cost per Engagement</div>
        <div className="blueBold">
          {formatNumber(data.avg_cost_per_engagement)}
        </div>
        <div className="text-lg font-semibold">Return on Investment</div>
        <div className="blueBold">{data.roi}</div>
        <div className="text-lg font-semibold">Cost per Milli</div>
        <div className="blueBold">{formatNumber(data.avg_cost_per_milli)}</div>
        <div className="text-lg font-semibold">Earned Media Value</div>
        <div className="blueBold">{formatNumber(data.earned_media_value)}</div>
      </div>
    </div>
  );
}

function LatestTransaction() {
  const dispatch = useDispatch();

  const { wallet } = useSelector(
    (state) => ({
      wallet: state.wallet,
    }),
    shallowEqual
  );

  useEffect(() => {
    const refresh = () => {
      dispatch(actions.wallet.getMyWallet());
    };
  }, []);

  return (
    <div className="px-5">
      <div className="customDropShadowNoHover p-2 text-center rounded-10px">
        <div className="blueBold mt-3">{formatCurrency(wallet.balance)}</div>
        <div className="font-semibold">Wallet Balance</div>
      </div>
      <div className="mt-5 text-center">
        <Button>Add money to the wallet</Button>
      </div>
      <LatestTransactions count={1} box={false} />
    </div>
  );
}

export function CostByGender() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getTransactionByGender());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '10%',
      // left: 'center',
      orient: 'vertical',
      right: 'right',
    },
    series: [
      {
        name: 'Cost',
        type: 'pie',
        radius: ['45%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'right',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '16',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: Object.keys(data).map((d) => ({
          value: parseInt(data[d]),
          name: d,
        })),
      },
    ],
  };

  return (
    <div className="">
      <div className="font-semibold text-lg">Absolute Cost By Gender</div>
      <ReactECharts echarts={echarts} option={option} />
    </div>
  );
}

export function ExpenditureByLocation({ titleCenter }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [mapData, setMapData] = useState({});
  const [mapVal, setMapVal] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getExpenditureByLocation());
    if (resp && !resp.error_code) {
      const loc = {};
      const map = {};
      let index = 0;

      //This is done to merge all cities data to state data so that we can show on map
      Object.keys(resp)
        .slice(0, 9)
        .map((s) => {
          const st = stateForCity(s);
          if (loc[st]) {
            loc[st] = loc[st] + resp[s];
          } else {
            loc[st] = resp[s];
            map[st] = colors()[index++];
          }
        });

      setMapData(map);
      setMapVal(loc);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="px-5">
      <div className="font-semibold text-lg">Top Expenditure by Location</div>
      <IndiaMap data={mapData} value={mapVal} />
    </div>
  );
}

export function LatestTransactions({ count = 5, box = true, header = true }) {
  const dispatch = useDispatch();
  const { wallet } = useSelector(
    (state) => ({
      wallet: state.wallet,
    }),
    shallowEqual
  );

  useEffect(() => {
    const refresh = () => {
      dispatch(actions.wallet.getMyWallet());
    };
  }, []);

  const router = useRouter();
  const viewAll = () => {
    router.push('/wallet');
  };

  return (
    <div className="h-full">
      {header && (
        <div
          className={`font-bold text-lg ${
            box ? '' : 'border-b-1 border-black'
          } m-2 pb-1 mt-5`}
        >
          Latest Transactions
        </div>
      )}
      <div
        className={`${box ? 'customDropShadowNoHover p-2' : ''}`}
        style={{ height: box ? '90%' : '' }}
      >
        {(!wallet.transactions || wallet.transactions.length === 0) && (
          <div className="flex flex-col h-full">
            <div className="flex-1"></div>
            <img
              src="/images/illustration/latest_transactions.png"
              width="90%"
              style={{ maxWidth: '200px' }}
              className="m-auto"
            />
            <div className="flex-1"></div>
          </div>
        )}
        {(wallet.transactions || []).slice(0, count).map((txn, i) => {
          return (
            <div className="border-b-1 p-2 gap-2" key={i}>
              <div className="text-lg">{txn.transaction_desc}</div>
              <div className="flex flex-row">
                <div className="font-bold flex-1">
                  <span
                    className={`font-bold ${
                      txn.transaction_status === 'Pending'
                        ? 'text-yellow-600'
                        : txn.transaction_type === 'Credit'
                        ? 'text-green-600'
                        : 'text-red-600'
                    }`}
                  >
                    {formatCurrency(txn.transaction_amount)}
                  </span>
                </div>
                <div className="text-gray-600">
                  {moment(new Date(txn.created_date)).format('DD MMM YYYY')}
                </div>
              </div>
            </div>
          );
        })}
        {wallet.transactions && wallet.transactions.length > 5 && (
          <div
            className="text-brand-light text-center cursor-pointer mt-2 mb-2"
            onClick={viewAll}
          >
            View All
          </div>
        )}
        {!wallet.transactions || (wallet.transactions.length === 0 && '-')}
      </div>
    </div>
  );
}

export function PlatformWiseExpenditure() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getTransactionByPlatform());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '10%',
      // left: 'center',
      orient: 'vertical',
      right: 'right',
    },
    series: [
      {
        name: 'Platform',
        type: 'pie',
        radius: [20, 120],
        center: ['50%', '50%'],
        roseType: 'area',
        itemStyle: {
          borderRadius: 8,
        },
        label: {
          show: false,
          position: 'right',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '16',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: Object.keys(data).map((d) => ({
          value: parseInt(data[d]),
          name: d,
        })),
      },
    ],
  };

  return (
    <div className="px-5">
      <div className="font-semibold text-lg">Platform-wise Expenditure</div>
      <ReactECharts echarts={echarts} option={option} />
    </div>
  );
}

export function ExpenditureByCampaign({ titleCenter }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getTransactionByCampaign());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const dt = Object.keys(data).slice(0, 5);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
      axisLabel: {
        formatter: function (value) {
          return shortNumber(value);
        },
      },
    },
    yAxis: {
      type: 'category',
      data: dt,
    },
    series: [
      {
        type: 'bar',
        data: dt.map((d, i) => ({
          value: data[d],
          itemStyle: {
            color: colors()[i],
          },
        })),
      },
    ],
  };
  return (
    <div className="px-5">
      <div
        className={`font-semibold text-lg ${titleCenter ? 'text-center' : ''}`}
      >
        Top Expenditure By Campaigns
      </div>
      {data && Object.keys(data).length ? (
        <ReactECharts echarts={echarts} option={option} />
      ) : (
        <NoChartData />
      )}
    </div>
  );
}
