import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import UserImg from '../user/UserImg';
import Button from '../action/Button';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import actions from '../../services/redux/actions';
import FluentIcon from '../fluenticons/FluentIcon';
import {
  fiAge,
  fiBarter,
  fiBrand,
  fiBuyProduct,
  fiDelete,
  fiDigitalProduct,
  fiLocation,
  fiMap,
  fiMoney,
  fiPeople,
  fiSend,
} from '../fluenticons';

export default function CampaignCardV4(props) {
  const {
    id,
    name,
    loading,
    company,
    description,
    image,
    uploaded_image,
    location,
    timeline,
    start_date,
    end_date,
    influencer_gender,
    collaboration_type,
    campaign_collaboration_type,
    type,
    campaignmodes,
    owner,
    clickAction,
    details = true,
    age_group,
    ownerType,
    status,
    campaign_state,
    rhs,
    no_of_influencers,
    version,
    buttonText = 'Apply',
    onboarded = true,
    showDetail = false,
    disabled,
    loadCampaigns,
    selectedProducts,
  } = props;

  // const loading = true;
  const { user, campaign } = useSelector(
    (state) => ({
      user: state.user,
      campaign: state.campaign,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [applCount, setApplCount] = useState(0);
  const [loadingApplCount, setLoadingApplCount] = useState(false);

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }
      setLoadingApplCount(true);
      const resp = await dispatch(
        actions.campaign.countCampaignApplications(null, id)
      );
      setApplCount(resp.count);
      setLoadingApplCount(false);
    })();
  }, [id]);

  const router = useRouter();
  const onClick = () => {
    if (disabled) {
      return;
    }

    if (clickAction === 'admin') {
      return;
    }

    if (clickAction === 'view') {
      router.push('/campaign/' + id);
      return;
    }

    if (clickAction === 'create') {
      router.push('/create-campaign-' + (version || 'v1') + '/' + id);
      return;
    }

    if (['Saved', 'Draft', 'Rejected'].indexOf(campaign_state) > -1) {
      router.push('/create-campaign-' + (version || 'v1') + '/' + id);
    } else {
      router.push('/campaign/' + id);
    }
  };

  let productIcon = fiSend;
  if (collaboration_type === 'Send Product') {
    productIcon = fiSend;
  } else if (collaboration_type === 'Buy Products') {
    productIcon = fiBuyProduct;
  } else if (collaboration_type === 'Digital Products') {
    productIcon = fiDigitalProduct;
  } else if (collaboration_type === 'Visits') {
    productIcon = fiLocation;
  }

  let collaborationIcon = fiMoney;
  if (campaign_collaboration_type === 'Paid') {
    collaborationIcon = fiMoney;
  } else if (campaign_collaboration_type === 'Barter') {
    collaborationIcon = fiBarter;
  }

  const deleteCampaign = (e) => {
    e.stopPropagation();

    dispatch(
      actions.modal.show({
        title: 'Delete Campaign',
        content: (
          <ConfirmDeleteDialog
            id={id}
            name={name}
            loadCampaigns={loadCampaigns}
          />
        ),
        showFooter: false,
        contentPadding: false,
      })
    );
  };

  const cm = campaignmodes ? campaignmodes[0] : {};
  let spots = cm ? cm.no_of_influencers - cm.no_of_approved_influencers : 0;
  if (spots < 0) {
    spots = 0;
  }

  const isFull = cm ? applCount > cm.no_of_influencers * 3 : false;
  const isRejected = campaign_state === 'Rejected';
  const isOwner = owner && owner.id === user.user_id;

  return (
    <>
      <div
        className={` overflow-hidden flex flex-col w-full campaign-card relative items-top justify-start bg-white rounded-10px transition duration-300 ${
          rhs !== false && !loading
            ? `md:shover:border-brand-light ${
                disabled
                  ? 'customDropShadowNoHover grayscale'
                  : 'customDropShadow cursor-pointer'
              }`
            : ''
        } ${loading && 'animate-pulse'}`}
        onClick={loading ? () => {} : onClick}
      >
        {campaign_state === 'Draft' && (
          <div
            className={`text-white bg-red-500 hover:bg-red-700 top-0 right-0 z-2 font-bold absolute flex items-end mt-2 mr-2 px-4 py-2 rounded-full`}
            onClick={deleteCampaign}
          >
            <FluentIcon icon={fiDelete} />
          </div>
        )}

        {!loading &&
          !loadingApplCount &&
          !isRejected &&
          !isFull &&
          campaign_state !== 'Draft' &&
          campaign_state !== 'Paused' &&
          campaign_state !== 'Completed' &&
          campaign_state !== 'Archived' &&
          spots > 0 && (
            <div
              className={`text-white bg-brand-base font-bold absolute flex items-end px-4 py-2 ${
                disabled ? 'hidden' : ''
              }`}
              style={{
                fontSize: '12px',
                top: '0',
                right: '0',
                borderBottomLeftRadius: '20px',
                zIndex: 1,
              }}
            >
              Only {spots} left!
            </div>
          )}
        {!loading &&
          !loadingApplCount &&
          !isRejected &&
          (isFull || spots === 0) &&
          campaign_state !== 'Draft' &&
          campaign_state !== 'Paused' &&
          campaign_state !== 'Completed' &&
          campaign_state !== 'Archived' && (
            <div
              className={`text-black bg-yellow-500 font-bold absolute flex items-end px-4 py-2 ${
                disabled ? 'hidden' : ''
              }`}
              style={{
                fontSize: '12px',
                top: '0',
                right: '0',
                borderBottomLeftRadius: '20px',
                zIndex: 1,
              }}
            >
              FULL
            </div>
          )}
        {!loading && isRejected && isOwner && (
          <div
            className={`text-white bg-red-700 font-bold absolute flex items-end px-4 py-2 ${
              disabled ? 'hidden' : ''
            }`}
            style={{
              fontSize: '12px',
              top: '0',
              right: '0',
              borderBottomLeftRadius: '20px',
              zIndex: 1,
            }}
          >
            REJECTED
          </div>
        )}
        {campaign_state === 'Paused' && !spots && (
          <div
            className={`text-white bg-brand-base font-bold absolute flex items-end px-4 py-2 ${
              disabled ? 'hidden' : ''
            }`}
            style={{
              fontSize: '12px',
              top: '0',
              right: '0',
              borderBottomLeftRadius: '20px',
              zIndex: 1,
            }}
          >
            Paused
          </div>
        )}
        <div className="flex items-top justify-start w-full flex-col">
          <div className="flex-1 flex flex-col">
            <div className="flex-shrink-0 h-40 w-full md:w-full md:h-40 rounded-t-25 border-b-1 border-gray-300 overflow-hidden bg-white">
              {
                loading ? (
                  <div className="h-40 w-full md:w-full rounded-25 bg-gsray-300 relative">
                    &nbsp;
                  </div>
                ) : (
                  <div className="w-full">
                    <div style={{ filter: 'blur(14px)', opacity: '0.7' }}>
                      <UserImg
                        image={uploaded_image || image}
                        email={name}
                        src={uploaded_image || image}
                        isCampaign={true}
                        alt={id}
                        defaultImg={
                          'identicon' ||
                          'https://www.infloso.com/icons/color/picture.svg'
                        }
                        className="m-auto h-40 w-full rounded-25  absolute backdrop-blur"
                      />
                    </div>
                    <UserImg
                      image={uploaded_image || image}
                      email={name}
                      src={uploaded_image || image}
                      isCampaign={true}
                      alt={id}
                      defaultImg={
                        'identicon' ||
                        'https://.infloso.com/icons/color/picture.svg'
                      }
                      className="m-auto h-40 rounded-25 border-b-1 border-gray-300 relative"
                    />
                  </div>
                )
                // <img
                //   src={image ? '/images/brands/' + image : "/27.png"}
                //   alt="image"
                //   className="h-32 w-32 rounded-25 relative border-r-2 border-gray-300"
                // />
              }
            </div>
            <div className="flex-1 m-4 sm:m-8 mt-2 sm:mt-2 mb-2 sm:mb-2 flex flex-col">
              <div className="text-md md:text-lg md:mt-1">
                {loading ? (
                  <div className="bg-gray-300 w-full md:w-72 mr-4 rounded-lg">
                    &nbsp;
                  </div>
                ) : (
                  <div className="flex flex-row gap-2">
                    <div
                      className="flex-1 md:flex-initial font-bold text-gray-800"
                      style={{ fontSize: '22px' }}
                    >
                      <div className="ellipsis-parent">
                        <div className="ellipsis-child">{name}</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {loading && (
                <div className="grid grid-cols-2 gap-2 mt-2">
                  {[1, 2, 3, 4, 5, 6].map((l, i) => {
                    return (
                      <div key={i} className="bg-gray-300 w-full rounded-lg">
                        &nbsp;
                      </div>
                    );
                  })}
                </div>
              )}
              {!loading && (
                <div className="flex flex-col gap-2 font-semibold text-sm overflow-hidden">
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-row gap-2 items-center w-6/12 sm:w-5/12">
                      <FluentIcon icon={fiBrand} />
                      <div className="whitespace-no-wrap ellipsis">
                        {company}
                      </div>
                    </div>
                    <div className="flex-1 flex-1 flex flex-row gap-2 items-center w-6/12 sm:w-7/12">
                      <FluentIcon icon={fiMap} />
                      <div className="whitespace-no-wrap ellipsis">
                        <div className="xl:hidden">
                          {(location || '').split('|')[0]}
                          {location && location.split('|').length > 1
                            ? ', +' + (location.split('|').length - 1) + ' more'
                            : ''}
                        </div>
                        <div className="hidden xl:block">
                          {(location || '').split('|')[0]}
                          {location && location.split('|').length > 1
                            ? ', ' + (location || '').split('|')[1]
                            : ''}
                          {location && location.split('|').length > 2
                            ? ', +' + (location.split('|').length - 2) + ' more'
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-row gap-2 items-center w-6/12 sm:w-5/12">
                      <FluentIcon icon={fiAge} />
                      <div className="whitespace-no-wrap ellipsis">
                        {age_group && age_group.join(' - ')} years
                      </div>
                    </div>
                    <div className="flex-1 flex flex-row gap-2 items-center">
                      <FluentIcon icon={fiPeople} />
                      <div>
                        {influencer_gender
                          ? influencer_gender.length === 3
                            ? 'All Genders'
                            : influencer_gender.join(', ')
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2">
                    <div className="flex flex-row gap-2 items-center w-6/12 sm:w-5/12">
                      <FluentIcon icon={productIcon} />
                      <div className="whitespace-no-wrap ellipsis">
                        {collaboration_type || '-'}
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                      <FluentIcon icon={collaborationIcon} />
                      <div>{campaign_collaboration_type || '-'}</div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mb-2 flex flex-row mt-4">
                <div className="flex-1">
                  {rhs !== false ? (
                    loading ? (
                      <div className="flex flex-row gap-2">
                        {[1, 2, 3].map((x, i) => (
                          <div
                            key={i}
                            className="bg-gray-300 rounded-full w-7 h-7"
                          ></div>
                        ))}
                      </div>
                    ) : (
                      <div className="flex flex-row gap-2 mt-1">
                        {campaignmodes &&
                          campaignmodes
                            .filter((m) => m.is_active)
                            .map((m, i) => (
                              <span className="flex-initial" key={i}>
                                {/*<i*/}
                                {/*  className={`icon-social-${m.mode_name.toLowerCase()} rounded-full border-2 p-1 border-${m.mode_name.toLowerCase()} text-${m.mode_name.toLowerCase()} text-md font-bold align-middle`}*/}
                                {/*/>*/}
                                <img
                                  className="w-7 h-7 rounded-25"
                                  src={`/images/social/color/${m.mode_name.toLowerCase()}_icon.png`}
                                />
                              </span>
                            ))}
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <div className={`w-40 ${disabled ? 'hidden' : ''}`}>
                  {!loading && rhs !== false && (
                    <div>
                      <Button className="w-full" disabled={disabled}>
                        {user.type === 'influencer' ? buttonText : 'View'}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ConfirmDeleteDialog({ id, onClose, loadCampaigns }) {
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);

  const deleteCampaign = async () => {
    setDeleting(true);
    await dispatch(actions.campaign.deleteCampaign({ id }));
    setDeleting(false);
    await dispatch(actions.modal.hide());
    await loadCampaigns();
  };

  return (
    <div>
      <div className="p-4">Are you sure you want to delete the campaign?</div>
      <div className="modal-footer children-x-2 flex flex-row justify-end">
        <Button onClick={onClose} type="secondary">
          Cancel
        </Button>
        <Button loading={deleting} onClick={deleteCampaign} type="danger">
          Delete
        </Button>
      </div>
    </div>
  );
}
