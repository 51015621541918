import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import actions from '../../services/redux/actions';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import Button from '../action/Button';

const ModalMobileHeader = ({
  onClose,
  showClose = true,
  onSave,
  title,
  successButtonText,
  showSuccessButton = true,
}) => (
  <div className="p-0 w-full h-16 flex shadow h-16">
    <div className="navbar-inner w-full flex items-center md:rounded-t-lg justify-start text-white md:text-brand-dark  bg-brand-light md:bg-gray-100">
      <div className="h-10 w-10">
        {showClose && (
          <button
            onClick={onClose}
            className="mx-1 md:hidden hover:bg-white rounded-full h-10 w-10 hover:text-brand-light"
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
        )}
      </div>
      <h1 className="title font-black w-full py-4 ">
        <div className="pl-2 sm:pl-8 w-full md:max-w-screen-lg m-auto">
          {title || ''}
        </div>
      </h1>
      {/*{onSave&& showSuccessButton && <button*/}
      {/*  className="btn btn-default rounded-full bg-white text-brand-light mr-1"*/}
      {/*  type="button"*/}
      {/*  onClick={onSave}>{successButtonText || 'Save'}</button>}*/}
    </div>
  </div>
);
const ModalHeader = ({
  onClose,
  showClose = true,
  onSave,
  title,
  successButtonText,
}) => (
  <div className="modal-header">
    {/*<button*/}
    {/*  className="mx-1 md:hidden hover:bg-white rounded-full h-12 w-12 hover:text-brand-light"*/}
    {/*  type="button" onClick={onClose}>*/}
    {/*  <FontAwesomeIcon icon={faArrowLeft} size="lg" />*/}
    {/*</button>*/}
    <h3 className="text-lg font-black">{title || 'Modal Title'}</h3>
    {showClose && (
      <button className="modal-close btn btn-transparent" onClick={onClose}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={`text-secondary stroke-current inline-block h-5 w-5`}
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    )}
  </div>
);

const ModalBody = ({
  body,
  onChange,
  onClose,
  onSave,
  errors,
  contentPadding,
}) => {
  const onSubmit = (data) => {};

  return (
    <div className={`${contentPadding && 'p-4'} h-full w-full overflow-auto`}>
      {React.cloneElement(body, {
        ...body.props,
        onChange: onChange,
        onClose: onClose,
        onSuccess: onSave,
        errors: errors,
      })}
    </div>
  );
};

const ModalFooter = ({
  onSave,
  onClose,
  showClose = true,
  successButtonText,
  successButtonType,
  showCloseButton = true,
  closeOnSubmit = true,
  successLoading = false,
  showSuccessButton = true,
  closeButtonText,
}) => (
  <div className="modal-footer children-x-2 text-right rounded-none">
    {showClose && showCloseButton && (
      <Button type="secondary" onClick={onClose}>
        {closeButtonText || 'Close'}
      </Button>
    )}
    {onSave && showSuccessButton && (
      <Button
        loading={successLoading}
        type={successButtonType}
        onClick={onSave}
      >
        {successButtonText || 'Save'}
      </Button>
    )}
  </div>
);

const GlobalModal = (props) => {
  const {
    onSuccess,
    onClose,
    buttonText,
    body,
    title,
    children,
    width = 'w-auto',
  } = props;
  const { modal } = useSelector(
    (state) => ({
      modal: state.modal,
    }),
    shallowEqual
  );

  const [successLoading, setSuccessLoading] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [value, setValue] = useState(null);
  const [errors, setErrors] = useState(null);
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    if (modal.open) {
      if (location.hash.indexOf('#') === -1) {
        router.push(
          router.asPath + '#' + modal.title.toLowerCase().replaceAll(' ', '_')
        );
      }
    }
    const doSomething = () => {
      // if(location.hash.indexOf('#') === 0) {
      dispatch(actions.modal.hide());
      // }
    };

    window.addEventListener('hashchange', doSomething, false);
    return () => {
      window.removeEventListener('hashchange', doSomething, false);
    };
  }, [modal]);

  useEffect(() => {
    const escFunction = () => {
      if (event.keyCode === 27) {
        close();
      }
    };

    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [modal]);

  const {
    successButtonText,
    successButtonType = 'primary',
    onSave,
    showFooter = true,
    bgFade = true,
    showSuccessButton = true,
    closeOnSubmit = true,
    contentPadding = true,
    showHeader = true,
    closeButtonText,
    showCloseButton = true,
  } = modal;

  const close = () => {
    if (location.hash.indexOf('#') === 0) {
      history.back();
    }
    // dispatch(actions.modal.close());
    // setHidden(true);
    // if (onClose) {
    //   onClose();
    // }
  };

  const change = (v, f) => {
    // if(modal.validate){
    //   const validationValue = modal.validate(v, f);
    //   if(validationValue !== true){ //some error is there
    //     setErrors( {...validationValue});
    //   }else{
    setErrors(null);
    //   }
    // }

    setValue(v);
  };

  const save = async (va) => {
    const v = va && va.currentTarget ? null : va;
    setSuccessLoading(true);
    if (modal.validate) {
      const validationValue = modal.validate(v || value || modal.defaultValue);
      if (validationValue !== true) {
        //some error is there
        setErrors({ ...validationValue });
        setSuccessLoading(false);
        return;
      } else {
        setErrors(null);
      }
    }

    if (modal.onSuccess) {
      try {
        await modal.onSuccess(v || value || modal.defaultValue);
      } catch (e) {}
    } else {
    }

    setSuccessLoading(false);
    if (closeOnSubmit) {
      close();
      dispatch(actions.modal.close());
    }
  };

  let childIsButton = false;

  try {
    childIsButton = (children.type + '').split('\n')[0].indexOf('Button') > 0;
  } catch (e) {
    //do nothing it does not matter, the child is not button
  }

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  const Header = isMobile ? ModalMobileHeader : ModalHeader;

  return (
    <>
      {modal.open ? (
        <>
          <div
            className={`backdrop ${
              bgFade ? 'fade-in bg-black' : 'bg-white'
            } fixed inset-0 z-40`}
          ></div>
          <div className="modal show justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className={`relative w-full h-full md:h-auto md:${
                modal.width || width
              }  mx-auto`}
            >
              <div className="modal-content h-full border-none md:border-1">
                {showHeader && (
                  <Header
                    showClose={
                      modal.showClose === false ? modal.showClose : true
                    }
                    onSave={modal.onSuccess ? save : null}
                    showSuccessButton={showSuccessButton}
                    onClose={close}
                    title={modal.title}
                    successButtonText={successButtonText}
                  />
                )}
                <ModalBody
                  body={modal.content}
                  onChange={change}
                  contentPadding={contentPadding}
                  errors={errors}
                  onClose={close}
                  onSave={modal.onSuccess ? save : null}
                />
                {showFooter && (
                  <ModalFooter
                    showClose={
                      modal.showClose === false ? modal.showClose : true
                    }
                    onSave={modal.onSuccess ? save : null}
                    onClose={close}
                    successLoading={successLoading}
                    closeOnSubmit={closeOnSubmit}
                    showCloseButton={showCloseButton}
                    showSuccessButton={showSuccessButton}
                    successButtonType={successButtonType}
                    successButtonText={successButtonText}
                    closeButtonText={closeButtonText}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default GlobalModal;
