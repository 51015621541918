import { markFailure, markLoading, markSuccess } from '../../util';
import { LOGOUT_SUCCESS } from './user';

export const GET_MY_WALLET_REQUEST = 'wallet/GET_MY_WALLET_REQUEST';
export const GET_MY_WALLET_SUCCESS = 'wallet/GET_MY_WALLET_SUCCESS';
export const GET_MY_WALLET_FAILURE = 'wallet/GET_MY_WALLET_FAILURE';

export const GET_MY_EARNING_REQUEST = 'wallet/GET_MY_EARNING_REQUEST';
export const GET_MY_EARNING_SUCCESS = 'wallet/GET_MY_EARNING_SUCCESS';
export const GET_MY_EARNING_FAILURE = 'wallet/GET_MY_EARNING_FAILURE';

export const GET_WALLET_REQUEST = 'wallet/GET_WALLET_REQUEST';
export const GET_WALLET_SUCCESS = 'wallet/GET_WALLET_SUCCESS';
export const GET_WALLET_FAILURE = 'wallet/GET_WALLET_FAILURE';

export const GET_ALL_WALLET_REQUEST = 'wallet/GET_ALL_WALLET_REQUEST';
export const GET_ALL_WALLET_SUCCESS = 'wallet/GET_ALL_WALLET_SUCCESS';
export const GET_ALL_WALLET_FAILURE = 'wallet/GET_ALL_WALLET_FAILURE';

export const WALLET_FILTER_REQUEST = 'wallet/WALLET_FILTER_REQUEST';
export const WALLET_FILTER_SUCCESS = 'wallet/WALLET_FILTER_SUCCESS';
export const WALLET_FILTER_FAILURE = 'wallet/WALLET_FILTER_FAILURE';

export const LOAD_BENEFICIARIES_REQUEST = 'wallet/LOAD_BENEFICIARIES_REQUEST';
export const LOAD_BENEFICIARIES_SUCCESS = 'wallet/LOAD_BENEFICIARIES_SUCCESS';
export const LOAD_BENEFICIARIES_FAILURE = 'wallet/LOAD_BENEFICIARIES_FAILURE';

export const ADD_BENEFICIARY_REQUEST = 'wallet/ADD_BENEFICIARY_REQUEST';
export const ADD_BENEFICIARY_SUCCESS = 'wallet/ADD_BENEFICIARY_SUCCESS';
export const ADD_BENEFICIARY_FAILURE = 'wallet/ADD_BENEFICIARY_FAILURE';

export const UPDATE_WALLET_REQUEST = 'wallet/UPDATE_WALLET_REQUEST';
export const UPDATE_WALLET_SUCCESS = 'wallet/UPDATE_WALLET_SUCCESS';
export const UPDATE_WALLET_FAILURE = 'wallet/UPDATE_WALLET_FAILURE';

export const DEPOSIT_REQUEST = 'wallet/DEPOSIT_REQUEST';
export const DEPOSIT_SUCCESS = 'wallet/DEPOSIT_SUCCESS';
export const DEPOSIT_FAILURE = 'wallet/DEPOSIT_FAILURE';

export const VERIFY_ORDER_REQUEST = 'wallet/VERIFY_ORDER_REQUEST';
export const VERIFY_ORDER_SUCCESS = 'wallet/VERIFY_ORDER_SUCCESS';
export const VERIFY_ORDER_FAILURE = 'wallet/VERIFY_ORDER_FAILURE';

export const CREATE_TRANSACTION_REQUEST = 'wallet/CREATE_TRANSACTION_REQUEST';
export const CREATE_TRANSACTION_SUCCESS = 'wallet/CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILURE = 'wallet/CREATE_TRANSACTION_FAILURE';

export const ADD_MONEY_REQUEST = 'wallet/ADD_MONEY_REQUEST';
export const ADD_MONEY_SUCCESS = 'wallet/ADD_MONEY_SUCCESS';
export const ADD_MONEY_FAILURE = 'wallet/ADD_MONEY_FAILURE';

export const ADD_MONEY_TAX_REQUEST = 'wallet/ADD_MONEY_TAX_REQUEST';
export const ADD_MONEY_TAX_SUCCESS = 'wallet/ADD_MONEY_TAX_SUCCESS';
export const ADD_MONEY_TAX_FAILURE = 'wallet/ADD_MONEY_TAX_FAILURE';

export const WITHDRAW_MONEY_REQUEST = 'wallet/WITHDRAW_MONEY_REQUEST';
export const WITHDRAW_MONEY_SUCCESS = 'wallet/WITHDRAW_MONEY_SUCCESS';
export const WITHDRAW_MONEY_FAILURE = 'wallet/WITHDRAW_MONEY_FAILURE';

export const CREATE_VERIFICATION_REQUEST = 'wallet/CREATE_VERIFICATION_REQUEST';
export const CREATE_VERIFICATION_SUCCESS = 'wallet/CREATE_VERIFICATION_SUCCESS';
export const CREATE_VERIFICATION_FAILURE = 'wallet/CREATE_VERIFICATION_FAILURE';

export const DELETE_TRANSACTION_REQUEST = 'wallet/DELETE_TRANSACTION_REQUEST';
export const DELETE_TRANSACTION_SUCCESS = 'wallet/DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILURE = 'wallet/DELETE_TRANSACTION_FAILURE';

export const CREATE_CAMPAIGN_WALLET_REQUEST =
  'wallet/CREATE_CAMPAIGN_WALLET_REQUEST';
export const CREATE_CAMPAIGN_WALLET_SUCCESS =
  'wallet/CREATE_CAMPAIGN_WALLET_SUCCESS';
export const CREATE_CAMPAIGN_WALLET_FAILURE =
  'wallet/CREATE_CAMPAIGN_WALLET_FAILURE';

export const GET_CAMPAIGN_WALLET_REQUEST = 'wallet/GET_CAMPAIGN_WALLET_REQUEST';
export const GET_CAMPAIGN_WALLET_SUCCESS = 'wallet/GET_CAMPAIGN_WALLET_SUCCESS';
export const GET_CAMPAIGN_WALLET_FAILURE = 'wallet/GET_CAMPAIGN_WALLET_FAILURE';

export const GET_CAMPAIGN_WALLET_TXNS_REQUEST =
  'wallet/GET_CAMPAIGN_WALLET_TXNS_REQUEST';
export const GET_CAMPAIGN_WALLET_TXNS_SUCCESS =
  'wallet/GET_CAMPAIGN_WALLET_TXNS_SUCCESS';
export const GET_CAMPAIGN_WALLET_TXNS_FAILURE =
  'wallet/GET_CAMPAIGN_WALLET_TXNS_FAILURE';

export const GET_WALLET_TXN_REQUEST = 'wallet/GET_WALLET_TXN_REQUEST';
export const GET_WALLET_TXN_SUCCESS = 'wallet/GET_WALLET_TXN_SUCCESS';
export const GET_WALLET_TXN_FAILURE = 'wallet/GET_WALLET_TXN_FAILURE';

const initialState = {
  balance: 0,
  loading: [],
  errors: {},
  success: {},
  campaign_wallet: {
    transactions: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS: {
      return { ...initialState };
    }
    case GET_ALL_WALLET_REQUEST:
    case WALLET_FILTER_REQUEST:
    case GET_MY_WALLET_REQUEST: {
      const mark = markLoading(action, state);
      return { ...state, ...mark };
    }
    case WALLET_FILTER_SUCCESS: {
      const mark = markSuccess(action, state);
      const txns =
        action.result && action.result.error_code ? [] : action.result;

      return { ...state, transactions: txns, ...mark };
    }
    case GET_ALL_WALLET_SUCCESS:
    case GET_MY_WALLET_SUCCESS: {
      const mark = markSuccess(action, state);
      const wallet = action.result;
      return { ...state, ...wallet, ...mark };
    }
    case GET_CAMPAIGN_WALLET_SUCCESS: {
      const mark = markSuccess(action, state);
      const wallet = action.result;
      return { ...state, campaign_wallet: wallet, ...mark };
    }
    case GET_CAMPAIGN_WALLET_TXNS_SUCCESS: {
      const mark = markSuccess(action, state);
      const wallet = action.result;
      if (wallet.error_code !== '404') {
        return {
          ...state,
          campaign_wallet: { ...state.campaign_wallet, transactions: wallet },
          ...mark,
        };
      } else {
        return { ...state };
      }
    }
    case GET_ALL_WALLET_FAILURE:
    case WALLET_FILTER_FAILURE:
    case GET_MY_WALLET_FAILURE: {
      const mark = markFailure(action, state);
      return { ...state, ...mark };
    }
    case CREATE_TRANSACTION_SUCCESS: {
      const wallet = action.result;
      return { ...state, ...wallet };
    }
    default: {
      return state;
    }
  }
}

export function getAllWallet() {
  return {
    payload: {},
    mark: { wallet: 1 },
    types: [
      GET_ALL_WALLET_REQUEST,
      GET_ALL_WALLET_SUCCESS,
      GET_ALL_WALLET_FAILURE,
    ],
    promise: (client) =>
      client('/inflosowallet/get-all-wallet?limit=9', {}, true),
  };
}

export function getMyWallet() {
  return {
    payload: {},
    mark: { wallet: 1 },
    types: [
      GET_MY_WALLET_REQUEST,
      GET_MY_WALLET_SUCCESS,
      GET_MY_WALLET_FAILURE,
    ],
    promise: (client) => client('/inflosowallet/wallets', {}, true),
  };
}

export function getMyEarnings() {
  return {
    payload: {},
    mark: { wallet: 1 },
    types: [
      GET_MY_EARNING_REQUEST,
      GET_MY_EARNING_SUCCESS,
      GET_MY_EARNING_FAILURE,
    ],
    promise: (client) => client('/inflosowallet/earnings', {}, true),
  };
}

export function getMyWalletFilter(start_date, end_date) {
  return {
    payload: {},
    mark: { wallet: 1 },
    types: [
      WALLET_FILTER_REQUEST,
      WALLET_FILTER_SUCCESS,
      WALLET_FILTER_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosowallet/trans-filter/?start_date=${start_date}&end_date=${end_date}`,
        {},
        true
      ),
  };
}

export function getWallet(user_id) {
  return {
    payload: {},
    mark: { wallet: 1 },
    types: [GET_WALLET_REQUEST, GET_WALLET_SUCCESS, GET_WALLET_FAILURE],
    promise: (client) =>
      client(`/inflosowallet/get-wallet?uid=${user_id}`, {}, true),
  };
}

export function getCampaignWallet(campaign_id) {
  return {
    payload: {},
    mark: { wallet: 1 },
    types: [
      GET_CAMPAIGN_WALLET_REQUEST,
      GET_CAMPAIGN_WALLET_SUCCESS,
      GET_CAMPAIGN_WALLET_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosowallet/campaign-wallets/${campaign_id}`, {}, true),
  };
}

export function getCampaignWalletTxns(campaign_id) {
  return {
    payload: {},
    mark: { wallet: 1 },
    types: [
      GET_CAMPAIGN_WALLET_TXNS_REQUEST,
      GET_CAMPAIGN_WALLET_TXNS_SUCCESS,
      GET_CAMPAIGN_WALLET_TXNS_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosowallet/campaign-wallet-transaction/${campaign_id}`,
        {},
        true
      ),
  };
}

export function getWalletTxn(txn_id) {
  return {
    payload: {},
    mark: { wallet: 1 },
    types: [
      GET_WALLET_TXN_REQUEST,
      GET_WALLET_TXN_SUCCESS,
      GET_WALLET_TXN_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosowallet/wallet-transaction/${txn_id}`, {}, true),
  };
}

export function deposit(wallet_id, txn) {
  return {
    payload: txn,
    mark: { 'wallet-deposit': 1 },
    types: [DEPOSIT_REQUEST, DEPOSIT_SUCCESS, DEPOSIT_FAILURE],
    promise: (client) =>
      client(
        `/inflosopayment/wallets/${wallet_id || 0}/deposit`,
        { method: 'POST', body: JSON.stringify(txn) },
        true
      ),
  };
}

export function verifyOrder(order_id) {
  return {
    payload: order_id,
    mark: { 'check-order': 1 },
    types: [VERIFY_ORDER_REQUEST, VERIFY_ORDER_SUCCESS, VERIFY_ORDER_FAILURE],
    promise: (client) =>
      client(`/inflosopayment/check_order/${order_id}`, {}, true),
  };
}

export function transact(txn) {
  // const payload = {
  //   "transaction_amount" : 500.00,
  //   "transaction_desc": "transaction_desc{{$timestamp}}",
  //   "transaction_type": "Credit"
  // };

  return {
    payload: txn,
    mark: { 'wallet-transaction': 1 },
    types: [
      CREATE_TRANSACTION_REQUEST,
      CREATE_TRANSACTION_SUCCESS,
      CREATE_TRANSACTION_FAILURE,
    ],
    promise: (client) =>
      client(
        '/inflosowallet/my-wallet-transaction',
        { method: 'POST', body: JSON.stringify(txn) },
        true
      ),
  };
}

export function addMoney(wallet_id, user_id, amount, description, ref) {
  const payload = {
    transaction_amount: amount,
    transaction_desc: description,
    transaction_type: 'Credit',
    transaction_reference: ref,
  };

  return {
    payload: payload,
    mark: { 'wallet-transaction': 1 },
    types: [ADD_MONEY_REQUEST, ADD_MONEY_SUCCESS, ADD_MONEY_FAILURE],
    promise: (client) =>
      client(
        `/inflosopayment/wallets/${wallet_id || 0}/add_money?uid=${user_id}`,
        { method: 'POST', body: JSON.stringify(payload) },
        true
      ),
  };
}

export function addMoneyTDS(wallet_id, user_id, amount, description, ref) {
  const payload = {
    transaction_amount: amount,
    transaction_desc: description,
    transaction_type: 'Credit',
    transaction_reference: ref,
  };

  return {
    payload: payload,
    mark: { 'wallet-transaction': 1 },
    types: [ADD_MONEY_REQUEST, ADD_MONEY_SUCCESS, ADD_MONEY_FAILURE],
    promise: (client) =>
      client(
        `/inflosopayment/wallets/${
          wallet_id || 0
        }/add_money_tds?uid=${user_id}`,
        { method: 'POST', body: JSON.stringify(payload) },
        true
      ),
  };
}

export function addMoneyTax(wallet_id, user_id, amount, description, ref) {
  const payload = {
    transaction_amount: amount,
    transaction_desc: description,
    transaction_type: 'Credit',
    transaction_reference: ref,
  };

  return {
    payload: payload,
    mark: { 'wallet-transaction': 1 },
    types: [
      ADD_MONEY_TAX_REQUEST,
      ADD_MONEY_TAX_SUCCESS,
      ADD_MONEY_TAX_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosopayment/wallets/${
          wallet_id || 0
        }/add_money_tax?uid=${user_id}`,
        { method: 'POST', body: JSON.stringify(payload) },
        true
      ),
  };
}

export function withdrawMoney(wallet_id, user_id, amount, description, ref) {
  const payload = {
    transaction_amount: amount,
    transaction_desc: description,
    transaction_type: 'Debit',
    transaction_reference: ref,
  };

  return {
    payload: payload,
    mark: { 'wallet-transaction': 1 },
    types: [
      WITHDRAW_MONEY_REQUEST,
      WITHDRAW_MONEY_SUCCESS,
      WITHDRAW_MONEY_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosopayment/wallets/${
          wallet_id || 0
        }/withdraw_money?uid=${user_id}`,
        { method: 'POST', body: JSON.stringify(payload) },
        true
      ),
  };
}

export function verifySignature(txn) {
  const payload = {
    razorpay_payment_id: txn.razorpay_payment_id,
    razorpay_order_id: txn.razorpay_order_id,
    razorpay_signature: txn.razorpay_signature,
  };

  return {
    payload: txn,
    mark: { 'wallet-transaction': 1 },
    types: [
      CREATE_VERIFICATION_REQUEST,
      CREATE_VERIFICATION_SUCCESS,
      CREATE_VERIFICATION_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosowallet/verify-razorpay-signature/${txn.order}`,
        { method: 'PUT', body: JSON.stringify(payload) },
        true
      ),
  };
}

export function deleteTransaction(txn) {
  const payload = {
    razorpay_payment_id: txn.razorpay_payment_id,
    razorpay_order_id: txn.razorpay_order_id,
    razorpay_signature: txn.razorpay_signature,
  };

  return {
    payload: txn,
    mark: { 'wallet-transaction': 1 },
    types: [
      DELETE_TRANSACTION_REQUEST,
      DELETE_TRANSACTION_SUCCESS,
      DELETE_TRANSACTION_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosowallet/verify-razorpay-signature/${txn.order}`,
        { method: 'DELETE' },
        true
      ),
  };
}

export function createCampaignWallet(campaign) {
  const payload = {
    campaign: campaign.id,
    budget_amount: parseFloat(campaign.campaign_budget, 10),
    no_of_influencers_paid: 0,
    no_of_influencers_unpaid: campaign.no_of_influencers,
  };

  return {
    payload: payload,
    mark: { 'wallet-transaction': 1 },
    types: [
      CREATE_CAMPAIGN_WALLET_REQUEST,
      CREATE_CAMPAIGN_WALLET_SUCCESS,
      CREATE_CAMPAIGN_WALLET_FAILURE,
    ],
    promise: (client) =>
      client(
        '/inflosowallet/campaign-wallet',
        { method: 'POST', body: JSON.stringify(payload) },
        true
      ),
  };
}

export function createUserWallet(campaign) {
  const payload = {
    campaign: campaign.id,
    budget_amount: parseInt(campaign.campaign_budget, 10),
    no_of_influencers_paid: 0,
    no_of_influencers_unpaid: campaign.no_of_influencers,
  };

  return {
    payload: payload,
    mark: { 'wallet-transaction': 1 },
    types: [
      CREATE_CAMPAIGN_WALLET_REQUEST,
      CREATE_CAMPAIGN_WALLET_SUCCESS,
      CREATE_CAMPAIGN_WALLET_FAILURE,
    ],
    promise: (client) =>
      client(
        '/inflosowallet/my-wallet-transaction',
        { method: 'POST', body: JSON.stringify(payload) },
        true
      ),
  };
}

export function loadBeneficiaries(wallet_id) {
  return {
    payload: {},
    mark: { load_beneficiaries: 1 },
    types: [
      LOAD_BENEFICIARIES_REQUEST,
      LOAD_BENEFICIARIES_SUCCESS,
      LOAD_BENEFICIARIES_FAILURE,
    ],
    promise: (client) =>
      client(`/inflosopayment/wallets/${wallet_id}/beneficiaries`, {}, true),
  };
}

export function addBeneficiary(wallet_id, beneficiary) {
  return {
    payload: beneficiary,
    mark: { 'add-beneficiary': 1 },
    types: [
      ADD_BENEFICIARY_REQUEST,
      ADD_BENEFICIARY_SUCCESS,
      ADD_BENEFICIARY_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosopayment/wallets/${wallet_id}/beneficiaries`,
        { method: 'POST', body: JSON.stringify(beneficiary) },
        true
      ),
  };
}

export function disableWallet(user_id) {
  return updateWallet(user_id, { disabled: true });
}

export function enableWallet(user_id) {
  return updateWallet(user_id, { disabled: false });
}

export function updateWallet(user_id, payload) {
  return {
    payload: payload,
    mark: { 'update-wallet': 1 },
    types: [
      UPDATE_WALLET_REQUEST,
      UPDATE_WALLET_SUCCESS,
      UPDATE_WALLET_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosowallet/update-wallet/${user_id}`,
        { method: 'PUT', body: JSON.stringify(payload) },
        true
      ),
  };
}

export function transferAmount(
  wallet_id,
  beneficiary_short_code,
  amount,
  isUPI
) {
  const payload = {
    transaction_amount: amount,
    transaction_desc: 'Withdraw',
    remarks: 'Withdraw',
  };

  if (isUPI) {
    payload.transferMode = 'upi';
  }
  return {
    payload: payload,
    mark: { 'withdraw-money': 1 },
    types: [
      ADD_BENEFICIARY_REQUEST,
      ADD_BENEFICIARY_SUCCESS,
      ADD_BENEFICIARY_FAILURE,
    ],
    promise: (client) =>
      client(
        `/inflosopayment/wallets/${wallet_id}/beneficiaries/${beneficiary_short_code}/pay-out`,
        { method: 'POST', body: JSON.stringify(payload) },
        true
      ),
  };
}
