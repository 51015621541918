import React from 'react';

export default function Alert({
  title,
  content,
  type,
  onClose,
  children,
  size = 3,
  bgShade = 100,
  textShade = 600,
}) {
  const TYPES = {
    info: 'blue',
    error: 'red',
    danger: 'red',
    success: 'green',
    message: 'gray',
    warning: 'yellow',
  };

  const color = TYPES[type || 'info'];

  return (
    <div
      className={`bg-${color}-${bgShade} w-full border border-${color}-400 text-${color}-${textShade} px-4 py-${size} rounded-lg relative`}
      role="alert"
    >
      <div>{title && <strong className="font-bold">{title}</strong>}</div>
      {(content || children) && (
        <span className="block sm:inline">{content || children}</span>
      )}
      {onClose && (
        <span className={`absolute top-0 bottom-0 right-0 px-4 py-${size}`}>
          <svg
            className="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      )}
    </div>
  );
}
