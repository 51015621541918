import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../layouts';
import actions from '../../services/redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { formatNumber, shortNumber } from '../../functions/numbers';
import OutlineButton from '../../components/action/OutlineButton';
import { useRouter } from 'next/router';
import { colors, isMobile, stateForCity } from '../../services/util';
import Notifications from '../../components/notifications/Notifications';
import ReactSwipe from 'react-swipe';
import {
  fiArrowCircleLeft,
  fiArrowCircleRight,
  fiCalligraphyPen,
  fiCaretDown,
  fiContactCard,
  fiImage,
  fiMap,
  fiPeople,
  fiPhone,
} from '../../components/fluenticons';
import FluentIcon from '../../components/fluenticons/FluentIcon';
import NoCampaigns from '../../components/campaign/NoCampaigns';
import { parseEachCampaignData } from '../../services/mapping';
import * as echarts from 'echarts/dist/echarts.js';
import ReactECharts from 'echarts-for-react';
import Link from 'next/link';
import Loader from '../../components/display/Loader';
import Button from '../../components/action/Button';
import UserImg from '../../components/user/UserImg';
import IndiaMap from '../../components/charts/IndiaMap';
import { OverviewFilterView } from './brand-dashboard';
import NoChartData from '../../components/charts/NoChartData';

export default function BrandDashboardCampaigns(props) {
  const { wallet, user, notifications } = useSelector(
    (state) => ({
      wallet: state.wallet,
      user: state.user,
      notifications: state.common.notifications,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.wallet.getMyWallet());
  }, []);

  const router = useRouter();
  const viewAll = () => {
    router.push('/wallet');
  };

  const items = [
    { name: 'Overview', url: '/dashboard' },
    { name: 'Campaign Overview', url: '/dashboard/campaign' },
    { name: 'Influencer Overview', url: '/dashboard/influencer' },
    { name: 'Transaction Overview', url: '/dashboard/transaction' },
  ];
  const [hidden, setHidden] = useState(true);
  const dropdownRef = React.createRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        (dropdownRef.current && dropdownRef.current.contains(event.target))
      ) {
        return false;
      }
      setHidden(!hidden);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <Layout title="Overview" mod="Overview">
      <div className="flex flex-row justify-center flex-wrap w-full">
        <div className="flex flex-col w-full">
          <OverviewFilterView />
          <div className="flex flex-row gap-10">
            <div className="w-1/2">
              <InfluencerInfo />
            </div>
            <div className="w-1/2">
              <InfluencersHired />
            </div>
          </div>
          <div className="flex flex-row gap-10 mt-10">
            <div className="w-1/2">
              <InfluencersGender />
            </div>
            <div className="w-1/2">
              <InfluencerLocation />
            </div>
          </div>
          <div className="flex flex-row gap-10 mt-10">
            <div className="w-1/2">
              <TopInfluencer />
            </div>
            <div className="w-1/2">
              <TopInfluencerReach />
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </Layout>
  );
}

function InfluencerInfo() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getInfluencerInfo());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading) {
    return (
      <div className="customDropShadowNoHover p-5 h-full text-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="customDropShadowNoHover p-5 h-full">
      <div className="text-lg font-semibold">Total Influencers</div>
      <div className="blueBold">{formatNumber(data.total_influencers)}</div>
      <div className="text-lg font-semibold">Active Influencers</div>
      <div className="blueBold">{formatNumber(data.active_influencers)}</div>
      <div className="text-lg font-semibold">Total Reach</div>
      <div className="blueBold">{formatNumber(data.total_reach)}</div>
      <div className="text-lg font-semibold">Current Reach</div>
      <div className="blueBold">{formatNumber(data.current_reach)}</div>
    </div>
  );
}

function TopInfluencer() {
  const dispatch = useDispatch();
  const [influencers, setInfluencers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    setLoading(false);
    const resp = await dispatch(actions.campaign.getTopInfluencer());
    if (resp && resp.length > 0) {
      setInfluencers(resp.slice(0, 6));
    }
    setLoading(false);
    setLoaded(true);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (influencers.length === 0 && loaded === true) {
    return (
      <div className="h-full">
        <div className="font-bold text-lg pb-1 mb-3">
          Top Influencers By Followers
        </div>
        <div className="customDropShadowNoHover h-full">
          <NoCampaigns />
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="font-bold text-lg pb-1 mb-3">
        Top Influencers By Followers
      </div>
      <div className="flex flex-col gap-5 font-bold text-md ellipsis p-4 h-full customDropShadowNoHover">
        {loading && <Loader />}
        {influencers.map((d, i) => {
          return (
            <Link key={i} href={`/influencer/${d.influencer_id}`}>
              <div className="flex flex-row gap-3 items-center cursor-pointer">
                <div className="h-8 w-8 rounded-full overflow-hidden">
                  <UserImg
                    image={d.image}
                    src={d.image}
                    alt={d.id}
                    className="object-cover w-8 h-8"
                  />
                </div>
                <div className="flex-1">{d.name}</div>
                <div>{shortNumber(d.followers)}</div>
              </div>
              {/*<div className="cursor-pointer">{d.image}</div>*/}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

function TopInfluencerReach() {
  const dispatch = useDispatch();
  const [influencers, setInfluencers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    setLoading(false);
    const resp = await dispatch(actions.campaign.getTopInfluencerReach());
    if (resp && resp.length > 0) {
      setInfluencers(resp.slice(0, 6));
    }
    setLoading(false);
    setLoaded(true);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (influencers.length === 0 && loaded === true) {
    return (
      <div className="h-full">
        <div className="font-bold text-lg pb-1 mb-3">
          Top Influencers By Reach
        </div>
        <div className="customDropShadowNoHover h-full">
          <NoCampaigns />
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="font-bold text-lg pb-1 mb-3">
        Top Influencers By Reach
      </div>
      <div className="flex flex-col gap-5 font-bold text-md ellipsis p-4 h-full customDropShadowNoHover">
        {loading && <Loader />}
        {influencers.map((d, i) => {
          return (
            <Link key={i} href={`/influencer/${d.influencer_id}`}>
              <div className="flex flex-row gap-3 items-center cursor-pointer">
                <div className="h-8 w-8 rounded-full overflow-hidden">
                  <UserImg
                    image={d.image}
                    src={d.image}
                    alt={d.id}
                    className="object-cover w-8 h-8"
                  />
                </div>
                <div className="flex-1">{d.name}</div>
                <div>{shortNumber(d.reach)}</div>
              </div>
              {/*<div className="cursor-pointer">{d.image}</div>*/}
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export function InfluencerLocation({ titleCenter }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [mapData, setMapData] = useState({});
  const [mapVal, setMapVal] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getTopAudience());
    if (resp && !resp.error_code) {
      const loc = {};
      const map = {};
      let index = 0;

      //This is done to merge all cities data to state data so that we can show on map
      Object.keys(resp)
        .slice(0, 9)
        .map((s) => {
          const st = stateForCity(s);
          if (loc[st]) {
            loc[st] = loc[st] + resp[s];
          } else {
            loc[st] = resp[s];
            map[st] = colors()[index++];
          }
        });

      setMapData(map);
      setMapVal(loc);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="px-5">
      <div className="font-semibold text-lg">Influencers Location</div>
      <IndiaMap data={mapData} value={mapVal} />
    </div>
  );
}

function ReachByPlatform({ titleCenter }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getReachByPlatform());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: Object.keys(data),
    },
    series: Object.keys(data).map((d) => {
      return {
        name: d,
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'series',
        },
        data: [data[d]],
      };
    }),
  };

  return (
    <div className="">
      <div
        className={`font-semibold text-lg ${titleCenter ? 'text-center' : ''}`}
      >
        Reach By Platform
      </div>
      <ReactECharts echarts={echarts} option={option} />
    </div>
  );
}

export function InfluencersGender({ titleCenter }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getInfluencersGender());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const dt = Object.keys(data).slice(0, 5);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: 'category',
      data: dt,
    },
    series: [
      {
        type: 'bar',
        data: dt.map((d, i) => ({
          value: data[d],
          itemStyle: {
            color: colors()[i],
          },
        })),
      },
    ],
  };
  return (
    <div className="px-5">
      <div
        className={`font-semibold text-lg ${titleCenter ? 'text-center' : ''}`}
      >
        Influencers Gender
      </div>
      {data && Object.keys(data).length > 0 ? (
        <ReactECharts echarts={echarts} option={option} />
      ) : (
        <NoChartData />
      )}
    </div>
  );
}

export function InfluencersHired() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getInfluencersHired());
    if (resp && !resp.error_code) {
      if (!resp['Facebook']) {
        resp['Facebook'] = 0;
      }
      if (!resp['Instagram']) {
        resp['Instagram'] = 0;
      }
      if (!resp['YouTube']) {
        resp['YouTube'] = 0;
      }
      if (!resp['LinkedIn']) {
        resp['LinkedIn'] = 0;
      }

      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '10%',
      // left: 'center',
      orient: 'vertical',
      right: 'right',
    },
    series: [
      {
        name: 'Platform',
        type: 'pie',
        radius: ['45%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'right',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '16',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: Object.keys(data).map((d) => ({
          value: parseInt(data[d]),
          name: d,
        })),
      },
    ],
  };

  return (
    <div className="px-5">
      <div className="font-semibold text-lg">Influencers Hired</div>
      <ReactECharts echarts={echarts} option={option} />
    </div>
  );
}
