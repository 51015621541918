import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../display/Loader';
import FluentIcon from '../fluenticons/FluentIcon';

export default function OutlineButton(props) {
  const {
    icon,
    label,
    onClick,
    className,
    style,
    type,
    disabled,
    children,
    size = 2,
    loading,
    iconloading,
  } = props;

  // const TYPES = {
  //   primary:{
  //     bgColor: disabled ? 'bg-gray-200' : 'bg-brand-light',
  //     color: disabled ? 'text-gray-500' : 'text-gray-100'
  //   },
  //   normal: {
  //     bgColor: 'bg-gray-300',
  //     color: 'text-brand-dark'
  //   }
  // }
  //
  // const {bgColor, color} = TYPES[type || 'primary'];

  return (
    <button
      className={`relative whitespace-no-wrap font-bold text-brand-base border-1 border-brand-base bg-white text-left transition duration-500 rounded-20px p-${size} px-${
        size * 2
      } opacity-100 ${disabled ? '' : 'hover:opacity-75 border-gray-100'} ${
        className || ''
      }`}
      style={style}
      onClick={disabled ? () => {} : onClick}
    >
      {icon && (
        <span className={`${loading ? 'invisible' : ''}`}>
          {icon.prefix ? (
            <FontAwesomeIcon icon={icon} spin={iconloading} />
          ) : (
            <FluentIcon icon={icon} spin={iconloading} />
          )}
        </span>
      )}
      {label && <span className="ml-1">{label}</span>}
      {children && (
        <span
          className={`inline-block w-full ${icon && 'pr-4'} text-center ${
            icon ? 'ml-1' : ''
          } ${loading ? 'invisible' : ''}`}
        >
          {children}
        </span>
      )}
      <div
        className={`absolute top-0 h-full -ml-4 w-full flex flex-row ${
          loading ? '' : 'invisible'
        }`}
      >
        <div className="self-center w-full text-center">
          <Loader />
        </div>
      </div>
    </button>
  );
}
