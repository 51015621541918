import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../layouts';
import Widget from '../../components/widget';
import actions from '../../services/redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { formatCurrency, formatNumber } from '../../functions/numbers';
import OutlineButton from '../../components/action/OutlineButton';
import { useRouter } from 'next/router';
import moment from 'moment';
import { colors, isMobile, round2, stateForCity } from '../../services/util';
import Notifications from '../../components/notifications/Notifications';
import ReactSwipe from 'react-swipe';
import {
  fiArrowCircleLeft,
  fiArrowCircleRight,
  fiCalligraphyPen,
  fiCaretDown,
  fiContactCard,
  fiEdit,
  fiImage,
  fiMap,
  fiPeople,
  fiPhone,
} from '../../components/fluenticons';
import FluentIcon from '../../components/fluenticons/FluentIcon';
import StarRating from '../../components/ui-elements/StarRating';
import { allPlatformsNames } from '../../platforms';
import SocialBlock from '../../components/blocks/SocialBlock';
import CampaignCardV4 from '../../components/ui-elements/CampaignCardV4';
import NoCampaigns from '../../components/campaign/NoCampaigns';
import { parseEachCampaignData } from '../../services/mapping';
import * as echarts from 'echarts/dist/echarts.js';
import ReactECharts from 'echarts-for-react';
import Link from 'next/link';
import { CircularBadge } from '../../components/badges';
import Loader from '../../components/display/Loader';
import Button from '../../components/action/Button';
import {
  getCampaignInfo,
  getTopAudience,
} from '../../services/redux/modules/campaign';
import IndiaMap from '../../components/charts/IndiaMap';
import { OverviewFilterView } from './brand-dashboard';
import NoChartData from '../../components/charts/NoChartData';

export default function BrandDashboardCampaigns(props) {
  const { wallet, user, notifications } = useSelector(
    (state) => ({
      wallet: state.wallet,
      user: state.user,
      notifications: state.common.notifications,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.wallet.getMyWallet());
  }, []);

  const router = useRouter();
  const viewAll = () => {
    router.push('/wallet');
  };

  const items = [
    { name: 'Overview', url: '/dashboard' },
    { name: 'Campaign Overview', url: '/dashboard/campaign' },
    { name: 'Influencer Overview', url: '/dashboard/influencer' },
    { name: 'Transaction Overview', url: '/dashboard/transaction' },
  ];
  const [hidden, setHidden] = useState(true);
  const dropdownRef = React.createRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        (dropdownRef.current && dropdownRef.current.contains(event.target))
      ) {
        return false;
      }
      setHidden(!hidden);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <Layout title="Overview" mod="Overview">
      <div className="flex flex-row justify-center flex-wrap w-full">
        <div className="flex flex-col w-full">
          <OverviewFilterView />
          <div className="flex flex-row gap-10">
            <div className="w-1/2">
              <CampaignInfo />
            </div>
            <div className="w-1/2">
              <ReachByPlatform />
            </div>
          </div>
          <div className="flex flex-row gap-10 mt-10">
            <div className="w-1/2">
              <ReachByGender />
            </div>
            <div className="w-1/2">
              <TransactionByPlatform />
            </div>
          </div>
          <div className="flex flex-row gap-10 mt-10">
            <div className="w-1/2">
              <CampaignByCollaboration />
            </div>
            <div className="w-1/2">
              <MediaByType />
            </div>
          </div>
          <div className="flex flex-row gap-10 mt-10">
            <div className="w-1/2">
              <TopHashtags />
            </div>
            <div className="w-1/2">
              <TopAudience />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function CampaignInfo() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getCampaignInfo());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loading) {
    return (
      <div className="customDropShadowNoHover p-5 h-full text-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="customDropShadowNoHover p-5 h-full">
      <div className="text-lg font-semibold">Total Campaigns</div>
      <div className="blueBold">{formatNumber(data.campaigns_count)}</div>
      <div className="text-lg font-semibold">Avg. Budget / Campaign</div>
      <div className="blueBold">{formatNumber(data.avg_campaign_budget)}</div>
      <div className="text-lg font-semibold">Avg. Influencer / Campaigns</div>
      <div className="blueBold">{formatNumber(data.avg_influencer_count)}</div>
      <div className="text-lg font-semibold">Lifetime Reach</div>
      <div className="blueBold">{formatNumber(data.reach)}</div>
    </div>
  );
}

export function ReachByPlatform({ titleCenter }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getReachByPlatform());
    if (resp && !resp.error_code) {
      if (!resp['Facebook']) {
        resp['Facebook'] = 0;
      }
      if (!resp['Instagram']) {
        resp['Instagram'] = 0;
      }
      if (!resp['YouTube']) {
        resp['YouTube'] = 0;
      }
      if (!resp['LinkedIn']) {
        resp['LinkedIn'] = 0;
      }

      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const dt = Object.keys(data).slice(0, 5);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: dt,
    },
    series: [
      {
        type: 'bar',
        data: dt.map((d, i) => ({
          value: data[d],
          itemStyle: {
            color: colors()[i],
          },
        })),
      },
    ],
  };

  return (
    <div className="">
      <div
        className={`font-semibold text-lg ${titleCenter ? 'text-center' : ''}`}
      >
        Reach By Platform
      </div>
      <ReactECharts echarts={echarts} option={option} />
    </div>
  );
}

export function ReachByGender({ titleCenter }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getReachByGender());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const dt = Object.keys(data).slice(0, 5);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: 'category',
      data: dt,
    },
    series: [
      {
        type: 'bar',
        data: dt.map((d, i) => ({
          value: data[d],
          itemStyle: {
            color: colors()[i],
          },
        })),
      },
    ],
  };
  return (
    <div className="">
      <div
        className={`font-semibold text-lg ${titleCenter ? 'text-center' : ''}`}
      >
        Reach By Gender
      </div>
      {data && Object.keys(data).length > 0 ? (
        <ReactECharts echarts={echarts} option={option} />
      ) : (
        <div>
          <NoChartData />
        </div>
      )}
    </div>
  );
}

export function TopAudience({ titleCenter }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [mapData, setMapData] = useState({});
  const [mapVal, setMapVal] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getTopAudienceReach());
    if (resp && !resp.error_code) {
      const loc = {};
      const map = {};
      let index = 0;

      let total = 0;
      //This is done to merge all cities data to state data so that we can show on map
      Object.keys(resp).map((s) => {
        const st = stateForCity(s);
        console.log(st, s);
        if (loc[st]) {
          loc[st] = loc[st] + resp[s];
        } else {
          loc[st] = resp[s];
          map[st] = colors()[index++];
        }
        total += resp[s];
      });

      const locd = {};
      Object.keys(loc).map((l) => {
        if (loc[l] === 0) {
          delete map[l];
        } else if (total > 0) {
          locd[l] = round2((loc[l] / total) * 100) + '%';
        } else {
          delete map[l];
        }
      });

      setMapData(map);
      setMapVal(locd);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  // console.log(mapData, mapVal);
  return (
    <div className="px-5">
      <div className="font-semibold text-lg">Top Audience</div>
      <IndiaMap data={mapData} value={mapVal} />
    </div>
  );
}

export function TopHashtags() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getTopHashtags());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const dts = Object.keys(data).sort((a, b) => {
    if (data[a] > data[b]) {
      return -1;
    } else if (data[a] < data[b]) {
      return 1;
    } else {
      return 0;
    }
  });

  const dt = dts.slice(0, 5);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: 'category',
      data: dt,
    },
    series: [
      {
        type: 'bar',
        data: dt.map((d, i) => ({
          value: data[d],
          itemStyle: {
            color: colors()[i],
          },
        })),
      },
    ],
  };

  return (
    <div className="px-5">
      <div className="font-semibold text-lg">Top Hashtags</div>
      {data && Object.keys(data).length > 0 ? (
        <ReactECharts echarts={echarts} option={option} />
      ) : (
        <NoChartData />
      )}
    </div>
  );
}

export function TransactionByPlatform() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getTransactionByPlatform());
    if (resp && !resp.error_code) {
      if (!resp['Facebook']) {
        resp['Facebook'] = 0;
      }
      if (!resp['Instagram']) {
        resp['Instagram'] = 0;
      }
      if (!resp['YouTube']) {
        resp['YouTube'] = 0;
      }
      if (!resp['LinkedIn']) {
        resp['LinkedIn'] = 0;
      }

      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '10%',
      // left: 'center',
      orient: 'vertical',
      right: 'right',
    },
    series: [
      {
        name: 'Spends',
        type: 'pie',
        radius: ['65%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'right',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '16',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: Object.keys(data).map((d) => ({
          value: parseInt(data[d]),
          name: d,
        })),
      },
    ],
  };

  return (
    <div className="px-5">
      <div className="font-semibold text-lg">Transaction By Platform</div>
      <ReactECharts echarts={echarts} option={option} />
    </div>
  );
}

export function CampaignByCollaboration() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getCampaignByCollaboration());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '10%',
      // left: 'center',
      orient: 'vertical',
      right: 'right',
    },
    series: [
      {
        name: 'Collaboration Type',
        type: 'pie',
        radius: ['45%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'right',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '16',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: Object.keys(data).map((d) => ({
          value: parseInt(data[d]),
          name: d,
        })),
      },
    ],
  };

  return (
    <div className="px-5">
      <div className="font-semibold text-lg">Collaboration Type</div>
      <ReactECharts echarts={echarts} option={option} />
    </div>
  );
}

export function MediaByType() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadData = async () => {
    setLoading(true);
    const resp = await dispatch(actions.campaign.getMediaByType());
    if (resp && !resp.error_code) {
      setData(resp);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const option = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '10%',
      // left: 'center',
      orient: 'vertical',
      right: 'right',
    },
    series: [
      {
        name: 'Platform',
        type: 'pie',
        radius: [20, 120],
        center: ['50%', '50%'],
        roseType: 'area',
        itemStyle: {
          borderRadius: 8,
        },
        label: {
          show: false,
          position: 'right',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '16',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: Object.keys(data).map((d) => ({
          value: parseInt(data[d]),
          name: d,
        })),
      },
    ],
  };

  return (
    <div className="px-5">
      <div className="font-semibold text-lg">Unique Content Generated</div>
      <ReactECharts echarts={echarts} option={option} />
    </div>
  );
}
