import React from 'react';

export default function ColorIcon(props) {
  const { size, icon, grayscale, className } = props;

  const style = grayscale ? { filter: 'grayscale(90%)' } : {};
  return (
    <div className={`w-${size || '4'} h-${size || '4'} ${className}`}>
      <img
        className="object-cover w-full h-full filter grayscale blur-md contrast-200"
        style={style}
        src={`/icons/color/${icon ? icon.toLowerCase() : 'user'}.svg`}
      />
    </div>
  );
}
