import React from 'react';

export default function NoChartData() {
  return (
    <div
      className="flex flex-col gap-5 font-bold text-md ellipsis p-4"
      style={{ height: '90%' }}
    >
      <div className="flex-1"></div>
      <div className="text-center">
        {/*<div className="mt-5 mb-5">No Campaigns Found</div>*/}
        <img
          src="/images/illustration/no_influencers_brand.png"
          width="50%"
          className="m-auto"
          style={{ maxWidth: '300px' }}
        />
      </div>
      <div className="flex-1"></div>
    </div>
  );
}
