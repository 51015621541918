import React from 'react';
import { loadTokens, ls } from './mapping';
// import users from "../json/users.json";

// const BASE_URL = '/api';
const BASE_URL = 'https://dev-api.infloso.com';

export function client(url, params, authenticated) {
  if (!params) {
    params = {};
  }

  // if(BASE_URL !== '/api') {
  //   params.credentials = 'include';
  // }

  if (!params.headers) {
    params.headers = {
      'Content-Type': 'application/json',
    };
  }

  // params.credentials =  'omit';

  if (authenticated) {
    const tokens = loadTokens();
    if (tokens && tokens.access) {
      //Access token is valid
      params.headers.Authorization = 'Bearer ' + tokens.access;
    } else if (tokens && tokens.refresh) {
      //
      // params.headers.Authorization = 'Bearer ' + tokens.refresh;
      // const resp = await fetch('/inflosousers/login/refresh',{method:'POST',body:JSON.stringify({refresh:tokens.refresh})})
      //   .then(response => {return response.json()});
      // ls.setItem('access',resp.access);
      // params.headers.Authorization = 'Bearer ' + resp.access;
    } else {
    }
  }

  // params.headers["Content-Type"] =  "application/json";
  // params.mode = 'no-cors';
  //

  const p = new Promise((resolve, reject) => {
    let nurl = url;
    // if(params.method === "GET" || url.indexOf('filter') > -1) {
    //   nurl = BASE_URL + url;
    // }else{
    //   nurl = '/api' + url;
    // }
    // if(BASE_URL !== '/api' && url.indexOf('/infloso') === 0) {
    //   nurl = BASE_URL + url;
    // }else{
    //   nurl = '/api' + url;
    // }
    try {
      fetch(nurl, params)
        .then(async (response) => {
          let data = null;
          let error = null;

          try {
            data = await response.json();
          } catch (e) {
            error = 'Invalid response from server';
          }

          if (
            response.status === 200 ||
            response.status === 404 ||
            response.status === 400
          ) {
            return { status: response.status, data: data };
          } else {
            return { status: response.status, error: data || error };
            //   return Promise.reject(response);
          }
        })
        .then((r) => {
          if (r.data) {
            resolve(r.data);
          } else {
            return reject(r.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } catch (e) {
      reject(e);
    }
  });

  return p;

  // return promise;
  // return new Promise(function(resolve, reject){
  //   resolve(users[0]);
  // })
}

export async function fileUpload(file, url, data, cb) {
  const p = new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    if (data) {
      Object.keys(data).map((d) => {
        formData.append(d, data[d]);
      });
    }

    const tokens = loadTokens();
    fetch(url, {
      method: 'POST',
      body: formData,
      headers: { Authorization: 'Bearer ' + tokens.access },
    })
      .then((r) => r.json())
      .then((d) => {
        resolve(d);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return p;
}

export async function fileUploadProgress(file, url, data, cb) {
  const tokens = loadTokens();
  const formData = new FormData();
  formData.append('file', file);
  if (data) {
    Object.keys(data).map((d) => {
      formData.append(d, data[d]);
    });
  }

  const xhr = new XMLHttpRequest();
  const p = new Promise((resolve, reject) => {
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        cb(event.loaded / event.total);
      }
    });
    // xhr.addEventListener('progress', (event) => {
    //   if (event.lengthComputable) {
    //     console.log('download progress:', event.loaded / event.total);
    //     downloadProgress.value = event.loaded / event.total;
    //   }
    // });
    xhr.addEventListener('loadend', () => {
      resolve(xhr.readyState === 4 && xhr.status === 200);
    });
    xhr.addEventListener('error', () => {
      reject();
    });
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + tokens.access);
    xhr.send(formData);
  });

  return p;
}
