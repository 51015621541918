import React, { useEffect, useState } from 'react';
import NavItems from './nav-items';
import { shallowEqual, useSelector } from 'react-redux';
import Button from '../action/Button';
import useLocalStorage from '../../hooks/useLocalStorage';

const Sidebar = ({ mod }) => {
  const { ui, collapsed, nav, user } = useSelector(
    (state) => ({
      collapsed: state.ui.collapsed,
      ui: state.ui,
      user: state.user,
      nav: state.navigation,
    }),
    shallowEqual
  );
  const [navigation, setNavigation] = useState(nav);
  useEffect(() => {
    setNavigation(nav);
  }, [nav]);

  const [tabs, setTabs] =
    !mod == '' ? useState(mod.toLowerCase()) : useState('dashboard');

  const [access, setAccess] = useLocalStorage('access');
  const [admin_access, setAdminAccess] = useLocalStorage('admin_access');
  const [refresh, setRefresh] = useLocalStorage('refresh');
  const [admin_refresh, setAdminRefresh] = useLocalStorage('admin_refresh');

  const backAsAdmin = () => {
    setAdminAccess((admin_access) => {
      if (admin_access) {
        setAccess(admin_access);
      }
      return null;
    });
    setAdminRefresh((admin_refresh) => {
      if (admin_refresh) {
        setRefresh(admin_refresh);
      }
      return null;
    });

    location.reload();
  };

  return (
    <div
      className={`left-sidebar-2 pl-3 lg:w-full text-sm overflow-hidden transition-all ease-in-out duration-500 bg-white font-poppins h-full font-semibold hidden md:block`}
      style={{ color: '#171725' }}
    >
      <div className="-mt-2 text-center lg:text-left lg:pl-6">
        <p className="font-semibold text-gray-500 text-sm tracking-widest">
          &nbsp;
        </p>
      </div>
      <NavItems navs={navigation[0].items} tabs={tabs} mod={mod} />

      {/* <div class=" mt-6 cursor-pointer" onClick={()=>{$crisp.push(['do', 'chat:show']);$crisp.push(['do', 'chat:open']);}}><div class="flex items-center justify-start lg:pl-6"> */}
        {/* <div class=" text-gray-500 undefined"><svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="inline-block  "><path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.96 9.96 0 0 1-4.587-1.112l-3.826 1.067a1.25 1.25 0 0 1-1.54-1.54l1.068-3.823A9.96 9.96 0 0 1 2 12C2 6.477 6.477 2 12 2Zm0 1.5A8.5 8.5 0 0 0 3.5 12c0 1.47.373 2.883 1.073 4.137l.15.27-1.112 3.984 3.987-1.112.27.15A8.5 8.5 0 1 0 12 3.5Zm0 12a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm0-8.75a2.75 2.75 0 0 1 2.75 2.75c0 1.01-.297 1.574-1.051 2.359l-.169.171c-.622.622-.78.886-.78 1.47a.75.75 0 0 1-1.5 0c0-1.01.297-1.574 1.051-2.359l.169-.171c.622-.622.78-.886.78-1.47a1.25 1.25 0 0 0-2.493-.128l-.007.128a.75.75 0 0 1-1.5 0A2.75 2.75 0 0 1 12 6.75Z" fill="currentColor"></path></svg></div><div class="flex flex-row items-center flex-1"><p class="ml-3 flex-1 text-lg hidden font-bold lg:block ">Chat with Us</p></div></div></div> */}


      {admin_access ? (
        <div className="text-center mt-10">
          <Button onClick={backAsAdmin}>Back as Admin</Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Sidebar;
