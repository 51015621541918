import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../layouts';
import Widget from '../../components/widget';
import actions from '../../services/redux/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  formatCurrency,
  formatNumber,
  roundNext10,
  shortNumber,
} from '../../functions/numbers';
import OutlineButton from '../../components/action/OutlineButton';
import { useRouter } from 'next/router';
import moment from 'moment';
import { isMobile } from '../../services/util';
import Notifications from '../../components/notifications/Notifications';
import ReactSwipe from 'react-swipe';
import {
  fiArrowCircleLeft,
  fiArrowCircleRight,
  fiCalligraphyPen,
  fiCaretDown,
  fiContactCard,
  fiImage,
  fiMap,
  fiPeople,
  fiPhone,
} from '../../components/fluenticons';
import FluentIcon from '../../components/fluenticons/FluentIcon';
import NoCampaigns from '../../components/campaign/NoCampaigns';
import { parseEachCampaignData } from '../../services/mapping';
import * as echarts from 'echarts/dist/echarts.js';
import ReactECharts from 'echarts-for-react';
import Link from 'next/link';
import Loader from '../../components/display/Loader';
import Button from '../../components/action/Button';
import {
  CampaignByCollaboration,
  MediaByType,
  ReachByGender,
  ReachByPlatform,
  TopAudience,
  TopHashtags,
  TransactionByPlatform,
} from './campaign';
import UserImg from '../../components/user/UserImg';
import {
  InfluencerLocation,
  InfluencersGender,
  InfluencersHired,
} from './influencer';
import {
  CostByGender,
  ExpenditureByCampaign,
  ExpenditureByLocation,
  LatestTransactions,
  PlatformWiseExpenditure,
} from './transaction';
import CampaignCardV4 from '../../components/ui-elements/CampaignCardV4';

export default function BrandDashboard(props) {
  const { wallet, user, notifications } = useSelector(
    (state) => ({
      wallet: state.wallet,
      user: state.user,
      notifications: state.common.notifications,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.wallet.getMyWallet());
  }, []);

  const router = useRouter();
  const viewAll = () => {
    router.push('/wallet');
  };

  return (
    <Layout title="Overview" mod="Overview">
      <div className="flex flex-row justify-center flex-wrap w-full">
        <div className="flex flex-col w-full">
          <OverviewFilterView />
          <ProfilePrompts />
          <ProfileInfo />
          <div className="flex flex-row gap-10 mb-5 mt-10">
            <div className="flex-1">
              <ActiveCampaigns />
            </div>
            <div className="">
              <CampaignAnalytics />
            </div>
          </div>
          <div className="flex flex-row gap-10 mb-5 mt-10">
            <div className="flex-1">
              <TopInfluencer />
            </div>
            <div className="">
              <InfluencerAnalytics />
            </div>
          </div>
          <div className="flex flex-row gap-10 mb-5 mt-10">
            <div className="flex-1">
              <LatestTransactionsBox />
            </div>
            <div className="">
              <TransactionAnalytics />
            </div>
          </div>
          <br />
          <br />
          <br />
        </div>
      </div>
    </Layout>
  );
}

export function OverviewFilterView() {
  const items = [
    { name: 'Overview', url: '/dashboard' },
    { name: 'Campaigns', url: '/dashboard/campaign' },
    { name: 'Influencers', url: '/dashboard/influencer' },
    { name: 'Transactions', url: '/dashboard/transaction' },
  ];

  const [hidden, setHidden] = useState(true);
  const dropdownRef = React.createRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        hidden ||
        (dropdownRef.current && dropdownRef.current.contains(event.target))
      ) {
        return false;
      }
      setHidden(!hidden);
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);

  const router = useRouter();

  const selected = items.filter((i) => i.url === router.pathname)[0].name;

  return (
    <div className="flex flex-row items-center mb-5">
      <div className="text-semibold text-lg">Filter View:</div>
      <div className="relative">
        <div
          className="font-bold text-brand-base cursor-pointer text-lg ml-4"
          onClick={() => setHidden(!hidden)}
        >
          {selected}
          <FluentIcon icon={fiCaretDown} />
        </div>
        <div
          ref={dropdownRef}
          style={{ right: '-8px', top: '30px', boxShadow: 'none' }}
          className={`navbar-dropdowns dropdown backdrop-blur bg-whitex dropdown-left w-40 z-10 ${
            hidden ? '' : 'open'
          }`}
        >
          <div className="w-40 dropdown-contents">
            <div className="flex flex-col w-full">
              {items.map((item, i) => {
                const lprops = { key: i };
                if (item.onClick) {
                  // lprops.onClick = item.onClick;
                  lprops.href = item.url || '/';
                } else if (item.url) {
                  lprops.href = item.url || '/';
                }

                return (
                  <Link href={item.url} key={i} {...lprops}>
                    <a
                      className="w-full flex items-center justify-start px-4 py-2 font-bold bg-white m-2 customShadow text-sm children-x-2 rounded-10px "
                      href={item.url}
                    >
                      <span className="text-left flex-1">{item.name}</span>
                    </a>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Spends() {
  const { user, page } = useSelector(
    (state) => ({
      user: state.user,
      page: state.page,
    }),
    shallowEqual
  );

  const spends =
    page && page.campaigns && page.campaigns.length > 0
      ? page.campaigns.reduce((prev, curr) => {
          return prev + parseInt(curr.campaign_budget);
        }, 0)
      : 0;

  const option_2 = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '10%',
      // left: 'center',
      orient: 'vertical',
      right: 'right',
    },
    series: [
      {
        name: 'Spends',
        type: 'pie',
        radius: ['65%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'right',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '16',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: (page.campaigns || []).map((r) => ({
          value: parseInt(r.campaign_budget),
          name: r.campaign_display_name,
        })),
      },
    ],
  };

  return (
    <div className="mt-10">
      <div className="font-bold text-lg border-b-1 border-black pb-1 mb-3">
        Spends
      </div>
      <div>
        <div className="text-lg">INR {spends}</div>
        <div className="text-gray-600">Lifetime Spend</div>
      </div>
      <div>
        <ReactECharts echarts={echarts} option={option_2} />
      </div>
    </div>
  );
}

function Earnings() {
  const dispatch = useDispatch();
  const [earning, setEarning] = useState(0);
  const [earnings, setEarnings] = useState([]);

  const loadEarnings = async () => {
    const resp = await dispatch(actions.wallet.getMyEarnings());
    if (resp && resp.length > 0) {
      let er = 0;
      resp.map((e) => {
        er = er + parseInt(e.transaction_amount);
      });
      setEarning(er);
      setEarnings(resp);
    }
  };

  useEffect(() => {
    loadEarnings();
  }, []);

  const option_2 = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      bottom: '0%',
      left: 'center',
    },
    series: [
      {
        name: 'Platform',
        type: 'pie',
        radius: ['65%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '16',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: earnings
          .filter((r) => parseInt(r.transaction_amount) > 0)
          .map((r) => ({
            value: parseInt(r.transaction_amount),
            name: r.transaction_desc.replace('Income from ', ''),
          })) /*[
          { value: 1048, name: 'Facebook' },
          { value: 735, name: 'YouTube' },
          { value: 580, name: 'Twitter' },
          { value: 484, name: 'Others' },
        ]*/,
      },
    ],
  };

  return (
    <div className="mt-10">
      <div className="font-bold text-lg border-b-1 border-black pb-1 mb-3">
        Earnings
      </div>
      <div>
        <div className="text-lg">INR {earning}</div>
        <div className="text-gray-600">Lifetime Earning</div>
      </div>
      <div>
        <ReactECharts echarts={echarts} option={option_2} />
      </div>
    </div>
  );
}
function DNotifications() {
  const { direction, notifications } = useSelector(
    (state) => ({
      direction: state.ui.direction,
      notifications: state.common.notifications,
    }),
    shallowEqual
  );

  const newNotifications = (notifications || [])
    .filter((n) => !n.is_read)
    .slice(0, 8);

  if (notifications && newNotifications.length === 0) {
    return (
      <div className="mt-10">
        <div className="font-bold text-lg border-b-1 border-black pb-1 mb-3">
          Recent Activity
        </div>
        {/*<div className="mb-5 text-center">No new notification</div>*/}
        <img
          src="/images/illustration/no_new_notification.svg"
          width="70%"
          style={{ maxWidth: '400px' }}
          className="m-auto"
        />
      </div>
    );
  } else {
    return (
      <div>
        <div className="mt-10 font-bold text-lg border-b-1 border-black pb-1 mb-3">
          Recent Activity
        </div>
        <div className="cursor-pointer">
          <Notifications notifications={newNotifications} />
        </div>
      </div>
    );
  }
}

function CampaignAnalytics() {
  const router = useRouter();

  const go = () => {
    router.push('/dashboard/campaign');
  };

  let reactSwipeEl;
  const prev = () => {
    reactSwipeEl.prev();
  };

  const next = () => {
    reactSwipeEl.next();
  };

  const graphs = [
    { content: <ReachByPlatform /> },
    { content: <ReachByGender /> },
    { content: <TopHashtags /> },
    { content: <TransactionByPlatform /> },
    { content: <CampaignByCollaboration /> },
    { content: <MediaByType /> },
    { content: <TopAudience /> },
  ];

  return (
    <div>
      <div className="text-right">
        <Button onClick={go}>Campaign Analytics</Button>
      </div>

      <div
        className="flex flex-row items-start text-center mx-5 mt-4"
        style={{ width: '500px' }}
      >
        <div className="cursor-pointer" onClick={prev}>
          <FluentIcon icon={fiArrowCircleLeft} />
        </div>
        <ReactSwipe
          className="carousel"
          continuous={true}
          ref={(el) => (reactSwipeEl = el)}
        >
          {graphs.map((p, i) => {
            return (
              <div key={i} style={{ fmaxWidth: '500px' }}>
                {p.content}
              </div>
            );
          })}
        </ReactSwipe>
        <div className="cursor-pointer" onClick={next}>
          <FluentIcon icon={fiArrowCircleRight} />
        </div>
      </div>
    </div>
  );
}

function TransactionAnalytics() {
  const router = useRouter();

  const go = () => {
    router.push('/dashboard/transaction');
  };

  let reactSwipeEl;
  const prev = () => {
    reactSwipeEl.prev();
  };

  const next = () => {
    reactSwipeEl.next();
  };

  const graphs = [
    { content: <ExpenditureByCampaign /> },
    { content: <PlatformWiseExpenditure /> },
    { content: <CostByGender /> },
    { content: <ExpenditureByLocation /> },
    // { content: <TopAudience /> },
  ];

  return (
    <div>
      <div className="text-right">
        <Button onClick={go}>Transaction Analytics</Button>
      </div>

      <div
        className="flex flex-row items-start text-center mx-5 mt-4"
        style={{ width: '500px' }}
      >
        <div className="cursor-pointer" onClick={prev}>
          <FluentIcon icon={fiArrowCircleLeft} />
        </div>
        <ReactSwipe
          className="carousel"
          continuous={true}
          ref={(el) => (reactSwipeEl = el)}
        >
          {graphs.map((p, i) => {
            return (
              <div key={i} style={{ fmaxWidth: '500px' }}>
                {p.content}
              </div>
            );
          })}
        </ReactSwipe>
        <div className="cursor-pointer" onClick={next}>
          <FluentIcon icon={fiArrowCircleRight} />
        </div>
      </div>
    </div>
  );
}

function InfluencerAnalytics() {
  const router = useRouter();

  const go = () => {
    router.push('/dashboard/influencer');
  };

  let reactSwipeEl;
  const prev = () => {
    reactSwipeEl.prev();
  };

  const next = () => {
    reactSwipeEl.next();
  };

  const graphs = [
    { content: <InfluencersGender /> },
    { content: <InfluencersHired /> },
    { content: <InfluencerLocation /> },
  ];

  return (
    <div>
      <div className="text-right">
        <Button onClick={go}>Influencer Analytics</Button>
      </div>

      <div
        className="flex flex-row items-start text-center mx-5 mt-4"
        style={{ width: '500px' }}
      >
        <div className="cursor-pointer" onClick={prev}>
          <FluentIcon icon={fiArrowCircleLeft} />
        </div>
        <ReactSwipe
          className="carousel"
          continuous={true}
          ref={(el) => (reactSwipeEl = el)}
        >
          {graphs.map((p, i) => {
            return (
              <div key={i} style={{ fmaxWidth: '500px' }}>
                {p.content}
              </div>
            );
          })}
        </ReactSwipe>
        <div className="cursor-pointer" onClick={next}>
          <FluentIcon icon={fiArrowCircleRight} />
        </div>
      </div>
    </div>
  );
}

function ActiveCampaigns() {
  const dispatch = useDispatch();
  const [campaigns, setCampaigns] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user } = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual
  );

  const states = [
    'Approved',
    'Submit Content',
    'Approve Content',
    'Reject Content',
    'Active',
    'Submit Link',
    'Approve Link',
    'Reject Link',
    'Submit Insight',
    'Insight Approved',
    'Insight Rejected',
    'Completed',
  ];

  const loadCampaigns = async () => {
    setLoading(true);
    if (user.type === 'influencer') {
      const resp = await dispatch(
        actions.campaign.getMyCampaignApplicationsByStatus(null, 'all')
      );
      if (resp && resp.results && resp.results.length > 0) {
        setCampaigns(
          resp.results
            // .filter((a) => a.influencer.influencer_id === user.influencer_id)
            .filter((a) => states.indexOf(a.application_status) > -1)
            .filter(
              (value, index, self) =>
                self.map((x) => x.campaign.id).indexOf(value.campaign.id) ==
                index
            )
            .map((a) => parseEachCampaignData(a.campaign))
        );
      }
    } else {
      const resp = await dispatch(
        actions.page.loadCampaigns(
          {
            query: `(campaign_state:Active OR campaign_state:Approved OR campaign_state:Completed OR campaign_state:Archived) AND (brand.user.id:${user.id})`,
          },
          null,
          100
        )
      );
      if (resp && resp.length > 0) {
        setCampaigns(
          resp
            // .filter((a) => a.influencer.influencer_id === user.influencer_id)
            .filter((a) => a.campaign_state === 'Active')
            .map((c) => parseEachCampaignData(c))
        );
      }
    }
    setLoaded(true);
    setLoading(false);
  };

  useEffect(() => {
    loadCampaigns();
  }, []);

  const router = useRouter();
  const createCampaign = () => {
    router.push('/create-campaign');
  };

  if (campaigns.length === 0 && loaded === true) {
    return (
      <div className="h-full">
        <div className="font-bold text-lg pb-1 mb-3" style={{ width: '175px' }}>
          Active Campaigns
        </div>
        <div className="customDropShadowNoHover h-full flex flex-col p-4">
          <div className="flex-1"></div>
          <NoCampaigns />
          <div className="flex-1"></div>
          <div className="text-center">
            <Button onClick={createCampaign}>Create Campaign</Button>
          </div>
        </div>
      </div>
    );
  }

  let reactSwipeEl;
  const prev = () => {
    reactSwipeEl.prev();
  };

  const next = () => {
    reactSwipeEl.next();
  };

  return (
    <div className="h-full">
      <div className="font-bold text-lg pb-1 mb-3" style={{ width: '175px' }}>
        Active Campaigns
      </div>
      {loading && <Loader />}
      {!loading && (
        <div
          className="flex flex-row"
          style={{ height: '90%', width: '430px' }}
        >
          <div className="cursor-pointer mt-3" onClick={prev}>
            <FluentIcon icon={fiArrowCircleLeft} />
          </div>
          <ReactSwipe
            className="carousel flex-1"
            continuous={true}
            ref={(el) => (reactSwipeEl = el)}
          >
            {campaigns.map((d, i) => {
              return (
                <div
                  key={i}
                  className="inline-block p-6"
                  style={{ width: '400px' }}
                >
                  <CampaignCardV4 {...d} />
                </div>
              );
            })}
          </ReactSwipe>
          <div className="cursor-pointer mt-3" onClick={next}>
            <FluentIcon icon={fiArrowCircleRight} />
          </div>
        </div>
      )}
    </div>
  );
}

function LatestTransactionsBox() {
  return <LatestTransactions />;
}

function TopInfluencer() {
  const dispatch = useDispatch();
  const [influencers, setInfluencers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    setLoading(true);
    try {
      const resp = await dispatch(actions.campaign.getTopInfluencer());
      if (resp && resp.length > 0) {
        setInfluencers(resp.slice(0, 7));
      }
    } catch (e) {}
    setLoaded(true);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  if (influencers.length === 0 && loaded === true) {
    return (
      <div className="h-full">
        <div className="font-bold text-lg pb-1 mb-3">Top Influencers</div>
        <div
          className="flex flex-col gap-5 font-bold text-md ellipsis p-4 customDropShadowNoHover"
          style={{ height: '90%' }}
        >
          <div className="flex-1"></div>
          <div className="text-center">
            {/*<div className="mt-5 mb-5">No Campaigns Found</div>*/}
            <img
              src="/images/illustration/no_influencers_brand.png"
              width="50%"
              className="m-auto"
              style={{ maxWidth: '300px' }}
            />
          </div>
          <div className="flex-1"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="font-bold text-lg pb-1 mb-3">Top Influencers</div>
      <div
        className="flex flex-col gap-5 font-bold text-md ellipsis p-4 customDropShadowNoHover"
        style={{ height: '90%' }}
      >
        {loading && <Loader />}
        {influencers.map((d, i) => {
          return (
            <Link key={i} href={`/influencer/${d.influencer_id}`}>
              <div className="flex flex-row gap-3 items-center cursor-pointer">
                <div className="h-8 w-8 rounded-full overflow-hidden">
                  <UserImg
                    image={d.image}
                    src={d.image}
                    alt={d.id}
                    name={d.name}
                    gender={d.gender}
                    className="object-cover w-8 h-8"
                  />
                </div>
                <div className="flex flex-row w-full">
                  <div className="flex-1">{d.name}</div>
                  <div>{shortNumber(d.followers)}</div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

function ProfileInfo() {
  const [data, setData] = useState({});
  const { user } = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual
  );

  const profileInfo = [
    { name: 'Total Campaigns', count: formatNumber(data.campaigns_count || 0) },
    {
      name: 'Influencers Hired',
      count: formatNumber(data.total_influencers || 0),
    },
    {
      name: 'Lifetime Expenditure',
      count: formatNumber(
        data.lifetime_spend ? roundNext10(data.lifetime_spend) : 0
      ),
    },
    { name: 'Lifetime Reach', count: formatNumber(data.total_reach || 0) },
  ];

  const dispatch = useDispatch();
  const loadData = async () => {
    const [r1, r2, r3] = await Promise.all([
      dispatch(actions.campaign.getCampaignInfo()),
      dispatch(actions.campaign.getInfluencerInfo()),
      dispatch(actions.campaign.getTransactionInfo()),
    ]);

    const data = { ...r1, ...r2, ...r3 };
    setData(data);
  };

  useEffect(() => {
    loadData();
  }, []);

  let reactSwipeEl;
  const prev = () => {
    reactSwipeEl.prev();
  };

  const next = () => {
    reactSwipeEl.next();
  };

  if (isMobile()) {
    return (
      <div className="flex flex-row items-center">
        <div className="cursor-pointer" onClick={prev}>
          <FluentIcon icon={fiArrowCircleLeft} />
        </div>
        <ReactSwipe
          className="carousel"
          continuous={true}
          ref={(el) => (reactSwipeEl = el)}
        >
          {profileInfo.map((p, i) => {
            return (
              <div key={i} className="py-4 px-8">
                <EachProfileInfo data={p} key={i} />
              </div>
            );
          })}
        </ReactSwipe>
        <div className="cursor-pointer" onClick={next}>
          <FluentIcon icon={fiArrowCircleRight} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col md:flex-row gap-10">
      {profileInfo.map((p, i) => {
        return <EachProfileInfo key={i} data={p} />;
      })}
    </div>
  );
}

function EachProfileInfo({ data }) {
  return (
    <div className="p-4 rounded-10px bg-white flex-1 customDropShadowNoHover">
      <div className="flex flex-col text-center gap-1">
        <div className="blueBold">{data.count}</div>
        <div className="font-semibold text-sm self-center">{data.name}</div>
      </div>
    </div>
  );
}

function ProfilePrompts() {
  const { user } = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual
  );
  const profilePrompts = [
    {
      key: 'bio',
      name: 'Profile Bio',
      summary: 'Add bio to describe yourself.',
      buttonText: 'Add',
      icon: fiCalligraphyPen,
    },
    {
      key: 'name',
      name: 'Name',
      summary: 'Add your name.',
      buttonText: 'Add',
      icon: fiContactCard,
    },
    {
      key: 'image',
      name: 'Profile Photo',
      summary: 'People recognise you by your logo.',
      buttonText: 'Add',
      icon: fiImage,
    },
    {
      key: 'location',
      name: 'Public Location',
      summary: 'Location is visible to everyone.',
      buttonText: 'Add',
      icon: fiMap,
    },
    {
      key: 'mobile',
      name: 'Mobile Number',
      summary: 'Mobile number is required to verify identity.',
      buttonText: 'Add',
      icon: fiPhone,
    },
  ];

  if (user.usertype === 'Influencer') {
    profilePrompts.push({
      key: 'gender',
      name: 'Gender',
      summary: 'You get delivered right campaigns.',
      buttonText: 'Add',
      icon: fiPeople,
    });
  }

  const filteredProfilePrompts = profilePrompts.filter((p) => !user[p.key]);

  if (filteredProfilePrompts.length === 0) {
    return <></>;
  }

  const platFormRef = useRef();
  const scrollPlatformRight = () => {
    platFormRef.current.scrollBy(200, 0);
  };

  const scrollPlatformLeft = () => {
    platFormRef.current.scrollBy(-200, 0);
  };

  return (
    <div className="flex flex-row items-center w-full gap-3">
      <div
        className="-ml-4 flex-1 overflow-auto noscrollbar"
        ref={platFormRef}
        style={{ scrollBehavior: 'smooth', width: '100px' }}
      >
        <div className="flex text-xs mr-4 my-1 md:px-2 text-brand-dark cursor-default gap-4 transition-all">
          {filteredProfilePrompts.map((d, i) => {
            return <EachProfilePrompt data={d} key={i} />;
          })}
          <div className="w-20">&nbsp;</div>
        </div>
      </div>
      <div
        className={`abssolute cursor-pointer ${
          filteredProfilePrompts.length < 3 ? 'hidden' : ''
        }`}
        style={{ right: '-8px', bottom: '70px' }}
        onClick={scrollPlatformRight}
      >
        <FluentIcon icon={fiArrowCircleRight} size={24} />
      </div>
    </div>
  );
}

function EachProfilePrompt({ data }) {
  const router = useRouter();
  const goToProfile = () => {
    router.push('/profile');
  };

  return (
    <div
      className="mx-2 customDropShadowNoHover rounded-10px mb-8 p-4 flex flex-row gap-2"
      style={{ minWidth: '350px' }}
    >
      <div className="flex-initial md:flex-1 flex flex-row">
        <div className="flex-initial self-center mr-3 text-gray-700">
          <FluentIcon icon={data.icon} />
        </div>
        <div className="flex-1">
          <div className="font-medium">{data.name}</div>
          <div className="text-sm text-gray-600">{data.summary}</div>
        </div>
      </div>
      <div className="md:flex-initial w-full md:w-auto self-center text-left">
        <OutlineButton size={1} className="w-20" onClick={goToProfile}>
          {data.buttonText}
        </OutlineButton>
      </div>
    </div>
  );
}
